import React from 'react';

const FilterReference = ({ setShowReference, searchReference, setSearchReference }) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowReference(false)}></button>
					<h2>레퍼런스</h2>
				</section>

				<section
					className="fliter_list" 
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA', paddingBottom:'0' }}
				>
					<div
						className="searched_brands"
						style={{ backgroundColor: '#fff', padding: '6vw 12.222vw 0 6.667vw' }}
					>
						<div className="search_wrap" style={{ position: 'relative' }}>
							<input
								type="text"
								className="input_t"
								placeholder="레퍼런스를 입력해주세요."
								value={searchReference}
								onChange={(e) => setSearchReference(e.target.value)}
							/>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSearchReference('')}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowReference(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterReference;
