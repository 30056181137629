import React,{useEffect} from 'react';
import StoreImg from 'assets-pc/front-img/tablet_landing_store.svg';
import ChromeIcon from 'assets-pc/front-img/chrome-logo2.png';
import MicroEdgeIcon from 'assets-pc/front-img/microsoft-edge-logo.png';
// import 'assets-pc/css/common.css';
// import 'assets-pc/css/font.css';
// import 'assets-pc/css/layout.css';
import 'assets-pc/scss/common.scss';
// import 'assets-pc/css/frontEndAssets.css';
import 'assets-pc/css/frontEndAssets.scss';
import 'assets-pc/scss/font.scss';
import 'assets-pc/scss/layout.scss';

const UnserviceableBrowser = () => {
	useEffect(() => {
		let findRoot = document.getElementById('root');
		findRoot.style.height = '100vh';
	}, []);
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					width: '300px',
					height: '300px',
					position: 'relative',
					overflow: 'hidden',
					borderRadius: '50%',
					margin: '7%',
				}}
			>
				<img
					src={StoreImg}
					style={{
						width: '1000px',
						height: '800px',
						position: 'absolute',
						top: '-58%',
						left: '-119%',
					}}
				/>
			</div>
			<div style={{ fontSize: '27px' }}>최적의 브라우저 환경에서 하이시간을 만나보세요.</div>
			<div style={{ fontSize: '20px', marginTop: '20px' }}>
				원활한 서비스 이용을 위해서 아래 브라우저만 지원하고 있습니다.
			</div>
			<div style={{ fontSize: '20px' }}>최적의 환경에서 HiSiGAN의 최신 기능을 이용하세요.</div>
			<div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
				<a
					href="https://www.google.co.kr/chrome/?brand=JJTC&gclid=Cj0KCQjws-OEBhCkARIsAPhOkIbyS9WBnZ2XsYG-tVGIGY3JSFhPvq7v1jp3EwxYQGOD95Gvg1wmnvMaAhvOEALw_wcB&gclsrc=aw.ds"
					style={{
						height: '60px',
						width: '40%',
						border: '1px solid lightgray',
						marginRight: '5px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div style={{ width: '60px' }}>
						<img src={ChromeIcon} style={{ width: '70px' }} />
					</div>
					Chrome 다운로드
				</a>
				<a
					href="https://www.microsoft.com/ko-kr/edge"
					style={{
						height: '60px',
						width: '40%',
						border: '1px solid lightgray',
						marginLeft: '5px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img src={MicroEdgeIcon} style={{ width: '33px', marginRight: '9px' }} />
					Microsoft Edge 다운로드
				</a>
			</div>
		</div>
	);
};

export default UnserviceableBrowser;
