import { apiCall } from './apiCall';
import { SERVER_API_HOST } from 'common/environment';

export const getRollingApiList = () => {
	return apiCall(`${SERVER_API_HOST}/api/watch/rolling`, 'get', {});
};

export const getMainPopupApiList = () => {
	return apiCall(`${SERVER_API_HOST}/api/public/popup/list`, 'get', {});
};

export const getTopBrandApiList = () => {
	return apiCall(`${SERVER_API_HOST}/api/watch/top/brand/list`, 'get', {});
};

export const getSliderApiList = (page, offset) => {
	return apiCall(`${SERVER_API_HOST}/api/banner/list?page=0&offset=10`, 'get', {});
};

// export const postSliderApiList = (authToken) => {
// 	return apiCall(
// 		`${SERVER_API_HOST}/api/banner`,
// 		'post',
// 		{
// 			title: '배너타이틀',
// 			content: '배너 내용',
// 			photoUrl: 'http://photoUrl',
// 			deviceType: 'PC',
// 			link: 'http://link',
// 		},
// 		{
// 			authorization: authToken,
// 		},
// 	);
// };

///메인 모바일

export const searchWatchModelApi = (modelInput) => {
	return apiCall(
		`${SERVER_API_HOST}/api/filter/standard`,
		'post',
		{
			filters: [
				{
					type: 'INTEGRATED',
					keyword: modelInput,
				},
			],
		},
		{},
	);
};

// createdAt desc

export const getMobileMainRecentRegisterApi = () => {
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/list?&offset=4&property=createdAt&direction=desc`,
		'post',
		{
			state: 'SALE_REGISTERED',
		},
	);
};

export const getInstaListApi = () => {
	return apiCall(
		'https://graph.instagram.com/4184650071555559/media?fields=id,media_type,media_url,permalink,thumbnail_url,username,caption&access_token=IGQVJYb1BnX00zVE9UN3JXZAm1kTnUxX1FOQ0RjeEhEb19jcVA2YXBTd0JjOGxMa1Y1X2lVMGhCVGZALa1pFNUFiMFlkZAE9RVEpURWlqVXRrbzdWRUxIZAUhkREgzSWozb3BEdy1LZAGpR',
		'get',
	);
};
