import React from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from './Pagination';

const ReviewList = ({
	noticeData,
	setOneNoticeData,
	setOpenDetail,
	prevPage,
	paginate,
	nextPage,
	newPageNumbers,
	page,
}) => {
	const history = useHistory();

	const goToDetail = (data) => {
		// setOneNoticeData(data);
		// setOpenDetail(true);
		sessionStorage.setItem('noticePage', page);
		history.push(`/review/${data?.id}`);
	};

	/**
	 * new마크 계산
	 */

	const currentDate = new Date().getTime();
	return (
		<>
			<div className="notice_list">
				<ul>
					<li className="tit">
						<span>제목</span>
						<span>작성일</span>
					</li>
					{noticeData?.content &&
						noticeData.content.map((data) => {
							return (
								<li
									key={data?.id ? data.id : ''}
									onClick={() => goToDetail(data ? data : '')}
									style={{ cursor: 'pointer' }}
								>
									<a className="ellipsis">
										{(currentDate - new Date(data?.createdAt ? data.createdAt : '').getTime()) /
											(1000 * 3600 * 24) <=
										7 ? (
											<span className="ico">New</span>
										) : (
											''
										)}
										{data?.title ? data.title : ''}
									</a>
									<span className="date">
										{data?.createdAt ? data.createdAt.slice(0, 10).replaceAll('-', '.') : ''}
									</span>
								</li>
							);
						})}
				</ul>
			</div>
			<Pagination
				noticeData={noticeData}
				prevPage={prevPage}
				paginate={paginate}
				nextPage={nextPage}
				newPageNumbers={newPageNumbers}
				page={page}
			/>
		</>
	);
};

export default React.memo(ReviewList);
