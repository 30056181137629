export const handleIsnpectionTime = (time) => {
	if (!!time) {
		const [left, right] = time.split('T');
		const [year, month, day] = left.split('-');
		const times1 = right.split(':')[0];
		const times2 = right.split(':')[1];
		return `${year}년 ${month}월 ${day}일 ${times1}:${times2}`;
	} else {
		return null;
	}
};

export const handlePremiumNum = (nowPrice, officialPrice) => {
	if (nowPrice && officialPrice) {
		let caculate = (nowPrice / officialPrice) * 100 - 100;
		caculate = Math.floor(caculate);
		if (caculate > 9) {
			return caculate;
		} else {
			return false;
		}
		//   let splitCaculate = caculate.split('.')[1];
	} else {
		return false;
	}
};
