import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import FaqDetail from './components/FaqDetail';
import { getFaqListApi, getFaqKeywordsListApi } from 'apis/faq';

const Faq = () => {
    const history = useHistory()
	const [faqList, setFaqList] = useState([]);
	const [oneFaqData, setOneFaqData] = useState({});
	const [openDetail, setOpenDetail] = useState(false);
	const [pagenation, setPagenation] = useState([]);

	const [page, setPage] = useState(0);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);

	useEffect(() => {
		window.scrollTo(0, 0);
	},[page])
	/**
	 * 페이지네이션
	 */

	const newPageArr = [];
	for (let i = 0; i < pagenation.totalPage; i++) {
		newPageArr.push(i + 1);
	}
	const filterNewPageArr = newPageArr.slice(start, end);

	const handlePagenation = (num) => {
		setPage(num - 1);
	};

	const handlePrevPage = () => {
		if (page === 0) {
			return alert('첫 페이지 입니다.');
		} else if ((page + 1) % 4 === 1) {
			const newStart = start > 0 ? start - 4 : 0;
			const newEnd = end > 0 ? end - 4 : end;
			setStart(newStart);
			setEnd(newEnd);
		}
		setPage(page - 1);
	};

	useEffect(() => {
		getFaqList();
	}, [page]);
	/**
	 * 전체 FAQ 리스트 API
	 */
	const getFaqList = () => {
		getFaqListApi(page).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setFaqList(data.content || []);
				setPagenation(data || []);
			} else {
				alert(detail);
			}
		});
	};

	const handleNextPage = () => {
		if (page + 1 === pagenation.totalPage) {
			return alert('마지막 페이지 입니다.');
		} else if ((page + 2) % 4 === 1) {
			const newStart = start + 4;
			const newEnd = end + 4;
			setStart(newStart);
			setEnd(newEnd);
		}
		setPage(page + 1);
	};

	const handleClickOneFaq = (faq) => {
		setOneFaqData(faq);
		setOpenDetail(true);
	};

	return (
		<>
			{openDetail ? (
				<FaqDetail setOpenDetail={setOpenDetail} oneFaqData={oneFaqData} />
			) : (
				<main className="mypage">
					<section className="back_wrap">
						<button className="btn_back" onClick={() => history.goBack()}></button>
						<h2>고객센터</h2>
					</section>

					<section className="faq_list">
						<p className="tit">무엇이 궁금 하신가요?</p>
						<ul>
							{faqList?.map((faq, idx) => (
								<li key={idx} onClick={() => handleClickOneFaq(faq)}>
									<a style={{width:'100%', overflow:'hidden',paddingRight:'13vw'}}>{faq.question || '-'}</a>
								</li>
							))}
						</ul>
						<div className="paging">
							<a className="btn prev" onClick={handlePrevPage}></a>
							{filterNewPageArr.map((num, idx) => (
								<a
									key={num}
									className={page === num - 1 ? 'num' : ''}
									onClick={() => handlePagenation(num)}
								>
									{num}
								</a>
							))}
							<a className="btn next" onClick={handleNextPage}></a>
						</div>
					</section>
				</main>
			)}
		</>
	);
};

export default Faq;
