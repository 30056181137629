import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { useHistory } from 'react-router-dom';
import { Viewer } from '@toast-ui/react-editor';
import styled, { css } from 'styled-components';
import Chart from 'chart.js';
import AuctionRegister from 'mobile-components/AuctionRegister';
import CommonHelper from 'helpers/CommonHelper';
import { postAuctionApplyApi } from 'apis/auctionApply';
import { useAppState } from 'context/AppContext';
// import AuctionDatePicker from './AuctionDatePicker';
import { getStoreListApi } from 'apis/buy';
import { getOneAuctionProductApi, sendTenderApi } from 'apis/auctionDetail';
import { GENDER_EN_KO, MOVEMENT_EN_KO, SUPPLEMENTS_EN_KO } from 'common/constants';
import { handlePremiumNum } from 'common/common';
import Warranty from 'assets-mobile/img/common/ic_certificate.svg';
import IcoBox from 'assets-mobile/img/common/ic_box.svg';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const AuctionDetail = () => {
	const [showAuctionRegister, setShowAuctionRegister] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [lastPriceInput, setLastPriceInput] = useState('');
	const history = useHistory();
	const [oneAuctionProductInfo, setOneAuctionProductInfo] = useState({});
	const [onePhotoUrl, setOnePhotoUrl] = useState('');
	const [currentStoreList, setCurrentStoreList] = useState([]);
	//팜업
	const [signUpAlert, setSignAlert] = useState(false);
	const [upgradeRankAlert, setUpgradeRankAlert] = useState(false);
	const [tenderAlert, setTenderAlert] = useState(false);
	const [tenderSuccessAlert, setTenderSuccessAlert] = useState(false);
	//
	const [auctionEnrollment, setAuctionEnrollment] = useState(false);
	const [enrollCheck, setEnrollCheck] = useState(false);
	const [enrollInputValue, setEnrollInputValue] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [validation, setValidation] = useState(false);
	// select된 시간을 서버에 보내는 형식으로 바꾸기
	const offset = new Date().getTimezoneOffset() * 60000;
	const convertStartDate = new Date(startDate - offset).toISOString().split('.')[0];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	// login data가져오기
	const data = useAppState();
	const userData = data?.user?.data?.roles?.includes('AUCTION');

	const auctionApplyBtn = () => {
		// if (!authToken) {
		// 	// setSignAlert(true); // 로그인하라고 popup
		// 	alert('로그인이 필요한 서비스입니다.');
		// 	history.push('/signIn');
		// } else if (authToken && !userData) {
		// 	setUpgradeRankAlert(true); //등업하라고 popup
		// } else if (authToken && userData) {
		// 	setTenderAlert(true); //입찰 popup
		// }
		if (!authToken) {
			setSignAlert(true);
			history.push('/signin?redirectUrl=/auction');
		} else {
			window.open(oneAuctionProductInfo.description)
		}
	};

	//옥션등업 팝업열기
	const goToEnrollment = () => {
		setAuctionEnrollment(true);
	};

	//큰 photoUrl선택
	const onClickOnePhotoUrl = (photoUrl) => {
		setOnePhotoUrl(photoUrl);
	};

	/**
	 * /auction/:id 상품상세로 들어왔을 때 최근 본 상품등록을 위해 localStorage에 저장
	 */
	const handleSessionRecently = () => {
		let getStorage = localStorage.getItem('auctionFilter');
		let nowProductId = window.location.pathname.split('/')[2];
		if (!getStorage) {
			let makeArrayForSession = nowProductId;
			localStorage.setItem('auctionFilter', `${makeArrayForSession}`);
		} else if (getStorage.split(',').length < 5) {
			let splitBox = getStorage.split(',');
			if (splitBox.includes(nowProductId)) {
				return;
			} else {
				localStorage.setItem('auctionFilter', `${nowProductId},${getStorage}`);
			}
		} else if (getStorage.split(',').length >= 5) {
			let splitBox = getStorage.split(',');
			splitBox.unshift(nowProductId);
			let sliceSplitBox = splitBox.slice(0, 5);
			let sliceSplitStringBox = sliceSplitBox.toString();
			localStorage.setItem('auctionFilter', `${sliceSplitStringBox}`);
		}
	};

	useEffect(() => {
		handleSessionRecently();
		getStoreList();
		getOneAuctionProduct();
	}, []);

	//옥션 등급 등업신청
	const handleEnrollCheck = () => {
		if (enrollInputValue.length > 0 && startDate) {
			postAuctionApplyApi(enrollInputValue, convertStartDate, authToken).then(
				({ data: { data, detail, status } }) => {
					if (status === 'SUCCESS') {
						setEnrollCheck(true);
						setEnrollInputValue('');
						setStartDate(new Date());
					} else {
						// alert('등업 신청에 실패하였습니다. 고객상담으로 문의주시면 도와드리겠습니다.')
						alert(detail);
					}
				},
			);
		} else {
			setValidation(true);
		}
	};

	const clickCancelBtn = () => {
		setSignAlert(false);
		setAuctionEnrollment(false);
		setUpgradeRankAlert(false);
		setSignAlert(false);
		setEnrollCheck(false);
		setValidation(false);
		setStartDate(new Date());
		setEnrollInputValue('');
	};

	const handleEnrollValue = (e) => {
		setEnrollInputValue(e.target.value);
		e.target.value.length > 0 ? setValidation(false) : setValidation(true);
	};

	//옥션상세 priceHistories의 차트
	const getChart = (oneAuctionData) => {
		if (!showAuctionRegister) {
			const productPriceArr = oneAuctionData?.priceHistories?.map((item) => {
				return item.price;
			});
			const productDayArr = oneAuctionData?.pricehistoires?.map((item) => {
				const year = item?.day?.slice(0, 4);
				const month = item?.day?.slice(4, 6);
				const day = item?.day?.slice(6, 8);
				return `${year}-${month}-${day}`;
			});

			let myChart = document.getElementById('mobileAuctionDetailChart');
			// productPriceArr?.map((item, idx) => {
			var ctx = myChart.getContext('2d');
			var gradientFill = ctx.createLinearGradient(0, 70, 0, 0);
			gradientFill.addColorStop(1, 'rgba(84, 93, 102, .5)');
			gradientFill.addColorStop(0, 'rgba(84, 93, 102, 0)');

			new Chart(ctx, {
				// The type of chart we want to create
				type: 'line',

				// The data for our dataset
				data: {
					labels: productDayArr?.slice(-4, -1),
					datasets: [
						{
							backgroundColor: gradientFill,
							borderColor: 'rgba(0, 0, 0, 0.25)',
							data: productPriceArr?.slice(-4, -1),
							pointRadius: 0,
							lineTension: 0,
							boderWidth: 1,
							showline: true,
						},
					],
				},

				// Configuration options go here
				options: {
					animation: {
						duration: 0,
					},
					legend: {
						display: false,
					},
					tooltips: false,
					clip: false,
					elements: {
						point: {
							radius: 0,
						},
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									display: true,
									color: '#F5F7FA',
								},
								ticks: {
									display: true,
									padding: 10,
								},
							},
						],
						yAxes: [
							{
								gridLines: {
									display: true,
									color: '#F5F7FA',
								},
								ticks: {
									display: true,
									padding: 10,
									min: Math.min.apply(this, productPriceArr.slice(-4, -1)),
									max: Math.max.apply(this, productPriceArr.slice(-4, -1)),
									callback: function (value, index, values) {
										if (index === values.length - 1) return Math.min.apply(this, productPriceArr.slice(-4, -1));
										else if (index === 0) return Math.max.apply(this, productPriceArr.slice(-4, -1));
										else return '';
									},
								},
							},
						],
					},
				},
			});
			// });
		}
	};

	//가격input 콤마 핸들링
	const handleLastPrice = (e) => {
		const { value } = e.target;
		let deleteAll = value
			.replace(/ /g, '')
			.replace(/₩/g, '')
			.replace(/,/g, '')
			.replace(/[^0-9]/g, '');
		setLastPriceInput(deleteAll);
	};

	const goToSell = (modelName) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			history.push(`/sell/${encodeURIComponent(modelName)}`);
		} else {
			setSignAlert(true);
		}
	};

	const openAuctionRegister = () => {
		setUpgradeRankAlert(false);
		setShowAuctionRegister(true);
	};

	// useEffect(() => {
	// 	if (oneAuctionProductInfo) {
	// 		let swiper12 = new Swiper('.product_img', {
	// 			slidesPerView: 1,
	// 			spaceBetween: 30,
	// 			loop: true,
	// 			pagination: {
	// 				el: '.product_img_paging',
	// 				clickable: true,
	// 			},
	// 		});
	// 	}
	// }, [oneAuctionProductInfo]);
	return (
		<>
			{showAuctionRegister ? (
				<AuctionRegister setShowAuctionRegister={setShowAuctionRegister} />
			) : (
				<>
					{tenderSuccessAlert && (
						<div className="bg_dimmed" style={{ display: 'block' }}>
							<article className="popup_alert">
								<p className="t2">입찰이 완료되었습니다.</p>
								<p className="t3">
									입찰 내역은 마이페이지에서 <br />
									확인하실 수 있습니다.
								</p>
								<button className="btn_ok" onClick={() => setTenderSuccessAlert(false)}>
									확인
								</button>
							</article>
						</div>
					)}
					{upgradeRankAlert && (
						<div className="bg_dimmed" style={{ display: 'block' }}>
							<article className="popup_alert">
								<p className="t1">
									옥션등급 회원만 이용 가능한 <br />
									서비스입니다. 옥션등급으로 승급을 <br />
									진행하시겠습니까?
								</p>
								<div className="btn_wrap">
									<button className="btn_cancel" onClick={() => setUpgradeRankAlert(false)}>
										취소
									</button>
									<button className="btn_ok" onClick={openAuctionRegister}>
										확인
									</button>
								</div>
							</article>
						</div>
					)}
					{tenderAlert && (
						<div className="bg_dimmed" style={{ display: 'block' }}>
							<article className="popup_alert">
								<p className="t4">
									입찰금액 : {lastPriceInput ? `${Number(lastPriceInput).toLocaleString()}` : `0`}원
								</p>
								<p className="t3">입력하신 금액으로 입찰하시겠습니까?</p>
								<div className="btn_wrap">
									<button className="btn_cancel" onClick={() => setTenderAlert(false)}>
										취소
									</button>
									<button className="btn_ok" onClick={sendTender}>
										확인
									</button>
								</div>
							</article>
						</div>
					)}
					<main className="auction">
						<section className="back_wrap">
							<button className="btn_back" onClick={() => history.goBack()}></button>
							<h2>제품 상세</h2>
						</section>
						<section className="product_detail">
							<div className="product_top">
								{!!oneAuctionProductInfo?.price &&
									!!oneAuctionProductInfo?.standardWatch?.officialPrice &&
									handlePremiumNum(
										oneAuctionProductInfo?.price,
										oneAuctionProductInfo?.standardWatch?.officialPrice,
									) && (
										<p className="sale">
											{handlePremiumNum(
												oneAuctionProductInfo?.price,
												oneAuctionProductInfo?.standardWatch?.officialPrice,
											)}
											% Premium
										</p>
									)}
								<Swiper
									slidesPerView={1}
									spaceBetween={30}
									loop={false}
									pagination={{
										el: '.swiper-pagination',
										clickable: true,
									}}
									renderBullet={function (index, className) {
										return '<span class="' + className + '">' + (index + 1) + '</span>';
									}}
								>
									<div className="swiper-container product_img">
										<div className="swiper-wrapper">
											{oneAuctionProductInfo?.attachedFiles?.map((photo, idx) => (
												<SwiperSlide className="swiper-slide" key={idx}>
													<div className="img_box">
														<img src={photo} alt="" />
													</div>
												</SwiperSlide>
											))}
										</div>
									</div>
								</Swiper>
								<div className="product_img_paging swiper-pagination"></div>
								<div className="count_box" id={`모바일-옥션-${oneAuctionProductInfo.id}`}>
									{initTimer(
										oneAuctionProductInfo?.endDate,
										`모바일-옥션-${oneAuctionProductInfo.id}`,
									)}
								</div>
								<p className="t1">
									최근 48시간 동안{' '}
									<b>
										{oneAuctionProductInfo?.hourCount
											? Number(oneAuctionProductInfo?.hourCount).toLocaleString()
											: 0}
										명
									</b>
									이 이 시계를 조회하였습니다.
								</p>
							</div>

							<div className="product_info">
								<div className="info_box">
									<p className="t1 poppins">{oneAuctionProductInfo?.watch?.brand || '-'}</p>
									<p className="t2">{oneAuctionProductInfo?.watch?.model || '-'}</p>
									<p className="t3">{/* {oneAuctionProductInfo?.description || '-'} */}</p>
									<div className="bidding_box">
										<p className="t1">
											{/* <b>
												{oneAuctionProductInfo?.orderPeopleCount
													? Number(oneAuctionProductInfo?.orderPeopleCount).toLocaleString()
													: 0}
												명
											</b>{' '}
											입찰 중 */}
										</p>
										<p className="price">
											₩{' '}
											{oneAuctionProductInfo?.price
												? Number(oneAuctionProductInfo?.price).toLocaleString()
												: '-'}
										</p>
										{/* <input
											type="text"
											className="input_t"
											// placeholder="입찰가격을 입력해주세요."
											value={
												oneAuctionProductInfo.price
													? `₩ ${Number(oneAuctionProductInfo.price).toLocaleString()}`
													: `₩ 0`
											}
											// onChange={handleLastPrice}
											disabled
										/> */}
										{errorText && <p className="error_txt">{errorText}</p>}
										{oneAuctionProductInfo?.auctionStatus === 'DONE' ? (
											<button
												className="btn"
												style={{ background: 'lightgray', cursor: 'default' }}
												disabled
											>
												종료된 상품입니다.
											</button>
										) : (
											<button className="btn" onClick={auctionApplyBtn}>
												드로우 신청
											</button>
										)}
									</div>
								</div>

								<ul className="info_list">
									<li>
										<b>생산년도</b>
										<div>{oneAuctionProductInfo?.watch?.producedYear || '미확인'}</div>
									</li>
									<li>
										<b>보증서</b>
										<div>
											{oneAuctionProductInfo?.watch?.supplements.includes('GUARANTEE') && (
												<img src={Warranty} alt="보증서 아이콘" />
											)}
										</div>
									</li>
									<li>
										<b>박스유무</b>
										<div>
											{oneAuctionProductInfo?.watch?.supplements.includes('BOX') && (
												<img src={IcoBox} alt="박스 아이콘" />
											)}
										</div>
									</li>
									<li>
										<b>가격변동</b>
										<div>
											<span className="fluctuations down">
												{oneAuctionProductInfo?.standardWatch?.hpi || '-'}
											</span>
										</div>
									</li>
								</ul>

								<div className="graph_box">
									<p>최근 가격 변동 추이</p>
									<div className="graph">
										<canvas
											id="mobileAuctionDetailChart"
											width="75.556vw"
											height="24.306vw"
										></canvas>
									</div>
								</div>

								<div className="branch_list">
									<h3>재고보유점</h3>
									<ul>
										{currentStoreList.map((store) => (
											<li
												key={store.id}
												className={
													oneAuctionProductInfo?.stores?.filter((el) => el.id === store.id).length >
													0
														? 'checked'
														: ''
												}
											>
												<label>
													<input
														type="checkbox"
														checked={
															oneAuctionProductInfo?.stores?.filter((el) => el.id === store.id)
																.length > 0
														}
														className="round"
														style={{ margin: '1px' }}
														disabled
													/>
													<span>{store.name || '-'}</span>
												</label>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className="info_wrap">
								<h3>기본</h3>
								<ul>
									<li>
										<b>레퍼런스</b>
										<span>{oneAuctionProductInfo?.watch?.serialNo || '-'}</span>
									</li>
									<li>
										<b>브랜드</b>
										<span>{oneAuctionProductInfo?.watch?.brand || '-'}</span>
									</li>
									<li>
										<b>모델</b>
										<span>{oneAuctionProductInfo?.watch?.model || '-'}</span>
									</li>
									<li>
										<b>무브먼트</b>
										<span>
											{oneAuctionProductInfo?.watch?.movement
												? MOVEMENT_EN_KO[oneAuctionProductInfo.watch.movement]
												: '-'}
										</span>
									</li>
									<li>
										<b>케이스 소재</b>
										<span>{oneAuctionProductInfo?.watch?.caseMaterial || '-'}</span>
									</li>
									<li>
										<b>지름 (mm)</b>
										<span>{oneAuctionProductInfo?.watch?.caseDiameter || '-'}</span>
									</li>
									<li>
										<b>구매년도</b>
										<span>{oneAuctionProductInfo?.watch?.producedYear || '미확인'}</span>
									</li>
									<li>
										<b>상품 컨디션 (0~10)</b>
										<span>{oneAuctionProductInfo?.watch?.condition || '-'}</span>
									</li>
									<li>
										<b>구성품</b>
										<span>
											{' '}
											{oneAuctionProductInfo?.watch?.supplements?.map((el, idx) => {
												return (
													<span style={{ marginRight: '10px' }} key={idx}>
														{el ? SUPPLEMENTS_EN_KO[el] : '-'}{' '}
													</span>
												);
											})}
										</span>
									</li>
									<li>
										<b>성별</b>
										<span>
											{oneAuctionProductInfo?.watch?.gender
												? GENDER_EN_KO[oneAuctionProductInfo?.watch?.gender]
												: '-'}
										</span>
									</li>
									<li>
										<b>다이얼 색상</b>
										<span>{oneAuctionProductInfo?.watch?.dialColor || '-'}</span>
									</li>
									<li>
										<b>다이얼 타입</b>
										<span>{oneAuctionProductInfo?.watch?.dialType || '-'}</span>
									</li>
								</ul>
								<h3>설명</h3>
								<p>
									<div style={{ margin: '30px auto', width: '80%' }}>
										{oneAuctionProductInfo?.additionalInfo && (
											<Wrapper>
												<Viewer
													initialValue={replaceNewlineWithBrTag(
														decodingHtmlTags(oneAuctionProductInfo?.additionalInfo || ''),
													)}
													initialEditType="markdown"
												/>
											</Wrapper>
										)}
									</div>
								</p>
							</div>

							<div className="btn_wrap">
								{oneAuctionProductInfo?.auctionStatus === 'DONE' ? (
									<button
										className="btn"
										style={{ background: 'lightgray', cursor: 'default' }}
										disabled
									>
										종료된 상품입니다.
									</button>
								) : (
									<button className="btn" onClick={auctionApplyBtn}>
										드로우 신청
									</button>
								)}
							</div>
						</section>
					</main>
				</>
			)}
		</>
	);

	function Lpad(str, len) {
		str = str + '';
		while (str.length < len) {
			str = '0' + str;
		}
		return str;
	}
	function sendTender() {
		//옥션아이디, 토큰, price
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		let nowProductId = window.location.pathname.split('/')[2];
		if (!!authToken) {
			sendTenderApi(authToken, nowProductId, lastPriceInput).then(({ data }) => {
				if (data?.status === 'SUCCESS') {
					setTenderAlert(false);
					setTenderSuccessAlert(true);
					setLastPriceInput('');
				} else {
					alert(data?.detail);
				}
			});
		} else {
			setSignAlert(true);
		}
	}

	function getStoreList() {
		getStoreListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data?.content || []);
			} else {
				alert(detail);
			}
		});
	}
	function getOneAuctionProduct() {
		let nowProductId = window.location.pathname.split('/')[2];
		getOneAuctionProductApi(nowProductId).then(({ data }) => {
			if (data?.status === 'SUCCESS') {
				setOneAuctionProductInfo(data.data || {});
				setOnePhotoUrl(data?.data?.attachedFiles[0] ? data.data.attachedFiles[0] : null);
				if (data?.data?.lastPrice === 0) {
					setLastPriceInput(data?.data?.price || 0);
				} else {
					setLastPriceInput(data?.data?.lastPrice || 0);
				}
				getChart(data.data || {});
			} else {
				alert(data?.detail);
			}
		});
	}

	function initTimer(endTime, name) {
		let getElement = document.getElementById(name);
		// let getElements = document.getElementsByClassName(name);
		// if (getElements.length < 3) {
		// 	getElement = getElements[0];
		// } else {
		// 	getElement = getElements[1];
		// }
		if (endTime && name && getElement) {
			function msg_timer(id) {
				let stDate = new Date().getTime();
				let endDate = new Date(endTime).getTime();
				let remainDate = endDate - stDate;
				let days = Math.floor(remainDate / (1000 * 60 * 60 * 24));

				let hours = Math.floor((remainDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((remainDate % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((remainDate % (1000 * 60)) / 1000);

				// let m = `${days}일 ${Lpad(hours, 2)}:${Lpad(minutes, 2)}:${Lpad(seconds, 2)}이후 마감`; // 남은 시간 text형태로 변경
				let m = `<span>${days}일</span><span>${Lpad(hours, 2)}</span>:<span>${Lpad(
					minutes,
					2,
				)}</span> :<span>${Lpad(seconds, 2)}</span>`;

				getElement.innerHTML = m; // div 영역에 보여줌

				if (remainDate < 0) {
					// 시간이 종료 되었으면..
					clearInterval(tid); // 타이머 해제
				} else {
					remainDate = remainDate - 1000; // 남은시간 -1초
				}
			}
			let tid = setInterval(msg_timer, 1000);
		}
	}
};

export default AuctionDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	display: flex;
	justify-content: center;
	img {
		max-width: 250px;
	}
`;
