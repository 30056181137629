import React, { useEffect, useState } from 'react';
import { getFilterApi, postFilterApi } from 'apis/filter';
import { useAppDispatch, useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';
import { getUserSessionApi } from 'apis/common';

const FilterMenu = () => {
	const [filterList, setFilterList] = useState([]);
	const [newFilterList, setNewFilterList] = useState([]);
	// 5개이상 클릭하려고 할 때 모달안내
	const [maxChecked, setMaxChecked] = useState(false);
	const appDispatch = useAppDispatch();
	const appState = useAppState();

	// 필터리스트만 반환
	const appfilter = appState?.user?.data && appState.user.data.favoriteFilters;
	// 수정하기 클릭 시 alert Modal
	const [modifyModal, setModifyModal] = useState(false);

	useEffect(() => {
		getFilterList();
	}, []);

	const getFilterList = () => {
		getFilterApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				if (data) {
					let findUseFilter = data.filter(
						(el) =>
							el.key === 'BRAND' ||
							el.key === 'MODEL' ||
							el.key === 'CONDITION' ||
							el.key === 'PRODUCED_YEAR' ||
							el.key === 'GENDER' ||
							el.key === 'CASE_DIAMETER' ||
							el.key === 'MOVEMENT' ||
							el.key === 'SUPPLEMENTS' ||
							el.key === 'CASE_MATERIAL' ||
							el.key === 'REFERENCE' ||
							el.key === 'DIAL_COLOR' ||
							el.key === 'DIAL_TYPE' ||
							el.key === 'STORE',
					);
					setFilterList(findUseFilter ? findUseFilter : []);
				}
			} else {
				alert(detail);
			}
		});
	};
	useEffect(() => {
		if (filterList && appfilter) {
			getNewFilterData();
		}
	}, [appfilter, filterList]);

	// 새로운 필터리스트로 반환하는 함수
	const getNewFilterData = () => {
		const newFilterData =
			filterList &&
			filterList.map((value) =>
				appfilter.includes(value.key) ? { ...value, favorite: true } : { ...value },
			);
		setNewFilterList(newFilterData);
	};
	// value가 true인것의 key값모음
	const selectedCheckBox =
		newFilterList &&
		newFilterList.filter((value) => value.favorite === true).map((value) => value.key);
	const MAXCOUNT = 5;

	// filterData받아온거 일반/기능을 기준으로 나누기
	const generalFilterNameList = [
		'BRAND',
		'MODEL',
		'CONDITION',
		'PRICE',
		'PRODUCED_YEAR',
		'GENDER',
		'SERIAL_NO',
		'COMPONENTS',
		'STORE',
	];

	const handleCheckBox = (e) => {
		const { name, checked } = e.target;
		if (selectedCheckBox.length < MAXCOUNT) {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: checked } : { ...value };
			});
			setNewFilterList(convert);
		} else if (selectedCheckBox.length === MAXCOUNT && !checked) {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: false } : { ...value };
			});
			setNewFilterList(convert);
		} else {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: false } : { ...value };
			});
			setNewFilterList(convert);
			setMaxChecked(true);
		}
	};

	const handleBtn = () => {
		setMaxChecked(false);
		setModifyModal(false);
	};

	const goToModify = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			postFilterApi(selectedCheckBox, authToken).then(({ data: { data, detail, status } }) => {
				if (status === 'SUCCESS') {
					getUserSessionApi(authToken).then(({ data }) => {
						if (data?.status === 'SUCCESS') {
							appDispatch({ type: 'UPDATE_USER_DATA', data: data?.data || {} });
							setModifyModal(true);
						} else {
							alert(data?.detail);
							//todo
						}
					});
				} else {
					alert(detail);
					//alert("오류안내")
				}
			});
		}
	};

	return (
		<>
			{modifyModal ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="alert">
						<p>필터설정이 변경되었습니다.</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={handleBtn}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			{maxChecked ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="alert">
						<p>
							필터설정은 최대 5개까지만 설정하실 수 <br />
							있습니다.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={handleBtn}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			<div className="mypage_cont">
				<div className="check_wrap">
					<h2>일반정보</h2>
					<div className="check_list">
						{newFilterList
							.filter((value) => generalFilterNameList.includes(value.key))
							.map((value, index) => {
								return (
									<label key={index}>
										<input
											type="checkbox"
											name={value.key}
											onChange={handleCheckBox}
											checked={value.favorite}
										/>
										<span>{value.name}</span>
									</label>
								);
							})}
						{/* {generalFilter.map((value, index) => {
							return (
								<label key={index}>
									<input type="checkbox" name={value.key} onChange={handleCheckBox} />
									<span>{value.name}</span>
								</label>
							);
						})} */}

						{/* <label>
							<input
								type="checkbox"
								name="MODEL"
								onChange={handleCheckBox}
								checked={checkBoxValues.MODEL}
							/>
							<span>모델</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="PRICE"
								onChange={handleCheckBox}
								checked={checkBoxValues.PRICE}
							/>
							<span>가격</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="PRODUCEDYEAR"
								onChange={handleCheckBox}
								checked={checkBoxValues.PRODUCEDYEAR}
							/>
							<span>년</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="KINDS"
								onChange={handleCheckBox}
								checked={checkBoxValues.KINDS}
							/>
							<span>종류</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="GENDER"
								onChange={handleCheckBox}
								checked={checkBoxValues.GENDER}
							/>
							<span>성별</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="CONDITION"
								onChange={handleCheckBox}
								checked={checkBoxValues.CONDITION}
							/>
							<span>컨디션</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="newOrOld"
								onChange={handleCheckBox}
								checked={checkBoxValues.newOrOld}
							/>
							<span>새/중고</span>
						</label> */}
					</div>
				</div>
				<div className="check_wrap">
					<h2>특징 및 기능</h2>
					<div className="check_list">
						{newFilterList
							.filter((value) => !generalFilterNameList.includes(value.key))
							.map((value, index) => {
								return (
									<label key={index}>
										<input
											type="checkbox"
											name={value.key}
											onChange={handleCheckBox}
											checked={value.favorite}
										/>
										<span>{value.name}</span>
									</label>
								);
							})}
						{/* {featureFilter.map((value, index) => {
							return (
								<label key={index}>
									<input type="checkbox" name={value.key} onChange={handleCheckBox} />
									<span>{value.name}</span>
								</label>
							);
						})} */}
						{/* <label>
							<input
								type="checkbox"
								name="MOVEMENT"
								onChange={handleCheckBox}
								checked={checkBoxValues.MOVEMENT}
							/>
							<span>무브먼트</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="HEIGHT"
								onChange={handleCheckBox}
								checked={checkBoxValues.HEIGHT}
							/>
							<span>두께</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="CASE"
								onChange={handleCheckBox}
								checked={checkBoxValues.CASE}
							/>
							<span>케이스</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="BEZEL"
								onChange={handleCheckBox}
								checked={checkBoxValues.BEZEL}
							/>
							<span>베젤</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="DIAL"
								onChange={handleCheckBox}
								checked={checkBoxValues.DIAL}
							/>
							<span>다이얼</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="GLASS"
								onChange={handleCheckBox}
								checked={checkBoxValues.GLASS}
							/>
							<span>유리</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="WATERPROOF"
								onChange={handleCheckBox}
								checked={checkBoxValues.WATERPROOF}
							/>
							<span>방수</span>
						</label>
						<label>
							<input
								type="checkbox"
								name="oldOrNew"
								onChange={handleCheckBox}
								checked={checkBoxValues.oldOrNew}
							/>
							<span>새/중고</span>
						</label> */}
					</div>
				</div>
				<div className="btn_wrap">
					<button>취소</button>
					<button className="ok" onClick={goToModify}>
						수정
					</button>
				</div>
			</div>
		</>
	);
};

export default FilterMenu;
