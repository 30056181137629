import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignUp from 'mobile-components/SignUp';
import FindMember from 'mobile-components/FindMember';
import { getSignInApi } from 'apis/signIn';
import { getUserSessionApi } from 'apis/common';
import { useAppDispatch, useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';
const errorStyle = {
	paddingTop: '3.333vw',
	color: '#ff5353',
	fontSize: '3.611vw',
};

const SignIn = ({}) => {
	const history = useHistory();
	//로그인
	const [loginId, setLoginId] = useState('');
	const [password, setPassword] = useState('');
	const [isAutoSigninChecked, setIsAutoSigninChecked] = useState(false);
	const [errorText, setErrorText] = useState('');
	const {
		user: {
			loggedIn,
			data: { roles: userRoles, name: userName, profileUrl },
		},
	} = useAppState();
	const appDispatch = useAppDispatch();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		switch (name) {
			case 'loginId':
				setLoginId(value);
				break;
			case 'password':
				setPassword(value);
				break;
		}
	};
	const handleOutoSignin = () => {
		setIsAutoSigninChecked(!isAutoSigninChecked);
	};

	const handleOnClickLogin = () => {
		if (loginId && password) {
			getSignInApi(loginId, password).then(({ data: { data, detail, status } }) => {
				if (status === 'SUCCESS') {
					if (data?.token) {
						getUserSessionApi(data.token).then(({ data }) => {
							if (data?.status === 'SUCCESS') {
								appDispatch({
									type: 'LOGGED_IN',
									loggedIn: true,
									data: data?.data || {},
								});
							}
						});
						if (isAutoSigninChecked) {
							CommonHelper.setAuthenticateToken(data.token); //localStorage
						} else {
							CommonHelper.setSessionAuthenticateToken(data.token); //sessionStorage
						}
						setErrorText('');
						setLoginId('');
						setPassword('');
						window.location.href = window.location.origin + window.location.search.split('=')[1];
					}
				} else if (status === 'FAILED') {
					setErrorText(detail);
				} else {
					alert(detail);
				}
			});
		} else if (!loginId && password) {
			setErrorText('아이디를 입력해 주세요.');
		} else if (loginId && !password) {
			setErrorText('비밀번호를 입력해 주세요.');
		} else {
			setErrorText('로그인정보를 입력해 주세요.');
		}
	};
	return (
		<>
			<div className="bg_dimmed" style={{ display: 'block' }}>
				<main className="join">
					<section className="login_wrap">
						<h2>로그인</h2>
						<div className="input_div">
							<input
								type="text"
								className="input_t"
								placeholder="아이디"
								name="loginId"
								value={loginId}
								onChange={handleInputChange}
							/>
						</div>
						<div className="input_div">
							<input
								type="password"
								className="input_t"
								placeholder="비밀번호"
								value={password}
								name="password"
								onChange={handleInputChange}
							/>
						</div>
						<div className="auto_login">
							<label>
								<input type="checkbox" checked={isAutoSigninChecked} onClick={handleOutoSignin} />
								<span>자동로그인</span>
							</label>
							<a href="/findMember">정보찾기</a>
						</div>
						{!!errorText && (
							<p className="txt_error" style={errorStyle}>
								{errorText}
							</p>
						)}

						<button className="btn_login" onClick={handleOnClickLogin}>
							로그인
						</button>
						<a href="/signUP" className="btn_join">
							회원가입
						</a>
						<button className="btn_closed" onClick={() => history.goBack()}></button>
					</section>
				</main>
			</div>
		</>
	);
};
export default SignIn;
