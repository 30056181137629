import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import CommonHelper from 'helpers/CommonHelper';
import Dots from 'assets-pc/front-img/btn-dots.png';
import Line from 'assets-pc/front-img/btn-line.png';

const storeBgClass = {
	1: 'busan',
	2: 'daegu',
	3: 'seoul',
};

const HaplSlider = ({ storeList, setCurrStep, setOneStoreReservation, goStep1 }) => {
	const history = useHistory();
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		autoplay: false,
		speed: 500,
		arrows: true,
	};

	const checkBeforeGoStep1 = (store) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('로그인 후 이용이 가능합니다.');
			history.push('/signin?redirectUrl=/hapl');
		} else {
			goStep1(store);
		}
	};

	return (
		<SliderWrapper>
			<section className="hapl-slider-wrapper">
				<div className="hapl-swiper">
					<Slider {...settings}>
						{storeList.length > 0 &&
							storeList.map((store, idx) => (
								<SliderDiv key={idx}>
									<div
										className={`hapl-swiper-slide ${storeBgClass[store.id]}`}
										onClick={() => checkBeforeGoStep1(store)}
									>
										<button className="slide-reservation-btn"></button>
										{/* <span className="reservation-btn-dots">.. ..</span> */}
										<img src={Dots} className="dots" alt="점" />
										<span className="reservation-text">예약신청</span>
										<img src={Line} className="line" alt="화살표" />
										<span className="store-info-wrapper">
											<div className="branch-name">직영점: {store.name}</div>
											<div className="branch-info">{store.name == "서울 압구정점"?'이용일자 : 일요일은 휴무':'이용일자 : 일요일, 공휴일 제외'}</div>
											<div className="branch-info">이용시간 : 10:00 ~19:00</div>
											<div className="branch-info">점심시간 : 13:00 ~14:00</div>
										</span>
										<div className="gray"></div>
									</div>
								</SliderDiv>
							))}
					</Slider>
				</div>
			</section>
		</SliderWrapper>
	);
};

export default HaplSlider;

const SliderWrapper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: ${(props) =>
			props.mostShowedProducts?.length === 1
				? '385px'
				: props.mostShowedProducts?.length === 2
				? '800px'
				: ''};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		display: ${(props) => (props.mostShowedProducts?.length < 3 ? 'flex' : 'block')};

		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		width: 60px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -66px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -60px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}
`;
const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
`;
