import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';
import { apiCall, apiCallByFormData } from './apiCall';

export const getDefaultMenuApi = (authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/session/detail`,
		'get',
		{},
		{ authorization: authToken },
	);
};
