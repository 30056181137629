import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import styled, { css } from 'styled-components';
import { getPopularListApi } from 'apis/buy';
import { handlePremiumNum } from 'common/common';

const BuySlider = () => {
	const [mostShowedProducts, setMostShowedProducts] = useState([]);
	const history = useHistory();
	useEffect(() => {
		getPopularList();
	}, []);

	const goToBuyDetailPage = (productId) => {
		history.push(`/buy/${productId}`);
	};
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: mostShowedProducts?.length < 3 ? mostShowedProducts?.length : 3,
		slidesToScroll: mostShowedProducts?.length < 3 ? mostShowedProducts?.length : 3,
		autoplay: true,
		speed: 500,
		autoplaySpeed: 3000,
		arrows: true,
		appendDots: (dots) => (
			<div
				style={{
					borderRadius: '10px',
					bottom: '-45px',
				}}
			>
				<ul style={{ margin: '0px' }}> {dots} </ul>
			</div>
		),

		customPaging: (i) => <div className="buy-swiper-pagination-bullet"></div>,
	};

	return (
		<>
			<SliderWripper mostShowedProducts={mostShowedProducts}>
				<section className="most_viewed deadline-wrapper">
					<h2>다른 사람들이 가장 많이 본 상품</h2>
					<div className="buy-swiper-container">
						<div className="buy-swiper-wrapper">
							<Slider {...settings}>
								{mostShowedProducts.map((product, idx) => (
									<SliderDiv key={product.id || idx}>
										<div
											className="buy-swiper-slide auction-swiper-slide-plus"
											onClick={() => goToBuyDetailPage(product.id)}
										>
											<div className="product_info product_info_plus">
												<p className="watch_name">
													{product?.watch?.brand || '-'} {product?.watch?.model || '-'}
												</p>
												<p className="price">
													₩ {product?.price ? Number(product?.price).toLocaleString() : '-'}
												</p>
												<p className="fluctuations">
													<span>-</span>
												</p>
												<p className="option option_plus">
													{product?.watch?.supplements?.includes('GUARANTEE') && (
														<img src={warrantyIco} alt="보증서" title="보증서가 있는 상품입니다." />
													)}
													{product?.watch?.supplements?.includes('BOX') && (
														<img src={boxIco} alt="정품박스" title="정품박스가 있는 상품입니다." />
													)}
												</p>
												<p className="comment">{product?.description || '-'}</p>
											</div>
											<img
												src={product?.attachedFiles[0] || buyImgWatch}
												className="slider_img_size"
												alt="시계 사진"
											/>
											{/* <p className="sale">30% Premium</p> */}
											{!!product?.price &&
												!!product?.standardWatch?.officialPrice &&
												handlePremiumNum(product?.price, product?.standardWatch?.officialPrice) && (
													<p className="sale">
														{handlePremiumNum(
															product?.price,
															product?.standardWatch?.officialPrice,
														)}
														% Premium
													</p>
												)}
										</div>
									</SliderDiv>
								))}
							</Slider>
						</div>
					</div>
					{/* <!-- Add Pagination --> */}
					<div className="buy-swiper-pagination"></div>
				</section>
			</SliderWripper>
		</>
	);

	function getPopularList() {
		getPopularListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setMostShowedProducts(data);
			} else {
				alert(detail);
			}
		});
	}
};

export default BuySlider;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: ${(props) =>
			props.mostShowedProducts?.length === 1
				? '385px'
				: props.mostShowedProducts?.length === 2
				? '800px'
				: ''};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		display: ${(props) => (props.mostShowedProducts?.length < 3 ? 'flex' : 'block')};

		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		width: 60px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -66px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -60px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */

	.slick-dots li {
		width: 20px;
		height: 2 px;
		margin: 0 5px;
	}

	.slick-dots .slick-active {
		width: 20px;
		height: 2px;
		background: #31383f;
		border-radius: 0;
		opacity: 1;
		width: 20px;
		top: 0px;
		overflow: hidden;
	}
`;
const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
`;
