import React, { useState } from 'react';
import Pagination from '../../MyReservationList/components/Pagination';

const SellReservation = ({ sellData, sellPage, setSellPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = sellData;
	const pageNumbers = [];

	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);
	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setSellPage(num);
	};
	const prevPage = () => {
		if (sellPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((sellPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setSellPage(sellPage - 1);
	};

	const nextPage = () => {
		if (sellPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setSellPage(sellPage + 1);
			if ((sellPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<ul className="history_list">
				{sellData?.content?.length > 0 ? (
					sellData.content &&
					sellData.content.map((data, index) => {
						return (
							<li key={index}>
								<p className="date">{data?.createdAt?.split('T')[0] || '-'}</p>
								<p className="watch_name">
									{data?.watch?.brand || '-'} <br />
									{data?.watch?.model || '-'} {data?.watch?.caseDiameter || '-'}mm
								</p>
								<dl>
									<div>
										<dt>참여자 수</dt>
										<dd>{data?.orderPeopleCount || '0'}명</dd>
									</div>
									<div>
										<dt>입찰가</dt>
										<dd>
											<b className="sell">
												{data?.lastPrice > 0
													? Number(data?.lastPrice).toLocaleString()
													: Number(data?.price).toLocaleString()}
											</b>
										</dd>
									</div>
								</dl>
								<span
									className={
										data?.auctionStatus === 'DONE'
											? 'status end'
											: data?.auctionStatus === 'RUN'
											? 'status ing'
											: data?.auctionStatus === 'WAIT'
											? 'status stay'
											: 'status'
									}
								>
									{data?.auctionStatus
										? data.auctionStatus === 'DONE'
											? '종료'
											: data.auctionStatus === 'WAIT'
											? '대기중'
											: data.auctionStatus === 'RUN'
											? '진행중'
											: ''
										: ''}
								</span>
							</li>
						);
					})
				) : (
					<li>
						<p>최근 예약내역이 없습니다.</p>
					</li>
				)}
			</ul>
			{sellData && sellData?.content?.length > 0 ? (
				<Pagination
					newPageNumbers={newPageNumbers}
					prevPage={prevPage}
					buyPage={sellPage}
					paginate={paginate}
					nextPage={nextPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default SellReservation;
