import React, { Children } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
// import 'assets-pc/css/common.css';
import 'assets-pc/css/font.css';
// import 'assets-pc/css/layout.css';
import 'assets-pc/scss/common.scss';
import 'assets-pc/css/frontEndAssets.css';
import 'assets-pc/css/frontEndAssets.scss';
import 'assets-pc/scss/font.scss';
import 'assets-pc/scss/layout.scss';

const Layout = ({ children }) => {
	return (
		<>
			<div className="bg_dimmed"></div>
			<div id="wrap" className="pc-wrapper" style={{ position: 'relative', minHeight: '100vh' }}>
				<Header />
				<div style={{ paddingBottom: '236px' }}>{children}</div>
				<div style={{ position: 'absolute', bottom: '0', width: '100%' }}>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default Layout;
