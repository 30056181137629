import React from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Notice/Pagination';

const ReviewList = ({
	noticeData,
	setOpenDetail,
	setOneNoticeData,
	prevPage,
	paginate,
	nextPage,
	newPageNumbers,
	page,
}) => {
	const history = useHistory();
	const goToDetail = (data) => {
		// setOneNoticeData(data);
		// setOpenDetail(true);
		sessionStorage.setItem('noticePage', page);
		history.push(`/review/${data?.id}`);
	};
	const currentDate = new Date().getTime();

	return (
		<>
			<section className="notice_list">
				<ul>
					{noticeData?.content &&
						noticeData?.content.map((data, idx) => (
							<li key={idx} onClick={() => goToDetail(data ? data : '')}>
								<a>
									<p className="tit">{data?.title ? data.title : ''}</p>
									<p className="date">
										<span>
											{data?.createdAt ? data.createdAt.slice(0, 10).replace(/-/g, '.') : ''}
										</span>
										<span className="new">
											{(currentDate - new Date(data?.createdAt ? data.createdAt : '').getTime()) /
												(1000 * 3600 * 24) <=
											7 ? (
												<span className="ico">New</span>
											) : (
												''
											)}
										</span>
									</p>
								</a>
							</li>
						))}
				</ul>
				<Pagination
					noticeData={noticeData}
					prevPage={prevPage}
					paginate={paginate}
					nextPage={nextPage}
					newPageNumbers={newPageNumbers}
					page={page}
				/>
			</section>
		</>
	);
};

export default ReviewList;
