import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getStoreListApi } from 'apis/buy';
import HaplMain from './components/HaplMain';
import HaplStep1 from './components/HaplStep1';
import HaplStep2 from './components/HaplStep2';
import HaplDetail from './components/HaplDetail';
import HaplStep1Modify from './components/HaplStep1Modify';
import './index.scss';
const DEFAULT_STORE_SELECT = {
	name: '직영점 선택',
	id: null,
};

const Hapl = () => {
	// const [filterSelectedStore, setFilterSelectedStore] = useState();
	const [filterSelectedStore, setFilterSelectedStore] = useState(DEFAULT_STORE_SELECT);
	const [storeList, setStoreList] = useState([]);
	const [oneStoreSearch, setOneStoreSearch] = useState();
	const [currStep, setCurrStep] = useState('main'); // main, step1, step2, detail, modifyStep1
	const [detailId, setDetailId] = useState();
	const [detailInfo, setDetailInfo] = useState();
	const [responseAfterReserve, setResponseAfterReserve] = useState();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currStep]);

	useEffect(() => {
		getStoreList();
	}, []);
	const goStore = () => {
		history.push('/store');
	};

	const goToMain = () => {
		setOneStoreSearch();
		if (currStep === 'main') {
			history.goBack();
		} else {
			setCurrStep('main');
		}
	};
	return (
		<section className="mobile-hapl-wrapper">
			<section className="back_wrap">
				<div>
					<button className="btn_back" onClick={goToMain}></button>
					<h2>공간대여</h2>
				</div>
			</section>
			<div className="top-photo-box">
				<div className="top-photo"></div>
				<div className="title-box">
					<div className="big-title">하이시간 공간대여 서비스</div>
					<div className="small-title">명품시계 어디서 직거래하시나요?</div>
					<div className="small-title2">하이시간에서 직거래도 명품답게 거래하세요.</div>
				</div>
				<div className="btn-wrapper">
					<div className="btn-box">
						<button className="reservation-btn">
							<span className="left-top-logo">HiSiGAN PLACE</span>
							<span className="content">공간대여 예약</span>
							<span className="line1"></span>
							<span className="line2"></span>
						</button>
						<button className="introduce-btn" onClick={goStore}>
							<span className="left-top-logo">HiSiGAN PLACE</span>
							<span className="content">부티크 소개</span>
							<span className="line1"></span>
							<span className="line2"></span>
						</button>
					</div>
				</div>
			</div>
			{currStep === 'main' ? (
				<HaplMain
					setOneStoreSearch={setOneStoreSearch}
					storeList={storeList}
					oneStoreSearch={oneStoreSearch}
					setCurrStep={setCurrStep}
					currStep={currStep}
					setDetailId={setDetailId}
					filterSelectedStore={filterSelectedStore}
					setFilterSelectedStore={setFilterSelectedStore}
					DEFAULT_STORE_SELECT={DEFAULT_STORE_SELECT}
				/>
			) : (
				''
			)}
			{currStep === 'step1' ? (
				<HaplStep1
					setOneStoreSearch={setOneStoreSearch}
					storeList={storeList}
					oneStoreSearch={oneStoreSearch}
					setCurrStep={setCurrStep}
					setResponseAfterReserve={setResponseAfterReserve}
				/>
			) : (
				''
			)}
			{currStep === 'step2' ? (
				<HaplStep2
					setOneStoreSearch={setOneStoreSearch}
					storeList={storeList}
					oneStoreSearch={oneStoreSearch}
					setCurrStep={setCurrStep}
					responseAfterReserve={responseAfterReserve}
					setDetailId={setDetailId}
				/>
			) : (
				''
			)}
			{currStep === 'detail' ? (
				<HaplDetail
					setOneStoreSearch={setOneStoreSearch}
					storeList={storeList}
					oneStoreSearch={oneStoreSearch}
					setCurrStep={setCurrStep}
					detailId={detailId}
					detailInfo={detailInfo}
					setDetailInfo={setDetailInfo}
				/>
			) : (
				''
			)}
			{currStep === 'modifyStep1' ? (
				<HaplStep1Modify
					setOneStoreSearch={setOneStoreSearch}
					storeList={storeList}
					oneStoreSearch={oneStoreSearch}
					setCurrStep={setCurrStep}
					detailInfo={detailInfo}
				/>
			) : (
				''
			)}
		</section>
	);
	// 부티크 지점 정보
	function getStoreList() {
		getStoreListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}
};
export default Hapl;
