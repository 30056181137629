import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'context/AppContext';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import buyImgWatch2 from 'assets-pc/img/common/buy_img_watch2.png';
import sale2Img from 'assets-pc/img/common/img_sale2.png';
import crossIco from 'assets-pc/img/common/ico_cross.svg';
import resetIco from 'assets-pc/img/common/ico_reset.svg';
import searchWatchIco from 'assets-pc/img/common/ico_search_watch.svg';
import BuySlider from './components/BuySlider';
import BuyFilterProduct from './components/BuyFilterProduct';

// 현재 쓰지 않는 컴포넌트
const Buy2 = () => {
	const [loginPopup, setLoginPopup] = useState(false);
	
	const {
		user: { data: userData, loggedIn },
	} = useAppState();

	const onClickButton = () => {
		if (userData.userType !== 'VISITOR') {
			//popup을 열어준다던가 api를 호출한다던가 다음 step
			setLoginPopup(true);
		} else {
			//구매예약을 할 수 있게 다음 step
		}
	};

	// 로그인 안되어있는데 구매예약 버튼 누르면 나타날 popup / 혹은 시계를 찾아줘 이용하려는데 로그인 안되어 있는 경우
	

	// 시계목록이 없어서 찾아줘 서비스 클릭하면 나올 popup

	// 찾아줘 서비스에서 신청 완료 popup
	
	
	return (
		<>
			<div className="container buy">
				<BuySlider />
				<section className="product_list">
					<BuyFilterProduct />
				</section>
				
			</div>
		</>
	);
};
export default Buy2;
