import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonHelper from 'helpers/CommonHelper';
import { getWithDrawalApi } from 'apis/myPage';
import { useAppState, useAppDispatch } from 'context/AppContext';

const WithdrawalPopup = withRouter(({ history }) => {
	const [isWithdrawalModalOpened, setIsWithdrawalModalOpened] = useState(false);
	const userId = useAppState().user.data.id;
	const appDispatch = useAppDispatch();

	const handleWithdrawalBtnClick = () => {
		setIsWithdrawalModalOpened(!isWithdrawalModalOpened);
	};

	const handleWithdrawalConfirm = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();

		if (authToken !== null && authToken !== undefined) {
			getWithDrawalApi(authToken, userId).then(({ data: { detail, status } }) => {
				if (status === 'SUCCESS') {
					CommonHelper.clearAuthenticateToken();
					CommonHelper.clearSessionAuthenticateToken();
					appDispatch({ type: 'LOGGED_OUT', data: { roles: [] } });
					history.push('/hapl');
				} else {
					alert(detail);
				}
			});
		}
	};

	const handleWithdrawalCancel = () => {
		setIsWithdrawalModalOpened(!isWithdrawalModalOpened);
	};

	return (
		<>
			<a className="btn_withdrawal" onClick={handleWithdrawalBtnClick}>
				회원탈퇴
			</a>
			{isWithdrawalModalOpened ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="alert">
						<p>
							<strong>정말 회원을 탈퇴 하시겠습니까?</strong>
							<br />
							회원을 탈퇴하시면 시계서비스에서 받으신 <br />
							혜택이 모두 사라지게 됩니다.
						</p>
						<div className="btn_wrap right">
							<button className="" onClick={handleWithdrawalCancel}>
								취소
							</button>
							<button className="ok" onClick={handleWithdrawalConfirm}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
		</>
	);
});

export default WithdrawalPopup;
