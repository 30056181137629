import { apiCall } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const postAuctionBuyMenuApi = (authToken, userId, OFFSET, buyPage) => {
	return apiCall(
		`${SERVER_API_HOST}/api/auction/order/list?page=${buyPage}&offset=${OFFSET}`,
		'get',
		{ userId },
		{
			authorization: authToken,
		},
	);
};
export const postAuctionSellMenuApi = (authToken, userId, OFFSET, sellPage) => {
	return apiCall(
		`${SERVER_API_HOST}/api/auction/list?page=${sellPage}&offset=${OFFSET}`,
		'post',
		{ userId },
		{
			authorization: authToken,
		},
	);
};
