import React, { useEffect, useRef, useState } from 'react';
import watchImg from 'assets-pc/img/common/img_watch.png';
import watchSImg from 'assets-pc/img/common/img_watch_s.png';
import Slick from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MainSlider = ({ sliderData }) => {
	const slickRef = useRef();
	const spanRef = useRef();
	const sliderLength = sliderData?.content ? sliderData.content.length : '';

	const onBeforeChange = (older, newidx) => {
		let calc = newidx / sliderLength; // 7 은 총 슬라이더 갯수
		let newCalc = (calc * sliderLength + 1) / sliderLength;
		spanRef.current.style.transForm = `translate3d(0px,0px,0px) scaleX(${newCalc}) scaleY(1)`;
		spanRef.current.style.width = newCalc * 100 + '%';
	};

	const settings = {
		beforeChange: onBeforeChange,
		fade: true,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1500,
		autoplaySpeed: 3000,
		arrows: true,
		appendDots: (dots) => (
			<div>
				<ul className="custom" style={{ bottom: '95px', left: '90px' }}>
					{dots}
				</ul>
			</div>
		),

		customPaging: (i) => <div>{i + 1}</div>,
	};

	const goToNext = () => {
		slickRef.current.slickNext();
	};

	const goToPrev = () => {
		slickRef.current.slickPrev();
	};

	return (
		<>
			<section className="main_top" style={{ height: '558px' }}>
				<SliderWripper>
					<div className="main-swiper-container" style={{ minHeight: '560px' }}>
						<div className="main-swiper-wrapper">
							<Slick {...settings} ref={slickRef}>
								{sliderData?.content ? (
									sliderData.content.map((data, index) => {
										return (
											<div className="main_cont" key={index}>
												<p className="t1">{data?.title ? data.title : '-'}</p>
												<p className="t2">
													{data?.content ? data.content : '-'}
													{/* 이번 달에도 변함 없는 <br />
														가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요. */}
												</p>
												{/* <a href={data?.link ? data.link : '#a'} className="link_shop">
													SHOP NOW
												</a> */}
												<a
													href="https://hisigan.co.kr/user/main/getSale.do?filter=N"
													className="link_shop"
												>
													SHOP NOW
												</a>
												<img
													src={data?.photoUrl ? data.photoUrl : '-'}
													style={{ width: '420px', height: '558px' }}
													alt=""
													className="big"
												/>
												<img
													src={data?.photoUrl ? data.photoUrl : '-'}
													style={{ width: '120px', height: '160px' }}
													alt=""
													className="small"
												/>
											</div>
										);
									})
								) : (
									<></>
									// <div className="swiper-slide main_cont" style={{minHeight:'560px'}}></div>
								)}
								{/* 
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너2</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div>
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너3</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div>
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너4</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div>
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너5</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div>
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너6</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div>
								<div className="swiper-slide main_cont">
									<p className="t1">이달의 시계 서브마리너7</p>
									<p className="t2">
										이번 달에도 변함 없는 <br />
										가치를 자랑하는 서브마리너, <br />그 품격을 느껴보세요.
									</p>
									<a className="link_shop">
										SHOP NOW
									</a>
									<img src={watchImg} alt="" className="big" />
									<img src={watchSImg} alt="" className="small" />
								</div> */}
							</Slick>
						</div>
					</div>

					{/* <!-- Add Pagination --> */}
					<div
						className="main-swiper-pagination bar main-swiper-pagination-progressbar"
						style={{
							position: 'relative',
						}}
					>
						<div
							className="main-swiper-pagination-progressbar-fill"
							ref={spanRef}
							style={{
								width: (1 / sliderLength) * 100 + '%',
								transitionDuration: '1000ms',
							}}
						></div>
					</div>

					{/* <!-- Add Arrows --> */}
					<div className="arrow_box" style={{ justifyContent: 'space-between' }}>
						<div className="main-swiper-button-prev" onClick={goToPrev}></div>
						<div className="main-swiper-button-next" onClick={goToNext}></div>
					</div>
				</SliderWripper>
			</section>
		</>
	);
};

export default MainSlider;


const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-slide {
		visibility: hidden;
	}
	.slick-slide.slick-active {
		visibility: visible;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;

		opacity: 0.75;
		color: white;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -25px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -25px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		/* margin-bottom: 30px; */
	}

	.slick-dots {
		position: absolute;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		/* width: 20px; */
		/* height: 2px; */
		/* margin: 0 6px; */
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		/* width: 20px; */
		/* height: 2px; */
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */
	/* .slick-dots li {
} */

	.slick-dots .slick-active {
		color: #31383f;
		border-radius: 0;
		opacity: 1;
		overflow: hidden;
	}
`;