import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swiper from 'swiper';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SeoulPic from 'assets-pc/front-img/m-store-seoul.png';
import DeaguPic from 'assets-pc/front-img/m-store-daegu.png';
import BusanPic from 'assets-pc/front-img/m-store-main.png';
import Boutique1 from 'assets-mobile/img/main/boutique_01.png';
import MarinPic from 'assets-pc/front-img/m-store-marin.png';
import 'assets-mobile/libs/swiper.min.css';

const StoreSlide = () => {
	const history = useHistory();
	const [totalWidth, setTotalWidth] = useState('');
	const sliderLength = 4;
	const onBeforeChange = (older, newidx) => {
		let calc = newidx / sliderLength; // 7 은 총 슬라이더 갯수
		let newCalc = (calc * sliderLength + 1) / sliderLength;
		// spanRef.current.style.transForm = `translate3d(0px,0px,0px) scaleX(${newCalc}) scaleY(1)`;
		// spanRef.current.style.width = newCalc * 100 + '%';
	};
	const sizeCheck = () => {
		setTotalWidth(window.innerWidth);
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);
	const settings = {
		beforeChange: onBeforeChange,
		fade: true,
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 3000,
		arrows: false,
		appendDots: (dots) => (
			<div>
				<ul className="custom" style={{ bottom: '116%', left: '85%' }}>
					{dots}
				</ul>
			</div>
		),

		customPaging: (i) => <div>{i + 1}</div>,
	};
	const imgStyle = {
		// width: '100%',
		// height: '100%',
		position: 'absolute',
		width: '600px',
		overflow: 'hidden',
		height: '400px',
		cursor: 'pointer',
	};
	useEffect(() => {
		const swiper2 = new Swiper('.boutique_list', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: false,
			pagination: {
				el: '.boutique_list_paging',
				clickable: true,
			},
		});
	}, []);
	return (
		<>
			<h2>하이시간 부티크</h2>
			<div className="swiper-container boutique_list">
				<div className="swiper-wrapper">
					<div className="swiper-slide" onClick={() => history.push('/store/0')}>
						<div
							style={{
								width: `${(totalWidth / 10) * 9}px`,
								height: `${(totalWidth / 10) * 8}px`,
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<img src={BusanPic} alt="본사 매장 사진" style={{ width: '100%', height: '100%' }} />
						</div>
						<p>하이시간 본사</p>
					</div>
					<div className="swiper-slide" onClick={() => history.push('/store/1')}>
						<div
							style={{
								width: `${(totalWidth / 10) * 9}px`,
								height: `${(totalWidth / 10) * 8}px`,
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<img
								src={SeoulPic}
								alt="서울 매장 사진"
								style={{
									width: '100%',
									height: '100%',
								}}
							/>
						</div>
						<p>서울 압구정점</p>
					</div>

					<div className="swiper-slide" onClick={() => history.push('/store/3')}>
						<div
							style={{
								width: `${(totalWidth / 10) * 9}px`,
								height: `${(totalWidth / 10) * 8}px`,
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<img src={DeaguPic} alt="대구 매장 사진" style={{ width: '100%', height: '100%' }} />
						</div>
						<p>대구 수성점</p>
					</div>
					<div className="swiper-slide" onClick={() => history.push('/store/2')}>
						<div
							style={{
								width: `${(totalWidth / 10) * 9}px`,
								height: `${(totalWidth / 10) * 8}px`,
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<img
								src={MarinPic}
								alt="마린시티 매장 사진"
								style={{
									width: '100%',
									height: '100%',
								}}
							/>
						</div>
						<p>마린시티점</p>
					</div>
				</div>
			</div>
			<div className="boutique_list_paging swiper-pagination"></div>
		</>
	);
};
export default StoreSlide;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-slide {
		visibility: hidden;
	}
	.slick-slide.slick-active {
		visibility: visible;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;

		opacity: 0.75;
		color: white;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -25px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -25px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		/* margin-bottom: 30px; */
	}

	.slick-dots {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		/* width: 20px; */
		/* height: 2px; */
		/* margin: 0 6px; */
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		/* width: 20px; */
		/* height: 2px; */
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */
	/* .slick-dots li {
} */

	.slick-dots .slick-active {
		color: #31383f;
		border-radius: 0;
		opacity: 1;
		overflow: hidden;
	}
`;
