import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { useHistory } from 'react-router-dom';
import { Viewer } from '@toast-ui/react-editor';
import styled, { css } from 'styled-components';
import Chart from 'chart.js';
import { getOneSellingProductApi, createPurchaseReservationApi } from 'apis/buyDetail';
import { getStoreListApi } from 'apis/buy';
import { handlePremiumNum } from 'common/common';
import CommonHelper from 'helpers/CommonHelper';
import Certification from 'assets-mobile/img/common/ic_certificate.svg';
import IcoBox from 'assets-mobile/img/common/ic_box.svg';
import { SUPPLEMENTS_EN_KO, GENDER_KO_EN, MOVEMENT_EN_KO, GENDER_EN_KO } from 'common/constants';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';
import BuyReservation from './components/BuyReservation';
import DetailView from './components/DetailView';

const BuyDetail = () => {
	const history = useHistory();
	const [oneSellingProductInfo, setOneSellingProductInfo] = useState({});
	const [onePhotoUrl, setOnePhotoUrl] = useState(null); //큰 사진 url
	const [currentStoreList, setCurrentStoreList] = useState([]);
	const [showLoginNeedPopup, setShowLoginNeedPopup] = useState(false);
	const [showReservationFormPopup, setShowReservationFormPopup] = useState(false);

	useEffect(() => {
		getOneSellingProduct();
		getStoreList();
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const checkMemberForReservation = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			setShowLoginNeedPopup(true);
		} else {
			setShowReservationFormPopup(true);
		}
	};

	const onClickCall = () => {
		window.location.href = 'tel:1644-5751';
	};

	const getChart = (oneProductData) => {
		const productPriceArr = oneProductData?.pricehistoires?.map((item) => {
			return item.price;
		});
		const productDayArr = oneProductData?.pricehistoires?.map((item) => {
			const year = item?.day?.slice(0, 4);
			const month = item?.day?.slice(4, 6);
			const day = item?.day?.slice(6, 8);
			return `${year}-${month}-${day}`;
		});

		let myChart = document.getElementById('mobileBuyDetailChart');
		// productPriceArr.map((item, idx) => {
		var ctx = myChart.getContext('2d');
		var gradientFill = ctx.createLinearGradient(0, 70, 0, 0);
		gradientFill.addColorStop(1, 'rgba(84, 93, 102, .5)');
		gradientFill.addColorStop(0, 'rgba(84, 93, 102, 0)');

		new Chart(ctx, {
			// The type of chart we want to create
			type: 'line',

			// The data for our dataset
			data: {
				labels: productDayArr?.slice(-4, -1),
				datasets: [
					{
						backgroundColor: gradientFill,
						borderColor: 'rgba(0, 0, 0, 0.25)',
						data: productPriceArr?.slice(-4, -1),
						pointRadius: 0,
						lineTension: 0,
						boderWidth: 1,
						showline: true,
					},
				],
			},

			// Configuration options go here
			options: {
				animation: {
					duration: 0,
				},
				legend: {
					display: false,
				},
				tooltips: false,
				clip: false,
				elements: {
					point: {
						radius: 0,
					},
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
								min: Math.min.apply(this, productPriceArr?.slice(-4, -1)),
								max: Math.max.apply(this, productPriceArr?.slice(-4, -1)),
								callback: function (value, index, values) {
									if (index === values.length - 1)
										return Math.min.apply(this, productPriceArr?.slice(-4, -1));
									else if (index === 0) return Math.max.apply(this, productPriceArr?.slice(-4, -1));
									else return '';
								},
							},
						},
					],
				},
			},
		});
		// });
	};

	const goToLinkUrl = () => {
		if (!oneSellingProductInfo.linkUrl) return;

		window.open(oneSellingProductInfo.linkUrl);
	};

	return (
		<>
			{showLoginNeedPopup && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t1">해당 기능은 로그인 후 사용 가능합니다.</p>
						<button className="btn_ok" onClick={() => history.push('/signIn')}>
							확인
						</button>
					</article>
				</div>
			)}
			{showReservationFormPopup ? (
				<BuyReservation
					setShowReservationFormPopup={setShowReservationFormPopup}
					currentStoreList={currentStoreList}
					oneSellingProductInfo={oneSellingProductInfo}
				/>
			) : (
				<>
					<main className="buy">
						<section className="back_wrap">
							<button className="btn_back" onClick={() => history.goBack()}></button>
							<h2>제품 상세</h2>
						</section>
						<section className="product_detail">
							<div className="product_top">
								{!!oneSellingProductInfo?.price &&
									!!oneSellingProductInfo?.standardWatch?.officialPrice &&
									handlePremiumNum(
										oneSellingProductInfo?.price,
										oneSellingProductInfo?.standardWatch?.officialPrice,
									) && (
										<p className="sale">
											{handlePremiumNum(
												oneSellingProductInfo?.price,
												oneSellingProductInfo?.standardWatch?.officialPrice,
											)}
											% Premium
										</p>
									)}
								{oneSellingProductInfo ? (
									<>
										<Swiper
											slidesPerView={1}
											spaceBetween={30}
											loop={false}
											pagination={{
												el: '.swiper-pagination',
												clickable: true,
											}}
											renderBullet={function (index, className) {
												return '<span class="' + className + '">' + (index + 1) + '</span>';
											}}
										>
											<div className="swiper-container product_img">
												<div className="swiper-wrapper">
													{oneSellingProductInfo?.attachedFiles?.map((photo, idx) => (
														<SwiperSlide className="swiper-slide" key={idx}>
															<div className="img_box">
																<img src={photo} alt="" />
															</div>
														</SwiperSlide>
													))}
												</div>
											</div>
											<div className="product_img_paging swiper-pagination"></div>
										</Swiper>
									</>
								) : (
									''
								)}
								<p className="t1">
									최근 48시간 동안{' '}
									<b>
										{oneSellingProductInfo?.hourCount
											? Number(oneSellingProductInfo?.hourCount).toLocaleString()
											: 0}
										명
									</b>
									이 이 시계를 조회하였습니다.
								</p>
							</div>

							<div className="product_info">
								<div className="info_box">
									<p className="t1 poppins">{oneSellingProductInfo?.watch?.brand || '-'} </p>
									<p className="t2">{oneSellingProductInfo?.watch?.model || '-'}</p>
									<p className="t3">{oneSellingProductInfo?.description || '-'}</p>
									<p className="price">
										₩{' '}
										{oneSellingProductInfo?.price
											? Number(oneSellingProductInfo?.price).toLocaleString()
											: '-'}
									</p>
								</div>

								<ul className="info_list">
									<li>
										<b>생산년도</b>
										<div>{oneSellingProductInfo?.watch?.producedYear || '미확인'}</div>
									</li>
									<li>
										<b>보증서</b>
										<div>
											{oneSellingProductInfo?.watch?.supplements.includes('GUARANTEE') && (
												<img src={Certification} alt="보증서 아이콘" />
											)}
										</div>
									</li>
									<li>
										<b>박스유무</b>
										<div>
											{oneSellingProductInfo?.watch?.supplements.includes('BOX') && (
												<img src={IcoBox} alt="박스 아이콘" />
											)}
										</div>
									</li>
									<li>
										<b>가격변동</b>
										<div>
											<span
												className="fluctuations down"
												// className={
												// 	oneSellingProductInfo?.standardWatch?.hpi > 0
												// 		? 'fluctuations up'
												// 		: oneSellingProductInfo?.standardWatch?.hpi < 0
												// 		? 'fluctuations down'
												// 		: oneSellingProductInfo?.standardWatch?.hpi === 0
												// 		? 'fluctuations'
												// 		: ''
												// }
											>
												{oneSellingProductInfo?.standardWatch?.hpi || '-'}
											</span>
										</div>
									</li>
								</ul>

								<div className="graph_box">
									<p>최근 가격 변동 추이</p>
									<div className="graph">
										<canvas id="mobileBuyDetailChart" width="75.556vw" height="24.306vw"></canvas>
									</div>
								</div>

								<div className="branch_list">
									<h3>재고보유점</h3>
									<ul>
										{currentStoreList.map((store) => (
											<li
												key={store.id}
												className={
													oneSellingProductInfo?.stores?.filter((el) => el.id === store.id).length >
													0
														? 'checked'
														: ''
												}
											>
												<label>
													<input
														type="radio"
														name={`branch ${store.id}`}
														// checked={store?.name === oneSellingProductInfo?.store?.name}
														checked={
															oneSellingProductInfo?.stores?.filter((el) => el.id === store.id)
																.length > 0
																? 'checked'
																: ''
														}
														className="checked"
														style={{ margin: '5px' }}
														disabled
													/>
													<span>{store.name || '-'}</span>
												</label>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className="info_wrap">
								<h3>정보</h3>
								<ul>
									<li>
										<b>레퍼런스</b>
										<span>{oneSellingProductInfo?.watch?.serialNo || '-'}</span>
									</li>
									<li>
										<b>브랜드</b>
										<span>{oneSellingProductInfo?.watch?.brand || '-'}</span>
									</li>
									<li>
										<b>모델</b>
										<span>{oneSellingProductInfo?.watch?.model || '-'}</span>
									</li>
									<li>
										<b>무브먼트</b>
										<span>
											{oneSellingProductInfo?.watch?.movement
												? MOVEMENT_EN_KO[oneSellingProductInfo?.watch?.movement]
												: '-'}
										</span>
									</li>
									<li>
										<b>케이스 소재</b>
										<span>{oneSellingProductInfo?.watch?.caseMaterial || '-'}</span>
									</li>
									<li>
										<b>지름 (mm)</b>
										<span>{oneSellingProductInfo?.watch?.caseDiameter || '-'}</span>
									</li>
									<li>
										<b>구매년도</b>
										<span>{oneSellingProductInfo?.watch?.producedYear || '미확인'}</span>
									</li>
									<li>
										<b>상품 컨디션 (0~10)</b>
										<span>{oneSellingProductInfo?.watch?.condition || '-'}</span>
									</li>
									<li>
										<b>구성품</b>
										<span>
											{oneSellingProductInfo?.watch?.supplements?.map((el, idx) => {
												return (
													<span style={{ marginRight: '10px' }} key={idx}>
														{el ? SUPPLEMENTS_EN_KO[el] : '-'}{' '}
													</span>
												);
											})}
										</span>
									</li>
									<li>
										<b>성별</b>
										<span>
											{oneSellingProductInfo?.watch?.gender
												? GENDER_EN_KO[oneSellingProductInfo?.watch?.gender]
												: '-'}
										</span>
									</li>
									<li>
										<b>다이얼 색상</b>
										<span>{oneSellingProductInfo?.watch?.dialColor || '-'}</span>
									</li>
									<li>
										<b>다이얼 타입</b>
										<span>{oneSellingProductInfo?.watch?.dialType || '-'}</span>
									</li>
									<li>
										<b>국내/국외 보유</b>
										<span>{oneSellingProductInfo?.holdingType || '-'}</span>
									</li>
									{/* <li>
										<b>영수증 유무</b>
										<span>{oneSellingProductInfo?.hasReceipt ? '유' : '무'}</span>
									</li> */}
								</ul>
								<h3>설명</h3>
								<p>
									<div style={{ margin: '30px auto', width: '80%' }}>
										{oneSellingProductInfo?.additionalInfo && (
											<Wrapper>
												{/* <Viewer
													initialValue={replaceNewlineWithBrTag(
														decodingHtmlTags(oneSellingProductInfo?.additionalInfo || ''),
													)}
													initialEditType="markdown"
												/> */}
												<DetailView markdown={oneSellingProductInfo?.additionalInfo || ''} />
											</Wrapper>
										)}
									</div>
								</p>
							</div>

							{oneSellingProductInfo?.state === 'SOLD' ? (
								<div className="btn_wrap">
									<button className="buy_reservation buy_bidding_end btn">
										판매 종료된 상품입니다.
									</button>
								</div>
							) : (
								<div className="btn_wrap_2">
									<button className="btn" onClick={onClickCall}>
										구입문의 1644-5751
									</button>
									<button
										className="btn"
										onClick={goToLinkUrl}
										disabled={!oneSellingProductInfo.linkUrl}
									>
										예약하러 가기
									</button>
								</div>
							)}
						</section>
					</main>
				</>
			)}
		</>
	);
	function getOneSellingProduct() {
		let nowProductId = window.location.pathname.split('/')[2];
		if (nowProductId) {
			getOneSellingProductApi(nowProductId).then(({ data }) => {
				if (data.status === 'SUCCESS') {
					setOneSellingProductInfo(data.data || {});
					setOnePhotoUrl(data?.data?.attachedFiles[0] ? data.data.attachedFiles[0] : null);
					getChart(data.data || {});
				} else {
					alert(data?.detail);
				}
			});
		}
	}

	function getStoreList() {
		getStoreListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}
};
export default BuyDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	display: flex;
	justify-content: center;
	img {
		max-width: 250px;
	}
`;
