import React, { useEffect, useRef } from 'react';
// import 'codemirror/lib/codemirror.css'; // Editor's Dependency Style
import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
import { Viewer } from '@toast-ui/react-editor';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const DetailView = ({ markdown }) => {
	const marked = replaceNewlineWithBrTag(decodingHtmlTags(markdown));
	const viewerRef = useRef(null);

	useEffect(() => {
		viewerRef.current.getInstance().setMarkdown(marked);
	}, [marked]);

	return <Viewer ref={viewerRef} initialValue={marked} initialEditType="markdown" />;
};

export default DetailView;
