import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Care1 from 'assets-pc/front-img/001.svg';
import Care2 from 'assets-pc/front-img/002.svg';
import Care3 from 'assets-pc/front-img/003.svg';
import Care4 from 'assets-pc/front-img/004.svg';
import Care5 from 'assets-pc/front-img/new_guarantee.svg';
import Care6 from 'assets-pc/front-img/new_care_6.svg';
import CareBanner from 'assets-pc/front-img/007_banner.svg';

const CareService = () => {
	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <section className="care_sub_top">
				<div className="
                care_sub_top_img"></div>
			</section> */}
			<section className="back_wrap">
				<button className="btn_back" onClick={() => history.goBack()}></button>
				<h2>하이시간케어서비스</h2>
			</section>
			<section className="care-wrapper">
				<div className="img-wrapper">
					<img src={Care1} className="care_img" />
				</div>
				<div className="care-second-wrapper">
					<div className="title-wrapper">
						하이시간 <span className="beige">케어프로그램</span>
					</div>
					<div className="content-box">
						고객님들에 더 풍부하고 더 혁신적인 맞춤 서비스를 제공하기 위해 오랜 경력의 전문 감정사가
						다양한 방법으로 검수 진행 합니다. 검증 완료된 상품은 하이시간 보증카드와 보증서를
						발급하여 시리얼 고유번호를 입력함으로써 도난방지 및 고객 맞춤 서비스를 진행해 드리는
						시스템 입니다.
					</div>
					<div className="content-box">
						오리지널 하이시간 보증 카드에 등록된 정보에 맞춰 고객님의 요구와 개인 맞춤 콘텐츠,
						기술적 전문 정보 등을 제공 해 드립니다.
					</div>
					<div className="content-box">하이시간 케어 프로그램은 세가지를 기반으로 합니다.</div>
					<ul className="content-box">
						<li>1년 품질 보증카드</li>
						<li>HPI 시세 제공</li>
						<li>사후관리 맞춤 서비스</li>
					</ul>
				</div>
				<div className="img-wrapper">
					<img src={Care4} className="care_img" />
				</div>
				<div className="care-third-wrapper">
					<div className="title-wrapper">
						하이시간 HPI(<span className="red">H</span>ISIGAN <span className="red">P</span>RICE{' '}
						<span className="red">I</span>NDEX)
					</div>
					<div className="content-box">
						가장 신뢰할 수 있는 표준 시계 거래 가격을 나타내는 하이시간 가격 지표 입니다. 다양한
						시계들의 정보를 통합하여 만들어진 HPI는 고객만족 향상을 위해 만들어진 가장 신뢰할 수
						있는 표준 시계 가격을 제공합니다.
					</div>
					<div className="bold-content-box">
						국내업계 최고 수준의 전문 감정을 기반으로 명확한 시세 동향과 가치를 제시하고 있습니다.
					</div>
				</div>
				<div className="img-wrapper">
					<img src={Care5} className="care_img" />
				</div>
				<div className="care-third-wrapper">
					<div className="last-title-wrapper">하이시간 사후관리 맞춤 시스템</div>
					<div className="content-box">
						하이시간 오리지널 "보증카드"에 입력된 고객정보로 시계사용 및 관리에 관한 맞춤 조언을
						받을 수 있습니다.
					</div>
				</div>
				<div className="care-third-wrapper">
					<img src={Care6} style={{ width: '100%' }} />
				</div>
			</section>
		</>
	);
};

export default CareService;

const ImgBox = styled.div`
	height: 400px;
	overflow: hidden;
	position: relative;
	div {
		position: absolute;
		background: linear-gradient(
				to right,
				black 16%,
				rgba(0, 0, 0, 0.75) 30%,
				rgba(0, 0, 0, 0.5) 50%,
				rgba(0, 0, 0, 0.5) 65%,
				rgba(0, 0, 0, 1) 100%
			),
			url('../../assets-pc/front-img/007_banner.svg');
		background-size: 100% 100%;
		background-position: 102% -630%;
		width: 756px;
		height: 400px;
		background-repeat: no-repeat;
	}
`;
