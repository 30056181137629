import axios from 'axios';
import CommonHelper from 'helpers/CommonHelper';

/**
 * Axios 통신 모듈 Response 공통 처리
 */
axios.interceptors.response.use(
	function (response) {
		// if (response?.data?.data?.loginId && response?.data?.data?.loginId === 'guest') {
		// 	localStorage.removeItem('siganAuthToken');
		// 	sessionStorage.removeItem('siganAuthToken');
		// }
		return response;
	},
	function (error) {
		if (error?.response?.status === 403 || error?.response?.status === 401) {
			console.log('인증 오류');
			localStorage.removeItem('siganAuthToken');
			sessionStorage.removeItem('siganAuthToken');
			alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
			window.location.replace('/signin');
		} else if (error?.response?.status === 500) {
			alert('서버에 문제가 생겼습니다.');
		}
		return Promise.reject(error);
	},
);

export const apiCall = (url, method = 'get', payload = {}, options) => {
	const { timeout, withCredentials, responseType, authorization } = options || {};
	const headers = {};

	if (authorization) {
		headers['Authorization'] = `Bearer ${authorization}`;
		headers['Access-Control-Allow-Origin'] = '*';
		headers['Content-Type'] = 'application/json';
	}

	return axios({
		url,
		method,
		data: (method === 'post' || method === 'put' || method === 'delete') && payload,
		params: method === 'get' && payload,
		// timeout: timeout || 60000, // 60초 timeout
		timeout: timeout || 300000, //5분 timeout, TODO: 60초로 변경할 것
		withCredentials: withCredentials || false, // CORS 관련 설정
		responseType: responseType || 'json', // response Type default : JSON,
		headers,
	});
};

export const apiCallByFormData = (url, formData, options) => {
	const { timeout, responseType } = options || {};

	return axios({
		url,
		method: 'post',
		data: formData,
		// timeout: timeout || 60000, // 60초 timeout
		timeout: timeout || 300000, //5분 timeout , TODO: 60초로 변경할 것
		// withCredentials: withCredentials || true, // CORS 관련 설정
		responseType: responseType || 'json', // response Type default : JSON,
		// maxContentLength: maxContentLength || 2000,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'multipart/form-data',
		},
	});
};
