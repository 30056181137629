import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { getShopInfoApi } from 'apis/map';
import { useHistory } from 'react-router';
import ShopContent from './ShopContent';
import GoogleMapComponent from './GoogleMapComponent';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Seoul from './components/Seoul';
import Deagu from './components/Deagu';
import Busan from './components/Busan';
import Marin from './components/Marin';
import HaplReservation from '../../assets-pc/front-img/hapl-4.png';
import ButiqueInfo from '../../assets-pc/front-img/boutique-4.png';
import './index.scss';

const ShopInfo = () => {
	const [shopDatas, setShopDatas] = useState({});
	const [oneData, setOneData] = useState({});
	const [shopName, setShopName] = useState(null);
	const [mockShopName, setMockShopName] = useState(null);
	const history = useHistory();
	const OFFSET = 100;
	const PAGE = 0;
	const { name } = useParams();
	useEffect(() => {
		getShopData();
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (window.location.pathname.split('/')[2]) {
			// const whichStore =
			// 	window.location.pathname?.split('/')[2]?.length > 0
			// 		? decodeURIComponent(window.location.pathname.split('/')[2])
			// 		: '';
			setMockShopName(window.location.pathname.split('/')[2].toString());
		} else {
			setMockShopName('1');
		}
	}, []);

	const getShopData = () => {
		getShopInfoApi(PAGE, OFFSET).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				const matched = data.content.find((data) => data.name === name);
				setShopDatas(data || {});
				// setOneData(data.content[0] || {});
				setOneData(matched ? matched : data?.content[0] ? data.content[0] : {});
				setShopName(
					matched?.name ? matched.name : data?.content[0]?.name ? data.content[0].name : {},
				);
			} else {
				alert(detail);
			}
		});
	};

	const handleClick = (e, data) => {
		const { name } = e.target;
		setShopName(name);
		setOneData(data);
	};
	const goHapl = () => {
		history.push('hapl');
	};

	return (
		<>
			<div className="container shop">
				<section>
					<div className="sub-top-hapl">
						{/* <div className="sub-wrapper"> */}
						<h1 style={{ position: 'absolute', bottom: '160px', fontWeight: '500', fontSize: '36px' }}>하이시간 부티크</h1>
						<div className="sub-title" style={{ position: 'absolute', bottom: '120px' }}>
							하이시간은 고객님 가까이에 있습니다. <br />
							개인간 직거래 공간대여도 하이시간 부티크에서 무료로 이용하세요.
						</div>
						<div className="btn-box" style={{ position: 'absolute', bottom: '36px' }}>
							{/* <button className="reservation-btn">
							<span className="left-top-logo">HiSiGAN PLACE</span>
							<span className="content">공간대여 예약</span>
							<span className="line1"></span>
							<span className="line2"></span>
						</button> */}
							<img
								src={HaplReservation}
								alt="공간대여 예약"
								style={{ marginRight: '2px', cursor: 'pointer', width: '211px' }}
								onClick={goHapl}
							/>
							{/* <button className="introduce-btn" onClick={goStore}>
							<span className="left-top-logo">HiSiGAN PLACE</span>
							<span className="content">부티크 소개</span>
							<span className="line1"></span>
							<span className="line2"></span>
						</button> */}
							<img
								src={ButiqueInfo}
								alt="부티크 소개"
								style={{ marginLeft: '2px', cursor: 'pointer', width: '211px' }}
							/>
						</div>
						{/* </div> */}
					</div>
				</section>
				{/* <section className="sub_top">
					<h1>매장안내</h1>
					<p>하이시간의 업데이트 정보 등 다양한 소식을 알려드립니다.</p>
				</section> */}
				{mockShopName === '1' && (
					<Busan setMockShopName={setMockShopName} mockShopName={mockShopName} />
				)}
				{mockShopName === '2' && (
					<Seoul setMockShopName={setMockShopName} mockShopName={mockShopName} />
				)}
				{mockShopName === '3' && (
					<Marin setMockShopName={setMockShopName} mockShopName={mockShopName} />
				)}
				{mockShopName === '4' && (
					<Deagu setMockShopName={setMockShopName} mockShopName={mockShopName} />
				)}
			</div>
		</>
	);
};

export default ShopInfo;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: ${(props) =>
			props.mostShowedProducts?.length === 1
				? '385px'
				: props.mostShowedProducts?.length === 2
				? '800px'
				: ''};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		display: ${(props) => (props.mostShowedProducts?.length < 3 ? 'flex' : 'block')};

		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		width: 60px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -66px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -60px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */

	.slick-dots li {
		width: 20px;
		height: 2 px;
		margin: 0 5px;
	}

	.slick-dots .slick-active {
		width: 20px;
		height: 2px;
		background: #31383f;
		border-radius: 0;
		opacity: 1;
		width: 20px;
		top: 0px;
		overflow: hidden;
	}
`;
const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
`;
