import React from 'react';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import buyImgWatch2 from 'assets-pc/img/common/buy_img_watch2.png';
import sale2Img from 'assets-pc/img/common/img_sale2.png';
import AuctionSlider from './components/AuctionSlider';
import AuctionFilterProduct from './components/AuctionFilterProduct';
import AuctionTop1 from 'assets-pc/front-img/auction_top_img_01.png';
import AuctionTop2 from 'assets-pc/front-img/auction_top_img_02.jpeg';

const Auction = () => {
	return (
		<>
			<div className="container auction">
				<div className="warranty">
					<section className="auction_sub_top auction_sub_top_2 hidden-center">
						<div className="whole_frame">
							<div className="auction-top-img">
								<div className="auction-top-first" />
							</div>
							<div className="auction-top-img">
								<div className="auction-top-second" />
							</div>
						</div>
						<h1 className="auction-top-text-big">하이드로우</h1>
						{/* <h4 className="auction-top-text">
							다수의 신청 희망자로부터 각자의 낙찰 희망 예정가격을 제출·입찰하게 하여
							<br />
							희망 판매예정가액이나 판매가격이 최저가격인 것이나 또는 구매가격이 최고인
							<br />
							입찰자와 계약을 체결하는 방식의 특별한 하이시간 옥션 기능입니다.
						</h4> */}
						<h4 className="auction-top-text-big" style={{marginTop: '15px', textAlign: 'center'}}>
							하이시간 드로우에 응모하여 당첨자에 한해서만 구매를 할 수 있는 하이시간 드로우입니다.
							<br />
							최근 거래가 보다 더싸게 구입할 수 있다는데, 하이드로우 당첨을 기대해보세요.
						</h4>
					</section>
				</div>
				<AuctionSlider />
				<div className="buy">
					<section className="product_list">
						<AuctionFilterProduct />
					</section>
				</div>
			</div>
		</>
	);
};

export default Auction;
