import { apiCall } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const postAuctionApplyApi = (depositor, convertStartDate, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/auction/apply`,
		'post',
		{ senderName: depositor, depositTime: convertStartDate },
		{
			authorization: authToken,
		},
	);
};
