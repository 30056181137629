import React, { useState } from 'react';
import AuctionSellPagination from './AuctionSellPagination';

const AuctionSell = ({ auctionSellData, sellPage, setSellPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = auctionSellData;

	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);

	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setSellPage(num);
	};
	const prevPage = () => {
		if (sellPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((sellPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setSellPage(sellPage - 1);
	};

	const nextPage = () => {
		if (sellPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setSellPage(sellPage + 1);
			if ((sellPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<table className="tbl">
				<colgroup>
					<col style={{ width: '5%' }} />
					<col style={{ width: '7%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<thead>
					<tr>
						<th>번호</th>
						<th>구분</th>
						<th>신청일</th>
						<th>상품명</th>
						<th>참여자수</th>
						<th>현재 최고가격</th>
						<th>상태</th>
					</tr>
				</thead>
				<tbody>
					{auctionSellData?.content?.length > 0 ? (
						auctionSellData &&
						auctionSellData.content.map((data, index) => {
							return (
								<tr key={index}>
									<td>{data?.id ? data.id : '-'}</td>
									<td>
										<span className="type">판매</span>
									</td>
									<td>{data?.createdAt ? data.createdAt.slice(0, 10) : '-'}</td>
									<td>
										<b>{data?.watch?.model ? data.watch.model : '-'}</b>
									</td>
									<td>{data?.orderPeopleCount ? data.orderPeopleCount + '명' : '-'}</td>
									<td>
										<b className="price">
											{
												data?.auctionStatus
													? data.auctionStatus === 'DONE'
														? data.fixedPrice === 0
															? Number(data.price).toLocaleString()
															: Number(data.fixedPrice).toLocaleString()
														: data.auctionStatus === 'RUN'
														? data.lastPrice > 0
															? Number(data.lastPrice).toLocaleString()
															: Number(data.price).toLocaleString()
														: data.auctionStatus === 'WAIT'
														? Number(data.price).toLocaleString()
														: '-'
													: '-'
												// data?.price ? data.price.toLocaleString() : '-'
											}
										</b>
									</td>
									<td>
										<span
											className={
												data?.auctionStatus
													? data.auctionStatus === 'DONE'
														? 'state_txt t1'
														: data.auctionStatus === 'WAIT'
														? 'state_txt stay'
														: data.auctionStatus === 'RUN'
														? 'state_txt'
														: ''
													: ''
											}
										>
											{data?.auctionStatus
												? data.auctionStatus === 'DONE'
													? '종료'
													: data.auctionStatus === 'WAIT'
													? '대기중'
													: data.auctionStatus === 'RUN'
													? '진행중'
													: ''
												: ''}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr className="empty">
							<td colSpan="7">
								<p>최근 이력이 없습니다.</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{auctionSellData && auctionSellData?.content?.length > 0 ? (
				<AuctionSellPagination
					prevPage={prevPage}
					paginate={paginate}
					nextPage={nextPage}
					newPageNumbers={newPageNumbers}
					sellPage={sellPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default AuctionSell;
