import React from 'react';
import HpiTop from 'assets-mobile/img/hpi/hpi_top.png';
import Graph1 from 'assets-mobile/img/hpi/hpi_graph_01.png';
import Graph2 from 'assets-mobile/img/hpi/hpi_graph_02.png';
import Graph3 from 'assets-mobile/img/hpi/hpi_graph_03.png';
import Graph4 from 'assets-mobile/img/hpi/hpi_graph_04.png';
import Graph5 from 'assets-mobile/img/hpi/hpi_graph_05.png';
import Graph6 from 'assets-mobile/img/hpi/hpi_graph_06.png';

const Hpi = () => {
	return (
		<>
			<main className="hpi">
				<section className="back_wrap">
					<button className="btn_back"></button>
					<h2>hpi</h2>
				</section>

				<section className="hpi_top">
					<img src={HpiTop} alt="" />
					<p className="t1">시계의 가격추이를 알 수 있는</p>
					<p className="t2">하이시간만의 가격지표 입니다.</p>
				</section>

				<section className="hpi_info">
					<p className="t1">
						<b>선형 회귀</b>라는 공식을 이용하여 <br />
						<strong>곡선인 차트를 선형화</strong> 시켰습니다.
					</p>
					<p className="t2">선형 회귀란?</p>
					<p className="t3">
						통계학에서, 선형 회귀(線型回歸, 영어: linear <br />
						regression)는 종속 변수 y와 한 개 이상의 <br />
						독립 변수 (또는 설명 변수) X와의 <br />
						선형 상관 관계를 모델링하는 회귀분석 기법입니다.
					</p>
					<p className="t4">
						이와같이 가격이 오르내리면{' '}
						<b>
							직관적으로 <br />
							가격의 추세를 알기가 어렵습니다.
						</b>
					</p>
					<img src={Graph1} alt="" />
					<p className="t4">
						하이시간에서는{' '}
						<b>
							가격을 표준화시켜 추세를 <br />좀 더 명확하게 알 수 있게
						</b>{' '}
						하였습니다.
					</p>
					<img src={Graph2} alt="" />

					<h3>가격이 쭉 상승하면 100점</h3>
					<img src={Graph3} alt="" />
					<h3>가격이 쭉 하락하면 0점</h3>
					<img src={Graph4} alt="" />
					<h3>가격이 일정 가격을 유지하면 50점</h3>
					<img src={Graph5} alt="" />
					<h3>
						50점과 0점 사이를 내린다면 해당 비율만큼 <br />
						점수를 부과하여 30점
					</h3>
					<img src={Graph6} alt="" />
				</section>
			</main>
		</>
	);
};

export default Hpi;
