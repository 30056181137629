import React, { useState } from 'react';
import FilterBrand from './FilterBrand';
import FilterModel from './FilterModel';
import FilterCondition from './FilterCondition';
import FilterProducedYear from './FilterProducedYear';
import FilterGender from './FilterGender';
import FilterCaseDiameter from './FilterCaseDiameter';
import FilterMovement from './FilterMovement';
import FilterSupplements from './FilterSupplements';
import FilterCaseMaterial from './FilterCaseMaterial';
import FilterDialColor from './FilterDialColor';
import FilterReference from './FilterReference';
import FilterDialType from './FilterDialType';
import FilterStores from './FilterStores'

const BuyFilter = ({
	setShowFilter,
	selectedBrandList,
	setSelectedBrandList,
	selectedModelFilter,
	setSelectedModelFilter,
	modelResultBox,
	searchModelInput,
	setSearchModelInput,
	searchReference,
	setSearchReference,
	selectedMinCaseDiameters,
	setSelectedMinCaseDiaMeters,
	selectedMaxCaseDiameters,
	setSelectedMaxCaseDiameters,
	selectedMovement,
	setSelectedMovement,
	selectedSupplements,
	setSelectedSupplements,
	selectedGender,
	setSelectedGender,
	selectedCaseMaterial,
	setSelectedCaseMaterial,
	selectedDialColor,
	setSelectedDialColor,
	selectedDialType,
	setSelectedDialType,
	selectedStoreIdList,
	setSelectedStoreIdList,
	producedYearMaxInput,
	setProducedYearMaxInput,
	producedYearMinInput,
	setProducedYearMinInput,
	conditionStart,
	setConditionStart,
	conditionEnd,
	setConditionEnd,
	totalBrandList,
	currentStoreList,
	selectedStoreList,
	setSelectedStoreList,
}) => {
	const [showBrandFilter, setShowBrandFilter] = useState(false);
	const [showModelFilter, setShowModelFilter] = useState(false);
	const [showConditionFilter, setShowConditionFilter] = useState(false);
	const [showProducedYearFilter, setShowProducedYearFilter] = useState(false);
	const [showGenderFilter, setShowGenderFilter] = useState(false);
	const [showCaseDiameter, setShowCaseDiameter] = useState(false);
	const [showMovement, setShowMovement] = useState(false);
	const [showSupplements, setShowSupplements] = useState(false);
	const [showCaseMaterial, setShowCaseMaterial] = useState(false);
	const [showDialColor, setShowDialColor] = useState(false);
	const [showReference, setShowReference] = useState(false);
	const [showDialType, setShowDialType] = useState(false);
	const [showStores, setShowStores] = useState(false);

	const deleteAllFilters = () => {
		setSelectedBrandList([]);//브랜드
		setSelectedModelFilter([]);//모델
		setSearchReference('');//레퍼런스
		setSelectedMinCaseDiaMeters('');//사이즈 미니멈
		setSelectedMaxCaseDiameters('');//사이즈 맥시멈
		setSelectedMovement([]);//구동방식
		setSelectedSupplements([]);//구성품
		setSelectedGender([]);//성별
		setSelectedCaseMaterial([]);//케이스 소재
		setSelectedDialColor([]);//다이얼 컬러
		setSelectedDialType([]);//다이얼 타입
		setSelectedStoreList([]);//store 이름 리스트
		setSelectedStoreIdList([]);//store 아이디 리스트
		setConditionStart('');//상품 컨디션
		setConditionEnd('');//상품 컨디션
		setProducedYearMinInput('');//구매년도 미니멈
		setProducedYearMaxInput('');//구매년도 맥시멈
	}

	/**
	 * 체크박스 필터 공통 핸들링
	 * @param {*} e
	 * @param {*} state
	 * @param {*} setState
	 */
	const handleCheckBoxCommon = (e, state, setState) => {
		const { name, checked } = e.target;
		let copyState = [...state];

		if (checked) {
			copyState.push(name);
		} else {
			copyState = copyState.filter((el) => el !== name);
		}
		setState(copyState);
	};

	const handleConditionInput = (e) => {
		const { value, name } = e.target;
		let deleteAll = value.replace(/[^0-9]/g, '');
		if (name === 'minimum') {
			if (deleteAll >= 0 && deleteAll <= 10) {
				setConditionStart(deleteAll);
			}
		} else if (name === 'maximum') {
			if (deleteAll >= 0 && deleteAll <= 10) {
				setConditionEnd(deleteAll);
			}
		}
	};

	return (
		<>
			{!showBrandFilter &&
				!showModelFilter &&
				!showConditionFilter &&
				!showProducedYearFilter &&
				!showGenderFilter &&
				!showCaseDiameter &&
				!showMovement &&
				!showSupplements &&
				!showCaseMaterial &&
				!showDialColor &&
				!showReference &&
				!showDialType &&
				!showStores && (
					<main className="buy">
						<section className="back_wrap">
							<button className="btn_closed" onClick={() => setShowFilter(false)}></button>
							<h2>필터</h2>
						</section>

						<section
							className="fliter_list"
							style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
						>
							<div className="card_list">
								<ul>
									<li>
										<a className="btn_link" onClick={() => setShowBrandFilter(true)}>
											<div>
												<p className="t1">브랜드</p>
												{selectedBrandList.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedBrandList.slice(0, 2).map((brand, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{brand}
															</div>
														))}{' '}
														{selectedBrandList.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedBrandList.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowModelFilter(true)}>
											<div>
												<p className="t1">모델명</p>
												{selectedModelFilter.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedModelFilter.slice(0, 1).map((brand, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{brand}
															</div>
														))}{' '}
														{selectedModelFilter.length > 1 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedModelFilter.slice(1).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li onClick={() => setShowConditionFilter(true)}>
										<a className="btn_link" onClick={() => setShowConditionFilter(true)}>
											<div>
												<p className="t1">컨디션</p>
												{conditionStart || conditionEnd ? (
													<>
														<p
															className="t2"
															style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
														>
															<div
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{conditionStart || '-'} ~ {conditionEnd || '-'}
															</div>
														</p>
													</>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowProducedYearFilter(true)}>
											<div>
												<p className="t1">구매년도</p>
												{producedYearMinInput || producedYearMaxInput ? (
													<p
														className="t2"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														<div
															style={{
																background: '#484D5A',
																padding: '2% 3%',
																borderRadius: '4px',
																color: 'white',
																marginRight: '2%',
																fontSize: '4vw',
																width: 'fit-content',
															}}
														>
															{producedYearMinInput || '-'} ~ {producedYearMaxInput || '-'}
														</div>
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowGenderFilter(true)}>
											<div>
												<p className="t1">성별</p>
												{selectedGender.length > 0 ? (
													<p
														className="t2"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedGender.map((el, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{el}
															</div>
														))}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowCaseDiameter(true)}>
											<div>
												<p className="t1">케이스 사이즈</p>
												{selectedMinCaseDiameters || selectedMaxCaseDiameters ? (
													<p
														className="t2"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														<div
															style={{
																background: '#484D5A',
																padding: '2% 3%',
																borderRadius: '4px',
																color: 'white',
																marginRight: '2%',
																fontSize: '4vw',
																width: 'fit-content',
															}}
														>
															{selectedMinCaseDiameters || '-'} ~ {selectedMaxCaseDiameters || '-'}
														</div>
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowMovement(true)}>
											<div>
												<p className="t1">무브먼트</p>
												{selectedMovement.length > 0 ? (
													<p
														className="t2"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedMovement.map((el, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{el}
															</div>
														))}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowSupplements(true)}>
											<div>
												<p className="t1">구성품</p>
												{selectedSupplements.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedSupplements.slice(0, 2).map((brand, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{brand}
															</div>
														))}{' '}
														{selectedSupplements.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedSupplements.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowCaseMaterial(true)}>
											<div>
												<p className="t1">케이스 소재</p>
												{selectedCaseMaterial.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedCaseMaterial.slice(0, 2).map((brand, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{brand}
															</div>
														))}{' '}
														{selectedCaseMaterial.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedCaseMaterial.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowDialColor(true)}>
											<div>
												<p className="t1">다이얼 컬러</p>
												{selectedDialColor.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedDialColor.slice(0, 2).map((brand, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{brand}
															</div>
														))}{' '}
														{selectedDialColor.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedDialColor.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowReference(true)}>
											<div>
												<p className="t1">레퍼런스</p>
												{searchReference.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														<div
															style={{
																background: '#484D5A',
																padding: '2% 3%',
																borderRadius: '4px',
																color: 'white',
																marginRight: '2%',
																fontSize: '4vw',
																width: 'fit-content',
															}}
														>
															{searchReference}
														</div>
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowDialType(true)}>
											<div>
												<p className="t1">다이얼 타입</p>
												{selectedDialType.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedDialType.slice(0, 2).map((dialType, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{dialType}
															</div>
														))}{' '}
														{selectedDialType.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedDialType.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
									<li>
										<a className="btn_link" onClick={() => setShowStores(true)}>
											<div>
												<p className="t1">보유지점</p>
												{selectedStoreList.length > 0 ? (
													<p
														className="t2 ellipsis"
														style={{ marginTop: '3%', display: 'flex', alignItems: 'center' }}
													>
														{selectedStoreList.slice(0, 2).map((store, idx) => (
															<div
																key={idx}
																style={{
																	background: '#484D5A',
																	padding: '2% 3%',
																	borderRadius: '4px',
																	color: 'white',
																	marginRight: '2%',
																	fontSize: '4vw',
																	width: 'fit-content',
																}}
															>
																{store}
															</div>
														))}{' '}
														{selectedStoreList.length > 2 && (
															<div style={{ fontSize: '3.889vw' }}>
																외 {selectedStoreList.slice(2).length}개
															</div>
														)}
													</p>
												) : (
													<p className="t2">적용된 필터값이 없습니다.</p>
												)}
											</div>
										</a>
									</li>
								</ul>
							</div>
							<div className="btn_wrap">
								<button className="btn_cancel" onClick={deleteAllFilters}>
									전체 해제
								</button>
								<button className="btn_ok" onClick={() => setShowFilter(false)}>
									닫기
								</button>
							</div>
						</section>
					</main>
				)}
			{showBrandFilter && (
				<FilterBrand
					totalBrandList={totalBrandList}
					handleCheckBoxCommon={handleCheckBoxCommon}
					selectedBrandList={selectedBrandList}
					setSelectedBrandList={setSelectedBrandList}
					setShowBrandFilter={setShowBrandFilter}
				/>
			)}
			{showModelFilter && (
				<FilterModel
					selectedModelFilter={selectedModelFilter}
					setSelectedModelFilter={setSelectedModelFilter}
					handleCheckBoxCommon={handleCheckBoxCommon}
					setShowModelFilter={setShowModelFilter}
					modelResultBox={modelResultBox}
					searchModelInput={searchModelInput}
					setSearchModelInput={setSearchModelInput}
				/>
			)}
			{showConditionFilter && (
				<FilterCondition
					handleCheckBoxCommon={handleCheckBoxCommon}
					conditionStart={conditionStart}
					setConditionStart={setConditionStart}
					conditionEnd={conditionEnd}
					setConditionEnd={setConditionEnd}
					setShowConditionFilter={setShowConditionFilter}
					handleConditionInput={handleConditionInput}
				/>
			)}
			{showProducedYearFilter && (
				<FilterProducedYear
					producedYearMaxInput={producedYearMaxInput}
					setProducedYearMaxInput={setProducedYearMaxInput}
					producedYearMinInput={producedYearMinInput}
					setProducedYearMinInput={setProducedYearMinInput}
					setShowProducedYearFilter={setShowProducedYearFilter}
				/>
			)}
			{showGenderFilter && (
				<FilterGender
					setShowGenderFilter={setShowGenderFilter}
					selectedGender={selectedGender}
					setSelectedGender={setSelectedGender}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showCaseDiameter && (
				<FilterCaseDiameter
					setShowCaseDiameter={setShowCaseDiameter}
					selectedMinCaseDiameters={selectedMinCaseDiameters}
					setSelectedMinCaseDiaMeters={setSelectedMinCaseDiaMeters}
					selectedMaxCaseDiameters={selectedMaxCaseDiameters}
					setSelectedMaxCaseDiameters={setSelectedMaxCaseDiameters}
				/>
			)}
			{showMovement && (
				<FilterMovement
					setShowMovement={setShowMovement}
					selectedMovement={selectedMovement}
					setSelectedMovement={setSelectedMovement}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showSupplements && (
				<FilterSupplements
					setShowSupplements={setShowSupplements}
					selectedSupplements={selectedSupplements}
					setSelectedSupplements={setSelectedSupplements}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showCaseMaterial && (
				<FilterCaseMaterial
					setShowCaseMaterial={setShowCaseMaterial}
					selectedCaseMaterial={selectedCaseMaterial}
					setSelectedCaseMaterial={setSelectedCaseMaterial}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showDialColor && (
				<FilterDialColor
					setShowDialColor={setShowDialColor}
					selectedDialColor={selectedDialColor}
					setSelectedDialColor={setSelectedDialColor}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showReference && (
				<FilterReference
					setShowReference={setShowReference}
					searchReference={searchReference}
					setSearchReference={setSearchReference}
				/>
			)}
			{showDialType && (
				<FilterDialType
					setShowDialType={setShowDialType}
					selectedDialType={selectedDialType}
					setSelectedDialType={setSelectedDialType}
					handleCheckBoxCommon={handleCheckBoxCommon}
				/>
			)}
			{showStores && (
				<FilterStores
					setShowStores={setShowStores}
					selectedStoreIdList={selectedStoreIdList}
					setSelectedStoreIdList={setSelectedStoreIdList}
					selectedStoreList={selectedStoreList}
					setSelectedStoreList={setSelectedStoreList}
					currentStoreList={currentStoreList}
				/>
			)}
		</>
	);
};

export default BuyFilter;
