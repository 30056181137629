import React from 'react';
import MobileDevice from 'assets-pc/front-img/mobile_device.svg';
import MobileLandingLogo from 'assets-pc/front-img/mobile_landing_logo.svg';
import DownAndroidMarket from 'assets-pc/front-img/down_google_app.svg';
import DownAppleMarket from 'assets-pc/front-img/down_appstore_app.svg';
import SecondStore from 'assets-pc/front-img/tablet_landing_store.svg';
import newGuarantee from 'assets-pc/front-img/new_guarantee2.svg'
import guaranteeCursor from 'assets-pc/front-img/guarantee_cursor.svg'
import Info3 from 'assets-pc/front-img/guarantee_info_03.svg'
import Info4 from 'assets-pc/front-img/guarantee_info_04.svg'

const TabletLanding = () => {
	return (
		<>
			<div
				style={{
					width: '100%',
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					marginTop: '0px',
					alignItems: 'center',
					marginBottom: '60px',
				}}
			>
				<img src={MobileDevice} style={{ width: '200px', height: '400px', margin: '20px' }} />
				<div style={{ margin: '20px' }}>
					<div
						style={{
							fontSize: '28px',
							lineHeight: '33.6px',
							width: '280px',
							marginTop: '51.2px',
							fontWeight: '700',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						시계의 가치를 더하는 곳
					</div>
					<div style={{ marginTop: '6px', display: 'flex', justifyContent: 'center' }}>
						<img src={MobileLandingLogo} style={{ width: '240px' }} />
					</div>
					<div
						style={{ marginTop: '16px', fontSize: '16px', fontWeight: '500', lineHeight: '19.2px' }}
					>
						하이시간에서 안전하게 보증거래하는 방법
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
						<a
							style={{ marginRight: '6px' }}
							href="https://play.google.com/store/apps/details?id=com.hisigan.sigan_mobile"
							target="_black"
						>
							<img src={DownAndroidMarket} />
						</a>
						<button
							style={{ marginLeft: '6px' }}
							onClick={() => {
								alert('앱 출시 준비중입니다.');
							}}
						>
							<img src={DownAppleMarket} />
						</button>
					</div>
				</div>
			</div>
			<div style={{ width: '100%' }}>
				<img src={SecondStore} style={{ width: '100%' }} />
			</div>
			<div>
				<img src={newGuarantee} style={{ width: '100%' }} />
			</div>
			{/* <div>
				<img src={ThirdGroup} style={{ width: '100%' }} />
			</div>
			<div style={{ display: 'flex' }}>
				<div
					style={{
						width: '50%',
						backgroundColor: '#FCFCFC',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							fontSize: '28px',
							fontWeight: '700',
							lineHeight: '33.6px',
							width: '70%',
							margin: '0 auto',
						}}
					>
						실시간 시세 그래프
					</div>
					<div
						style={{
							fontWeight: '500',
							width: '70%',
							margin: '12px auto 0',
							opacity: '0.8',
							color: '##343A40',
						}}
					>
						판매 완료된 상품의 시세를 간단한 그래프로 확인하여 구매자와 판매자가 시세를 예측할 수
						있어 손해를 보지 않아요.
					</div>
				</div>
				<div style={{ width: '50%' }}>
					<img src={Info1} style={{ width: '100%' }} />
				</div>
			</div>
			<div style={{ display: 'flex' }}>
				<div style={{ width: '50%' }}>
					<img src={Info2} style={{ width: '100%' }} />
				</div>
				<div
					style={{
						width: '50%',
						backgroundColor: '#FCFCFC',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							fontSize: '26px',
							fontWeight: '500',
							lineHeight: '42px',
							width: '75%',
							margin: '0 auto',
						}}
					>
						전문감정사의 검수로
						<br />
						<span style={{ fontWeight: '700' }}>가품 걱정 없이 </span>거래하세요.
					</div>
					<div
						style={{
							fontWeight: '500',
							width: '75%',
							margin: '12px auto 0',
							opacity: '0.8',
							color: '#343A40',
						}}
					>
						모든 시계는 오랜 경력의 수석 엔지니어의 꼼꼼한 검수로 진행됩니다. 기본 진, 가품 서비스
						외 오버홀 및 폴리싱 서비스로 옵션으로 진행하실 수 있어요
					</div>
					<div style={{ fontWeight: '500', width: '75%', margin: '32px auto 0', fontSize: '14px' }}>
						*정품이 아닌 경우 100% 책임보장해드립니다.
					</div>
				</div>
			</div> */}
			<div style={{position:'relative', marginBottom:'70px'}}>
				<img src={Info3} style={{ width: '100%' }} />
				<img src={guaranteeCursor} style={{position:'absolute', bottom:'-40px',right:'50%', width:'11px', height:'78px'}} />
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<img src={Info4} style={{ width: '50%', marginBottom: '120px' }} />
			</div>
			{window?.location?.pathname.split('/')[1] !== 'guarantee' && (
				<a
					href="https://cafe.naver.com/hisigan"
					target="_blank"
					style={{
						position: 'fixed',
						bottom: '0',
						height: '9%',
						zIndex: '100',
						width: '100%',
						backgroundColor: 'rgb(189,145,97)',
						color: 'white',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: '22px',
						fontWeight: 'bold',
					}}
				>
					하이시간 카페로 이동
				</a>
			)}
		</>
	);
};

export default TabletLanding;
