import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
	data: {
		siteCheck: false,
	},
};

const siteReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'STOP_SERVICE':
			return {
				...state,
				data: action.data,
			};
		default:
			return state;
	}
};

const SiteContext = createContext(null);

export const SiteProvider = ({ children }) => {
	const [state, dispatch] = useReducer(siteReducer, initialState);
	return <SiteContext.Provider value={{ state, dispatch }}>{children}</SiteContext.Provider>;
};

export const useSiteState = () => {
	const { state } = useContext(SiteContext);
	return state;
};

export const useSiteDispatch = () => {
	const { dispatch } = useContext(SiteContext);
	return dispatch;
};
