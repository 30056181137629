import React, { useState } from 'react';
import MyPage from 'mobile-components/MyPage';
import MyReservationList from 'mobile-components/MyReservationList';
import MyAuctionList from 'mobile-components/MyAuctionList';
import MyFilter from 'mobile-components/MyFilter';
import CommonHelper from 'helpers/CommonHelper';
import { useAppDispatch, useAppState } from 'context/AppContext';
import IcoProfile from 'assets-mobile/img/common/ic_profile.svg';

const MobileNav = () => {
	const [showMyPage, setShowMyPage] = useState(false);
	const [showMyReservation, setShowMyRerservation] = useState(false);
	const [showMyAuction, setShowMyAuction] = useState(false);
	const [showMyFilter, setShowMyFilter] = useState(false);

	const {
		user: {
			loggedIn,
			data: { roles: userRoles, name: userName, profileUrl },
		},
	} = useAppState();

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	return (
		<>
			{/* 햄버거-마이페이지 */}
			{showMyPage && <MyPage showMyPage={showMyPage} setShowMyPage={setShowMyPage} />}
			{/* 햄버거-예약내역 */}
			{showMyReservation && <MyReservationList setShowMyRerservation={setShowMyRerservation} />}
			{/* 햄버거-옥션내역 */}
			{showMyAuction && <MyAuctionList setShowMyAuction={setShowMyAuction} />}
			{/* 햄버거-필터관리 */}
			{showMyFilter && <MyFilter setShowMyFilter={setShowMyFilter} />}
			{!showMyPage && !showMyReservation && !showMyAuction && !showMyFilter && (
				<main className="mypage">
					<h1>전체</h1>
					{!authToken && (
						<a href="/signIn?redirectUrl=/hapl" className="profile_info">
							<img src={IcoProfile} alt="" />
							<div>
								<p>
									<span>로그인 및 회원가입</span>
								</p>
							</div>
						</a>
					)}
					{authToken && userRoles.filter((el) => el === 'AUCTION').length === 0 && (
						<a className="profile_info" onClick={() => setShowMyPage(true)}>
							<img src={profileUrl || IcoProfile} alt="" />
							<div>
								<p>
									<strong>{userName || ''}님,</strong> <span>환영합니다.</span>
								</p>
								<p>
									<b>일반등급</b>
								</p>
							</div>
						</a>
					)}
					{authToken && userRoles.filter((el) => el === 'AUCTION').length !== 0 && (
						<a className="profile_info" onClick={() => setShowMyPage(true)}>
							<img src={profileUrl || IcoProfile} alt="" />
							<div>
								<p>
									<strong>{userName || ''}님,</strong> <span>환영합니다.</span>
								</p>
								<p>
									<b className="auction">옥션등급</b>
								</p>
							</div>
						</a>
					)}
					{!!authToken && (
						<div className="card_list">
							<ul>
								<li>
									<a className="btn_link" onClick={() => setShowMyRerservation(true)}>
										<div>
											<p className="t1">예약내역</p>
											<p className="t2">예약 현황을 확인 하실 수 있습니다.</p>
										</div>
									</a>
								</li>
								{/* <li>
									<a className="btn_link" onClick={() => setShowMyAuction(true)}>
										<div>
											<p className="t1">옥션내역</p>
											<p className="t2">옥션 진행사항 및 현황을 확인 하실 수 있습니다.</p>
										</div>
									</a>
								</li> */}
								<li>
									<a className="btn_link" onClick={() => setShowMyFilter(true)}>
										<div>
											<p className="t1">필터관리</p>
											<p className="t2">자주 사용하는 필터를 설정하실 수 있습니다.</p>
										</div>
									</a>
								</li>
								{/* <li>
									<a className="btn_link">
										<div>
											<p className="t1">알림 키워드 관리</p>
											<p className="t2">알림 받을 키워드를 설정하실 수 있습니다.</p>
										</div>
									</a>
								</li> */}
							</ul>
						</div>
					)}

					<div className="card link_list">
						<ul>
							<li>
								<a href="/store">부티크</a>
							</li>
							<li>
								<a href="/notice">뉴스&이벤트</a>
							</li>
							<li>
								<a href="/review">리뷰pick</a>
							</li>
							<li>
								<a href="/faq">고객센터</a>
							</li>
							<li>
								<a href="/termsOfService">이용약관</a>
							</li>
							<li>
								<a href="/personalInfo">개인정보처리방침</a>
							</li>
							{/* <li>
								<a>설정</a>
							</li> */}
						</ul>
					</div>
				</main>
			)}
		</>
	);
};
export default MobileNav;
