import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuctionRegister from 'mobile-components/AuctionRegister';
import { useAppState, useAppDispatch } from 'context/AppContext';
import { getWithDrawalApi, getModifiedUserInfoApi } from 'apis/myPage';
import { getUserSessionApi } from 'apis/common';
import IcoProfile from 'assets-mobile/img/common/ic_profile.svg';
import CommonHelper from 'helpers/CommonHelper';

const MyPage = ({ showMyPage, setShowMyPage }) => {
	const history = useHistory();
	const [showAuctionRegister, setShowAuctionRegister] = useState(false);
	const [showCheckLogout, setShowCheckLogout] = useState(false);
	const [showCheckWithdrawal, setShowCheckWithdrawal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [isModifyConfirmModalOpened, setIsModifyConfirmModalOpened] = useState(false);
	const {
		user: { loggedIn, data: onlyUserData },
	} = useAppState();
	//input state
	const [inputValues, setInputValues] = useState({
		loginId: '',
		password: '',
		rePassword: '',
		name: '',
		email: '',
		phone: '',
		nickName: '',
		agreeMail: '',
		agreeSms: '',
		userId: '',
		roles: [],
	});

	const appDispatch = useAppDispatch();
	//전역 비구조화할당
	const {
		loginId,
		password,
		rePassword,
		name,
		email,
		nickName,
		phone,
		userId,
		agreeMail,
		agreeSms,
		roles,
	} = inputValues;

	const handleLogout = () => {
		CommonHelper.clearAuthenticateToken();
		CommonHelper.clearSessionAuthenticateToken();
		appDispatch({ type: 'LOGGED_OUT' });
		setShowCheckLogout(false);
		setShowMyPage(false);
	};

	const handleWithdrawalConfirm = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();

		if (authToken !== null && authToken !== undefined) {
			getWithDrawalApi(authToken, onlyUserData?.id).then(({ data: { detail, status } }) => {
				if (status === 'SUCCESS') {
					CommonHelper.clearAuthenticateToken();
					CommonHelper.clearSessionAuthenticateToken();
					appDispatch({ type: 'LOGGED_OUT', data: { roles: [] } });
					setShowCheckWithdrawal(false);
					setShowMyPage(false);
				} else {
					alert(detail);
				}
			});
		}
	};
	//전역 - 토큰
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	useEffect(() => {
		const { loginId, name, email, phone, nickName, agreeMail, agreeSms, id, roles } = onlyUserData;
		setInputValues({
			...inputValues,
			loginId,
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			userId: id,
			roles,
		});
	}, [onlyUserData]);

	// input change
	const handleValueChange = (e) => {
		const { name, value } = e.target;
		if (name === 'phone') {
			let deleteAll = value.replace(/-/g, '').replace(/[^0-9]/g, '');
			setInputValues({
				...inputValues,
				[name]: deleteAll,
			});
		} else {
			setInputValues({
				...inputValues,
				[name]: value,
			});
		}
	};

	const handleModifyConfirm = () => {
		if (!password && !rePassword) {
			return setErrorText('비밀번호를 입력해주세요.');
		} else if (password !== rePassword) {
			return setErrorText('비밀번호가 일치하지 않습니다.');
		} else if (password && rePassword && !CommonHelper.passwordRules(password)) {
			return setErrorText('비밀번호는 20자 이내의 영문/숫자/특수문자를 모두 사용해주세요.');
		} else if (!CommonHelper.nameRule(name)) {
			return setErrorText('이름은 국문으로 2자 이상 입력해주세요.');
		} else if (!CommonHelper.emailIsValid(email)) {
			return setErrorText('이메일 형식이 올바르지 않습니다.');
		} else if (phone.length < 10) {
			return setErrorText('핸드폰 번호는 - 없이 10~11자를 입력해주세요.');
		} else if (!CommonHelper.nickNameRule(nickName)) {
			return setErrorText('닉네임은 국문으로 2자 이상 혹은 영문으로 4자 이상 입력해주세요.');
		} else {
			return (
				setErrorText(''),
				!!authToken &&
					getModifiedUserInfoApi(
						authToken,
						userId,
						password,
						name,
						email,
						phone,
						nickName,
						agreeMail,
						agreeSms,
					).then(({ data: { data, status, detail } }) => {
						if (status === 'SUCCESS') {
							getUserSessionApi(authToken).then(({ data: { status, detail } }) => {
								if (status === 'SUCCESS') {
									appDispatch({ type: 'UPDATE_USER_DATA', loggedIn: true, data: data || {} });
									setIsModifyConfirmModalOpened(true);
								} else {
									alert(detail);
								}
							});
						} else {
							alert(detail);
						}
					})
			);
		}
	};

	const handleModifyCancel = () => {
		const { loginId, name, email, phone, nickName, agreeMail, agreeSms, roles } = onlyUserData;

		setInputValues({
			...inputValues,
			loginId,
			password: '',
			rePassword: '',
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			roles,
		});
	};

	const handleModifyComplete = () => {
		setIsModifyConfirmModalOpened(!isModifyConfirmModalOpened);
		setInputValues({
			...inputValues,
			password: '',
			rePassword: '',
		});
	};

	const handleCheckBox = (e) => {
		switch (e.target.name) {
			case 'agreeMail':
				setInputValues({ ...inputValues, agreeMail: !agreeMail });
				break;
			case 'agreeSms':
				setInputValues({ ...inputValues, agreeSms: !agreeSms });
				break;
		}
	};

	return (
		<>
			{showAuctionRegister ? (
				<AuctionRegister setShowAuctionRegister={setShowAuctionRegister} />
			) : (
				<main className="mypage">
					<section className="back_wrap">
						<button className="btn_back" onClick={() => setShowMyPage(false)}></button>
						<h2>마이 페이지</h2>
					</section>
					<a className="profile_info logout" onClick={() => setShowCheckLogout(true)}>
						<img src={onlyUserData?.profileUrl || IcoProfile} alt="프로필 사진" />
						<div>
							<p>
								<strong>{onlyUserData?.name || ''}님,</strong> <span>환영합니다.</span>
							</p>
							<p>
								{onlyUserData?.roles.filter((el) => el === 'AUCTION').length === 0 ? (
									<b className="">일반등급</b>
								) : (
									<b className="auction">옥션등급</b>
								)}
							</p>
						</div>
					</a>
					<section className="my_info">
						<h3>등급</h3>
						{onlyUserData?.roles.filter((el) => el === 'AUCTION').length === 0 ? (
							<div className="grade_wrap">
								<b>일반등급</b>
								<a onClick={() => setShowAuctionRegister(true)}>
									옥션등급 신청
								</a>
							</div>
						) : (
							<div className="grade_wrap auction">
								<b>옥션등급</b>
								<a>옥션등급 신청</a>
							</div>
						)}

						<h3>로그인 정보</h3>
						<p className="tit">아이디</p>
						<div className="input_div">
							<input
								type="text"
								className="input_t"
								disabled
								value={onlyUserData?.loginId || '-'}
							/>
						</div>

						<p className="tit">비밀번호</p>
						<div className="input_div">
							<input
								type="password"
								className="input_t"
								placeholder="비밀번호"
								value={password}
								onChange={handleValueChange}
								name="password"
							/>
							<input
								type="password"
								className="input_t"
								placeholder="비밀번호 확인"
								value={rePassword}
								onChange={handleValueChange}
								name="rePassword"
							/>
						</div>

						<h3>개인정보</h3>
						<div className="input_div">
							<input
								type="text"
								className="input_t"
								placeholder="이름"
								value={name}
								onChange={handleValueChange}
								name="name"
							/>
							<input
								type="text"
								className="input_t"
								placeholder="이메일"
								value={email}
								onChange={handleValueChange}
								name="email"
							/>
							<input
								type="text"
								className="input_t"
								placeholder="01012345678"
								value={phone}
								onChange={handleValueChange}
								name="phone"
							/>
							<input
								type="text"
								className="input_t"
								placeholder="닉네임"
								value={nickName}
								onChange={handleValueChange}
								name="nickName"
							/>
						</div>

						<div className="check_wrap ">
							<label>
								<input
									type="checkbox"
									name="agreeMail"
									checked={agreeMail}
									onClick={handleCheckBox}
								/>
								<span>정보 메일을 받겠습니다.</span>
							</label>
							<label>
								<input
									type="checkbox"
									name="agreeSms"
									checked={agreeSms}
									onClick={handleCheckBox}
								/>
								<span>문자메세지를 받겠습니다.</span>
							</label>
						</div>
						{errorText && <p className="error_txt">{errorText}</p>}
						<a className="btn_modify" onClick={handleModifyConfirm}>
							수정
						</a>
						<p className="desc_txt">
							회원탈퇴를 원하시면{' '}
							<a onClick={() => setShowCheckWithdrawal(true)}>
								여기
							</a>
							를 눌러주세요.
						</p>
					</section>
				</main>
			)}
			{showCheckLogout && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t2">로그아웃 하시겠습니까?</p>
						<div className="btn_wrap">
							<button className="btn_cancel" onClick={() => setShowCheckLogout(false)}>
								취소
							</button>
							<button className="btn_ok" onClick={handleLogout}>
								확인
							</button>
						</div>
					</article>
				</div>
			)}
			{showCheckWithdrawal && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t2">정말 회원을 탈퇴 하시겠습니까?</p>
						<p className="t3">
							회원을 탈퇴하시면 시계 서비스에서 <br />
							받으신 혜택이 모두 사라집니다.
						</p>
						<div className="btn_wrap">
							<button className="btn_cancel" onClick={() => setShowCheckWithdrawal(false)}>
								취소
							</button>
							<button className="btn_ok" onClick={handleWithdrawalConfirm}>
								확인
							</button>
						</div>
					</article>
				</div>
			)}
			{isModifyConfirmModalOpened && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t1">정보수정이 완료되었습니다.</p>
						<button className="btn_ok" onClick={handleModifyComplete}>
							확인
						</button>
					</article>
				</div>
			)}
		</>
	);
};
export default MyPage;

