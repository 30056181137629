import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CommonHelper from 'helpers/CommonHelper';
import { Link } from 'react-router-dom';
import PcLogo from 'assets-pc/img/common/pc_logo.png';
import CenterLogo from 'assets-pc/front-img/center-main-logo.svg';
import { useAppDispatch } from 'context/AppContext';

const Header = () => {
	const [tokenStore, setTokenStore] = useState(null);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const appDispatch = useAppDispatch();
	const history = useHistory();

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	useEffect(() => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();

		if (!!authToken) {
			setTokenStore(authToken);
		} else {
			setTokenStore(null);
		}
	}, [authToken, window.location.pathname]);

	const handleLogoutBtnClick = () => {
		setShowLogoutModal(true);
	};
	const handleLogoutCancel = () => {
		setShowLogoutModal(false);
	};

	const handleLogoutConfirm = () => {
		CommonHelper.clearAuthenticateToken();
		CommonHelper.clearSessionAuthenticateToken();
		appDispatch({ type: 'LOGGED_OUT' });
		setShowLogoutModal(false);
		history.push('/hapl');
	};
	return (
		<>
			{showLogoutModal && (
				<div className="bg_dimmed dimmed_block">
					<section className="alert">
						<p>로그아웃 하시겠습니까?</p>
						<div className="btn_wrap right">
							<button className="" onClick={handleLogoutCancel}>
								취소
							</button>
							<button className="ok" onClick={handleLogoutConfirm}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			<header
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					height: '132px',
					position: 'relative',
				}}
			>
				<div
					className="header_inner_wrap"
					style={{ height: '132px', position: 'relative', width: '100%' }}
				>
					<h1>
						<Link to="/">
							<img src={CenterLogo} alt="Hi sigan" />
						</Link>
					</h1>
					<ul className="lnb" style={{ position: 'absolute', right: '32px', top: '24px' }}>
						{!!tokenStore ? (
							<>
								<li>
									<Link to="/myPage">My Page</Link>
								</li>
								<li className="open-chat">
									<div onClick={handleLogoutBtnClick}>Logout</div>
								</li>
								<li>
									<a href="https://open.kakao.com/o/gmjFdtcd" target="_blank">
										OpenChat
									</a>
								</li>
							</>
						) : (
							<>
								<li>
									<Link to="/signin?redirectUrl=/hapl">Login</Link>
								</li>
								<li className="open-chat">
									<Link to="/signUp">Join US</Link>
								</li>
								<li>
									<a href="https://open.kakao.com/o/gmjFdtcd" target="_blank">
										OpenChat
									</a>
								</li>
							</>
						)}
					</ul>
				</div>
			</header>
			<header style={{ justifyContent: 'center', marginBottom: '10px' }}>
				<div className="header_inner_wrap">
					<nav>
						<ul className="gnb">
							{/* 시계구매 / 공간대여 / 부티크 / 뉴스&이벤트 / 시계판매 / 고객센터/ 드로우 */}
							<li>
								{/* <Link to="/buy">시계구매</Link> */}
								<a href="https://hisigan.co.kr/user/main/getSale.do?filter=N">
									시계구매
								</a>
							</li>
							<li className="header-new-menu-li">
								<Link to="/hapl">공간대여</Link>
							</li>
							<li className="header-new-menu-li">
								<Link to="/review">리뷰pick</Link>
							</li>
							<li className="header-new-menu-li">
								<Link to="/shop">부티크</Link>
							</li>
							<li className="header-new-menu-li">
								<Link to="/notice">뉴스&이벤트</Link>
							</li>
							{/* <li className="header-new-menu-li">
								<Link to="/sell">시계판매</Link>
							</li> */}
							<li className="header-new-menu-li">
								<Link to="/faq">고객센터</Link>
							</li>
							<li className="header-new-menu-li">
								<a href="https://hisigan.co.kr/user/main/getMain.do">시세조회</a>
							</li>
							{/* <li className="header-new-menu-li">
								<Link to="/auction">하이옥션</Link>
							</li> */}
						</ul>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
