import React, { useState, useEffect } from 'react';
import modelImg1 from 'assets-pc/img/common/img_model_01.png';
import useDebounce from 'utils/useDebounce';
import { getModelListApi } from 'apis/sell';
import CommonHelper from 'helpers/CommonHelper';
import { withRouter } from 'react-router-dom';

const SellSearchPopup = withRouter(
	({ setShowModelPopup, searchModelInput, setOneModel, history, showLoginNeedPopup, setShowLoginNeedPopup }) => {
		const [modelLists, setModelLists] = useState([]);
		const [searchModelPopupInput, setSearchModelPopupInput] = useState('');
		const debouncedSearchTerm = useDebounce(searchModelPopupInput, 500); //0.5초뒤에 검색
		// const [showLoginNeedPopup, setShowLoginNeedPopup] = useState(false);

		useEffect(() => {
			getModelList();
		}, [debouncedSearchTerm]);
		useEffect(() => {
			setSearchModelPopupInput(searchModelInput);
		}, [searchModelInput]);

		const goToSellRegister = (oneModel) => {
			const authToken = CommonHelper.getAuthToken()
				? CommonHelper.getAuthToken()
				: CommonHelper.getSessionAuthToken();

			if (!authToken) {
				setShowLoginNeedPopup(true);
			} else if (authToken) {
				setOneModel(oneModel);
				setShowModelPopup(false);
			}
		};

		const handleGoToSignInPage = () => {
			setShowLoginNeedPopup(false);
			history.push('/signin?redirectUrl=/sell');
		};

		return (
			<>
				{showLoginNeedPopup && (
					<div className="bg_dimmed" style={{ display: 'block', zIndex: 1000 }}>
						<section className="alert">
							<p>
								로그인이 필요한 서비스입니다. <br />
								회원이시라면 로그인 후 이용해주세요.
							</p>
							<div className="btn_wrap right">
								<button className="ok" onClick={handleGoToSignInPage}>
									확인
								</button>
							</div>
						</section>
					</div>
				)}
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="popup model_search">
						<h2>모델 검색</h2>
						<div className="search_box">
							<input
								type="text"
								placeholder="모델명을 입력해주세요."
								value={searchModelPopupInput}
								onChange={(e) => setSearchModelPopupInput(e.target.value)}
							/>
							<button></button>
						</div>
						<p className="t1">
							{searchModelPopupInput}에 대한 검색결과 <b>총 {modelLists.length || '0'} 건</b>{' '}
							입니다.
						</p>
						<p className="t2">모델을 선택해주세요</p>
						<div className="cont">
							<ul className="select_model">
								{modelLists.map((model, idx) => (
									<li key={idx} onClick={() => goToSellRegister(model)}>
										{/* <div className="img_box">
											<img src={modelImg1} alt="" />
										</div> */}
										<div className="txt_box">
											<p className="brand">{model.brand || '-'}</p>
											<p className="modle_name ellipsis">
												{model.model || ''} {model.caseDiameter ? `${model.caseDiameter}mm` : ''}{' '}
												{model.serialNo || ''}
											</p>
										</div>
									</li>
								))}
							</ul>
						</div>
						<div className="btn_wrap right">
							<button className="" onClick={() => setShowModelPopup(false)}>
								닫기
							</button>
							<button className="ok">선택하기</button>
						</div>
					</section>
				</div>
			</>
		);

		function getModelList() {
			getModelListApi(searchModelPopupInput).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					setModelLists(data);
				} else {
					alert(detail);
				}
			});
		}
	},
);

export default SellSearchPopup;
