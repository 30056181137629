import React, { useState } from 'react';
import Instagram from 'assets-mobile/img/sns-in.svg';
import Facebook from 'assets-mobile/img/sns-facebook.svg';
import Youtube from 'assets-mobile/img/sns-youtube.svg';
import Naver from 'assets-mobile/img/sns-naver.svg';
import Blog from 'assets-mobile/img/sns-blog.svg';
import Kakao from 'assets-mobile/img/sns-channel.svg';
const MobileFooter = () => {
	const [showMoreFooter, setShowMoreFooter] = useState(false);
	const goToFaq = () => {
		window.location.href = '/faq';
	};
	return (
		<>
			<footer className="footer" style={{ padding: '0' }}>
				<section className="inner">
					<p className="t1">고객센터 1644-5751</p>
					<p className="t2">운영시간평일 10:00 -18:00(토일, 공휴일 휴무)</p>
					<p className="t2">점심시간평일 13:00 - 14:00</p>
				</section>
				<div className="faq_box">
					<strong className="tit" onClick={goToFaq}>
						자주 묻는 질문
					</strong>
					<div className="poppins">
						<ul>
							<li>
								<strong onClick={goToFaq} style={{ fontWeight: '900', color: '#666666' }}>
									이용안내
								</strong>
							</li>
							<li>
								<a href="/faq">제품등급</a>
							</li>
							<li>
								<a href="/faq">위탁기간과 수수료</a>
							</li>
							<li>
								<a href="/faq">반품과 환불절차</a>
							</li>
							<li>
								<a href="/faq">보증카드</a>
							</li>
						</ul>
						<ul>
							<li>
								<strong style={{ fontWeight: '900', color: '#666666' }}>고객지원</strong>
							</li>
							<li>
								<a href="/notice">뉴스&이벤트</a>
							</li>
							<li>
								<a href="/care">하이서비스케어</a>
							</li>
							<li>
								<a href="/store">부티크</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="agree_box">
					<a href="/termsOfService">이용약관</a>
					<a href="/personalInfo">개인정보취급방침</a>
				</div>
				<div className="sns_list">
					<ul style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
						<li style={{ width: '11%' }}>
							<a
								href="https://www.instagram.com/hisigan_official/"
								target="_blank"
								style={{ width: '100%' }}
							>
								<img src={Instagram} alt="instagram" style={{ width: '100%' }} />
							</a>
						</li>
						<li style={{ width: '11%' }}>
							<a
								href="https://m.facebook.com/hisigan.info/"
								target="_blank"
								style={{ width: '100%' }}
							>
								<img src={Facebook} alt="facebook" style={{ width: '100%' }} />
							</a>
						</li>
						<li style={{ width: '11%' }}>
							<a
								href="https://www.youtube.com/channel/UChurZ73ZijJ3wSSplPlXE5g"
								target="_blank"
								style={{ width: '100%' }}
							>
								<img src={Youtube} alt="youtube" style={{ width: '100%' }} />
							</a>
						</li>
						<li style={{ width: '11%' }}>
							<a href="https://cafe.naver.com/hisigan" target="_blank" style={{ width: '100%' }}>
								<img src={Naver} alt="naver cafe" style={{ width: '100%' }} />
							</a>
						</li>
						<li style={{ width: '11%' }}>
							<a href="https://blog.naver.com/hi_sigan" target="_blank" style={{ width: '100%' }}>
								<img src={Blog} alt="naver blog" style={{ width: '100%', height: '100%' }} />
							</a>
						</li>
						<li style={{ width: '10%' }}>
							<a href="https://pf.kakao.com/_dnSxgs" target="_blank" style={{ width: '100%' }}>
								<img src={Kakao} alt="kakao channel" style={{ width: '100%' }} />
							</a>
						</li>
					</ul>
					<button className="poppins" onClick={() => setShowMoreFooter(!showMoreFooter)}>
						사업자정보
					</button>
				</div>
				<p className="copy poppins">ⓒ2020 ㈜시간. All Rights Reserved.</p>
				<ul className="footer_btm poppins" style={showMoreFooter ? { display: 'block' } : {}}>
					<li>
						<strong>주소</strong>
						<span>
							부산광역시 해운대구 센텀중앙로97 <br />
							A동2710호
						</span>
					</li>
					<li>
						<strong>사업자등록번호</strong>
						<span>406-87-01998</span>
					</li>
					<li>
						<strong>통신판매신고번호</strong>
						<span>제2020=부산해운대-1610호</span>
					</li>
				</ul>
			</footer>
		</>
	);
};

export default MobileFooter;
