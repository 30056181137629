import React from 'react';
import { useHistory } from 'react-router-dom';

const PersonalInfoRule = () => {
	const history = useHistory();
	return (
		<>
			<div className="container login">
				<section className="inner">
					<h2>개인정보취급방침</h2>
					<div className="join_agree">
						{/* <p>
							회원가입약관 및 개인정보처리방침의 내용에 동의하셔야 <br />
							회원가입 하실 수 있습니다.
						</p> */}
						<div className="scroll_box" style={{ height: '500px' }}>
							'(주)시간'은 개인정보 보호법 제30조에 따라 정보주체(고객)의 개인정보를 보호하고 이와
							관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
							처리지침을 수립 · 공개합니다. <br />
							<br />
							1. 개인정보의 처리목적 <br />
							'(주)시간'은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의
							용도로는 이용하지 않습니다.
							<br /> - 회원가입 <br />
							- 게시판: 질문과 답변 <br />- 회원가입시 : 로그인ID , 비밀번호 , 이름 , 전화번호 ,
							이메일 , 닉네임, 주소 <br />- 서비스 신청시 : 보유 상품정보, 구매처, 연식, 방문 예약
							일정, 내용, 사진 <br />
							- 결제에 필요한 정보(유료 서비스 이용시) <br />
							<br />
							2. 개인정보의 처리 및 보유기간 <br />① '(주)시간'은 정보주체로부터 개인정보를 수집할
							때 동의받은 개인정보 보유 · 이용기간 또는 법령에 따른 개인정보 보유 · 이용기간 내에서
							개인정보를 처리 · 보유합니다. <br />② 구체적인 개인정보 처리 및 보유 기간은 다음과
							같습니다. <br />- 소비자의 불만 및 분쟁처리에 관한 기록 : 3년 <br />- 로그기록 : 3개월
							<br />
							<br />
							3. 개인정보의 제3자 제공 <br />
							'(주)시간'은 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에
							해당하는 경우 외에는 개인정보를 제3자에게 제공하지 않습니다. <br />
							<br />
							4. 개인정보처리의 위탁 <br />① '(주)시간'은 원활한 개인정보 업무처리를 위하여 다음과
							같이 개인정보 처리업무를 외부에 위탁하고 있습니다. <br />
							[디에프] 웹사이트 및 시스템 관리 <br />② '(주)시간'은 위탁계약 체결시 개인정보 보호법
							제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 재위탁 제한, 수탁자에 대한 관리
							· 감독, 책임에 관한 사항을 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
							감독하고 있습니다. <br />
							<br />
							5. 정보주체와 법정대리인의 권리 · 의무 및 행사방법 <br />
							1. 회원 및 법정대리인은 언제든지 등록되어 있는 자신 또는 당해 만14세 미만 아동의
							개인정보를 조회하거나 수정할 수 있으며 가입해지(동의철회)를 요청할 수도 있습니다.{' '}
							<br />
							2. 회원 또는 만14세 미만 아동의 개인정보 조회, 수정을 위해서는 “회원정보수정”을
							가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인절차를 거치신 후 직접
							열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호책임자에게 서면, 전화, 이메일
							또는 상담게시판으로 연락하시면 지체 없이 조치하겠습니다. <br />
							3. 회원 또는 법정대리인이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
							완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를
							제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이
							이루어지도록 하겠습니다. <br />
							4. 회사는 회원 또는 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보를 “3. 수집한
							개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
							이용할 수 없도록 처리하고 있습니다. <br />
							<br />
							6. 처리하는 개인정보 항목 <br />
							'(주)시간'은 다음의 개인정보 항목을 처리하고 있습니다. 1) 회사는 고객상담 등을 위해
							아래와 같은 개인정보를 수집하고 있습니다. <br />- 회원정보 : 아이디, 비밀번호, 이름,
							닉네임, 핸드폰번호, 이메일 등 <br />- 법인설립정보 : 회사상호, 국문상호, 영문상호,
							본점소재지, 회사 성입 년/월/일, 설립자본금, 1주의 금액, 납입은행, 납입은행 지점,
							회계연도/공고신문, 사업목적, 주주 및 임원 등 <br />
							2) 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수
							있습니다. <br />
							서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 이용정지기록, 불량이용기록{' '}
							<br />
							<br />
							7. 개인정보의 파기 <br />① '(주)시간'은 개인정보 보유기간의 경과, 처리목적 달성 등
							개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br />②
							'(주)시간'은 다음의 방법으로 개인정보를 파기합니다. <br />- 전자적 파일 : 파일 삭제 및
							디스크 등 저장매체 포맷 <br />- 수기(手記) 문서 : 분쇄하거나 소각 <br />
							<br />
							8. 개인정보의 안전성 확보조치 <br />
							'(주)시간'은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br />-
							관리적 조치 : 내부관리계획 수립 · 시행, 직원 등에 대한 정기적 교육 <br />- 기술적 조치
							: 개인정보처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호 설정 등 접근권한 관리,
							백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화 <br />- 물리적
							조치 : 개인정보가 저장 · 보관된 장소의 시건, 출입통제 등 <br />
							<br />
							9. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항 <br />① '(주)시간'은
							이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
							‘쿠기(cookie)’를 사용합니다. <br />② 쿠키는 웹사이트를 운영하는데 이용되는
							서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
							컴퓨터내의 하드디스크에 저장되기도 합니다. <br />
							<br />
							가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
							인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
							사용됩니다. <br />
							나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션
							설정을 통해 쿠키 저장을 거부 할 수 있습니다. <br />
							다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. <br />
							<br />
							10. 개인정보 보호책임자 <br />
							'(주)시간'은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
							정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를
							지정하고 있습니다. <br />
							<br />
							[개인정보 담당자] <br />· 대표 지성식 <br />· Tel: 051-711-3050 <br />· Fax:
							051-711-3049 <br />· Email: sigan24.com@gmail.com <br />
							<br />
							<br />제 11조 개인정보관련 신고 및 분쟁조정 <br />
							개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 한국인터넷진흥원(KISA)
							개인정보침해신고센터로 문의하시기 바랍니다. 또한, 귀하가 개인정보침해를 통한 금전적,
							정신적 피해를 입으신 경우에는 개인정보분쟁조정위원회에 피해구제를 신청하실 수
							있습니다. <br />
							<br />
							개인분쟁조정위원회 (www.1336.or.kr/1336) <br />
							정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4) <br />
							대검찰청 인터넷범죄수사센터 (www.icic.sppo.go.kr/02-3480-3600) <br />
							경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330) <br />
							<br />제 12조 고지의 의무 법령, 정책 또는 보안기술 등의 변경에 따라 현
							개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정되는 개인정보처리방침을
							시행하기 최소 7일전에 홈페이지의 '공지사항'을 통해 고지할 것입니다. <br />
							<br />- 공고일자 : 2020년 10월 1일 <br />- 시행일자 : 2020년 10월 7일
						</div>
						{/* <label>
							<input
								type="checkbox"
								value="privacyPolicyCheckbox"
								// checked={checkedBox.privacyPolicyCheckbox}
							/>
							<span>개인정보처리방침안내</span>
						</label>
						<div className="scroll_box">
							제1조(목적) 본 약관은 하이시간 회원이 ㈜시간(이하 "회사")에서 제공하는 하이시간
							서비스를 이용함에 있어 하이시간 회원과 회사과 이용자의 권리의무 및 책임사항을 규정함을
							목적으로 합니다. <br />
							<br />
							※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을
							준용합니다」
						</div> */}
						<button
							className="btn_join"
							onClick={() => history.goBack()}
							// onClick={goToSignUp}
						>
							돌아가기
						</button>
						{/* <a
							className="btn_home"
							// onClick={goToHome}
						>
							홈으로 돌아가기
						</a> */}
					</div>
				</section>
			</div>
		</>
	);
};

export default PersonalInfoRule;
