import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Viewer } from '@toast-ui/react-editor';
import { getNoticeDetailApi } from 'apis/notice';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const NoticeDetail = ({ oneNoticeData, setOpenDetail }) => {
	const [detailData, setDetailData] = useState({});
	const [photoSize, setPhotoSize] = useState('');
	const history = useHistory();

	useEffect(() => {
		let nowProductId = window.location.pathname.split('/')[2];
		nowProductId && getNoticeDetail(nowProductId);
	}, []);

	const sizeCheck = () => {
		setPhotoSize(window.innerWidth);
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	const getNoticeDetail = (id) => {
		getNoticeDetailApi(id).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setDetailData(data ? data : {});
			} else {
				alert(detail);
			}
		});
	};

	const goBackNoticeList = () => {
		history.goBack();
	};

	const currentDate = new Date().getTime();
	const createDate = new Date(detailData.createdAt).getTime();
	const marked = replaceNewlineWithBrTag(decodingHtmlTags(detailData.content));
	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={goBackNoticeList}></button>
					<h2>뉴스&이벤트</h2>
				</section>
				<section className="notice_view">
					<div className="view_top">
						<p className="tit">{detailData?.title ? detailData.title : ''}</p>
						<p className="date">
							<span>
								{' '}
								{detailData?.createdAt ? detailData.createdAt.split('T')[0].replace(/-/g, '.') : ''}
							</span>
							<span className="new">
								{(currentDate - createDate) / (1000 * 3600 * 24) <= 7 ? (
									<span className="ico">New</span>
								) : (
									''
								)}{' '}
							</span>
						</p>
					</div>
					<div
						// className="view_cont"
						style={{ padding: '5.556vw 0' }}
					>
						{marked ? (
							<Wrapper size={photoSize}>
								<Viewer initialValue={marked} initialEditType="markdown" />
							</Wrapper>
						) : (
							''
						)}
					</div>
				</section>
			</main>
		</>
	);
};

export default NoticeDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	/* display: flex;
	justify-content: center; */
	img {
		width: ${(props) => (props.size ? `${props.size}px` : '240px')} !important;
	}
	p {
		font-size: 3.889vw;
		color: #31383f;
		margin-bottom: 5.556vw;
		line-height: 1.6;
	}
`;
