import React from 'react';
import { useSiteState } from 'context/SiteContext';

const FilterDialType = ({
	setShowDialType,
	selectedDialType,
	setSelectedDialType,
	handleCheckBoxCommon,
}) => {
	const siteState = useSiteState()?.data;
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowDialType(false)}></button>
					<h2>다이얼 타입</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									다이얼 컬러
									<div style={{ fontSize: '3.8vw', opacity: '0.5', fontWeight: '500' }}>
										선택 컬러({selectedDialType.length || 0}건)
									</div>
								</h3>
							</div>
							<ul>
								{siteState?.dialTypes?.map((type, idx) => (
									<li key={idx}>
										<b>{type || '-'}</b>
										<input
											type="checkbox"
											name={type || '-'}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedDialType, setSelectedDialType)
											}
											checked={selectedDialType.includes(type)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedDialType([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowDialType(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterDialType;
