import React from 'react';
import { useSiteState } from 'context/SiteContext';

const FilterDialColor = ({
	setShowDialColor,
	selectedDialColor,
	setSelectedDialColor,
	handleCheckBoxCommon,
}) => {
	const siteState = useSiteState()?.data;
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowDialColor(false)}></button>
					<h2>다이얼 컬러</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									다이얼 컬러
									<div style={{ fontSize: '3.8vw', opacity: '0.5', fontWeight: '500' }}>
										선택 컬러({selectedDialColor.length || 0}건)
									</div>
								</h3>
							</div>
							<ul>
								{siteState?.dialColors?.map((color, idx) => (
									<li key={idx}>
										<b>{color || '-'}</b>
										<input
											type="checkbox"
											name={color || '-'}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedDialColor, setSelectedDialColor)
											}
											checked={selectedDialColor.includes(color)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedDialColor([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowDialColor(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterDialColor;
