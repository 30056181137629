import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Linkify from 'react-linkify';
import { Viewer } from '@toast-ui/react-editor';
import { getNoticeDetailApi } from '../../apis/notice';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';
const componentDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">
		{text}
	</a>
);

const NoticeDetail = ({ oneNoticeData, setOpenDetail }) => {
	const [detailData, setDetailData] = useState({});
	const [markState, setMarkState] = useState();
	const history = useHistory();

	useEffect(() => {
		let nowProductId = window.location.pathname.split('/')[2];
		nowProductId && getNoticeDetail(nowProductId);
	}, []);

	const getNoticeDetail = (id) => {
		getNoticeDetailApi(id).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setDetailData(data ? data : {});
				setMarkState(replaceNewlineWithBrTag(decodingHtmlTags(data?.content)));
			} else {
				alert(detail);
			}
		});
	};

	const currentDate = new Date().getTime();
	const createDate = new Date(detailData?.createdAt).getTime();
	// const marked = replaceNewlineWithBrTag(decodingHtmlTags(detailData?.content));

	return (
		<>
			<div className="container notice">
				<section className="sub_top">
					<div className="sub_top_mini_img"></div>
					<h1 style={{ zIndex: '100' }}>뉴스&이벤트</h1>
					<p style={{ zIndex: '100' }}>하이시간의 업데이트 정보 등 다양한 소식을 알려드립니다.</p>
				</section>
				<section className="inner">
					<div className="notice_view">
						<div className="view_top">
							<p className="tit">
								{(currentDate - createDate) / (1000 * 3600 * 24) <= 7 ? (
									<span className="ico">New</span>
								) : (
									''
								)}{' '}
								{detailData?.title ? detailData.title : ''}
							</p>
							<p className="date">
								{detailData?.createdAt
									? detailData.createdAt.slice(0, 10).replaceAll('-', '.')
									: ''}
							</p>
						</div>
						<div className="text_box">
							<span>
								{markState ? <Viewer initialValue={markState} initialEditType="markdown" /> : ''}
								{/* <Linkify componentDecorator={componentDecorator}>
								{detailData?.content
									? detailData.content.split('&lt;br/&gt;').map((line, index) => {
											return (
												<span key={index}>
													{line}
													<br />
												</span>
											);
									  })
									: ''}
							</Linkify> */}
							</span>
						</div>
					</div>
					<div className="btn_wrap">
						<a className="btn_list" onClick={() => history.goBack()}>
							목록으로 돌아가기
						</a>
					</div>
				</section>
			</div>
		</>
	);
};

export default NoticeDetail;
