import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NoticeList from './components/NoticeList';
import NoticeDetail from './components/NoticeDetail';
import { getNoticeListApi } from '../../apis/notice';

const Notice = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [isCheckedPage, setIsCheckedPage] = useState(false);
	const [noticeData, setNoticeData] = useState({});
	const [oneNoticeData, setOneNoticeData] = useState({});
	const [openDetail, setOpenDetail] = useState(false);
	const [page, setPage] = useState(0);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const LIMIT = 10;
	useEffect(() => {
		isCheckedPage && getNoticeList();
	}, [page]);

	useEffect(() => {
		checkPage();
	}, []);

	const checkPage = () => {
		let storePage = sessionStorage.getItem('noticePage');
		if (storePage) {
			setPage(Number(storePage));
		}
		sessionStorage.removeItem('noticePage');
		getNoticeList();
		setIsCheckedPage(true);
	};

	const getNoticeList = () => {
		try {
			setIsLoading(true);
			getNoticeListApi(LIMIT, page).then(({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setNoticeData(data || {});
				} else {
					alert(detail);
				}
				setIsLoading(false);
			});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const { totalPage } = noticeData;

	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);

	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setPage(num);
	};

	const prevPage = () => {
		if (page + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((page + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setPage(page - 1);
	};

	const nextPage = () => {
		if (page + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setPage(page + 1);
			if ((page + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};

	return (
		<>
			<main className="mypage">
				{openDetail && oneNoticeData ? (
					<NoticeDetail oneNoticeData={oneNoticeData} setOpenDetail={setOpenDetail} />
				) : (
					<>
						{isLoading ? (
							<section className="notice_list">
								<ul>
									<li>
										<a>
											<p className="tit">등록된 이벤트를 불러오고 있습니다...</p>
										</a>
									</li>
								</ul>
							</section>
						) : (
							<>
								{noticeData?.content?.length > 0 && (
									<NoticeList
										noticeData={noticeData}
										setOpenDetail={setOpenDetail}
										setOneNoticeData={setOneNoticeData}
										prevPage={prevPage}
										paginate={paginate}
										nextPage={nextPage}
										newPageNumbers={newPageNumbers}
										page={page}
									/>
								)}
								{noticeData?.content?.length === 0 && (
									<section className="notice_list">
										<ul>
											<li>
												<a>
													<p className="tit">등록된 이벤트가 없습니다.</p>
												</a>
											</li>
										</ul>
									</section>
								)}
							</>
						)}
					</>
				)}
			</main>
		</>
	);
};
export default Notice;
