import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Watch1 from 'assets-mobile/img/main/watch_01.png';
import ModelSearch from './components/ModelSearch';
import BuyFilter from './components/BuyFilter';
import CommonPagination from '../../components/CommonPagination';
import { PROPERTY_DIRECTION_MENU } from 'common/constants';
import { handlePremiumNum } from 'common/common';
import {
	getMobileSellingProductsApi,
	searchModelListApi,
	getBrandListApi,
	getStoreListApi,
	getFilterLisetApi,
	getSiteInfoApi,
} from 'apis/buy.js';
import CommonHelper from 'helpers/CommonHelper';
import Warranty from 'assets-mobile/img/main/ico_warranty.svg';
import IcoBox from 'assets-mobile/img/main/ico_box.svg';
import { useSiteState } from 'context/SiteContext';
import useDebounce from 'utils/useDebounce';

const Buy = () => {
	const history = useHistory();
	const [checkStorage, setCheckStorage] = useState(false);
	const [checkUrl, setCheckUrl] = useState(false); //메인에서 url타고 왔는지 확인
	const [showModelSearch, setShowModelSearch] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [showSort, setShowSort] = useState(false);
	const [currentSellingProducts, setCurrentSellingProducts] = useState([]);
	const [currentSellingInfo, setCurrentSellingInfo] = useState({});
	////검색 리스트
	// const [selectedSearchResult, setSelectedSearchResult] = useState([]);
	const siteState = useSiteState().data;
	///필터
	const [filterFirst, setFilterFirst] = useState([]); //상세필터 전 6개 (favorite true가 앞으로)
	const [filterSecond, setFilterSecond] = useState([]); // 상세필터 후 7개
	/////필터-브랜드
	const [totalBrandList, setTotalBrandList] = useState([]); // api 받아온 전체 브랜드 리스트
	const [popularBrandList, setPopularBrandList] = useState([]);
	const [selectedBrandList, setSelectedBrandList] = useState([]); //선택된 브랜드 리스트
	///////필터-모델
	const [searchModelInput, setSearchModelInput] = useState('');
	const [modelResultBox, setModelResultBox] = useState([]);
	const [selectedModelFilter, setSelectedModelFilter] = useState([]);
	const debouncedSearchTerm = useDebounce(searchModelInput, 500);
	//////레퍼런스
	const [searchReference, setSearchReference] = useState('');
	////케이스 사이즈
	const [selectedMinCaseDiameters, setSelectedMinCaseDiaMeters] = useState('');
	const [selectedMaxCaseDiameters, setSelectedMaxCaseDiameters] = useState('');
	//////구동방식
	const [selectedMovement, setSelectedMovement] = useState([]);
	/////////구매년도
	const [producedYearMinInput, setProducedYearMinInput] = useState('');
	const [producedYearMaxInput, setProducedYearMaxInput] = useState('');
	/////////구성품
	const [selectedSupplements, setSelectedSupplements] = useState([]);
	///////성별
	const [selectedGender, setSelectedGender] = useState([]);
	///////케이스 소재
	const [selectedCaseMaterial, setSelectedCaseMaterial] = useState([]);
	//////다이얼 색상
	const [selectedDialColor, setSelectedDialColor] = useState([]);
	///////다이얼 타입
	const [selectedDialType, setSelectedDialType] = useState([]);
	////////
	const [rangeValue, setRangeValue] = useState(0);
	const [conditionStart, setConditionStart] = useState('');
	const [conditionEnd, setConditionEnd] = useState('');
	////////////////상품 리스트 정렬방식
	const [propertyDirection, setPropertyDirection] = useState(PROPERTY_DIRECTION_MENU[0]);
	/////
	const [page, setPage] = useState(0);
	///////보유지점에 보여줄 지점 리스트
	const [currentStoreList, setCurrentStoreList] = useState([]); //보여주기 위해 store name 모음
	/////필터 선택된 지점 리스트
	const [selectedStoreList, setSelectedStoreList] = useState([]);
	const [selectedStoreIdList, setSelectedStoreIdList] = useState([]); // 데이터로 보낼 store ids
	//구매 상단 검색창 (모델, 레퍼런스)
	const [topSearchBox, setTopSearchBox] = useState('');
	const debouncedReferenceSearch = useDebounce(searchReference, 500);
	const debouncedTopSearch = useDebounce(topSearchBox, 500);
	const [statusBox, setStatusBox] = useState(['SALE_REGISTERED', 'SOLD']);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [page]);

	const handleStatus = (status) => {
		const isExistStatus = statusBox.some((oneStatus) => oneStatus === status);
		let result = [...statusBox];
		if (isExistStatus) {
			result = result.filter((oneStatus) => oneStatus !== status);
		} else {
			result.push(status);
		}
		setStatusBox(result);
	};

	useEffect(() => {
		checkUrl && getSellingProducts();
	}, [debouncedTopSearch]);

	useEffect(() => {
		searchModelList();
	}, [debouncedSearchTerm]);

	useEffect(() => {
		checkUrl && getSellingProducts();
	}, [debouncedReferenceSearch]);

	const handleStandard = (oneName) => {
		let findOne = PROPERTY_DIRECTION_MENU.filter((el) => el.name === oneName);
		setPropertyDirection(findOne[0]);
		setShowSort(false);
	};

	useEffect(() => {
		checkUrl && getSellingProductsOtherPage();
	}, [page, checkUrl]);

	useEffect(() => {
		checkStorage && history.action !== 'POP' ? setPage(0) : setPage(page);
	}, [topSearchBox, searchReference, searchModelInput]);

	useEffect(() => {
		checkUrl && getSellingProducts();
		checkStorage && history.action !== 'POP' ? setPage(0) : setPage(page);
	}, [
		propertyDirection,
		selectedBrandList,
		selectedModelFilter,
		// searchReference,
		selectedMinCaseDiameters,
		selectedMaxCaseDiameters,
		selectedMovement,
		selectedSupplements,
		selectedGender,
		selectedCaseMaterial,
		selectedDialColor,
		selectedDialType,
		selectedStoreIdList,
		producedYearMaxInput,
		producedYearMinInput,
		conditionStart,
		conditionEnd,
		checkUrl,
		statusBox,
	]);

	const handleIfFromRankRolling = () => {
		let getBrand = JSON.parse(localStorage.getItem('mainBrand'));
		if (!!getBrand) {
			const searchBrandArray =
				selectedBrandList.filter((el) => el === getBrand).length === 0
					? [...selectedBrandList, getBrand]
					: selectedBrandList;
			setSelectedBrandList(searchBrandArray);
			localStorage.removeItem('mainBrand');
		}

		let getMainSearch = JSON.parse(localStorage.getItem('mainSearch'));
		if (!!getMainSearch) {
			setTopSearchBox(getMainSearch);
			localStorage.removeItem('mainSearch');
		}

		let getRollingModel = JSON.parse(localStorage.getItem('rollingModel'));
		if (!!getRollingModel) {
			const copyModel =
				selectedModelFilter.filter((el) => el === getRollingModel).length === 0
					? [...selectedModelFilter, getRollingModel]
					: selectedModelFilter;
			setSelectedModelFilter(copyModel);
			localStorage.removeItem('rollingModel');
		}
		setCheckUrl(true);
	};

	useEffect(() => {
		handleIfFromRankRolling();
		getBrandList();
		getStoreList();
	}, []);

	useEffect(() => {
		history.action === 'POP' && searchOptions();
	}, [history.action]);

	const handleSearchResult = (brand) => {
		setTopSearchBox('');
		deleteAllFilters();
		let copyState = [...selectedBrandList];
		let isExist = copyState.filter((el) => el === brand);
		if (isExist.length === 0) {
			copyState = [brand]; //하나만 가능하게F
		} else {
			copyState = []; //같은걸 눌렀으면 취소
		}
		setSelectedBrandList(copyState);
		// setShowModelSearch(false);
	};

	const goToDetail = (id) => {
		const filterOptions = {
			propertyDirection,
			page,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
		};
		sessionStorage.setItem('buyFilterCache', JSON.stringify(filterOptions));
		history.push(`/buy/${id}`);
	};

	const searchOptions = () => {
		let result = JSON.parse(sessionStorage.getItem('buyFilterCache'));

		if (result) {
			const {
				propertyDirection,
				page,
				selectedBrandList,
				selectedModelFilter,
				searchReference,
				selectedMinCaseDiameters,
				selectedMaxCaseDiameters,
				selectedMovement,
				selectedSupplements,
				selectedGender,
				selectedCaseMaterial,
				selectedDialColor,
				selectedDialType,
				selectedStoreIdList,
				producedYearMinInput,
				producedYearMaxInput,
				conditionStart,
				conditionEnd,
			} = result;

			sessionStorage.removeItem('buyFilterCache');
			setPropertyDirection(propertyDirection);
			setPage(page);
			setSelectedBrandList(selectedBrandList);
			setSelectedModelFilter(selectedModelFilter);
			setSearchReference(searchReference);
			setSelectedMinCaseDiaMeters(selectedMinCaseDiameters);
			setSelectedMaxCaseDiameters(selectedMaxCaseDiameters);
			setSelectedMovement(selectedMovement);
			setSelectedSupplements(selectedSupplements);
			setSelectedGender(selectedGender);
			setSelectedCaseMaterial(selectedCaseMaterial);
			setSelectedDialColor(selectedDialColor);
			setSelectedDialType(selectedDialType);
			setSelectedStoreIdList(selectedStoreIdList);
			setProducedYearMinInput(producedYearMinInput);
			setProducedYearMaxInput(producedYearMaxInput);
			setConditionStart(conditionStart);
			setConditionEnd(conditionEnd);
		}

		setCheckStorage(true);
	};

	const checkTokenAndOpenFind = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('시계찾아줘 요청은 로그인 후 사용 해주세요.');
			history.push('/signin?redirectUrl=/buy');
		} else {
			history.push('/findWatch');
		}
	};
	const caculateForTotalFilter = (oneFilter, twoFilter) => {
		if (oneFilter?.length > 0 || twoFilter?.length > 0) return 1;
		else return 0;
	};
	let totalFilterSelected =
		selectedBrandList?.length +
		selectedModelFilter?.length +
		caculateForTotalFilter(searchReference) +
		caculateForTotalFilter(selectedMinCaseDiameters, selectedMaxCaseDiameters) +
		selectedMovement?.length +
		caculateForTotalFilter(producedYearMinInput, producedYearMaxInput) +
		selectedSupplements?.length +
		selectedGender?.length +
		selectedCaseMaterial?.length +
		selectedDialColor?.length +
		selectedDialType?.length +
		selectedStoreIdList?.length +
		caculateForTotalFilter(conditionStart, conditionEnd);

	/**
	 * 검색 하면 필터 리셋
	 * @param {*} e
	 */
	const onChangeSearch = (e) => {
		const value = e.target.value;
		setTopSearchBox(value);
		deleteAllFilters();
	};

	const deleteAllFilters = () => {
		setSelectedBrandList([]); //브랜드
		setSelectedModelFilter([]); //모델
		setSearchReference(''); //레퍼런스
		setSelectedMinCaseDiaMeters(''); //사이즈 미니멈
		setSelectedMaxCaseDiameters(''); //사이즈 맥시멈
		setSelectedMovement([]); //구동방식
		setSelectedSupplements([]); //구성품
		setSelectedGender([]); //성별
		setSelectedCaseMaterial([]); //케이스 소재
		setSelectedDialColor([]); //다이얼 컬러
		setSelectedDialType([]); //다이얼 타입
		setSelectedStoreList([]); //store 이름 리스트
		setSelectedStoreIdList([]); //store 아이디 리스트
		setConditionStart(''); //상품 컨디션
		setConditionEnd(''); //상품 컨디션
		setProducedYearMinInput(''); //구매년도 미니멈
		setProducedYearMaxInput(''); //구매년도 맥시멈
	};

	return (
		<>
			{/* {showModelSearch ? (
				<ModelSearch
					setShowModelSearch={setShowModelSearch}
					// setSelectedSearchResult={setSelectedSearchResult}
					// selectedSearchResult={selectedSearchResult}
					selectedModelFilter={selectedModelFilter}
					setSelectedModelFilter={setSelectedModelFilter}
					popularBrandList={popularBrandList}
					selectedBrandList={selectedBrandList}
					setSelectedBrandList={setSelectedBrandList}
					searchReference={searchReference}
					setSearchReference={setSearchReference}
				/>
			) :  */}
			{showFilter ? (
				<BuyFilter
					setShowFilter={setShowFilter}
					selectedBrandList={selectedBrandList}
					setSelectedBrandList={setSelectedBrandList}
					selectedModelFilter={selectedModelFilter}
					setSelectedModelFilter={setSelectedModelFilter}
					modelResultBox={modelResultBox}
					searchModelInput={searchModelInput}
					setSearchModelInput={setSearchModelInput}
					searchReference={searchReference}
					setSearchReference={setSearchReference}
					selectedMinCaseDiameters={selectedMinCaseDiameters}
					setSelectedMinCaseDiaMeters={setSelectedMinCaseDiaMeters}
					selectedMaxCaseDiameters={selectedMaxCaseDiameters}
					setSelectedMaxCaseDiameters={setSelectedMaxCaseDiameters}
					selectedMovement={selectedMovement}
					setSelectedMovement={setSelectedMovement}
					selectedSupplements={selectedSupplements}
					setSelectedSupplements={setSelectedSupplements}
					selectedGender={selectedGender}
					setSelectedGender={setSelectedGender}
					selectedCaseMaterial={selectedCaseMaterial}
					setSelectedCaseMaterial={setSelectedCaseMaterial}
					selectedDialColor={selectedDialColor}
					setSelectedDialColor={setSelectedDialColor}
					selectedDialType={selectedDialType}
					setSelectedDialType={setSelectedDialType}
					selectedStoreIdList={selectedStoreIdList}
					setSelectedStoreIdList={setSelectedStoreIdList}
					producedYearMaxInput={producedYearMaxInput}
					setProducedYearMaxInput={setProducedYearMaxInput}
					producedYearMinInput={producedYearMinInput}
					setProducedYearMinInput={setProducedYearMinInput}
					conditionStart={conditionStart}
					setConditionStart={setConditionStart}
					conditionEnd={conditionEnd}
					setConditionEnd={setConditionEnd}
					totalBrandList={totalBrandList}
					currentStoreList={currentStoreList}
					selectedStoreList={selectedStoreList}
					setSelectedStoreList={setSelectedStoreList}
				/>
			) : (
				<main className="buy">
					<section className="buy_top">
						<h2>시계구매</h2>
						<div
							className="search_wrap"
							// onClick={() => setShowModelSearch(true)}
						>
							<input
								type="text"
								className="input_t"
								placeholder="모델명 또는 레퍼런스 검색"
								value={topSearchBox}
								onChange={onChangeSearch}
							/>
							<button className="btn_search"></button>
						</div>
						<h3>인기 브랜드</h3>
						<ul className="best_brand">
							{popularBrandList.map((brandObject, idx) => (
								<li
									key={idx}
									onClick={() => handleSearchResult(brandObject.brand)}
									style={
										selectedBrandList.filter((el) => el === brandObject.brand).length > 0
											? { border: '1px solid gray' }
											: {}
									}
								>
									<span>{brandObject.brand}</span>
								</li>
							))}
						</ul>
					</section>

					<section className="product_list">
						<p className="total_txt">
							<div>
								전체{' '}
								<b>
									{statusBox.length === 0
										? '0'
										: currentSellingInfo?.total
										? Number(currentSellingInfo.total).toLocaleString()
										: '0'}
									개
								</b>
							</div>
							<div>
								<button
									className={
										statusBox.some((oneStatus) => oneStatus === 'SALE_REGISTERED') ? '' : 'off'
									}
									onClick={() => handleStatus('SALE_REGISTERED')}
								>
									판매중
								</button>
								<button
									className={statusBox.some((oneStatus) => oneStatus === 'SOLD') ? '' : 'off'}
									onClick={() => handleStatus('SOLD')}
								>
									판매완료
								</button>
							</div>
							{/* <button onClick={() => setSelectedSearchResult([])}>전체 해제</button> */}
						</p>
						{statusBox.length > 0 && currentSellingProducts.length > 0 ? (
							<>
								<ul>
									{currentSellingProducts.map((product, idx) => (
										<li key={idx} onClick={() => goToDetail(product.id)}>
											<div className="top">
												<img src={product.attachedFiles[0] || Watch1} alt="시계사진" />
												<div className="info">
													{product?.state === 'SOLD' ? (
														<p className="sale" style={{ background: 'black', margin: '5px' }}>
															SOLD OUT
														</p>
													) : (
														<>
															{!!product?.price &&
																!!product?.standardWatch?.officialPrice &&
																handlePremiumNum(
																	product?.price,
																	product?.standardWatch?.officialPrice,
																) && (
																	<p className="sale">
																		{handlePremiumNum(
																			product?.price,
																			product?.standardWatch?.officialPrice,
																		)}
																		% Premium
																	</p>
																)}
														</>
													)}
													<p className="option">
														{product?.watch?.supplements?.includes('GUARANTEE') && (
															<img src={Warranty} alt="보증서" title="보증서가 있는 상품입니다." />
														)}
														{product?.watch?.supplements?.includes('BOX') && (
															<img
																src={IcoBox}
																alt="정품박스"
																title="정품박스가 있는 상품입니다."
															/>
														)}
													</p>
													{!!product?.standardWatch?.hpi ? (
														<p
															className={
																product?.standardWatch?.hpi > 0
																	? 'fluctuations up'
																	: product?.standardWatch?.hpi < 0
																	? 'fluctuations down'
																	: product?.standardWatch?.hpi === 0
																	? 'fluctuations'
																	: ''
															}
														>
															{product?.standardWatch?.hpi}
														</p>
													) : (
														''
													)}
												</div>
											</div>
											<div className="btm">
												<p className="watch_name ellipsis poppins">
													{product?.watch?.brand || '-'} <br />
													{product?.watch?.model || '-'}
												</p>
												<p className="price">
													₩ {product?.price ? Number(product?.price).toLocaleString() : '-'}
												</p>
												<ul>
													<li>{product?.watch?.producedYear || '-'}</li>
													<li>{product?.watch?.condition || '-'}</li>
													<li>{product?.watch?.serialNo || '-'}</li>
												</ul>
											</div>
										</li>
									))}
								</ul>
								<CommonPagination
									limit="16"
									commonData={currentSellingInfo}
									page={page}
									setPage={setPage}
								/>
							</>
						) : (
							<section className="nodata_wrap">
								<p className="t1">
									{/* 조건에 맞는 시계를 찾을 수 없습니다. <br />
									조건을 바꿔보세요. */}
									조건에 맞는 시계를 찾고 있습니다.
									<br />
									찾는 시계가 없다면 조건을 바꿔보세요.
								</p>
								<p className="t2">
									혹시 찾으시는 시계를 찾을 수 없다면 <br />
									<b>‘시계찾아줘’</b>를 요청해보세요.
								</p>
								<button className="link" onClick={checkTokenAndOpenFind}>
									시계 찾아줘 바로가기
								</button>
							</section>
						)}
					</section>
					{/* <section className="product_list">
					<p className="total_txt">
						전체 <b>0개</b>
					</p>
					<div className="nodata">
						<p className="t1">
							조건에 맞는 시계를 찾을 수 없습니다. <br />
							조건을 바꿔보세요.
						</p>
						<p className="t2">
							혹시 찾으시는 시계를 찾을 수 없다면 <br />
							<b>‘시계찾아줘’</b>를 요청해보세요.
						</p>
						<a href="#a" className="link">
							시계 찾아줘 바로가기
						</a>
					</div>
				</section> */}
					{/* <article className="login_wrap">
					<h2>로그인</h2>
					<div className="input_div">
						<input type="text" className="input_t" placeholder="아이디" />
					</div>
					<div className="input_div">
						<input type="password" className="input_t" placeholder="비밀번호" />
					</div>
					<div className="auto_login">
						<label>
							<input type="checkbox" />
							<span>자동로그인</span>
						</label>
						<a href="#a">정보찾기</a>
					</div>
					<button className="btn_login">로그인</button>
					<a href="#a" className="btn_join">
						회원가입
					</a>
					<button className="btn_closed"></button>
				</article> */}
					<section className="filter_wrap">
						<button className="btn_filter" onClick={() => setShowFilter(true)}>
							필터
							<span>{totalFilterSelected}</span>
						</button>
						<button className="btn_sort" onClick={() => setShowSort(true)}>
							정렬
						</button>
					</section>
				</main>
			)}
			{showSort && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="fliter_pop">
						<ul>
							{PROPERTY_DIRECTION_MENU.map((standard, idx) => (
								<li key={idx}>
									<button onClick={() => handleStandard(standard.name)}>{standard.name}</button>
								</li>
							))}
						</ul>
					</article>
				</div>
			)}
		</>
	);
	function getBrandList() {
		getBrandListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				const cutPopularFive = data
					.sort(function (a, b) {
						return b.count - a.count;
					})
					.slice(0, 5);
				setTotalBrandList(data);
				setPopularBrandList(cutPopularFive);
			} else {
				alert(detail);
			}
		});
	}

	function getSellingProductsOtherPage() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getMobileSellingProductsApi(
			propertyDirection,
			page,
			authToken,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
			topSearchBox,
			statusBox,
			// status,
		).then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentSellingProducts(data.content || []);
				setCurrentSellingInfo(data);
			} else {
				alert(detail);
			}
		});
	}
	function getSellingProducts() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		// const decodeBrand =
		// 	window.location.pathname?.split('/')[3]?.length > 0
		// 		? decodeURIComponent(window.location.pathname.split('/')[3])
		// 		: '';
		// const urlBrandArray =
		// 	window.location.pathname.split('/')[3]?.length > 0
		// 		? [...selectedBrandList, decodeBrand]
		// 		: selectedBrandList;
		getMobileSellingProductsApi(
			propertyDirection,
			page,
			authToken,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
			topSearchBox,
			statusBox,
			// status,
		).then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentSellingProducts(data.content || []);
				setCurrentSellingInfo(data);
				setPage(0);
			} else {
				alert(detail);
			}
		});
	}
	function searchModelList() {
		searchModelListApi(searchModelInput).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setModelResultBox(data || []);
			} else {
				alert(detail);
			}
		});
	}
	function getStoreList() {
		getStoreListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}
};
export default Buy;
