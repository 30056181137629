import React, { useState } from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import Logo from 'assets-mobile/img/common/logo.svg';
import ButtonM from 'assets-mobile/img/common/btn_m.svg';
function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

const MobileHeader = ({ mobileNav, setMobileNav }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 3,
		autoplay: false,
		arrows: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<SliderWrapper>
			<header className="header">
				<div className="inner">
					<h1 className="logo">
						<a href="/">
							<img src={Logo} alt="HISIGAN" />
						</a>
					</h1>
					<button onClick={() => setMobileNav(!mobileNav)}>
						<img src={ButtonM} alt="" />
					</button>
				</div>
				<nav>
					<ul
					// style={{ display: 'flex', justifyContent: 'space-between' }}
					// style={{ transform: `translateX(${(-100 / 7) * (0.5 + currentIndex)}%)` }}
					>
						{/* 시계구매 / 공간대여 / 부티크 / 뉴스&이벤트 / 시계판매 / 고객센터/ 드로우 */}
						<Slider {...settings}>
							<li>
								{/* <a href="/buy">시계구매</a> */}
								<a href="https://hisigan.co.kr/user/main/getSale.do?filter=N">시계구매</a>
							</li>
							<li>
								<a href="/hapl">공간대여</a>
							</li>
							<li>
								<a href="/review">리뷰pick</a>
							</li>
							<li>
								<a href="/store">부티크</a>
							</li>
							<li>
								<a href="/notice">{`뉴스&이벤트 `}</a>
							</li>
							{/* <li>
								<a href="/sell">시계판매</a>
							</li> */}
							<li>
								<a href="/faq">고객센터</a>
							</li>
							<li>
								<a href="https://hisigan.co.kr/user/main/getMain.do">시세조회</a>
							</li>
							{/* <li>
								<a href="/auction">하이옥션</a>
							</li> */}
						</Slider>
					</ul>
				</nav>
			</header>
		</SliderWrapper>
	);
};

export default MobileHeader;

const SliderWrapper = styled('div')`
	.slick-list {
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					display: flex;
				`;
			}
		}}
		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		/* display: block; */
		display: flex;
		justify-content: center;
		align-items: center;
	}
	/* .slick-initialized .slick-slide:nth-child(4n) {
		display: block; */
	/* display: flex;
		justify-content: end;
		align-items: center; */
	/* } */
	/* .slick-initialized .slick-slide:nth-child(5n) { */
	/* display: block; */
	/* display: flex;
		justify-content: end;
		align-items: center; */
	/* } */
	/* .slick-initialized .slick-slide:nth-child(6n) { */
	/* display: block; */
	/* display: flex;
		justify-content: end;
		align-items: center; */
	/* } */
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		/* display: block; */
		display: flex;
		justify-content: center;
		align-items: center;
		/* width: 30px; */
		/* height: 30px; */
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		/* font-size: 30px; */
		line-height: 1;
		/* width: 30px; */
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -5.9274vw;
		width: 5.4795vw;
		display: flex !important;
		justify-content: center;
	}
	.slick-prev:before {
		content: '<';
	}
	[dir='rtl'] .slick-prev:before {
		content: '>';
	}

	.slick-next {
		/* right: -60px; */
		right: -7.0973vw;
	}
	.slick-next:before {
		content: '>';
	}
	[dir='rtl'] .slick-next:before {
		content: '<';
	}
`;

const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
	/* width:390px;
	height:250px; */
`;
