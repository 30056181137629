import React, { useRef, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Chart from 'chart.js';
import YTSearch from 'youtube-api-search';
import { useSiteState } from 'context/SiteContext';
import mainBanner1 from 'assets-pc/img/common/img_main_banner_01.png';
import mainBanner2 from 'assets-pc/img/common/img_main_banner_02.png';
import { getShopInfoApi } from 'apis/map';
import { getHaplMainListApi } from 'apis/hapl';
import DEFAULT_IMAGE from 'assets-pc/front-img/DEFAULT.jpeg';
import MainSlider from './components/MainSlider';
import {
	getRollingApiList,
	getSliderApiList,
	getTopBrandApiList,
	postSliderApiList,
	getMainPopupApiList,
} from 'apis/mainPage';
import { getYoutubeApi } from 'apis/map';
import { getReviewListApi } from 'apis/review';
import styled, { keyframes } from 'styled-components';
import CommonHelper from 'helpers/CommonHelper';
import SeoulPic from 'assets-pc/front-img/pc-place-seoul.png';
import DeaguPic from 'assets-pc/front-img/pc-place-daegu.png';
import BusanPic from 'assets-pc/front-img/main_busan.jpeg';
import MarinPic from 'assets-pc/front-img/marin_pc_main.png';
import YoutubeThumbnail3 from 'assets-pc/front-img/youtube33.jpeg';
import YoutubeThumbnail2 from 'assets-pc/front-img/youtube22.jpeg';
import YoutubeThumbnail1 from 'assets-pc/front-img/youtube11.jpeg';
import YoutubeButton from 'assets-pc/front-img/youtube_button.png';
import KakaoPopup from 'assets-mobile/img/main/kakao-popup-gif.gif';
import PlacePopup from 'assets-pc/front-img/Sign-up-popup.png';
import Certification1 from 'assets-pc/front-img/certification_1.svg';
import Certification2 from 'assets-pc/front-img/certification_2.svg';
import Certification3 from 'assets-pc/front-img/certification_3.svg';
import Certification4 from 'assets-pc/front-img/certification_4.svg';
import Certification5 from 'assets-pc/front-img/certification_5.svg';
import Certification6 from 'assets-pc/front-img/certification_6.svg';
import Certification7 from 'assets-pc/front-img/certification_7.svg';
import Certification8 from 'assets-pc/front-img/certification_8.svg';
import PcMainHapl from 'assets-pc/front-img/pc-main-hapl-3.png';
import Review1 from 'assets-pc/front-img/review1.svg';
import Review2 from 'assets-pc/front-img/review2.svg';
import Review3 from 'assets-pc/front-img/review3.svg';
import Review4 from 'assets-pc/front-img/review4.svg';
import OtherGuarantee from 'assets-pc/front-img/other-guarantee.png';
import OtherCare from 'assets-pc/front-img/other-care.png';
import OtherPrice from 'assets-pc/front-img/other-price.png';
import './index.scss';

//데이터 길이 변화에도 롤링 잘되는지 & 확인하기 위한 목데이터 (4개들어있음)
// import { rollingData } from './rollingData';
// 탑브랜드 순위변동 0일경우 && 데이터5개 안될때 박스크기 확인하기 위한 목데이터
// import { waitData, runData, doneData } from './topBrandeData';

const scrolling = (dataLength) => keyframes`
from {
				transform: translateX(-100%);
			}
	to  {
				transform: translateX(${dataLength}%);
			}
`;



const Main = () => {
	const [rollingData, setRollingData] = useState([]);
	const [isGotRollingAPI, setIsGotRollingAPI] = useState(false);
	const [sliderData, setSliderData] = useState([]);
	const [waitData, setWaitData] = useState([]);
	const [doneData, setDoneData] = useState([]);
	const [runData, setRunData] = useState([]);

	const [videoList, setVideoList] = useState([]);

	const [mainPopupTotal, setMainPopupTotal] = useState([]);
	const [mainPopupCookieData, setMainPopupCookieData] = useState([]);
	const [todayPopupIdArr, setTodayPopupIdArr] = useState([]);
	const [againPopupIdArr, setAgainPopupIdArr] = useState([]);
	const [mockShopSelected, setMockShopSelected] = useState('하이시간 본사');
	const [kakaoPopupLeft, setKakaoPopupLeft] = useState(0);
	const [placePopupRight, setPlacePopupRight] = useState(0);
	const [popupHeight, setPopupHeight] = useState(0);
	const [buttonHeight, setButtonHeight] = useState(0);
	const [showNewPopup, setShowNewPopup] = useState(true);
	const [showOrNot, setShowOrNot] = useState(true);
	const rollingRef = useRef(null);
	const [reviewData, setReviewData] = useState([]);
	const { data: siteData } = useSiteState();

	const [haplData, setHaplData] = useState([]);

	useEffect(() => {
		getMainHaplList();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		getShopInfo();
		getRollingApi();
		getCookie();
		getTopBrandApi();
		getSliderData();
		getReviewList();
		// getYoutubeList().then((res) => {
		// 	setVideoList(res.slice(0,3))
		// })
	}, []);

	useEffect(() => {
		if (window.innerWidth) {
			caculatePopupPlace();
		}
	}, [window.innerWidth]);

	useEffect(() => {
		if (window.outerHeight) {
			setPopupHeight(window.outerHeight * 0.3);
			setButtonHeight(window.outerHeight * 0.3 + 425);
		}
	}, [window.outerHeight]);

	useEffect(() => {
		// getChart();
	}, [isGotRollingAPI]);

	const getYoutubeList = async () => {
		// getYoutubeApi().then((res) => console.log(res))
		return new Promise((resolve) => {
			YTSearch({ key: 'AIzaSyDEgw7n8zkp-sGupGbQQrOGe1OKela274w', term: '하이시간' }, (data) => {
				resolve(
					data.map((video) => {
						return {
							title: video.snippet.title,
							url: video.snippet.thumbnails.default.url,
							description: video.snippet.description,
							id: video.id.videoId,
						};
					}),
				);
			});
		});
	};

	/**
	 * 롤링배너 api
	 */
	const getRollingApi = () => {
		getRollingApiList().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setRollingData(data ? data : []);
				setIsGotRollingAPI(true);
			} else {
				alert(detail);
			}
		});
	};

	/**
	 * 롤링배너 차트
	 */
	const getChart = () => {
		const rollingPrice = rollingData.map((item) => {
			return item.priceHistories;
		});

		const rollingPriceArr = rollingPrice.map((item) => {
			return item.map((item) => {
				return item.price;
			});
		});

		var chart1 = document.getElementsByClassName('chart1');
		var chart2 = document.getElementsByClassName('chart2');

		rollingPriceArr.map((item, idx) => {
			var ctx = chart1[idx].getContext('2d');
			var gradientFill = ctx.createLinearGradient(0, 50, 0, 0);
			gradientFill.addColorStop(1, 'rgba(190, 145, 98, 1)');
			gradientFill.addColorStop(0, 'rgba(190, 145, 98, 0)');
			new Chart(ctx, {
				type: 'line',
				data: {
					labels: ['1', '2', '3', '4', '5', '6', '7'],
					datasets: [
						{
							backgroundColor: gradientFill,
							borderColor: 'rgba(190, 145, 98, 0.25)',
							data: item,
							pointRadius: 0,
						},
					],
				},
				options: {
					animation: {
						duration: 0,
					},
					legend: {
						display: false,
					},
					tooltips: false,
					clip: false,
					elements: {
						point: {
							radius: 0,
						},
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									display: false,
									color: '#2B3035',
								},
								ticks: {
									display: false,
									padding: 10,
								},
							},
						],
						yAxes: [
							{
								gridLines: {
									display: false,
									color: '#2B3035',
								},
								ticks: {
									display: false,
									padding: 10,
								},
							},
						],
					},
				},
			});

			var ctx2 = chart2[idx].getContext('2d');
			var gradientFill = ctx2.createLinearGradient(0, 50, 0, 0);
			gradientFill.addColorStop(1, 'rgba(190, 145, 98, 1)');
			gradientFill.addColorStop(0, 'rgba(190, 145, 98, 0)');
			new Chart(ctx2, {
				type: 'line',
				data: {
					labels: ['1', '2', '3', '4', '5', '6', '7'],
					datasets: [
						{
							backgroundColor: gradientFill,
							borderColor: 'rgba(190, 145, 98, 0.25)',
							data: item,
							pointRadius: 0,
						},
					],
				},
				options: {
					animation: {
						duration: 0,
					},
					legend: {
						display: false,
					},
					tooltips: false,
					clip: false,
					elements: {
						point: {
							radius: 0,
						},
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									display: false,
									color: '#2B3035',
								},
								ticks: {
									display: false,
									padding: 10,
								},
							},
						],
						yAxes: [
							{
								gridLines: {
									display: false,
									color: '#2B3035',
								},
								ticks: {
									display: false,
									padding: 10,
								},
							},
						],
					},
				},
			});
		});
	};

	const history = useHistory();
	const OFFSET = 100;
	const PAGE = 0;
	const imgStyle = {
		// width: '100%',
		// height: '100%',
		position: 'absolute',
		width: '600px',
		overflow: 'hidden',
		height: '400px',
		cursor: 'pointer',
	};

	// main slider 데이터 생성 함수
	const getSliderData = () => {
		getSliderApiList().then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setSliderData(data ? data : {});
			} else {
				alert(detail);
			}
		});
	};

	const getReviewList = () => {
		try {
			// setIsLoading(true);
			getReviewListApi(4, 0).then(({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setReviewData(data.content || {});
				} else {
					alert(detail);
				}
				// setIsLoading(false);
			});
		} catch (err) {
			// setIsLoading(false);
		}
	};

	const [shopDatas, setShopDatas] = useState({});
	const getShopInfo = () => {
		getShopInfoApi(PAGE, OFFSET).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setShopDatas(data ? data : {});
			} else {
				alert(detail);
			}
		});
	};

	/**
	 * name => 서울, 대구, 부산
	 */
	const goToShopInfo = (name) => {
		history.push(`/shop/${name}`);
	};

	const getTopBrandApi = () => {
		getTopBrandApiList().then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setDoneData(data?.DONE || []);
				setRunData(data?.RUN || []);
				setWaitData(data?.WAIT || []);
			} else {
				alert(detail);
			}
		});
	};

	//메인팝업
	const getCookie = () => {
		getMainPopupApiList().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				const cookie = document.cookie;
				const cookieSplit = cookie.split(';');

				const cookieMap = cookieSplit.map((item) => {
					return item.trim().split('=');
				});

				const filterCookie = cookieMap.filter((item) => {
					return item[0] && item[0].startsWith('NOT_SEEING');
				});

				const filterCookieMap = filterCookie.map((item) => {
					return item.map((item) => {
						return item.split(',');
					});
				});
				const filterCookieMapFlat = filterCookieMap.flat();

				const todayPopupId = filterCookieMapFlat.map((item, idx) => {
					return filterCookieMapFlat[idx] == 'NOT_SEEING_TODAY' ? filterCookieMapFlat[idx + 1] : [];
				});

				const againPopupId = filterCookieMapFlat.map((item, idx) => {
					return filterCookieMapFlat[idx] == 'NOT_SEEING_AGAIN' ? filterCookieMapFlat[idx + 1] : [];
				});

				const againPopupIdFlat = againPopupId.flat();
				const todayPopupIdFlat = todayPopupId.flat();

				const todayNum =
					todayPopupIdFlat &&
					todayPopupIdFlat.map((item) => {
						return Number(item);
					});

				const againNum =
					againPopupIdFlat &&
					againPopupIdFlat.map((item) => {
						return Number(item);
					});

				const copyMainPopupCookieData = [...data];
				const allpopupId = againNum.concat(todayNum);

				const arr = copyMainPopupCookieData.filter((item) => {
					return !allpopupId.includes(item.id);
				});

				setMainPopupCookieData((arr && arr) || []);
				setMainPopupTotal((arr && arr) || []);
				setTodayPopupIdArr((todayNum && todayNum) || []);
				setAgainPopupIdArr((againNum && againNum) || []);
			} else {
				alert(detail);
			}
		});
	};

	/**
	 * 팝업닫기버튼
	 * @param {*} e
	 */
	const handleMainPopupClosed = (e) => {
		const popupId = +e.target.id;
		const copyMainPopupCookieData = [...mainPopupCookieData];
		const filterArr = copyMainPopupCookieData.filter((item) => {
			return item.id !== popupId;
		});
		setMainPopupCookieData(filterArr);
	};

	const setCookie = (name, value, exp) => {
		var date = new Date();
		date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
		document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
	};

	/**
	 * 팝업 다시보지않기 버튼
	 * @param {*} e
	 */
	const handleNotSeeing = (e) => {
		let popupId = +e.target.id;
		const popupValue = e.target.value;
		const copyMainPopupCookieData = [...mainPopupCookieData];
		const filterArr = copyMainPopupCookieData.filter((item) => {
			return item.id !== popupId;
		});
		setMainPopupCookieData(filterArr);

		if (popupValue === 'NOT_SEEING_TODAY') {
			todayPopupIdArr.push(popupId);
			const popupIdToString = todayPopupIdArr.toString();
			return setTodayPopupIdArr(todayPopupIdArr), setCookie(popupValue, popupIdToString, 1);
		} else if (popupValue === 'NOT_SEEING_AGAIN') {
			againPopupIdArr.push(popupId);
			const popupAgainIdToString = againPopupIdArr.toString();
			return (
				setAgainPopupIdArr(againPopupIdArr), setCookie(popupValue, popupAgainIdToString, 10000)
			);
		}
	};

	const goToBuyPage = (brand) => {
		// history.push(`/buy/brand/${encodeURIComponent(brand)}`);
		window.location.href = 'https://hisigan.co.kr/user/main/getSale.do?filter=N';
	};
	const goToBuyDonePage = (brand) => {
		window.location.href = 'https://hisigan.co.kr/user/main/getSale.do?filter=N';
		// history.push(`/buy/sold/${encodeURIComponent(brand)}`);
	};

	const caculatePopupPlace = () => {
		const nowWidth = window.innerWidth;
		if (nowWidth > 1666) {
			setShowOrNot(true);
			setPlacePopupRight(nowWidth / 2 - 848);
			setKakaoPopupLeft(nowWidth / 2 - 818);
		} else {
			setShowOrNot(false);
			setPlacePopupRight(nowWidth / 2 - 848);
			setKakaoPopupLeft(nowWidth / 2 - 818);
		}
	};

	const openRegister = () => {
		window.open('https://cafe.naver.com/hisigan');
	};

	const heightCheck = () => {
		const nowHeight = window.outerHeight;
		let getPopupElement = document?.getElementById('KakaoPopup')?.getBoundingClientRect()?.top + 15;
		if (nowHeight * 0.3 <= getPopupElement) {
			setShowNewPopup(true);
		} else {
			setShowNewPopup(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', heightCheck);
	}, [window.outerHeight]);

	const goHaplPage = () => {
		history.push('/hapl');
	};

	const goToReview = (reviewPage) => {
		window.location.href = reviewPage;
	};

	const goComparePrice = (siteData) => {
		window.location.href = siteData.saleBackgroundImg;
	};

	return (
		<>
			<div className="container main">
				{/* <!-- 상단 스크롤 차트 --> */}
				{/* <img
						src={KakaoPopup}
						style={{ position: 'fixed', left: `${kakaoPopupLeft}px`, zIndex: '100', top: '40%' }}
						alt="카카오 채널 추가하는 방법"
					/>
					<a
						href="https://form.office.naver.com/form/responseView.cmd?formkey=ODhkOThkNmEtYzE2ZS00OTNjLWE2ZDctODQxZGUyMTRmMjJk&sourceId=urlshare"
						target="_blank"
						rel="noreferrer"
					>
						<img
							src={PlacePopup}
							style={{
								position: 'fixed',
								right: `${placePopupRight}px`,
								zIndex: '100',
								top: '40%',
							}}
							alt="공간대여 신청하기"
						/>
					</a> */}

				<div>
					{mainPopupTotal.length < 2
						? mainPopupCookieData.map((item, idx) => {
								return (
									<div
										key={idx}
										className="main_pop"
										style={
											!!item?.left
												? {
														width: item.width,
														height: item.height + 77,
														left: item.left || 0,
												  }
												: {
														width: item.width,
														height: item.height + 77,
														right: item.right || 0,
												  }
										}
									>
										<div className="pop_cont" style={{ width: item.width, height: item.height }}>
											<a href={item.link} target="blank">
												<img
													src={item.photoUrl}
													style={{ width: item.width, height: item.height }}
												/>
											</a>
										</div>
										{item.hiddenOption === 'NONE' ? (
											<div className="option">
												<label></label>
												<button className="closed_btn" id={item.id} onClick={handleMainPopupClosed}>
													닫기
												</button>
											</div>
										) : (
											<div className="option">
												<label>
													<input
														id={item.id}
														type="checkbox"
														value={item.hiddenOption}
														onClick={handleNotSeeing}
													/>
													{(item.hiddenOption === 'NOT_SEEING_TODAY' && (
														<span>오늘 하루 보지 않기</span>
													)) ||
														(item.hiddenOption === 'NOT_SEEING_AGAIN' && (
															<span>다시 보지 않기</span>
														))}
												</label>
												<button className="closed_btn" id={item.id} onClick={handleMainPopupClosed}>
													닫기
												</button>
											</div>
										)}
									</div>
								);
						  })
						: mainPopupCookieData.map((item, idx) => {
								return (
									<div
										key={idx}
										className="main_pop"
										style={
											!!item?.left
												? {
														width: item.width,
														height: item.height + 77,
														left: item.left || 0,
												  }
												: {
														width: item.width,
														height: item.height + 77,
														right: item.right || 0,
												  }
										}
									>
										<div className="pop_cont" style={{ width: item.width, height: item.height }}>
											<a href={item.link} target="blank">
												<img
													src={item.photoUrl}
													style={{ width: item.width, height: item.height }}
													alt="팝업"
												/>
											</a>
										</div>
										{item.hiddenOption === 'NONE' ? (
											<div className="option">
												<label></label>
												<button className="closed_btn" id={item.id} onClick={handleMainPopupClosed}>
													닫기
												</button>
											</div>
										) : (
											<div className="option">
												<label>
													<input
														id={item.id}
														type="checkbox"
														value={item.hiddenOption}
														onClick={handleNotSeeing}
													/>
													{(item.hiddenOption === 'NOT_SEEING_TODAY' && (
														<span>오늘 하루 보지 않기</span>
													)) ||
														(item.hiddenOption === 'NOT_SEEING_AGAIN' && (
															<span>다시 보지 않기</span>
														))}
												</label>
												<button className="closed_btn" id={item.id} onClick={handleMainPopupClosed}>
													닫기
												</button>
											</div>
										)}
									</div>
								);
						  })}
				</div>

				<MainSlider sliderData={sliderData} />
				{/* iframe */}
				{/* <section>
						<div className="main-iframe-title-wrapper">
							<div className="title">시세현황</div>
						</div>
					</section> */}

				{/* 외부 팝업 */}
				<section className="main-iframe-content-wrapper">
					<div className="iframe-wrapper new-popup-img-container" id="KakaoPopup">
						{showOrNot ? (
							<>
								<a href="http://pf.kakao.com/_dnSxgs" target="_blank" rel="noreferrer">
									<img
										src={KakaoPopup}
										className={showNewPopup ? 'new-popup-img kakao-popup' : ''}
										alt="카카오 채널 추가하는 방법"
										style={
											!showNewPopup
												? {
														position: 'fixed',
														left: `${kakaoPopupLeft}px`,
														zIndex: '100',
														top: `${popupHeight}px`,
												  }
												: {}
										}
									/>
								</a>
								<div style={{ position: 'relative' }}>
									<div style={{ position: 'absolute' }}></div>
									<a
										href="https://www.hisigan.co.kr/price.do"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src={PlacePopup}
											className={showNewPopup ? 'new-popup-img place-popup' : ''}
											alt="공간대여 신청하기"
											id="PlacePopup"
											style={
												!showNewPopup
													? {
															position: 'fixed',
															right: `${placePopupRight}px`,
															zIndex: '100',
															top: `${popupHeight}px`,
													  }
													: {}
											}
										/>
									</a>
									<div
										className={showNewPopup ? 'new-popup-button' : ''}
										onClick={openRegister}
										style={
											!showNewPopup
												? {
														position: 'fixed',
														right: `${placePopupRight}px`,
														zIndex: '100',
														top: `${buttonHeight}px`,
														width: '200px',
														height: '150px',
														cursor: 'pointer',
												  }
												: {}
										}
									></div>
								</div>
							</>
						) : (
							''
						)}

						<iframe src={'https://sigan24.cafe24.com/graph/flex.html'}></iframe>
					</div>
				</section>
				<section className="hapl-section" onClick={goHaplPage} style={{ cursor: 'pointer' }}>
					<div className="top-section">
						<div className="title">
							하이시간 공간대여
							<span className="desc">명품시계 직거래도 역시 하이시간 공간대여 서비스 입니다</span>
						</div>
						<span className="more">MORE</span>
					</div>
					<div className="img-list-section">
						<div className="img-box">
							<img src={PcMainHapl} className="hapl-img" alt="공간대여 이미지" />
						</div>
						<div className="reserve-box">
							<div className="part">
								<div className="head">직영점</div>
								<ul className="body">
									{haplData?.length > 0
										? haplData?.map((el, idx) => (
												<li className="list" key={idx}>
													{el.store.name || ''}
												</li>
										  ))
										: ''}
								</ul>
							</div>
							<div className="part">
								<div className="head">예약일</div>
								<ul className="body">
									{haplData?.length > 0
										? haplData?.map((el, idx) => (
												<li className="list" key={idx}>
													{el.day || ''}
												</li>
										  ))
										: ''}
								</ul>
							</div>
							<div className="part">
								<div className="head">예약시간</div>
								<ul className="body">
									{haplData?.length > 0
										? haplData?.map((el, idx) => (
												<li className="list" key={idx}>
													{el.timeStr || ''}
												</li>
										  ))
										: ''}
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section className="boutique-wrapper">
					{/* <p className="tit">
							하이시간은
							<b>
								명품시계 P2P 거래를 <br />
								전문으로 하는 중개 서비스 회사
							</b>
							입니다.
						</p> */}

					<div className="boutique-content-wrapper">
						<p className="tit title">
							가까운 <span className="big_text">하이시간 부티크</span>
							로 <br />
							방문하세요
							<div className="btn-wrapper">
								<button
									style={
										mockShopSelected === '하이시간 본사'
											? {
													background: '#D1AD86',
													width: '125px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
											: {
													background: '#2B3035',
													width: '125px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
									}
									onClick={() => setMockShopSelected('하이시간 본사')}
								>
									하이시간 본사
								</button>
								<button
									style={
										mockShopSelected === '서울 압구정점'
											? {
													background: '#D1AD86',
													width: '125px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
											: {
													background: '#2B3035',
													width: '125px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
									}
									onClick={() => setMockShopSelected('서울 압구정점')}
								>
									서울 압구정점
								</button>
								<button
									style={
										mockShopSelected === '마린시티점'
											? {
													background: '#D1AD86',
													width: '118px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
											: {
													background: '#2B3035',
													width: '118px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
									}
									onClick={() => setMockShopSelected('마린시티점')}
								>
									마린시티점
								</button>
								<button
									style={
										mockShopSelected === '대구 수성점'
											? {
													background: '#D1AD86',
													width: '118px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
											: {
													background: '#2B3035',
													width: '118px',
													height: '52px',
													fontSize: '16px',
													fontWeight: '700',
													lineHeight: '20px',
											  }
									}
									onClick={() => setMockShopSelected('대구 수성점')}
								>
									대구 수성점
								</button>
							</div>
						</p>

						<div className="img-box">
							{mockShopSelected === '하이시간 본사' && (
								<img
									src={BusanPic}
									style={imgStyle}
									alt="본점 매장 사진"
									className="new_main_shop_photo "
									onClick={() => goToShopInfo(1)}
								/>
							)}
							{mockShopSelected === '서울 압구정점' && (
								<img
									src={SeoulPic}
									style={imgStyle}
									alt="서울 매장 사진"
									className="new_main_shop_photo "
									onClick={() => goToShopInfo(2)}
								/>
							)}
							{mockShopSelected === '마린시티점' && (
								<img
									src={MarinPic}
									style={imgStyle}
									alt="마린시티점 사진"
									className="new_main_shop_photo "
									onClick={() => goToShopInfo(3)}
								/>
							)}
							{mockShopSelected === '대구 수성점' && (
								<img
									src={DeaguPic}
									style={imgStyle}
									alt="대구 매장 사진"
									className="new_main_shop_photo "
									onClick={() => goToShopInfo(4)}
								/>
							)}
						</div>
					</div>
				</section>
				<section className="review-section">
					<div className="top-section">
						<span className="left">리뷰pick</span>
						<span className="right">MORE</span>
					</div>
					<div className="review-container">
						<div
							className="one-review"
							onClick={() =>
								goToReview(`https://main.hisigan.com/review/${reviewData[0].id || ''}`)
							}
						>
							<img
								src={reviewData[0]?.attacheFiles.length > 0 ? reviewData[0]?.attacheFiles[0] : ''}
								style={{ width: '298px', height: '385px' }}
							/>
							<div className="desc">{reviewData[0]?.title || ''}</div>
						</div>
						<div
							className="one-review"
							onClick={() =>
								goToReview(`https://main.hisigan.com/review/${reviewData[1].id || ''}`)
							}
						>
							<img
								src={reviewData[1]?.attacheFiles.length > 0 ? reviewData[1]?.attacheFiles[0] : ''}
								style={{ width: '298px', height: '385px' }}
							/>
							<div className="desc">{reviewData[1]?.title || ''}</div>
						</div>
						<div
							className="one-review"
							onClick={() =>
								goToReview(`https://main.hisigan.com/review/${reviewData[2].id || ''}`)
							}
						>
							<img
								src={reviewData[2]?.attacheFiles.length > 0 ? reviewData[2]?.attacheFiles[0] : ''}
								style={{ width: '298px', height: '385px' }}
							/>
							<div className="desc">{reviewData[2]?.title || ''}</div>
						</div>
						<div
							className="one-review"
							onClick={() =>
								goToReview(`https://main.hisigan.com/review/${reviewData[3].id || ''}`)
							}
						>
							<img
								src={reviewData[3]?.attacheFiles.length > 0 ? reviewData[3]?.attacheFiles[0] : ''}
								style={{ width: '298px', height: '385px' }}
							/>
							<div className="desc">{reviewData[3]?.title || ''}</div>
						</div>
					</div>
				</section>
				{siteData ? (
					<section className="other-section">
						<div className="top-section">바로가기</div>
						<div className="content-section">
							<div className="one-content" onClick={() => history.push('/guarantee')}>
								<img src={OtherGuarantee} alt="보증거래서비스" />
								<div className="btn">
									보증상품거래서비스
									<span>+</span>
								</div>
							</div>
							<div className="one-content" onClick={() => history.push('/care')}>
								<img src={OtherCare} alt="하이시간케어서비스" />
								<div className="btn">
									하이시간케어서비스
									<span>+</span>
								</div>
							</div>
							<div className="one-content" onClick={() => goComparePrice(siteData)}>
								<img src={OtherPrice} alt="가격정보비교" />
								<div className="btn">
									가격정보비교
									<span>+</span>
								</div>
							</div>
						</div>
					</section>
				) : (
					''
				)}
				{/* 유튜브 */}
				<section className="youtube-section">
					<div className="new_youtube_title_wrapper">
						<div className="title">최신영상</div>
						<a
							className="more"
							href="https://www.youtube.com/channel/UChurZ73ZijJ3wSSplPlXE5g"
							target="_blank"
						>
							MORE
						</a>
					</div>

					<div className="new_youtube_wrapper">
						<div className="img_wrapper">
							<a href={`https://www.youtube.com/watch?v=z6qPPHYZ-zk`} target="_black">
								<div
									style={{
										width: '418px',
										zIndex: '99',
										backgroundColor: 'rgba(0,0,0,0.2)',
										height: '235px',
										position: 'absolute',
									}}
								/>
								<img
									src={YoutubeThumbnail1}
									alt={'youtube 동영상'}
									style={{ width: '418px', position: 'absolute' }}
								/>
								<img src={YoutubeButton} alt="재생 버튼" className="btn-img" />
							</a>
							<div className="content" style={{ padding: '0 53px' }}>
							[하이시간TV] 2023년 롤렉스 신제품 발표 롤렉스의 알록달록한 세상
							</div>
						</div>
						<div className="img_wrapper">
							<a href={`https://www.youtube.com/watch?v=iNgOpXBMS-8&t=541s`} target="_black">
								<div
									style={{
										width: '418px',
										zIndex: '99',
										backgroundColor: 'rgba(0,0,0,0.2)',
										height: '235px',
										position: 'absolute',
									}}
								/>
								<img
									src={YoutubeThumbnail2}
									alt={'youtube 동영상'}
									style={{
										width: '418px',
										position: 'absolute',
									}}
								/>
								<img src={YoutubeButton} alt="재생 버튼" className="btn-img" />
							</a>
							<div className="content" style={{ padding: '0 53px' }}>
							[하이시간TV] 오데마피게 로얄오크 시계에 전부 다이아로 커스텀을 한다면? 당신의 선택은? 
							</div>
						</div>
						<div
							style={{
								width: '418px',
								height: '298px',
								position: 'relative',
								border: '1px solid lightgray',
							}}
						>
							<a
								href={`https://www.youtube.com/watch?v=-ZIaknL9T_4`}
								target="_black"
								style={{
									position: 'absolute',
									width: '417px',
									height: '235px',
									overflow: 'hidden',
								}}
							>
								<img
									src={YoutubeThumbnail3}
									alt={'youtube 동영상'}
									style={{
										width: '416px',
										position: 'absolute',
									}}
								/>
								<div
									style={{
										width: '416px',
										zIndex: '99',
										backgroundColor: 'rgba(0,0,0,0.2)',
										height: '262px',
										position: 'absolute',
									}}
								/>
								<img
									src={YoutubeButton}
									alt="재생 버튼"
									style={{
										width: '84px',
										position: 'absolute',
										zIndex: '100',
										top: '75px',
										left: '160px',
									}}
								/>
							</a>
							<div
								style={{
									position: 'absolute',
									height: '60px',
									width: '418px',
									bottom: '0',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: '15px',
									padding: '0 53px',
								}}
							>
								[하이시간TV] 롤렉스를 이길자 누구인가? 성골하면 프리미엄 상승 4월 셋째 주 롤렉스 시세는?
							</div>
						</div>
						{/* {videoList.map((video, idx) => (
								<div style={{ display: 'flex', flexDirection: 'column' }} key={idx}>
									<a
										href={`https://www.youtube.com/watch?v=${video.id}`}
										target="_black"
										style={{
											position: 'relative',
											width: '418px',
											height: '250px',
											overflow: 'hidden',
										}}
									>
										<img
											src={`http://img.youtube.com/vi/${video.id}/0.jpg`}
											alt={'youtube 동영상'}
											style={{
												width: '418px',
												position: 'absolute',
												top: '-37px',
												height: '294px',
											}}
										/>
									</a>
								</div>
							))} */}
					</div>
				</section>

				{/* 롤링 */}
				<section
					className="chart_slider"
					style={{ background: 'transparent', margin: '60px auto' }}
				>
					<ul
						id="scroller"
						ref={rollingRef}
						style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}
					>
						{/* <div
								style={{
									position: 'absolute',
									width: '220px',
									height: '96px',
									left: '-110px',
									zIndex: '50',
									background: 'linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 72.45%)',
								}}
							></div> */}
						{rollingData &&
							rollingData.map((item, idx) => {
								return (
									<Rolling
										key={idx}
										onClick={() => goToBuyPage(item.brand)}
										dataLength={(rollingData?.length + 1) * -100}
										className="price_box"
										style={{
											left: 210 * (idx + 1),
											background: '#2b3035',
											width: '204px',
											height: '84px',
											borderRadius: '3px',
											padding: '12px',
										}}
									>
										<p className="t1" style={{ height: '14px' }}>
											{item.brand || '-'}
										</p>
										<p
											className="t2"
											style={{
												fontSize: '13px',
												height: '20px',
												marginTop: '2px',
												alignItems: 'center',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												display: 'block',
											}}
										>
											{item.model || '-'}
										</p>
										<p
											style={{
												// color: '#be9162',
												right: '12px',
												fontSize: '18px',
												height: '20px',
												bottom: '10px',
												position: 'absolute',
												color: '#DBB58E',
												lineHeight: '20px',
												textAlign: 'right',
												opacity: '0.9',
											}}
										>
											₩
											{item?.priceHistories[item?.priceHistories?.length - 1]?.price
												? Number(
														item?.priceHistories[item?.priceHistories?.length - 1]?.price,
												  ).toLocaleString()
												: '-'}
										</p>
										{/* <div className="graph_box"> */}
										{/* <canvas className="chart1" /> */}
										{/* <p className="t1">hi</p>
											</div> */}
									</Rolling>
								);
							})}
						{rollingData &&
							rollingData.map((item, idx) => {
								return (
									<Rolling
										key={idx}
										onClick={() => goToBuyPage(item.brand)}
										dataLength={(rollingData?.length + 1) * -100}
										className="price_box"
										style={{
											left: 210 * (idx + 1 + rollingData?.length),
											background: '#2b3035',
											width: '204px',
											height: '84px',
											borderRadius: '3px',
											padding: '12px',
										}}
									>
										<p className="t1" style={{ height: '14px' }}>
											{item.brand || '-'}
										</p>
										<p
											className="t2"
											style={{
												fontSize: '13px',
												height: '20px',
												marginTop: '2px',
												alignItems: 'center',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												display: 'block',
											}}
										>
											{item.model || '-'}
										</p>
										<p
											style={{
												right: '12px',
												fontSize: '18px',
												height: '20px',
												bottom: '10px',
												position: 'absolute',
												color: '#DBB58E',
												lineHeight: '20px',
												textAlign: 'right',
												opacity: '0.9',
											}}
										>
											₩
											{item?.priceHistories[item?.priceHistories?.length - 1]?.price
												? Number(
														item?.priceHistories[item?.priceHistories?.length - 1]?.price,
												  ).toLocaleString()
												: '-'}
										</p>
										{/* <div className="graph_box"> */}
										{/* <canvas className="chart2" /> */}
										{/* <p className="t1">hi</p>
											</div> */}
									</Rolling>
								);
							})}
						{rollingData &&
							rollingData.map((item, idx) => {
								return (
									<Rolling
										key={idx}
										onClick={() => goToBuyPage(item.brand)}
										dataLength={(rollingData?.length + 1) * -100}
										className="price_box"
										style={{
											left: 210 * (idx + 1 + rollingData?.length + rollingData?.length),
											background: '#2b3035',
											width: '204px',
											height: '84px',
											borderRadius: '3px',
											padding: '12px',
										}}
									>
										<p className="t1" style={{ height: '14px' }}>
											{item.brand || '-'}
										</p>
										<p
											className="t2"
											style={{
												fontSize: '13px',
												height: '20px',
												marginTop: '2px',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												display: 'block',
												alignItems: 'center',
											}}
										>
											{item.model || '-'}
										</p>
										<p
											style={{
												// color: '#be9162',
												right: '12px',
												fontSize: '18px',
												height: '20px',
												bottom: '10px',
												position: 'absolute',
												color: '#DBB58E',
												lineHeight: '20px',
												textAlign: 'right',
												opacity: '0.9',
											}}
										>
											₩
											{item?.priceHistories[item?.priceHistories?.length - 1]?.price
												? Number(
														item?.priceHistories[item?.priceHistories?.length - 1]?.price,
												  ).toLocaleString()
												: '-'}
										</p>
										{/* <div className="graph_box"> */}
										{/* <canvas className="chart1" /> */}
										{/* <p className="t1">hi</p>
											</div> */}
									</Rolling>
								);
							})}
						{/* <div
								style={{
									position: 'absolute',
									width: '220px',
									height: '96px',
									right: '-110px',
									zIndex: '50',
									background: 'linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 72.45%)',
									transform: 'rotate(-180deg)',
								}}
							></div> */}
					</ul>
				</section>

				{/* 순위 */}
				<section className="rank_list">
					<div className="enrollment">
						<p className="tit">
							<strong>등록 Top 5</strong>
							<span>(건수)</span>
						</p>
						<ol>
							{waitData &&
								waitData.map((item, idx) => {
									return (
										<li
											key={idx}
											onClick={() => goToBuyPage(item.brand)}
											style={{ cursor: 'pointer' }}
										>
											<span className="rank">{item.rank || '-'}</span>
											<p>
												{item.brand || '-'}
												<span
													className={
														(item.diffRank > 0 && 'fluctuations up') ||
														(item.diffRank === 0 && '') ||
														(item.lastRank === 0 && item.diffRank < -1
															? 'fluctuations down'
															: '') ||
														(item.diffRank < 0 && item.lastRank !== 0 && 'fluctuations down')
													}
												>
													{(item.lastRank > 0 && Math.abs(item.diffRank)) ||
														(item.lastRank === 0 && item.diffRank === -1
															? ''
															: Math.abs(item.diffRank + 1))}
												</span>
											</p>
											<span className="count"> {item.count || '-'}</span>
										</li>
									);
								})}
						</ol>
					</div>
					<div className="trading">
						<p className="tit">
							<strong>거래중 Top 5</strong>
							<span>(건수)</span>
						</p>
						<ol>
							{runData &&
								runData.map((item, idx) => {
									return (
										<li
											key={idx}
											onClick={() => goToBuyPage(item.brand)}
											style={{ cursor: 'pointer' }}
										>
											<span className="rank">{item.rank || '-'}</span>
											<p>
												{item.brand || '-'}
												<span
													className={
														(item.diffRank > 0 && 'fluctuations up') ||
														(item.diffRank === 0 && '') ||
														(item.lastRank === 0 && item.diffRank < -1
															? 'fluctuations down'
															: '') ||
														(item.diffRank < 0 && item.lastRank !== 0 && 'fluctuations down')
													}
												>
													{(item.lastRank > 0 && Math.abs(item.diffRank)) ||
														(item.lastRank === 0 && item.diffRank === -1
															? ''
															: Math.abs(item.diffRank + 1))}
												</span>
											</p>
											<span className="count"> {item.count || '-'}</span>
										</li>
									);
								})}
						</ol>
					</div>
					<div className="deal_done">
						<p className="tit">
							<strong>거래완료 Top 5</strong>
							<span>(건수)</span>
						</p>
						<ol>
							{doneData &&
								doneData.map((item, idx) => {
									return (
										<li
											key={idx}
											onClick={() => goToBuyDonePage(item.brand)}
											style={{ cursor: 'pointer' }}
										>
											<span className="rank">{item.rank || '-'}</span>
											<p>
												{item.brand || '-'}
												<span
													className={
														(item.diffRank > 0 && 'fluctuations up') ||
														(item.diffRank === 0 && '') ||
														(item.lastRank === 0 && item.diffRank < -1
															? 'fluctuations down'
															: '') ||
														(item.diffRank < 0 && item.lastRank !== 0 && 'fluctuations down')
													}
												>
													{(item.lastRank > 0 && Math.abs(item.diffRank)) ||
														(item.lastRank === 0 && item.diffRank === -1
															? ''
															: Math.abs(item.diffRank + 1))}
												</span>
											</p>
											<span className="count"> {item.count || '-'}</span>
										</li>
									);
								})}
						</ol>
					</div>
				</section>
				<section className="certification-section">
					<div className="certification-box">
						<img src={Certification1} alt="인증서" />
						<img src={Certification2} alt="인증서" />
						<img src={Certification3} alt="인증서" />
						<img src={Certification4} alt="인증서" />
						<img src={Certification5} alt="인증서" />
						<img src={Certification6} alt="인증서" />
						<img src={Certification7} alt="인증서" />
						<img src={Certification8} alt="인증서" />
					</div>
				</section>
			</div>
		</>
	);

	function getMainHaplList() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getHaplMainListApi(null, '', 0).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setHaplData(data?.content?.slice(0, 5) || []);
			} else {
				alert(detail);
			}
		});
	}
};

export default Main;
const Rolling = styled.li`
	animation: ${(props) => scrolling(props.dataLength ? `${props.dataLength}` : '1')} 50s linear
		infinite;
`;