import { apiCall } from './apiCall';
import { SERVER_API_HOST } from 'common/environment';

//회원정보수정
export const getModifiedUserInfoApi = (
	authToken,
	userId,
	password,
	name,
	email,
	phone,
	nickName,
	agreeMail,
	agreeSms,
	profileUrl,
) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/update/${userId}`,
		'put',
		{
			password,
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			profileUrl,
		},
		{ ContentType: 'application/json', authorization: authToken },
	);
};

//비밀번호 확인
export const getPasswordCheckApi = (passwordInputValue, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/password/check`,
		'post',
		{ password: passwordInputValue },
		{ ContentType: 'application/json', authorization: authToken },
	);
};

//회원탈퇴
export const getWithDrawalApi = (authToken, userId) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/stop/${userId}`,
		'delete',
		{},
		{ authorization: authToken },
	);
};
