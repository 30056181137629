import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import 'assets-pc/css/common.css';

const BuyDetailDatePicker = ({ visitDate, setVisitDate }) => {
	
	return (
		<ReactDatePicker
			locale={ko}
			selected={visitDate}
			onChange={(date) => setVisitDate(date)}
			showTimeSelect
			timeFormat="HH:mm"
			timeIntervals={60}
			timeCaption="time"
			dateFormat="yyyy, MMMM dd h:mm aa"
			wrapperClassName="datePicker"
		/>
	);
};

export default BuyDetailDatePicker;
