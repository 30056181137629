import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPurchaseReservationApi } from 'apis/buyDetail';
import CommonHelper from 'helpers/CommonHelper';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';

const BuyReservation = ({
	setShowReservationFormPopup,
	currentStoreList,
	oneSellingProductInfo,
}) => {
	const [showCreateSuccessPopup, setShowCreateSuccessPopup] = useState(false);
	const [oneStoreIdReservation, setOneStoreIdReservation] = useState(null); //지점선택
	const [visitDate, setVisitDate] = useState(new Date()); //방문일정
	const [errorText, setErrorText] = useState('');
	const [agreeChecked, setAgreeChecked] = useState(false);

	const offset = new Date().getTimezoneOffset() * 60000;

	const convertVisitDate = new Date(visitDate - offset).toISOString().split('.')[0];

	const handleCheckboxStore = (e) => {
		const { name, checked } = e.target;
		if (checked) {
			setOneStoreIdReservation(name);
		} else {
			setOneStoreIdReservation(null);
		}
	};

	const checkValidation = () => {
		if (!oneStoreIdReservation) {
			setErrorText('방문 지점을 선택해주세요.');
		} else if (!agreeChecked) {
			setErrorText('개인정보수집 이용 동의에 체크해주세요.');
		} else {
			setErrorText('');
			createPurchaseReservation();
		}
	};

	const checkCompletePopup = () => {
		setShowReservationFormPopup(false);
		setOneStoreIdReservation(null);
		setVisitDate(new Date());
		setAgreeChecked(false);
		setShowCreateSuccessPopup(false);
	};
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowReservationFormPopup(false)}></button>
					<h2>구매예약</h2>
				</section>
				<section className="purchase_reservation">
					<p className="t1">구매예약을 위해서 아래의 정보를 입력해주세요.</p>

					<h3>
						방문지점 <span>*</span>
					</h3>
					<ul className="branch_list">
						{currentStoreList.map((store) => (
							<li key={store.id}>
								<label>
									<input
										type="radio"
										className="check"
										checked={oneStoreIdReservation === store.id.toString()}
										disabled={false}
										name={store.id}
										onChange={handleCheckboxStore}
									/>
									<span>{store.name}</span>
								</label>
							</li>
						))}
					</ul>

					<h3>
						방문일정 <span>*</span>
					</h3>
					<div className="visit_schedule">
						<ReactDatePicker
							locale={ko}
							selected={visitDate}
							onChange={(date) => setVisitDate(date)}
							showTimeSelect
							timeFormat="HH:mm"
							dateFormat="MMMM d, yyyy h:mm aa"
							wrapperClassName="mobile-wrapper input_t date"
							customInput={
								<input
									type="text"
									className="input_t"
									style={{ width: '100%' }}
									placeholder="입금시간을 입력해주세요."
								/>
							}
						/>
					</div>

					<div className="agree_box">
						<input
							type="checkbox"
							checked={agreeChecked}
							onChange={() => setAgreeChecked(!agreeChecked)}
						/>
						<div>
							<p className="t1">
								<a>개인정보수집</a> 이용에 동의합니다.
							</p>
							<p className="t2">
								서비스 이용을 위해 필요한 정보로써, 동의를 해주셔야 <br />
								서비스를 이용 하실 수 있습니다.
							</p>
						</div>
					</div>
					{errorText && <p className="error_txt">{errorText}</p>}
					<div className="btn_wrap">
						<button className="btn" onClick={checkValidation}>
							구매 예약
						</button>
					</div>
				</section>
			</main>
			{showCreateSuccessPopup && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t2">구매예약이 완료되었습니다.</p>
						<p className="t3">
							구매예약한 내용은 마이페이지에서 <br />
							확인하실 수 있습니다.
						</p>
						<button className="btn_ok" onClick={checkCompletePopup}>
							확인
						</button>
					</article>
				</div>
			)}
		</>
	);
	function createPurchaseReservation() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		let nowProductId = window.location.pathname.split('/')[2];
		let storeArray = [oneStoreIdReservation];
		if (!!authToken) {
			createPurchaseReservationApi(
				authToken,
				nowProductId,
				oneSellingProductInfo,
				storeArray,
				convertVisitDate,
			).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					setShowCreateSuccessPopup(true);
				} else {
					alert(detail);
				}
			});
		}
	}
};

export default BuyReservation;
