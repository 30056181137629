import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import CommonHelper from 'helpers/CommonHelper';
import { postSignUpApi } from 'apis/signUp';
import { useHistory } from 'react-router-dom';
import Captcha from 'demos-react-captcha';

const SignUp = () => {
	//약관동의
	const [allCheckBox, setAllCheckBox] = useState(false);
	const [checkedBox, setCheckedBox] = useState({
		termsCheck: false,
		privacyPolicyCheckbox: false,
	});
	const [nextPage, setNextPage] = useState(false);

	const handleAllCheck = (e) => {
		const { checked } = e.target;
		if (checked) {
			setCheckedBox({ termsCheck: true, privacyPolicyCheckbox: true });
			setAllCheckBox(true);
		} else {
			setCheckedBox({ termsCheck: false, privacyPolicyCheckbox: false });
			setAllCheckBox(false);
		}
	};

	useEffect(() => {
		if (checkedBox.termsCheck && checkedBox.privacyPolicyCheckbox) {
			setAllCheckBox(true);
		} else {
			setAllCheckBox(false);
		}
	}, [checkedBox]);

	const handleCheck = (e) => {
		const { value, checked } = e.target;
		setCheckedBox({ ...checkedBox, [value]: checked });
	};

	const goToSignUp = () => {
		if (checkedBox.termsCheck && checkedBox.privacyPolicyCheckbox) {
			setNextPage(true);
		} else {
			alert('회원가입약관 및 개인정보처리방침의 내용에 동의하셔야 회원가입 하실 수 있습니다');
		}
	};

	// 회원가입
	const pStyle = {
		color: 'red',
		fontSize: '12px',
	};

	const [signUpInputsValue, setSignUpInputsValue] = useState({
		id: '',
		password: '',
		rePassword: '',
		name: '',
		email: '',
		phoneNumber: '',
		nickName: '',
	});

	const [agreeValues, setAgreeValues] = useState({
		agreeMail: false,
		agreeSms: false,
	});
	const handleInputsValue = (e) => {
		const copySignUpInputsValue = { ...signUpInputsValue };
		const { name, value } = e.target;
		if (name === 'phoneNumber') {
			let deleteAll = value.replaceAll('-','').replaceAll(/[^0-9]/g,'');
			copySignUpInputsValue[name] = deleteAll;
			
		} else {
			copySignUpInputsValue[name] = value;
		}
		setSignUpInputsValue(copySignUpInputsValue);
	};

	const handleAgreeVelues = (e) => {
		const copyValues = { ...agreeValues };
		const { value, checked } = e.target;
		copyValues[value] = checked;
		setAgreeValues(copyValues);
	};

	const history = useHistory();
	const [isValid, setIsValid] = useState(false);
	const { id, password, rePassword, name, phoneNumber, nickName, email } = signUpInputsValue;
	const { agreeMail, agreeSms } = agreeValues;

	// Captcha
	const [captchaValue, setCaptchaValue] = useState(false);
	const captchaChange = (value) => {
		setCaptchaValue(value);
	};
	const checkValidation = () => {
		if (
			CommonHelper.idRule(id) &&
			CommonHelper.passwordRules(password) &&
			password === rePassword &&
			CommonHelper.nameRule(name) &&
			CommonHelper.emailIsValid(email) &&
			phoneNumber.length > 9 &&
			CommonHelper.nickNameRule(nickName) &&
			captchaValue
		) {
			postSignUpApi(id, password, email, nickName, phoneNumber, name, agreeMail, agreeSms).then(
				({ data: { status, detail } }) => {
					if (status === 'SUCCESS') {
						if (window.confirm(' 회원가입이 완료되었습니다. 로그인창으로 이동하시겠습니까?')) {
							history.push('/signin?redirectUrl=/hapl');
						} else {
							history.push('/hapl');
						}
					} else {
						alert(detail);
					}
				},
			);
		} else {
			setIsValid(true);
		}
	};

	const goToHome = () => {
		history.push('/hapl');
	};

	return (
		<>
			{nextPage ? (
				<div className="container login">
					<section className="inner">
						<h2>회원정보 입력</h2>
						<div className="join_member">
							<div>
								<p className="t1">아이디</p>
								<input
									type="text"
									placeholder="아이디(3자 이상, 영문/숫자만)"
									name="id"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.id || ''}
								/>
								{isValid && !CommonHelper.idRule(id) ? (
									<p style={pStyle}>아이디를 입력해주세요. (3자 이상, 영문/숫자만)</p>
								) : (
									''
								)}
								<p className="t1">비빌번호</p>
								<input
									type="password"
									placeholder="비밀번호"
									name="password"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.password || ''}
								/>
								{isValid && !CommonHelper.passwordRules(password) ? (
									<p style={pStyle}>
										비밀번호를 입력해주세요. (영문/숫자/특수문자를 포함해 2자~20자)
									</p>
								) : (
									''
								)}
								<input
									type="password"
									placeholder="비밀번호 확인"
									name="rePassword"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.rePassword || ''}
								/>
								{isValid && password !== rePassword ? (
									<p style={pStyle}>비밀번호가 일치하지 않습니다.</p>
								) : (
									''
								)}
								<p className="t1">개인정보</p>
								<input
									type="text"
									placeholder="이름"
									name="name"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.name || ''}
								/>

								{isValid && !CommonHelper.nameRule(name) ? (
									<p style={pStyle}>이름은 국문으로 2자 이상 입력해주세요.</p>
								) : (
									''
								)}

								<input
									type="text"
									placeholder="이메일"
									name="email"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.email || ''}
								/>
								{isValid && !CommonHelper.emailIsValid(email) ? (
									<p style={pStyle}>이메일 형식이 올바르지 않습니다.</p>
								) : (
									''
								)}

								<input
									type="text"
									placeholder="휴대폰 번호"
									name="phoneNumber"
									value={signUpInputsValue?.phoneNumber || ''}
									onChange={(e) => handleInputsValue(e)}
								/>
								{isValid && phoneNumber.length < 10 ? (
									<p style={pStyle}>핸드폰 번호는 - 없이 10~11자를 입력해주세요.</p>
								) : (
									''
								)}

								<input
									type="text"
									placeholder="닉네임 (한글 2자, 영문 4자 이상)"
									name="nickName"
									onChange={(e) => handleInputsValue(e)}
									value={signUpInputsValue?.nickName || ''}
								/>
								{isValid && !CommonHelper.nickNameRule(nickName) ? (
									<p style={pStyle}>
										닉네임은 국문으로 2자 이상 혹은 영문으로 4자 이상 입력해주세요.
									</p>
								) : (
									''
								)}
							</div>
							<label>
								<input type="checkbox" value="agreeMail" onChange={handleAgreeVelues} />
								<span>정보 메일을 받겠습니다.</span>
							</label>
							<label>
								<input type="checkbox" value="agreeSms" onChange={handleAgreeVelues} />
								<span>휴대폰 문자메세지를 받겠습니다.</span>
							</label>
							<p className="txt">자동등록방지 숫자를 순서대로 입력하세요.</p>
							<div className="auto_regist">
								<CaptchaWrapper>
									<Captcha onChange={captchaChange} placeholder="Enter captcha" />
								</CaptchaWrapper>
								{isValid && !captchaValue ? (
									<p style={pStyle}>자동등록방지 숫자를 순서대로 입력하세요.</p>
								) : (
									''
								)}
							</div>
							<button className="btn_join" onClick={checkValidation}>
								회원가입
							</button>
							<a className="btn_home" onClick={goToHome}>
								홈으로 돌아가기
							</a>
						</div>
					</section>
				</div>
			) : (
				// {/* 회원가입 전에 동의 체크 */}
				<div className="container login">
					<section className="inner">
						<h2>회원가입</h2>
						<div className="join_agree">
							{!(checkedBox.termsCheck && checkedBox.privacyPolicyCheckbox) ? (
								<p>
									회원가입약관 및 개인정보처리방침의 내용에 동의하셔야 <br />
									회원가입 하실 수 있습니다.
								</p>
							) : (
								''
							)}
							<label>
								<input
									type="checkbox"
									value="allCheckBox"
									onChange={(e) => handleAllCheck(e)}
									checked={allCheckBox}
								/>
								<span>회원가입 약관에 모두 동의합니다.</span>
							</label>
							<label>
								<input
									type="checkbox"
									value="termsCheck"
									onChange={(e) => handleCheck(e)}
									checked={checkedBox.termsCheck}
								/>
								<span>회원가입약관</span>
							</label>
							<div className="scroll_box">
								제1조(목적) 본 약관은 하이시간 회원이 ㈜시간(이하 "회사")에서 제공하는 하이시간
								서비스를 이용함에 있어 하이시간 회원과 회사과 이용자의 권리의무 및 책임사항을
								규정함을 목적으로 합니다. <br />
								<br />
								※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이
								약관을 준용합니다」
							</div>
							<label>
								<input
									type="checkbox"
									value="privacyPolicyCheckbox"
									onChange={(e) => handleCheck(e)}
									checked={checkedBox.privacyPolicyCheckbox}
								/>
								<span>개인정보처리방침안내</span>
							</label>
							<div className="scroll_box">
								제1조(목적) 본 약관은 하이시간 회원이 ㈜시간(이하 "회사")에서 제공하는 하이시간
								서비스를 이용함에 있어 하이시간 회원과 회사과 이용자의 권리의무 및 책임사항을
								규정함을 목적으로 합니다. <br />
								<br />
								※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이
								약관을 준용합니다」
							</div>
							<button className="btn_join" onClick={goToSignUp}>
								회원가입
							</button>
							<a className="btn_home" onClick={goToHome}>
								홈으로 돌아가기
							</a>
						</div>
					</section>
				</div>
			)}
		</>
	);
};

export default SignUp;

const CaptchaWrapper = styled.div`
.rnc{
	max-width: 500px;
}
`