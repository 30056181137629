import React from 'react';

const FilterMovement = ({
	setShowMovement,
	selectedMovement,
	setSelectedMovement,
	handleCheckBoxCommon,
}) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowMovement(false)}></button>
					<h2>무브먼트</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									무브먼트
								</h3>
							</div>
							<ul>
								<li>
									<b>오토매틱</b>
									<input
										type="checkbox"
										name="오토매틱"
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
										checked={selectedMovement.includes('오토매틱')}
									/>
								</li>
								<li>
									<b>수동</b>
									<input
										type="checkbox"
										name="수동"
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
										checked={selectedMovement.includes('수동')}
									/>
								</li>
								<li>
									<b>쿼츠</b>
									<input
										type="checkbox"
										name="쿼츠"
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
										checked={selectedMovement.includes('쿼츠')}
									/>
								</li>
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedMovement([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowMovement(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterMovement;
