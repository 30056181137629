import React, { useState } from 'react';
import AuctionBuyPagination from './AuctionBuyPagination';

const AuctionBuy = ({ auctionBuyData, buyPage, setBuyPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = auctionBuyData;
	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);
	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setBuyPage(num);
	};

	const prevPage = () => {
		if (buyPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((buyPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setBuyPage(buyPage - 1);
	};

	const nextPage = () => {
		if (buyPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setBuyPage(buyPage + 1);
			if ((buyPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<table className="tbl">
				<colgroup>
					<col style={{ width: '5%' }} />
					<col style={{ width: '7%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<thead>
					<tr>
						<th>번호</th>
						<th>구분</th>
						<th>신청일</th>
						<th>상품명</th>
						<th>참여자수</th>
						<th>입찰가격</th>
						<th>상태</th>
					</tr>
				</thead>
				<tbody>
					{auctionBuyData?.content?.length > 0 ? (
						auctionBuyData.content &&
						auctionBuyData.content.map((data, index) => {
							return (
								<tr key={index}>
									<td>{data?.auction?.id ? data.auction.id : '-'}</td>
									<td>
										<span className="type">구매</span>
									</td>
									<td>{data?.auction?.createdAt ? data.auction.createdAt.slice(0, 10) : '-'}</td>
									<td>
										<b>{data?.auction?.watch?.model ? data.auction.watch.model : '-'}</b>
									</td>
									<td>
										{data?.auction?.orderPeopleCount ? data.auction.orderPeopleCount + '명' : '-'}
									</td>
									<td>
										<b>{data?.price ? data.price.toLocaleString('en') : '-'}</b>
									</td>
									<td>
										<span
											className={
												data?.auction?.auctionStatus
													? data?.auction?.auctionStatus === 'WAIT'
														? 'state_txt stay'
														: data?.auction?.auctionStatus === 'RUN'
														? 'state_txt'
														: data?.auction?.auctionStatus === 'DONE'
														? 'state_txt t1'
														: ''
													: ''
											}
										>
											{data?.auction?.auctionStatus
												? data.auction.auctionStatus === 'WAIT'
													? '대기중'
													: data.auction.auctionStatus === 'RUN'
													? '진행중'
													: data.auction.auctionStatus === 'DONE'
													? '완료'
													: ''
												: ''}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr className="empty">
							<td colSpan="7">
								<p>최근 이력이 없습니다.</p>
							</td>
						</tr>
					)}
					{/* <tr>
						<td>2</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>15명</td>
						<td>
							<b>2,000,000</b>
						</td>
						<td>
							<span className="state_txt">진행중</span>
						</td>
					</tr>
					<tr>
						<td>3</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>15명</td>
						<td>
							<b>2,000,000</b>
						</td>
						<td>
							<span className="state_txt t1">취소</span>
						</td>
					</tr>
					<tr>
						<td>4</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>15명</td>
						<td>
							<b>2,000,000</b>
						</td>
						<td>
							<span className="state_txt stay">승인 대기중</span>
						</td>
					</tr>
					<tr>
						<td>5</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>15명</td>
						<td>
							<b>2,000,000</b>
						</td>
						<td>
							<span className="state_txt">진행중</span>
						</td>
					</tr> */}
				</tbody>
			</table>
			{auctionBuyData?.content?.length > 0 ? (
				<AuctionBuyPagination
					prevPage={prevPage}
					paginate={paginate}
					nextPage={nextPage}
					newPageNumbers={newPageNumbers}
					buyPage={buyPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default AuctionBuy;
