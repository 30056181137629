import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import 'assets-pc/css/common.css';

const AuctionDatePicker = ({ startDate, setStartDate }) => {
	// const ExampleCustomInput = ({ value, onClick }) => (
	// 	<button className="example-custom-input" style onClick={onClick}>
	// 		{value}
	// 	</button>
	// );

	return (
		<ReactDatePicker
			locale={ko}
			selected={startDate}
			onChange={(date) => setStartDate(date)}
			showTimeSelect
			timeFormat="HH:mm"
			timeIntervals={60}
			timeCaption="time"
			dateFormat="MMMM d, yyyy h:mm aa"
			wrapperClassName="datePicker"
		/>
	);
};

export default AuctionDatePicker;
