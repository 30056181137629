import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import 'es6-promise/auto';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from 'context/AppContext';
import { SiteProvider } from 'context/SiteContext';
import './setupTests';
import './MutationObservers';
require('es5-shim');
require('es5-shim/es5-sham');
require('console-polyfill');
require('es6-promise');
require('fetch-ie8');

ReactDOM.render(
	<BrowserRouter>
		<AppProvider>
			<SiteProvider>
				<App />
			</SiteProvider>
		</AppProvider>
	</BrowserRouter>,
	document.getElementById('root'),
);
