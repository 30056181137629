import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router';
import { HAPLE_STATUS } from 'common/constants';

const HaplStep2 = ({ responseAfterReserve, setCurrStep, setDetailId, setOneStoreSearch }) => {
	const history = useHistory();

	const showReservationDetail = () => {
		setCurrStep('detail');
		setDetailId(responseAfterReserve.id);
	};
	const goStore = () => {
		history.push('/store');
	};

	const goToMain = () => {
		setOneStoreSearch();
		setCurrStep('main');
	};
	return (
		<>
			<section className="step2-wrapper">
				<div className="step2-numbering">
					<div className="light-gray">
						<div className="num">1</div>
					</div>
					<div className="line" />

					<div className="light-brown">
						<div className="num">2</div>
					</div>
					<div className="step1-text">나의예약정보</div>
					<div className="step2-text">예약완료</div>
				</div>
				<div className="complete-txt">예약 신청이 정상적으로 처리되었습니다.</div>
				<div className="reservation-info">
					<table className="tbl">
						<colgroup>
							<col className="table-col-first" />
							<col className="table-col-second" />
						</colgroup>
						<thead>
							<tr>
								<th>판매자/구매자</th>
								<td>{responseAfterReserve?.userType === 'SELLER' ? '판매자' : '구매자'}</td>
							</tr>
							<tr>
								<th>신청자 명</th>
								<td>{responseAfterReserve?.name || '-'}</td>
							</tr>
							<tr>
								<th>직영점</th>
								<td>{responseAfterReserve?.store?.name || '-'}</td>
							</tr>
							<tr>
								<th>이용일자</th>
								<td>{responseAfterReserve?.day || 'test'}</td>
							</tr>
							<tr>
								<th>이용시간</th>
								<td>{responseAfterReserve?.timeStr || 'test'}</td>
							</tr>
							<tr>
								<th>이용인원</th>
								<td>{responseAfterReserve?.userCount || 'test'}&nbsp;명</td>
							</tr>
							<tr>
								<th>예약상태</th>
								<td>{HAPLE_STATUS[responseAfterReserve?.status] || '-'}</td>
							</tr>
						</thead>
					</table>
				</div>
				<div className="step1-btn-box">
					<button className="btn-cancel" onClick={() => history.push('/')}>
						메인페이지 바로가기
					</button>
					<button className="btn-reservation" onClick={showReservationDetail}>
						예약상세보기
					</button>
				</div>
			</section>
		</>
	);
};

export default HaplStep2;
