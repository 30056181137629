import React, { useState } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import Captcha from 'demos-react-captcha';
import { getFindMemberApi } from 'apis/signIn';

const FindMember = ({}) => {
	const [emailAddress, setEmailAddress] = useState('');
	const [captchaValue, setCaptchaValue] = useState(false);
	const [completeSendEmail, setCompleteSendEmail] = useState(false);
	const [errorText, setErrorText] = useState('');
	const history = useHistory();

	const handleEmailInputChange = (e) => {
		let newAddress = e.target.value.replace(/ /g, '');
		setEmailAddress(newAddress);
	};

	const captchaChange = (value) => {
		setCaptchaValue(value);
	};

	const handleOnClickFindmember = () => {
		if (!emailAddress) {
			setErrorText('이메일을 입력해주세요.');
		} else if (!!emailAddress && !captchaValue) {
			setErrorText('자동등록방지 숫자가 일치하지 않습니다.');
		} else if (emailAddress && captchaValue) {
			getFindMemberApi(emailAddress).then(({ data: { status, data, detail } }) => {
				if (status === 'SUCCESS') {
					if (data === true) {
						setErrorText('');
						setEmailAddress('');
						setCaptchaValue(false);
						setCompleteSendEmail(true);
					} else {
						setErrorText(detail);
					}
				} else {
					setErrorText('');
					setEmailAddress('');
					setCaptchaValue(false);
					alert(detail);
				}
			});
		}
	};

	const completeChecked = () => {
		history.goBack();
	};
	return (
		<>
			<main className="join">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => history.goBack()}></button>
					<h2>회원정보 찾기</h2>
				</section>
				<section className="join_agree">
					<p className="txt">
						회원가입 시 등록하신 이메일 주소를 입력해 주세요. <br />
						해당 이메일로 아이디와 비밀번호 정보를 보내드립니다.
					</p>
					<div className="join_list">
						<p className="tit">
							이메일 <span>*</span>
						</p>
						<div className="input_div">
							<input
								type="text"
								className="input_t"
								placeholder="이메일을 입력해주세요."
								onChange={handleEmailInputChange}
								value={emailAddress}
							/>
						</div>

						<div className="captcha_box">
							<p>자동등록방지 숫자를 순서대로 입력하세요.</p>
							{/* <div className="captcha_wrap"> */}
							{/* <div className="img_box">
									<img src="assets/img/common/img_captcha.png" alt="" />
								</div>
								<div className="btn_wrap">
									<button>음성으로 듣기</button>
									<button>새로고침</button>
								</div> */}
								<CaptchaWrapper>
							<Captcha onChange={captchaChange} placeholder="Enter captcha" />
							</CaptchaWrapper>
							{/* </div> */}
							{/* <div className="input_div">
								<input type="text" className="input_t" placeholder="자동등록방지 숫자" />
							</div> */}
						</div>
					</div>
					{errorText && <p className="txt_error">{errorText}</p>}
					<button className="btn_join" onClick={handleOnClickFindmember}>
						메일 발송
					</button>
				</section>
			</main>
			{completeSendEmail && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t1">
							회원가입시 등록하신 메일로 <br />
							회원님의 정보를 발송해 드렸습니다.
						</p>
						<button className="btn_ok" onClick={completeChecked}>
							확인
						</button>
					</article>
				</div>
			)}
		</>
	);
};
export default FindMember;
const CaptchaWrapper = styled.div`
	.rnc {
		max-width: 500px;
	}
`;