import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Linkify from 'react-linkify';
import { Viewer } from '@toast-ui/react-editor';
import useDebounce from 'utils/useDebounce';
import { getFaqListApi, getFaqKeywordsListApi } from 'apis/faq';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';
import FaqDetail from './FaqDetail';

const componentDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">
		{text}
	</a>
);

const Faq = () => {
	const [faqList, setFaqList] = useState([]);

	const [keywordList, setKeywordsList] = useState([]);

	const [text, setText] = useState('');
	const debouncedSearchTerm = useDebounce(text, 500);

	const [pagenation, setPagenation] = useState([]);
	const [page, setPage] = useState(0);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);

	const [clickedListArr, setClickedListArr] = useState([]);
	const [clickedListId, setClickedListId] = useState([]);
	const [isFaqListClicked, setIsFaqListClicked] = useState(false);
	const [clickedKeywordIndexArr, setClickedKeywordIndexArr] = useState([]);

	useEffect(() => {
		getFaqList();
	}, [debouncedSearchTerm, page]);

	useEffect(() => {
		getFaqKeywordsList();
	}, []);

	useEffect(() => {
		setClickedListArr([]);
	}, [page]);

	/**
	 * 전체 FAQ 리스트 API
	 */
	const getFaqList = () => {
		getFaqListApi(page, text).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setFaqList(data.content || []);
				setPagenation(data || []);
			} else {
				alert(detail);
			}
		});
	};

	/**
	 * 전체 키워드 리스트 API
	 */
	const getFaqKeywordsList = () => {
		getFaqKeywordsListApi().then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setKeywordsList(data.searchFaqKeywords || []);
			} else {
				alert(detail);
			}
		});
	};

	/**
	 * 키워드 클릭
	 */
	const handleKeywordClick = (e) => {
		setClickedListArr([]);
		setPage(0);

		const pageIndex = +e.target.id;
		const newClickedKeywordIndexArr = [];
		if (clickedKeywordIndexArr[0] === pageIndex) {
			return (
				clickedKeywordIndexArr.slice(0, 1) && setClickedKeywordIndexArr(newClickedKeywordIndexArr),
				setText('')
			);
		} else {
			return (
				newClickedKeywordIndexArr.push(pageIndex) &&
					setClickedKeywordIndexArr(newClickedKeywordIndexArr),
				setText(e.target.innerHTML)
			);
		}
	};

	/**
	 * 검색 기능
	 */
	const handleFaqSearch = (e) => {
		setClickedKeywordIndexArr([]); //보더 지우기
		setClickedListArr([]); //전체 토글 끔
		setPage(0);
		setText(e.target.value);
	};

	/**
	 * 리스트토글
	 * @param {*} e
	 */
	const handleFaqToggle = (e) => {
		const deleteIdIdx = clickedListArr.indexOf(+e.target.id);
		clickedListArr.includes(+e.target.id)
			? clickedListArr.splice(deleteIdIdx, 1) && setIsFaqListClicked(false)
			: clickedListArr.push(+e.target.id) && setIsFaqListClicked(true);
		setClickedListId(+e.target.id);
		setClickedListArr(clickedListArr);
	};

	/**
	 * 페이지네이션
	 */

	const newPageArr = [];
	for (let i = 0; i < pagenation.totalPage; i++) {
		newPageArr.push(i + 1);
	}
	const filterNewPageArr = newPageArr.slice(start, end);

	const handlePagenation = (num) => {
		setPage(num - 1);
	};

	const handlePrevPage = () => {
		if (page === 0) {
			return alert('첫 페이지 입니다.');
		} else if ((page + 1) % 4 === 1) {
			const newStart = start > 0 ? start - 4 : 0;
			const newEnd = end > 0 ? end - 4 : end;
			setStart(newStart);
			setEnd(newEnd);
		}
		setPage(page - 1);
	};

	const handleNextPage = () => {
		if (page + 1 === pagenation.totalPage) {
			return alert('마지막 페이지 입니다.');
		} else if ((page + 2) % 4 === 1) {
			const newStart = start + 4;
			const newEnd = end + 4;
			setStart(newStart);
			setEnd(newEnd);
		}
		setPage(page + 1);
	};

	return (
		<>
			<div className="container faq">
				<section className="sub_top">
					<div className="sub_top_mini_img"></div>
					<h1 style={{ zIndex: '100' }}>고객센터</h1>
					<p style={{ zIndex: '100' }}>언제나 고객님의 만족을 위해 노력할 것을 약속합니다.</p>
				</section>
				<section className="inner">
					<div className="search_box">
						<input
							type="text"
							placeholder="궁금한 사항을 검색해 보세요."
							value={text}
							onChange={handleFaqSearch}
						/>
						<button></button>
					</div>
					<ul className="keyword_list">
						{keywordList?.map((keyword, idx) => {
							return (
								<li key={idx}>
									<button
										id={idx}
										style={
											clickedKeywordIndexArr[0] === idx
												? { border: '2px solid rgb(1, 94, 204)' }
												: {}
										}
										onClick={handleKeywordClick}
									>
										{keyword ? keyword : '-'}
									</button>
								</li>
							);
						})}
					</ul>
					<ul className="faq_list">
						{faqList?.length !== 0 ? (
							<>
								{faqList?.map((faq, idx) => {
									return (
										<li key={idx}>
											<>
												<button
													id={faq.id}
													className={clickedListArr.includes(faq.id) ? 'on' : ''}
													onClick={handleFaqToggle}
												>
													{faq?.question ? faq.question : '-'}
												</button>
												<div
													className={
														clickedListArr.includes(faq.id) ? 'answer-on' : 'onAnswer-close'
													}
												>
													{clickedListArr.includes(faq.id) ? (
														<FaqDetail clickedListArr={clickedListArr} faq={faq}/>
													) : (
														''
													)}
													{/* <Linkify componentDecorator={componentDecorator}>
														{faq?.answer
															? faq.answer.split('&lt;br/&gt;').map((line, idx) => {
																	return (
																		<span key={idx}>
																			{line}
																			<br />
																		</span>
																	);
															  })
															: '-'}
													</Linkify> */}
												</div>
											</>
										</li>
									);
								})}
								<div className="paging">
									<a className="btn prev" onClick={handlePrevPage}></a>
									{filterNewPageArr.map((num, idx) => (
										<a
											key={num}
											className={page === num - 1 ? 'num' : ''}
											onClick={() => handlePagenation(num)}
										>
											{num}
										</a>
									))}
									<a className="btn next" onClick={handleNextPage}></a>
								</div>
							</>
						) : (
							<li className="empty">해당하는 FAQ가 없습니다.</li>
						)}
					</ul>
				</section>
			</div>
		</>
	);
};

export default Faq;
const Wrapper = styled.div`
	${(props) => {
		if (!props.close) {
			return css`
				display: none;
			`;
		}
	}}
	img {
		max-width: 1000px;
	}
`;
