import { SERVER_API_HOST } from 'common/environment';
import { apiCall } from './apiCall';

export const postFilterApi = (selectedCheckBox, authToken) => {
	return apiCall(`${SERVER_API_HOST}/api/user/update/favorite/filter`, 'put', selectedCheckBox, {
		authorization: authToken,
	});
};

export const getFilterApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/site/filter/list`, 'get', {}, {});
};
