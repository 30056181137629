import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import Slick from 'react-slick';
import styled from 'styled-components';
import { getPopularListApi } from 'apis/buy';

import IcoBox from 'assets-mobile/img/main/ico_box.svg';
import Thumnail from 'assets-mobile/img/main/img_thumnail.png';
import Warranty from 'assets-mobile/img/main/ico_warranty.svg';
import { handlePremiumNum } from 'common/common';

const RecentlyProducts = () => {
	//21.10.5 가장 많이 본 상품과 최근 등록 데이터를 교체함
	//RecentlyProducts는 PopularProduct 사용
	const slickRef = useRef();
	const spanRef = useRef();
	const recentlyProductsContainer = useRef(null);
	const recentlyReference = useRef(null);
	const paginationRef = useRef(null);
	const [recentRegisterProducts, setRecentRegisterProducts] = useState([]);
	const sliderLength = recentRegisterProducts?.content ? recentRegisterProducts.content.length : '';

	useEffect(() => {
		getPopularList();
	}, []);

	// useEffect(() => {
	// 	recentlyReference.current = new Swiper(recentlyProductsContainer.current, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 30,
	// 		loop: true,
	// 		pagination: {
	// 			el: paginationRef.current,
	// 			clickable: true,
	// renderBullet: function (index, className) {
	// 	return '<span class="' + className + '">' + (index + 1) + '</span>';
	// },
	// 		},
	// 	});
	// }, [recentRegisterProducts]);

	const goToBuyDetail = (id) => {
		window.location.href = `/buy/${id}`;
	};
	const onBeforeChange = (older, newidx) => {
		let calc = newidx / sliderLength; // 7 은 총 슬라이더 갯수
		let newCalc = (calc * sliderLength + 1) / sliderLength;
		// spanRef.current.style.transForm = `translate3d(0px,0px,0px) scaleX(${newCalc}) scaleY(1)`;
		// spanRef.current.style.width = newCalc * 100 + '%';
	};

	const settings = {
		beforeChange: onBeforeChange,
		fade: true,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 3000,
		arrows: false,
		appendDots: (dots) => (
			<div>
				<ul style={{ display: 'flex' }}>{dots}</ul>
			</div>
		),

		customPaging: (i) => <div>{i + 1}</div>,
	};
	return (
		<>
			<section className="recently_products">
				<h2>가장 많이 본 인기상품</h2>
				<SliderWripper>
					<div className="m-recent-swiper-container recently_pro">
						<div className="m-recent-swiper-wrapper" style={{ position: 'relative' }}>
							{/* <Carousel settings={settings} swiperData={[]} /> */}
							<Slick {...settings} ref={slickRef}>
								{recentRegisterProducts.map((product, idx) => (
									<div
										className="m-recent-swiper-slide"
										key={idx}
										onClick={() => goToBuyDetail(product.id)}
									>
										<div className="img_box">
											<img src={product.attachedFiles[0] || Thumnail} alt="시계사진" />
										</div>
										<div className="info_box">
											{!!product?.price &&
												!!product?.standardWatch?.officialPrice &&
												handlePremiumNum(product?.price, product?.standardWatch?.officialPrice) && (
													<p className="sale" style={{ position: 'absolute', left: '0', top: '0' }}>
														{handlePremiumNum(
															product?.price,
															product?.standardWatch?.officialPrice,
														)}
														% Premium
													</p>
												)}
											<p className="watch_name ellipsis poppins">
												{product?.watch?.brand || '-'} <br />
												{product?.watch?.model || '-'}
											</p>
											<p className="price">
												₩ {product?.price ? Number(product?.price).toLocaleString() : '-'}
											</p>
											<ul>
												<li>{product?.watch?.producedYear || '-'}</li>
												<li>{product?.watch?.condition || '-'}</li>
												<li>{product?.watch?.serialNo || '-'}</li>
											</ul>
											<div>
												<p className="option">
													{product?.watch?.supplements?.includes('GUARANTEE') && (
														<img src={Warranty} alt="보증서" title="보증서가 있는 상품입니다." />
													)}
													{product?.watch?.supplements?.includes('BOX') && (
														<img src={IcoBox} alt="정품박스" title="정품박스가 있는 상품입니다." />
													)}
												</p>
												{!!product?.standardWatch?.hpi ? (
													<p
														className={
															product?.standardWatch?.hpi > 0
																? 'fluctuations up'
																: product?.standardWatch?.hpi < 0
																? 'fluctuations down'
																: product?.standardWatch?.hpi === 0
																? 'fluctuations'
																: ''
														}
													>
														{product?.standardWatch?.hpi}
													</p>
												) : (
													''
												)}
											</div>
										</div>
									</div>
								))}
							</Slick>
						</div>
					</div>
					{/* <div className="recently_pro_paging m-recent-swiper-pagination"></div> */}
				</SliderWripper>
			</section>
		</>
	);

	function getPopularList() {
		getPopularListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setRecentRegisterProducts(data.slice(0, 3));
			} else {
				alert(detail);
			}
		});
	}
};

export default RecentlyProducts;
const SliderWripper = styled('div')`
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-slide {
		visibility: hidden;
		display: flex;
	}
	.slick-slide.slick-active {
		visibility: visible;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;

		opacity: 0.75;
		color: white;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -25px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -25px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		/* margin-bottom: 30px; */
	}

	.slick-dots {
		position: absolute;
		left: 80%;
		top: -15%;
		height: fit-content;
		font-family: Poppins;
		position: absolute;
		text-align: center;
		transition: 0.3s opacity;
		transform: translate3d(0, 0, 0);
		z-index: 10;
	}
	.slick-dots li {
		width: 4.722vw;
		height: 4.722vw;
		font-size: 4.444vw;
		border-radius: 0;
		line-height: 4.722vw;
		background-color: #000;
		margin: 0 0.5vw;
		opacity: 0.2;
		cursor: pointer;
		display: inline-block;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
		color: #fff;
	}
	.slick-dots li.slick-active button:before {
		color: lightgray;
	}

	.slick-dots .slick-active {
		color: #fff;
		background-color: lightgray;
		background-color: black;
		border-radius: 0;
		opacity: 1;
	}
`;
