import React from 'react';

const AuctionSellPagination = ({ prevPage, paginate, nextPage, sellPage, newPageNumbers }) => {
	return (
		<>
			<div className="paging">
				<a className="btn prev" onClick={() => prevPage()} />
				{newPageNumbers &&
					newPageNumbers.map((number) => {
						return (
							<a
								key={number}
								className={number === sellPage + 1 ? 'num' : ''}
								onClick={() => paginate(number)}
							>
								{number}
							</a>
						);
					})}

				<a className="btn next" onClick={() => nextPage()} />
			</div>
		</>
	);
};

export default AuctionSellPagination;
