import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getOneSellingProductApi = (id) => {
	return apiCall(`${SERVER_API_HOST}/api/reservation/sale/get/${id}`, 'get', {}, {});
};

export const createPurchaseReservationApi = (
	authToken,
    nowProductId,
	oneSellingProductInfo,
	oneStoreIdReservation,
	convertVisitDate,
) => {
    return apiCall(
			`${SERVER_API_HOST}/api/reservation/purchase/create`,
			'post',
			{
				saleReservationId: nowProductId,
				watch: oneSellingProductInfo.watch || {},
				attachedFiles: oneSellingProductInfo.attachedFiles || [],
				storeIds: oneStoreIdReservation,
				visitDateTime: convertVisitDate,
				price: oneSellingProductInfo.price || null,
				description: oneSellingProductInfo.description || null,
			},
			{
				authorization: authToken,
			},
		);
};