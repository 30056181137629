import React from 'react';

const FilterMovement = ({
	setShowSupplements,
	selectedSupplements,
	setSelectedSupplements,
	handleCheckBoxCommon,
}) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowSupplements(false)}></button>
					<h2>구성품</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									구성품
								</h3>
							</div>
							<ul>
								<li>
									<b>보증서</b>
									<input
										type="checkbox"
										name="보증서"
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
										checked={selectedSupplements.includes('보증서')}
									/>
								</li>
								<li>
									<b>박스</b>
									<input
										type="checkbox"
										name="박스"
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
										checked={selectedSupplements.includes('박스')}
									/>
								</li>
								<li>
									<b>북렛</b>
									<input
										type="checkbox"
										name="북렛"
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
										checked={selectedSupplements.includes('북렛')}
									/>
								</li>
								<li>
									<b>Medal</b>
									<input
										type="checkbox"
										name="MEDAL"
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
										checked={selectedSupplements.includes('MEDAL')}
									/>
								</li>
								<li>
									<b>하이시간보증서</b>
									<input
										type="checkbox"
										name="하이시간보증서"
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
										checked={selectedSupplements.includes('하이시간보증서')}
									/>
								</li>
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedSupplements([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowSupplements(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterMovement;
