import React, { useState } from 'react';
import { postAuctionApplyApi } from 'apis/auctionApply';
import CommonHelper from 'helpers/CommonHelper';
import AuctionDatePicker from '../../../AuctionDetail/AuctionDatePicker';
import { useAppState } from 'context/AppContext';

const fontStyle = {
	marginTop: '5px',
	marginBottom: '12px',
	color: 'red',
	fontSize: '12px',
};

const MypageAuctionPopup = () => {
	const userData = useAppState();
	const { roles } = userData.user.data;

	const [auctionApplyCompletedPopup, setAuctionApplyCompletedPopup] = useState(false);
	const [isAuctionPopuped, setIsAuctionPopuped] = useState(false);

	//input value
	const [accountHolderName, setAccountHolderName] = useState('');
	const [nameValidation, setNameValidation] = useState(true);

	const [startDate, setStartDate] = useState(new Date());
	const [dateValidation, setDateValidation] = useState(true);

	const offset = new Date().getTimezoneOffset() * 60000;
	const convertStartDate = new Date(startDate - offset).toISOString().split('.')[0];

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	const handleAuctionPopup = () => {
		setIsAuctionPopuped(!isAuctionPopuped);
	};
	const handleAccountHolderName = (e) => {
		setAccountHolderName(e.target.value);
	};

	const handleAuctionApply = () => {
		if (!accountHolderName && !startDate) {
			return setNameValidation(false), setDateValidation(false);
		} else if (!accountHolderName && startDate) {
			return setNameValidation(false), setDateValidation(true);
		} else if (accountHolderName && !startDate) {
			return setNameValidation(true), setDateValidation(false);
		} else if (accountHolderName && startDate) {
			return postAuctionApplyApi(accountHolderName, convertStartDate, authToken).then(
				({ data: { data, detail, status } }) => {
					if (status === 'SUCCESS') {
						setAuctionApplyCompletedPopup(true);
						setNameValidation(true);
						setDateValidation(true);
						setAccountHolderName('');
						setStartDate(new Date());
					} else {
						// alert('등업 신청에 실패하였습니다. 고객상담으로 문의주시면 도와드리겠습니다.');
						alert(detail)
					}
				},
			);
		}
	};

	const clickCancelBtn = () => {
		setIsAuctionPopuped(!isAuctionPopuped);
		setAuctionApplyCompletedPopup(false);
		setNameValidation(true);
		setStartDate(new Date());
		setAccountHolderName('');
	};
	return (
		<>
			{roles && roles.includes('AUCTION') ? (
				''
			) : (
				<a style={{ cursor: 'pointer' }} onClick={handleAuctionPopup}>
					옥션등급 올리기
				</a>
			)}
			{isAuctionPopuped ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="popup grade_application">
						<h2>옥션등급 신청</h2>
						<div className="txt_box">
							<p className="t1">
								옥션에 참여하기 위해선 옥션등급으로 올려야 참여가 가능합니다. 옥션등급은
								하이시간계좌번호로 50000원을 입금하시면 관리자 확인 후 일반 등급에서 옥션등급으로
								올라갑니다.
							</p>
							<p className="t2">
								이후 옥션 참여시 입찰할 수 있는 권한이 생기며 입찰 후 구매취소를 하게 되면 보증금이
								차감되면서 일반등급으로 강등됩니다.
							</p>
						</div>
						<div className="cont">
							<h3>예금주</h3>
							<input
								type="text"
								placeholder="예금주를 입력해주세요."
								onChange={handleAccountHolderName}
								value={accountHolderName}
							/>
							{nameValidation ? '' : <p style={fontStyle}>예금주를 입력해주세요</p>}
							<h3>입금시간</h3>
							<div style={{ display: 'block' }}>
								<AuctionDatePicker startDate={startDate} setStartDate={setStartDate} />
								{dateValidation ? '' : <p style={fontStyle}>날짜를 선택해주세요</p>}
							</div>
						</div>
						<div className="cont2">
							<h3>입금하실 곳</h3>
							<div>
								<dl>
									<dt>은행</dt>
									<dd>기업은행</dd>
								</dl>
								<dl>
									<dt>계좌번호</dt>
									<dd>126301 04 391926</dd>
								</dl>
								<dl>
									<dt>예금주</dt>
									<dd>(주) 시간</dd>
								</dl>
							</div>
						</div>
						<div className="btn_wrap">
							<button className="ok" onClick={handleAuctionApply}>
								신청하기
							</button>
							<button onClick={clickCancelBtn}>취소</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}

			{auctionApplyCompletedPopup ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="alert">
						<p> 신청이 완료 되었습니다.</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={clickCancelBtn}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default MypageAuctionPopup;
