import React, { useState } from 'react';
import Pagination from './Pagination';

const SellReservation = ({ sellData, sellPage, setSellPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = sellData;
	const pageNumbers = [];

	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);
	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setSellPage(num);
	};
	const prevPage = () => {
		if (sellPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((sellPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setSellPage(sellPage - 1);
	};

	const nextPage = () => {
		if (sellPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setSellPage(sellPage + 1);
			if ((sellPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<ul className="history_list">
				{sellData?.content?.length > 0 ? (
					sellData.content &&
					sellData.content.map((data, index) => {
						return (
							<li key={index}>
								<p className="date">{data?.createdAt?.split('T')[0] || '-'}</p>
								<p className="watch_name">
									{data?.watch?.brand || '-'} <br />
									{data?.watch?.model || '-'}{' '}
									{data?.watch?.caseDiameter || '-'}mm
								</p>
								<dl>
									<div>
										<dt>방문지점</dt>
										<dd>{data?.stores[0]?.name || '-'}</dd>
									</div>
									<div>
										<dt>예약일</dt>
										<dd>
											{data?.visitDateTime.split('T')[0]}{' '}
											{data?.visitDateTime.split('T')[1].split(':')[0]}시
										</dd>
									</div>
								</dl>
								<button className={data?.state === 'SOLD' ? 'status cancel' : 'status'}>
									{data?.state === 'REGISTERED'
										? '고객등록'
										: 'APPROVED'
										? '승인'
										: 'VISITED'
										? '방문'
										: 'SALE_REGISTERED'
										? '판매 등록'
										: 'SOLD'
										? '판매완료'
										: '-'}
								</button>
							</li>
						);
					})
				) : (
					<li>
						<p>최근 예약내역이 없습니다.</p>
					</li>
				)}
			</ul>
			{sellData && sellData?.content?.length > 0 ? (
				<Pagination
					newPageNumbers={newPageNumbers}
					prevPage={prevPage}
					buyPage={sellPage}
					paginate={paginate}
					nextPage={nextPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default SellReservation;
