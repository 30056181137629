import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import Thumnail from 'assets-mobile/img/main/img_thumnail.png';
import Warranty from 'assets-mobile/img/main/ico_warranty.svg';
import IcoBox from 'assets-mobile/img/main/ico_box.svg';
import { handlePremiumNum } from 'common/common';
import { getDeadlineAuctionListApi } from 'apis/auctionMain';

const settings = {
	slidesPerView: 1,
	spaceBetween: 15,
	centeredSlides: true,
	simulateTouch: true,
	loop: false,
};

const DeadlineAuction = () => {
	const history = useHistory();
	const [swiper, setSwiper] = useState(null);
	const [deadlineAuctions, setDeadlineAuctions] = useState([]);
	const [testState, setTestState] = useState(false);

	useEffect(() => {
		getDeadlineAuctionList();
	}, []);
	return (
		<section className="recently_products">
			<h3>마감 임박 하이드로우</h3>
			<div className="swiper-container recently_pro">
				<div className="swiper-wrapper" id="AuctionCarouselWrapper">
					<Swiper getSwiper={setSwiper} {...settings}>
						{deadlineAuctions?.slice(0, 1).map((auction, idx) => (
							<div
								className="swiper-slide"
								key={idx}
								style={{ width: '100%' }}
								onClick={() => history.push(`/auction/${auction.id}`)}
							>
								<div className="img_box">
									<img src={auction?.attachedFiles ? auction.attachedFiles[0] : Thumnail} alt="" />
								</div>
								<div className="info_box">
									{/* <p
												className={`end 마감임박-${auction?.id}`}
												style={{ height: '24px', fontSize: '17px' }}
											>
												{initTimer(auction?.endDate, `마감임박-옥션-${auction?.id}`)}
											</p> */}
									<div className="count_box" id={`마감임박-옥션-${auction?.id}`}>
										{/* <span>20</span> :<span>20</span> :<span>10</span> */}
										{initTimer(auction?.endDate, `마감임박-옥션-${auction?.id}`)}
									</div>
									{!!auction?.price &&
										!!auction?.standardWatch?.officialPrice &&
										handlePremiumNum(auction?.price, auction?.standardWatch?.officialPrice) && (
											<p className="sale">
												{handlePremiumNum(auction?.price, auction?.standardWatch?.officialPrice)}%
												Premium
											</p>
										)}
									<p className="watch_name ellipsis poppins">
										{auction?.watch?.brand || '-'} <br />
										{auction?.watch?.model || '-'}
									</p>
									<p className="user">
										{/* <b>
											{auction?.orderPeopleCount
												? Number(auction?.orderPeopleCount).toLocaleString()
												: 0}
											명
										</b>{' '}
										참여 중 */}
									</p>
									<ul>
										<li>{auction?.watch?.producedYear || '-'}</li>
										<li>{auction?.watch?.condition || '-'}</li>
										<li>{auction?.watch?.serialNo || '-'}</li>
									</ul>
									<div>
										<p className="option">
											{auction?.watch?.supplements?.includes('GUARANTEE') && (
												<img src={Warranty} alt="보증서" title="보증서가 있는 상품입니다." />
											)}
											{auction?.watch?.supplements?.includes('BOX') && (
												<img src={IcoBox} alt="정품박스" title="정품박스가 있는 상품입니다." />
											)}
										</p>
										{!!auction?.standardWatch?.hpi ? (
											<p
												className={
													auction?.standardWatch?.hpi > 0
														? 'fluctuations up'
														: auction?.standardWatch?.hpi < 0
														? 'fluctuations down'
														: auction?.standardWatch?.hpi === 0
														? 'fluctuations'
														: ''
												}
											>
												{auction?.standardWatch?.hpi}
											</p>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						))}
					</Swiper>
				</div>
			</div>
			<div className="recently_pro_paging swiper-pagination"></div>
		</section>
	);

	function getDeadlineAuctionList() {
		getDeadlineAuctionListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setDeadlineAuctions(data);
				setTestState(!testState);
			} else {
				alert(detail);
			}
		});
	}
	function Lpad(str, len) {
		str = str + '';
		while (str.length < len) {
			str = '0' + str;
		}
		return str;
	}
	function initTimer(endTime, name) {
		let getElement = document.getElementById(name);
		// let getElements = document.getElementsByClassName(name);
		// if (getElements.length < 3) {
		// 	getElement = getElements[0];
		// } else {
		// 	getElement = getElements[1];
		// }
		if (endTime && name && getElement) {
			function msg_timer(id) {
				let stDate = new Date().getTime();
				let endDate = new Date(endTime).getTime();
				let remainDate = endDate - stDate;
				let days = Math.floor(remainDate / (1000 * 60 * 60 * 24));

				let hours = Math.floor((remainDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((remainDate % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((remainDate % (1000 * 60)) / 1000);

				// let m = `${days}일 ${Lpad(hours, 2)}:${Lpad(minutes, 2)}:${Lpad(seconds, 2)}이후 마감`; // 남은 시간 text형태로 변경
				let m = `<span>${days}일</span><span>${Lpad(hours, 2)}</span>:<span>${Lpad(
					minutes,
					2,
				)}</span> :<span>${Lpad(seconds, 2)}</span>`;

				getElement.innerHTML = m; // div 영역에 보여줌

				if (remainDate < 0) {
					// 시간이 종료 되었으면..
					clearInterval(tid); // 타이머 해제
				} else {
					remainDate = remainDate - 1000; // 남은시간 -1초
				}
			}
			let tid = setInterval(msg_timer, 1000);
		}
	}
};
export default DeadlineAuction;
