import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getReservationDetailInfoApi, deleteOneHaplApi } from 'apis/hapl';
import CommonHelper from 'helpers/CommonHelper';
import moment from 'moment';
import { HAPLE_STATUS } from 'common/constants';

const HaplDetail = ({ detailId, setCurrStep, detailInfo, setDetailInfo, setOneStoreSearch }) => {
	const [reservedDay, setReservedDay] = useState();
	const [show, setShow] = useState(false);
	const [showCancel, setShowCancel] = useState(true);
	const history = useHistory();

	useEffect(() => {
		if (!!detailInfo?.day) {
			let today = new Date();
			let nowYear = today.getFullYear();
			let nowMonth = today.getMonth() + 1;
			let nowDay = today.getDate() - 1;
			// 오늘 날짜
			let [year, month, day] = detailInfo?.day?.split('-');
			let hours = detailInfo?.timeStr?.split('~')[0].split(':')[0];
			// 예약 날짜

			let todayDate = new Date(nowYear, nowMonth, nowDay);
			let reserveDate = new Date(Number(year), Number(month), Number(day));
			let caculate = reserveDate - todayDate;

			if (caculate <= 0 || detailInfo?.status === 'CANCEL') {
				setShowCancel(false); // 0 보다 작다 => 과거이면 취소버튼 hide
			} else {
				setShowCancel(true);
			}
		}
	}, [detailInfo]);

	useEffect(() => {
		if (detailId) {
			getReservationDetailInfo();
		}
	}, [detailId]);

	const getTime = () => {
		const nowDate = moment(reservedDay);
		const prevDate = nowDate.clone().subtract(1, 'days').format('YYYY-MM-DD');
		return prevDate;
	};

	const handleHaplChange = () => {
		setCurrStep('modifyStep1');
	};

	const beforeHandleHaplCancel = () => {
		let today = new Date();
		let nowYear = today.getFullYear();
		let nowMonth = today.getMonth() + 1;
		let nowDay = today.getDate();
		// 오늘 날짜

		let [year, month, day] = detailInfo.day.split('-');
		// 예약 날짜

		if (
			Number(nowYear) === Number(year) &&
			Number(nowMonth) === Number(month) &&
			Number(nowDay) === Number(day)
		) {
			alert('당일취소는 해당 매장에 직접 전화해서 진행해주세요.');
		} else {
			handleHaplCancel();
		}
	};

	const goStore = () => {
		history.push('/store');
	};

	const beforeHandleHaplChange = () => {
		let today = new Date();
		let nowYear = today.getFullYear();
		let nowMonth = today.getMonth() + 1;
		let nowDay = today.getDate();
		// 오늘 날짜

		let [year, month, day] = detailInfo.day.split('-');
		// 예약 날짜
		let todayDate = new Date(nowYear, nowMonth, nowDay);
		let reserveDate = new Date(Number(year), Number(month), Number(day));
		let caculate = reserveDate - todayDate;

		if (
			Number(nowYear) === Number(year) &&
			Number(nowMonth) === Number(month) &&
			Number(nowDay) === Number(day)
		) {
			alert('당일 예약변경은 해당 매장에 직접 전화해서 진행해주세요.');
		} else {
			handleHaplChange();
		}
	};

	const goToMain = () => {
		setOneStoreSearch();
		setCurrStep('main');
	};
	return (
		<>
			<title className="detail-title">공간대여서비스 신청현황</title>
			<section className="detail-wrapper">
				<div className="img-wrapper">
					<img src={detailInfo?.store?.profiles[0]} alt="" />
				</div>
				<div className="reservation-info">
					<table className="tbl">
						<colgroup>
							<col className="table-col-first" />
							<col className="table-col-second" />
						</colgroup>
						<thead>
							<tr>
								<th>예약현황</th>
								<td>{HAPLE_STATUS[detailInfo?.status] || '-'}</td>
							</tr>
							<tr>
								<th>이용일자</th>
								<td>{detailInfo?.day || '-'}</td>
							</tr>
							<tr>
								<th>이용시간</th>
								<td>{detailInfo?.timeStr || '-'}</td>
							</tr>
							<tr>
								<th>이용인원</th>
								<td>{detailInfo?.userCount || '-'}</td>
							</tr>
							<tr>
								<th>취소기한</th>
								<td>{detailInfo && reservedDay ? getTime() : ''}</td>
							</tr>
						</thead>
					</table>
					<div className="notice">·&nbsp;취소시 최소 24시간 이상의 기간이 필요합니다.</div>
					<div className="title">서비스 정보</div>
					<table className="tbl">
						<colgroup>
							<col className="table-col-first" />
							<col className="table-col-second" />
						</colgroup>
						<thead>
							<tr>
								<th>직영점</th>
								<td>{detailInfo?.store?.name || '-'}</td>
							</tr>
							<tr>
								<th>주소</th>
								<td>{detailInfo?.store?.address || '-'}</td>
							</tr>
							{/* <tr>
							<th>담당자</th>
							<td>{detailInfo?. || '-'}</td>
						</tr> */}
							<tr className="brown-text">
								<th>문의처</th>
								<td>{detailInfo?.store?.contactNumber || '-'}</td>
							</tr>
						</thead>
					</table>
					<div className="title">이용자 정보</div>
					<table className="tbl">
						<colgroup>
							<col className="table-col-first" />
							<col className="table-col-second" />
						</colgroup>
						<thead>
							<tr>
								<th>구매자/판매자</th>
								<td>{detailInfo?.userType === 'SELLER' ? '판매자' : '구매자'}</td>
							</tr>
							<tr>
								<th>신청자 명</th>
								<td>{detailInfo?.name || '-'}</td>
							</tr>
							{/* <tr>
							<th>핸드폰</th>
							<td>{detailInfo?. || '-'}</td>
						</tr> */}
							<tr>
								<th>핸드폰</th>
								<td>{detailInfo?.email || '-'}</td>
							</tr>
							<tr className="content long">
								<th className="content long">기타내용</th>
								<td className="long">
									<div style={{ wordBreak: 'break-all', height: '40vw', overflow: 'scroll' }}>
										{detailInfo?.etcQuestion || '-'}
									</div>
								</td>
							</tr>
						</thead>
					</table>
				</div>
				<div className="btn-box">
					{showCancel ? (
						<>
							<button className="cancel" onClick={beforeHandleHaplCancel}>
								예약취소
							</button>
							<button className="change" onClick={beforeHandleHaplChange}>
								예약변경
							</button>
						</>
					) : (
						''
					)}
					<button className="list" onClick={goToMain}>
						목록
					</button>
				</div>
			</section>
		</>
	);
	function getReservationDetailInfo() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getReservationDetailInfoApi(detailId, authToken).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setShow(true);
				setDetailInfo(data || {});
				setReservedDay(data.day);
			} else {
				setShow(false);
				alert(detail);
				setCurrStep('main');
			}
		});
	}

	function handleHaplCancel() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		deleteOneHaplApi(detailId, authToken).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				alert('예약이 삭제되었습니다.');
				setOneStoreSearch();
				setCurrStep('main');
			} else {
				alert(detail);
			}
		});
	}
};

export default HaplDetail;
