import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';
import Chart from 'chart.js';
import CommonHelper from 'helpers/CommonHelper';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import calendarIco from 'assets-pc/img/common/ico_calendar.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import { getOneSellingProductApi, createPurchaseReservationApi } from 'apis/buyDetail';
import { getStoreListApi } from 'apis/buy';
import BuyDetailDatePicker from './BuyDetailDatePicker';
import BuyDetailSlider from './components/BuyDetailSlider';
import { SUPPLEMENTS_EN_KO, GENDER_KO_EN, MOVEMENT_EN_KO, GENDER_EN_KO } from 'common/constants';
import { handlePremiumNum } from 'common/common';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const BuyDetail = () => {
	const [oneSellingProductInfo, setOneSellingProductInfo] = useState({});
	const [onePhotoUrl, setOnePhotoUrl] = useState(null); //큰 사진 url
	const [currentStoreList, setCurrentStoreList] = useState([]);

	const [showLoginNeedPopup, setShowLoginNeedPopup] = useState(false);
	const [showReservationFormPopup, setShowReservationFormPopup] = useState(false);
	const [showCreateSuccessPopup, setShowCreateSuccessPopup] = useState(false);
	//구매예약 지점선택
	const [oneStoreIdReservation, setOneStoreIdReservation] = useState(null);
	//구매예약 방문일정
	const [visitDate, setVisitDate] = useState(new Date());
	const offset = new Date().getTimezoneOffset() * 60000;
	const convertVisitDate = new Date(visitDate - offset).toISOString().split('.')[0];
	///////
	const [errorText, setErrorText] = useState('');
	const [agreeChecked, setAgreeChecked] = useState(false);

	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onClickonePhotoUrl = (photoUrl) => {
		setOnePhotoUrl(photoUrl);
	};
	/**
	 * /buy/:id 상품상세로 들어왔을 때 최근 본 상품등록을 위해 localStorage에 저장
	 */
	const handleSessionRecently = () => {
		let getStorage = localStorage.getItem('buyFilters');
		let nowProductId = window.location.pathname.split('/')[2];
		if (!getStorage) {
			let makeArrayForSession = nowProductId;
			localStorage.setItem('buyFilters', `${makeArrayForSession}`);
		} else if (getStorage.split(',').length < 5) {
			let splitBox = getStorage.split(',');
			if (splitBox.includes(nowProductId)) {
				return;
			} else {
				localStorage.setItem('buyFilters', `${nowProductId},${getStorage}`);
			}
		} else if (getStorage.split(',').length >= 5) {
			let splitBox = getStorage.split(',');
			splitBox.unshift(nowProductId);
			let sliceSplitBox = splitBox.slice(0, 5);
			let sliceSplitStringBox = sliceSplitBox.toString();
			localStorage.setItem('buyFilters', `${sliceSplitStringBox}`);
		}
	};

	useEffect(() => {
		handleSessionRecently();
		getOneSellingProduct();
		getStoreList();
		// renderViewerComp();
	}, []);

	const checkMemberForReservation = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			setShowLoginNeedPopup(true);
		} else {
			setShowReservationFormPopup(true);
		}
	};
	// 로그인 안되어있는데 구매예약 버튼 누르면 나타날 popup / 혹은 시계를 찾아줘 이용하려는데 로그인 안되어 있는 경우

	const getChart = (oneProductData) => {
		const productPriceArr = oneProductData?.pricehistoires?.slice(-180, -1)?.map((item) => {
			//6개월
			return item.price;
		});
		const productDayArr = oneProductData?.pricehistoires?.slice(-180, -1)?.map((item) => {
			//6개월
			const year = item?.day?.slice(0, 4);
			const month = item?.day?.slice(4, 6);
			const day = item?.day?.slice(6, 8);
			return `${year}-${month}-${day}`;
		});

		const getOnlyMonth = (list) => {
			//1,2,3,4,5,6월 하나씩만 담기게
			let newArray = [];
			for (let i = 0; i < list.length; i++) {
				let month = list[i].split('-')[1];
				newArray =
					newArray.filter((el) => el.split('-')[1] === month).length === 0
						? [...newArray, list[i]]
						: newArray;
			}
			return newArray;
		};

		let myChart = document.getElementById('myChart');
		// productPriceArr.map((item, idx) => {
		var ctx = myChart.getContext('2d');
		var gradientFill = ctx.createLinearGradient(0, 70, 0, 0);
		gradientFill.addColorStop(1, 'rgba(84, 93, 102, .5)');
		gradientFill.addColorStop(0, 'rgba(84, 93, 102, 0)');

		new Chart(ctx, {
			// The type of chart we want to create
			type: 'line',

			// The data for our dataset
			data: {
				// labels: getOnlyMonth(productDayArr),
				labels: productDayArr,
				datasets: [
					{
						backgroundColor: gradientFill,
						borderColor: 'rgba(0, 0, 0, 0.25)',
						data: productPriceArr,
						pointRadius: 0,
						lineTension: 0,
						boderWidth: 1,
						showline: true,
					},
				],
			},

			// Configuration options go here
			options: {
				animation: {
					duration: 0,
				},
				legend: {
					display: false,
				},
				tooltips: false,
				clip: false,
				elements: {
					point: {
						radius: 0,
					},
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
								min: Math.min.apply(this, productPriceArr?.slice(-180, -1)), //최대 최소 6개월
								max: Math.max.apply(this, productPriceArr?.slice(-180, -1)),
								callback: function (value, index, values) {
									if (index === values.length - 1)
										return Math.min.apply(this, productPriceArr?.slice(-180, -1));
									else if (index === 0)
										return Math.max.apply(this, productPriceArr?.slice(-180, -1));
									else return '';
								},
							},
						},
					],
				},
			},
		});
		// });
	};

	const handleCheckboxStore = (e) => {
		const { name, checked } = e.target;
		if (checked) {
			setOneStoreIdReservation(name);
		} else {
			setOneStoreIdReservation(null);
		}
	};

	const checkValidation = () => {
		if (!oneStoreIdReservation) {
			setErrorText('방문 지점을 선택해주세요.');
		} else if (!agreeChecked) {
			setErrorText('개인정보수집 이용 동의에 체크해주세요.');
		} else {
			setErrorText('');
			createPurchaseReservation();
		}
	};

	const goToSell = (modelName) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			history.push(`/sell/${encodeURIComponent(modelName)}`);
		} else {
			setShowLoginNeedPopup(true);
		}
	};

	const goToLoginPage = () => {
		setShowLoginNeedPopup(false);
		history.push('/signin?redirectUrl=/buy');
	};

	const goToLinkUrl = () => {
		if (!oneSellingProductInfo.linkUrl) return;

		window.open(oneSellingProductInfo.linkUrl);
	};
	return (
		<>
			{showLoginNeedPopup && (
				<div className="bg_dimmed dimmed_block">
					<section className="alert">
						<p>
							로그인이 필요한 서비스입니다. <br />
							회원이시라면 로그인 후 이용해주세요.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={goToLoginPage}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			{showReservationFormPopup && (
				<div className="bg_dimmed dimmed_block">
					<section className="popup reservation">
						<h2>구매예약</h2>
						<p>구매예약을 위해서 정보를 입력해주세요.</p>
						<div className="cont">
							<h3>
								방문지점<span>*</span>
							</h3>
							<ul className="check_list">
								{currentStoreList.map((store) => (
									<li key={store.id} style={{ marginRight: '15px' }}>
										<label>
											<input
												type="checkbox"
												checked={oneStoreIdReservation === store.id.toString()}
												disabled={false}
												name={store.id}
												onChange={handleCheckboxStore}
											/>
											<span>{store.name || '-'}</span>
										</label>
									</li>
								))}
							</ul>
							<h3>
								방문일정<span>*</span>
							</h3>
							<div className="calendar_wrap">
								<div style={{ width: '100%' }}>
									<BuyDetailDatePicker visitDate={visitDate} setVisitDate={setVisitDate} />
									{/* <input type="text" value="2020년 12월 06일" /> */}
									<button className="calendar_img">
										<img src={calendarIco} alt="달력 아이콘" />
									</button>
								</div>
								{/* <select name="" id="">
									<option value="">10-11시</option>
								</select> */}
							</div>
							<p className="alert_txt">{errorText}</p>
							<div className="agree_box">
								<input
									type="checkbox"
									checked={agreeChecked}
									onChange={() => setAgreeChecked(!agreeChecked)}
								/>
								<span>
									<a>개인정보수집</a> 이용에 동의합니다.
								</span>
								<p>
									서비스 이용을 위해 필요한 정보로써, 동의를 해주셔야 서비스를 이용 하실 수
									있습니다.
								</p>
							</div>
						</div>
						<div className="btn_wrap">
							<button className="full" style={{ margin: '3px' }} onClick={checkValidation}>
								예약하기
							</button>
							<button
								className="full"
								style={{ margin: '3px' }}
								onClick={() => setShowReservationFormPopup(false)}
							>
								닫기
							</button>
						</div>
					</section>
				</div>
			)}
			{showCreateSuccessPopup && (
				<div className="bg_dimmed dimmed_block">
					<section className="alert">
						<p>
							<strong>구매예약이 완료 되었습니다.</strong> <br />
							구매예약한 내용은 마이페이지에서 <br />
							확인하실 수 있습니다.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={() => setShowCreateSuccessPopup(false)}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			<div className="container buy_detail">
				<div className="top_cont">
					<div className="watch_list">
						<ul>
							<li className="on on_plus">
								<img src={onePhotoUrl || null} alt="시계 사진" className="watch_img_plus" />
							</li>
						</ul>
					</div>
					<div className="watch_info">
						<p className="bland">
							{oneSellingProductInfo?.watch?.brand || '-'}{' '}
							{oneSellingProductInfo?.watch?.model || '-'}
						</p>
						<p className="option">
							{oneSellingProductInfo?.watch?.supplements.includes('GUARANTEE') && (
								<img src={warrantyIco} alt="보증서" title="보증서가 있는 상품입니다." />
							)}
							{oneSellingProductInfo?.watch?.supplements.includes('BOX') && (
								<img src={boxIco} alt="정품박스" title="정품박스가 있는 상품입니다." />
							)}
						</p>
						<p className="watch_name">
							{oneSellingProductInfo?.watch?.brand || '-'}{' '}
							{oneSellingProductInfo?.watch?.model || '-'}
						</p>
						<p className="t1">{oneSellingProductInfo?.description || '-'}</p>
						<p className="t2">
							최근 48시간 동안{' '}
							<span>
								{oneSellingProductInfo?.hourCount
									? Number(oneSellingProductInfo?.hourCount).toLocaleString()
									: 0}
								명
							</span>
							이 이 시계를 조회하였습니다.
						</p>
						<div className="t3">
							<strong className="price">
								₩{' '}
								{oneSellingProductInfo?.price
									? Number(oneSellingProductInfo?.price).toLocaleString()
									: '-'}
							</strong>
							<p
								className={
									oneSellingProductInfo?.standardWatch?.hpi > 0
										? 'fluctuations up'
										: oneSellingProductInfo?.standardWatch?.hpi < 0
										? 'fluctuations down'
										: oneSellingProductInfo?.standardWatch?.hpi === 0
										? 'fluctuations'
										: ''
								}
							>
								<span>{oneSellingProductInfo?.standardWatch?.hpi || '-'}</span>
							</p>
						</div>
						{!!oneSellingProductInfo?.price &&
							!!oneSellingProductInfo?.standardWatch?.officialPrice &&
							handlePremiumNum(
								oneSellingProductInfo?.price,
								oneSellingProductInfo?.standardWatch?.officialPrice,
							) && (
								<p className="sale">
									{handlePremiumNum(
										oneSellingProductInfo?.price,
										oneSellingProductInfo?.standardWatch?.officialPrice,
									)}
									% Premium
								</p>
							)}
						<p className="t4">재고보유지점</p>
						<ul className="store_list">
							{currentStoreList.map((store) => (
								<li key={store.id}>
									<label>
										<input
											type="checkbox"
											checked={
												oneSellingProductInfo?.stores?.filter((el) => el.id === store.id).length > 0
											}
											className="round"
											style={{ margin: '5px' }}
											disabled
										/>
										<span>{store.name || '-'}</span>
									</label>
								</li>
							))}
						</ul>
						{oneSellingProductInfo?.state === 'SOLD' ? (
							<button className="buy_reservation buy_bidding_end">판매 종료된 상품입니다.</button>
						) : (
							<>
								<button className="buy_reservation">구입문의 1644-5751</button>
								<button
									className="buy_reservation_link"
									onClick={goToLinkUrl}
									disabled={!oneSellingProductInfo.linkUrl}
								>
									예약하러 가기
								</button>
							</>
						)}
						{/* <p className="t5">
							<span>혹시 동일한 시계 모델을 판매하시고 싶은신가요?</span>{' '}
							<a
								style={{ cursor: 'pointer' }}
								onClick={() => goToSell(oneSellingProductInfo?.watch?.model)}
							>
								판매하러 가기
							</a>
						</p> */}
					</div>
				</div>
				<div className="tab_list">
					<BuyDetailSlider
						oneSellingProductInfo={oneSellingProductInfo}
						onePhotoUrl={onePhotoUrl}
						setOnePhotoUrl={setOnePhotoUrl}
					/>
				</div>
				<div className="graph_box">
					<p>최근 가격 변동 추이</p>
					<div className="graph" style={{ paddingTop: '22px' }}>
						<canvas id="myChart" width="1128" height="81"></canvas>
					</div>
				</div>
				<div style={{ margin: '30px auto', width: '80%' }}>
					{oneSellingProductInfo?.additionalInfo && (
						<Wrapper>
							<Viewer
								initialValue={replaceNewlineWithBrTag(
									decodingHtmlTags(oneSellingProductInfo?.additionalInfo || ''),
								)}
								initialEditType="markdown"
							/>
						</Wrapper>
					)}
				</div>
				<h3>정보</h3>
				<table className="tbl">
					<colgroup>
						<col className="table-col-first" />
						<col className="table-col-second" />
					</colgroup>
					<thead>
						<tr>
							<th>레퍼런스</th>
							<td>{oneSellingProductInfo?.watch?.serialNo || '-'}</td>
						</tr>
						<tr>
							<th>브랜드</th>
							<td>{oneSellingProductInfo?.watch?.brand || '-'}</td>
						</tr>
						<tr>
							<th>모델</th>
							<td>{oneSellingProductInfo?.watch?.model || '-'}</td>
						</tr>
						<tr>
							<th>무브먼트</th>
							<td>
								{oneSellingProductInfo?.watch?.movement
									? MOVEMENT_EN_KO[oneSellingProductInfo?.watch?.movement]
									: '-'}
							</td>
						</tr>
						<tr>
							<th>케이스 소재</th>
							<td>{oneSellingProductInfo?.watch?.caseMaterial || '-'}</td>
						</tr>
						<tr>
							<th>지름 (mm)</th>
							<td>{oneSellingProductInfo?.watch?.caseDiameter || '-'}</td>
						</tr>
						<tr>
							<th>구매년도</th>
							<td>{oneSellingProductInfo?.watch?.producedYear || '미확인'}</td>
						</tr>
						<tr>
							<th>상품 컨디션 (0~10)</th>
							<td>{oneSellingProductInfo?.watch?.condition || '-'}</td>
						</tr>
						<tr>
							<th>구성품</th>
							<td>
								{oneSellingProductInfo?.watch?.supplements?.map((el, idx) => {
									return (
										<span style={{ marginRight: '10px' }} key={idx}>
											{el ? SUPPLEMENTS_EN_KO[el] : '-'}{' '}
										</span>
									);
								})}
							</td>
						</tr>
						<tr>
							<th>성별</th>
							<td>
								{oneSellingProductInfo?.watch?.gender
									? GENDER_EN_KO[oneSellingProductInfo?.watch?.gender]
									: '-'}
							</td>
						</tr>
						<tr>
							<th>다이얼 색상</th>
							<td>{oneSellingProductInfo?.watch?.dialColor || '-'}</td>
						</tr>
						<tr>
							<th>다이얼 타입</th>
							<td>{oneSellingProductInfo?.watch?.dialType || '-'}</td>
						</tr>
						<tr>
							<th>국내/국외 보유</th>
							<td>{oneSellingProductInfo?.holdingType || '-'}</td>
						</tr>
						{/* <tr>
							<th>영수증 유무</th>
							<td>{oneSellingProductInfo?.hasReceipt ? '유' : '무'}</td>
						</tr> */}
					</thead>
				</table>
				{/* <h3>무브먼트/칼리버</h3>
				<table className="tbl">
					<colgroup>
						<col style={{ width: '300px' }} />
						<col style={{ width: 'auto' }} />
					</colgroup>
					<thead>
						<tr>
							<th>무브먼트</th>
							<td>자동</td>
						</tr>
						<tr>
							<th>무브먼트/칼리버</th>
							<td>Automatic</td>
						</tr>
						<tr>
							<th>진동 빈도</th>
							<td>28800 A/h</td>
						</tr>
						<tr>
							<th>케이스</th>
							<td>-</td>
						</tr>
						<tr>
							<th>케이스 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>케이스 지름</th>
							<td>39 mm</td>
						</tr>
						<tr>
							<th>두께</th>
							<td>14.5 mm</td>
						</tr>
						<tr>
							<th>베젤 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>유리</th>
							<td>사파이어 글라스</td>
						</tr>
						<tr>
							<th>다이얼</th>
							<td>흰색</td>
						</tr>
						<tr>
							<th>다이얼번호</th>
							<td>로마숫자</td>
						</tr>
					</thead>
				</table>
				<h3>브레이슬릿/스트랩</h3>
				<table className="tbl">
					<colgroup>
						<col style={{ width: '300px' }} />
						<col style={{ width: 'auto' }} />
					</colgroup>
					<thead>
						<tr>
							<th>시계줄 소재</th>
							<td>가죽</td>
						</tr>
						<tr>
							<th>브레이슬릿 색상</th>
							<td>검정색</td>
						</tr>
						<tr>
							<th>잠금장치</th>
							<td>잠금장치</td>
						</tr>
						<tr>
							<th>잠금장치 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>기능</th>
							<td>날짜</td>
						</tr>
						<tr>
							<th>기타</th>
							<td>뒷면 표시, 발광 시계 바늘, 순정부품</td>
						</tr>
					</thead>
				</table> */}
			</div>
		</>
	);
	function getOneSellingProduct() {
		let nowProductId = window.location.pathname.split('/')[2];
		getOneSellingProductApi(nowProductId).then(({ data }) => {
			if (data.status === 'SUCCESS') {
				setOneSellingProductInfo(data.data || {});
				setOnePhotoUrl(data?.data?.attachedFiles[0] ? data.data.attachedFiles[0] : null);
				getChart(data.data || {});
			} else {
				alert(data?.detail);
			}
		});
	}

	function getStoreList() {
		getStoreListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}

	function createPurchaseReservation() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		let nowProductId = window.location.pathname.split('/')[2];
		let storeArray = [oneStoreIdReservation];
		if (!!authToken) {
			createPurchaseReservationApi(
				authToken,
				nowProductId,
				oneSellingProductInfo,
				storeArray,
				convertVisitDate,
			).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					setShowCreateSuccessPopup(true);
					setShowReservationFormPopup(false);
					setOneStoreIdReservation(null);
					setVisitDate(new Date());
					setAgreeChecked(false);
				} else {
					alert(detail);
				}
			});
		}
	}
};

export default BuyDetail;

const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	display: flex;
	justify-content: center;
	img {
		max-width: 1100px;
	}
`;
