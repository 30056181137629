import React, { useEffect, useState } from 'react';
import BuyAuction from './components/BuyAuction';
import SellAuction from './components/SellAuction';
import { postAuctionBuyMenuApi, postAuctionSellMenuApi } from 'apis/auctionMenu';
import { useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';

const MyAuctionList = ({ setShowMyAuction }) => {
	const [auctionBuyData, setAuctionBuyData] = useState({});
	const [auctionSellData, setAuctionSellData] = useState({});
	const [btnValue, setBtnValue] = useState('buy');
	const [buyPage, setBuyPage] = useState(0);
	const [sellPage, setSellPage] = useState(0);
	const appState = useAppState();
	const userId = appState && appState.user.data.id;
	const OFFSET = 15;
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	useEffect(() => {
		getAuctionBuyData();
	}, [buyPage]);

	const getAuctionBuyData = () => {
		postAuctionBuyMenuApi(authToken, userId, OFFSET, buyPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setAuctionBuyData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};
	useEffect(() => {
		getAuctionSellData();
	}, [sellPage]);

	const getAuctionSellData = () => {
		postAuctionSellMenuApi(authToken, userId, OFFSET, sellPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setAuctionSellData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};

	const handleBtnValue = (e) => {
		const { name } = e.target;
		if (name === 'buy') {
			setBtnValue('buy');
		} else {
			setBtnValue('sell');
		}
	};

	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowMyAuction(false)}></button>
					<h2>옥션내역</h2>
				</section>

				<section className="booking history">
					<div className="tab_wrap">
						<a
							className={btnValue === 'buy' ? 'on' : ''}
							name="buy"
							onClick={(e) => handleBtnValue(e)}
						>
							구매
						</a>
						<a
							className={btnValue === 'sell' ? 'on' : ''}
							name="sell"
							onClick={(e) => handleBtnValue(e)}
						>
							판매
						</a>
					</div>
					{/* <p className="txt">취소 관련 문의는 예약지점으로 전화 문의해주세요.</p> */}
					<p className="txt">* 예약을 취소하실 경우 전화주세요. 1644-5751</p>
					{btnValue === 'buy' ? (
						<BuyAuction buyData={auctionBuyData} buyPage={buyPage} setBuyPage={setBuyPage} />
					) : (
						<SellAuction sellData={auctionSellData} sellPage={sellPage} setSellPage={setSellPage} />
					)}
				</section>
			</main>
		</>
	);
};
export default MyAuctionList;
