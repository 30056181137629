/**
 * @desc 공통 함수 Helper
 */
export default class CommonHelper {
	/**
	 * 한글유무
	 * @param {string} str
	 * @returns
	 */
	static checkKor(str) {
		const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
		if (regExp.test(str)) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * 패스워드 규칙
	 * @param email
	 * @returns {boolean}
	 */
	static emailIsValid(email) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	}

	/**
	 * 영문(대소문자구분X)/숫자/특수문자 -길이제한 : 국문기준 2자~20자
	 * @param password
	 * @returns {boolean}
	 */
	static passwordRules(password) {
		return /^.*(?=^.{2,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(password);
	}

	/**
	 * 숫자 10~11자
	 * @param phoneNumber
	 * @returns {boolean}
	 */
	static phoneNumberRules(phoneNumber) {
		return /^\d{3}-\d{3,4}-\d{4}$/.test(phoneNumber);
	}

	/**
	 * 이름 2~10자(한글)
	 * @param name
	 * @returns {boolean}
	 */
	static nameRule(name) {
		return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2,10}$/.test(name);
	}

	/**
	 * 영문/숫자 -길이제한 : 영문기준 3자~20자
	 * @param  id
	 */
	static idRule(id) {
		return /^[A-Za-z0-9]{3,20}/g.test(id);
	}

	/**
	 * 한글은 2글자 영문은 4글자이상
	 * @param nickName
	 */
	static nickNameRule(nickName) {
		return /^[가-힣]{2,}|[a-zA-Z]{4,}$/.test(nickName);
	}

	/**
	 * 핸드폰 규칙 (010-0000-0000)
	 * @param phone
	 * @returns {boolean}
	 */
	static phoneRules(phone) {
		return /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/.test(phone);
	}

	/**
	 * 인증 토큰 로컬 스토리지 저장
	 * @param authToken
	 */
	static setAuthenticateToken(authToken) {
		window.localStorage.setItem('siganAuthToken', authToken);
	}

	/**
	 * 인증 토큰 삭제
	 */
	static clearAuthenticateToken() {
		window.localStorage.removeItem('siganAuthToken');
	}

	/**
	 * 인증 토큰 - AuthToken
	 */
	static getAuthToken() {
		return window.localStorage.getItem('siganAuthToken');
	}

	/**
	 * 세션스토리지 인증 토큰 저장
	 * @param authToken
	 */
	static setSessionAuthenticateToken(authToken) {
		window.sessionStorage.setItem('siganAuthToken', authToken);
	}

	/**
	 * 세션스토리지 인증 토큰 삭제
	 */
	static clearSessionAuthenticateToken() {
		window.sessionStorage.removeItem('siganAuthToken');
	}

	/**
	 * 세션스토리지 인증 토큰 가져오기 - AuthToken
	 */
	static getSessionAuthToken() {
		return window.sessionStorage.getItem('siganAuthToken');
	}

	static setRecentlyProducts(name, array) {
		window.sessionStorage.setItem(name, array);
	}

	static clearRecentlyProducts(name) {
		window.sessionStorage.removeItem(name);
	}

	static getRecentlyProducts(name) {
		window.sessionStorage.getItem(name);
	}

	/**
	 * 쿠키저장
	 */
	static setCookie(cookieName, cookieValue, cookieExpireDays) {
		let d = new Date();
		d.setTime(d.getTime() + cookieExpireDays * 24 * 60 * 60 * 1000);
		let expires = 'expires=' + d.toUTCString();
		document.cookie = cookieName + '=' + cookieValue + ';' + expires;
	}

	/**
	 * 쿠키 가져오기
	 */
	static getCookie(cookieName) {
		let name = cookieName + '=';
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == '') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	/**
	 * 쿠키삭제
	 */
	static deleteCookie(cookieName) {
		document.setCookie(cookieName);
	}

	/**\
	 * 배열데이터 쿠키에 저장
	 */
	static setCookieArray(cookieName, cookieArray, expiredDays) {
		let str = '';
		for (let key in cookieArray) {
			if (str != '') str += ',';
			str += key + ':' + cookieArray[key];
		}
		document.setCookie(cookieName, str, expiredDays);
	}

	/**
	 * 배열데이터 가지고옴
	 */
	static getCookieArray(name) {
		let str = document.getCookie(name);
		let tmp1 = str.split(',');
		let reData = {};
		for (let i in tmp1) {
			let tmp2 = tmp1[i].split(':');
			reData[tmp2[0]] = tmp2[1];
		}
		return reData;
	}

	static masking = function (str) {
		
		const checkNull = (str) => {
			if (typeof str == undefined || str == null || str === '') {
				return true;
			} else {
				return false;
			}
		};

		let originStr = str;
		let maskingStr;
		let strLength;
		if (checkNull(originStr) === true) {
			return originStr;
		}
		strLength = originStr.length;
		if (strLength < 3) {
			maskingStr = originStr.replace(/(?:.{1})./gi, '*');
		} else {
			maskingStr = originStr.slice(0, -2) + '**';
		}
		return maskingStr;
	};
}
