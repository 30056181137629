import { apiCall } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getBuyReservationApi = (authToken, userId, OFFSET, page) => {
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/purchase/list?page=${page}&offset=${OFFSET}&direction=desc&property=createdAt`,
		'post',
		{ userId: userId },
		{
			authorization: authToken,
		},
	);
};

export const getSellReservationApi = (authToken, userId, OFFSET, page) => {
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/list?page=${page}&offset=${OFFSET}&direction=desc&property=createdAt`,
		'post',
		{ userId: userId },
		{
			authorization: authToken,
			ContentType: 'application/json',
		},
	);
};
