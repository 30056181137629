import React from 'react';

const FilterProducedYear = ({
	producedYearMaxInput,
	setProducedYearMaxInput,
	producedYearMinInput,
	setProducedYearMinInput,
	setShowProducedYearFilter,
}) => {
	const deleteAllProducedYear = () => {
		setProducedYearMinInput('');
		setProducedYearMaxInput('');
	};

	const handleProducedYearInput = (e) => {
		const { value, name } = e.target;
		if (name === 'minimum') {
			if (value.length < 6) {
				setProducedYearMinInput(value);
			}
		} else if (name === 'maximum') {
			if (value.length < 6) {
				setProducedYearMaxInput(value);
			}
		}
	};
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowProducedYearFilter(false)}></button>
					<h2>구매년도</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										border: '0',
									}}
								>
									구매년도
								</h3>
							</div>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<input
										type="text"
										value={producedYearMinInput}
										onChange={handleProducedYearInput}
										name="minimum"
										style={{
											display: 'flex',
											alignItems: 'center',
											padding: '12px',
											fontWeight: '500',
											fontSize: '12px',
											lineHeight: '14px',
											width: '100%',
											height: '38px',
											border: '1px solid #aab4c4',
											background: 'transparent',
										}}
									/>
								</div>
								<div style={{ margin: '0 3%', display: 'flex', alignItems: 'center' }}>~</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<input
										type="text"
										value={producedYearMaxInput}
										onChange={handleProducedYearInput}
										name="maximum"
										style={{
											display: 'flex',
											alignItems: 'center',
											padding: '12px',
											fontWeight: '500',
											fontSize: '12px',
											lineHeight: '14px',
											width: '100%',
											height: '38px',
											border: '1px solid #aab4c4',
											background: 'transparent',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={deleteAllProducedYear}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowProducedYearFilter(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterProducedYear;
