import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonHelper from 'helpers/CommonHelper';
import { getPasswordCheckApi } from 'apis/myPage';

import MyPage from '../../MyPage';

const PasswordCheckPage = withRouter(({ history }) => {
	const [passwordInputValue, setPasswordInputValue] = useState('');
	const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);

	const [isPasswordChecked, setIsPasswordChecked] = useState(false);

	const getPasswordCheck = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();

		if (authToken !== null && authToken !== undefined) {
			getPasswordCheckApi(passwordInputValue, authToken).then(
				({ data: { data, detail, status } }) => {
					if (status === 'SUCCESS') {
						if (data.status === true) {
							history.push('/mypage/modify');
							setIsPasswordChecked(true);
						} else {
							setIsPasswordCorrect(false);
						}
					} else {
						alert(detail);
					}
				},
			);
		}
	};

	const handlePasswordInputValue = (e) => {
		setPasswordInputValue(e.target.value);
	};

	return (
		<>
			{isPasswordChecked ? (
				<MyPage />
			) : (
				<div className="container mypage">
					<section className="inner">
						<div className="password_check">
							<h2>비밀번호 확인</h2>
							<p className="stit">
								회원님의 정보를 안전하게 보호하기 위해 <br />
								비밀번호를 다시 한번 더 확인합니다.
							</p>
							<div className="input_box">
								<p>비밀번호</p>
								<input
									type="password"
									value={passwordInputValue}
									placeholder="비밀번호를 입력해주세요"
									onChange={handlePasswordInputValue}
								/>
								{isPasswordCorrect ? (
									''
								) : (
									<p className="error_txt">비밀번호가 다릅니다. 다시 한번 확인해주세요.</p>
								)}
							</div>
							<div className="btn_wrap">
								<button className="ok" onClick={getPasswordCheck}>
									확인
								</button>
							</div>
						</div>
					</section>
				</div>
			)}
		</>
	);
});

export default PasswordCheckPage;
