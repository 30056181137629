import React, { useEffect, useState } from 'react';
import { useAppState } from 'context/AppContext';
import { getModifiedUserInfoApi } from 'apis/myPage';
import { getUserSessionApi } from 'apis/common';
import { uploadFile } from 'apis/uploadFile';
import CommonHelper from 'helpers/CommonHelper';
import ico_profile from 'assets-pc/img/common/ico_profile.svg';
import { SERVER_API_HOST } from 'common/environment';
import AuctionPopup from '../Popup/AuctionPopup';

import { useAppDispatch } from 'context/AppContext';

const ModifyInfoMenu = () => {
	const appDispatch = useAppDispatch();
	const userData = useAppState();

	//input state
	const [inputValues, setInputValues] = useState({
		loginId: '',
		password: '',
		rePassword: '',
		name: '',
		email: '',
		phone: '',
		nickName: '',
		agreeMail: '',
		agreeSms: '',
		userId: '',
		roles: [],
	});

	//img state
	const [file, setFile] = useState('');
	const [profileUrl, setProfileUrl] = useState('');
	const [isModifyConfirmModalOpened, setIsModifyConfirmModalOpened] = useState(false);

	//유효성검사
	const [errorText, setErrorText] = useState('');

	//전역 비구조화할당
	const {
		loginId,
		password,
		rePassword,
		name,
		email,
		nickName,
		phone,
		userId,
		agreeMail,
		agreeSms,
		roles,
	} = inputValues;

	//전역 - 토큰
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	useEffect(() => {
		const {
			loginId,
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			id,
			roles,
			profileUrl,
		} = userData.user.data;
		setInputValues({
			...inputValues,
			loginId,
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			userId: id,
			roles,
		});
		setProfileUrl(profileUrl);
	}, [userData]);

	// input change
	const handleValueChange = (e) => {
		const { id, value } = e.target;
		if (id === 'phone') {
			let deleteAll = value.replaceAll('-', '').replaceAll(/[^0-9]/g, '');
			setInputValues({
				...inputValues,
				[id]: deleteAll,
			});
		} else {
			setInputValues({
				...inputValues,
				[id]: value,
			});
		}
	};

	const handleModifyConfirm = () => {
		if (!password && !rePassword) {
			return setErrorText('비밀번호를 입력해주세요.');
		} else if (password !== rePassword) {
			return setErrorText('비밀번호가 일치하지 않습니다.');
		} else if (password && rePassword && !CommonHelper.passwordRules(password)) {
			return setErrorText('비밀번호는 20자 이내의 영문/숫자/특수문자를 모두 사용해주세요.');
		} else if (!CommonHelper.nameRule(name)) {
			return setErrorText('이름은 국문으로 2자 이상 입력해주세요.');
		} else if (!CommonHelper.emailIsValid(email)) {
			return setErrorText('이메일 형식이 올바르지 않습니다.');
		} else if (phone.length < 10) {
			return setErrorText('핸드폰 번호는 - 없이 10~11자를 입력해주세요.');
		} else if (!CommonHelper.nickNameRule(nickName)) {
			return setErrorText('닉네임은 국문으로 2자 이상 혹은 영문으로 4자 이상 입력해주세요.');
		} else {
			return (
				setErrorText(''),
				!!authToken &&
					getModifiedUserInfoApi(
						authToken,
						userId,
						password,
						name,
						email,
						phone,
						nickName,
						agreeMail,
						agreeSms,
						profileUrl,
					).then(({ data: { data, status, detail } }) => {
						if (status === 'SUCCESS') {
							getUserSessionApi(authToken).then(({ data: { status, detail } }) => {
								if (status === 'SUCCESS') {
									appDispatch({ type: 'UPDATE_USER_DATA', loggedIn: true, data: data || {} });
									setIsModifyConfirmModalOpened(true);
								} else {
									alert(detail);
								}
							});
						} else {
							alert(detail);
						}
					})
			);
		}
	};

	const handleModifyCancel = () => {
		const {
			loginId,
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			roles,
			profileUrl,
		} = userData.user.data;

		setInputValues({
			...inputValues,
			loginId,
			password: '',
			rePassword: '',
			name,
			email,
			phone,
			nickName,
			agreeMail,
			agreeSms,
			roles,
		});
		setFile('');
		setProfileUrl(profileUrl);
	};

	const handleModifyComplete = () => {
		setIsModifyConfirmModalOpened(!isModifyConfirmModalOpened);
		setInputValues({
			...inputValues,
			password: '',
			rePassword: '',
		});
		setFile('');
	};

	const handleCheckBox = (e) => {
		switch (e.target.id) {
			case 'agreeMail':
				setInputValues({ ...inputValues, agreeMail: !agreeMail });
				break;
			case 'agreeSms':
				setInputValues({ ...inputValues, agreeSms: !agreeSms });
				break;
		}
	};

	/**
	 * 이미지 업로드 함수
	 * @param {*} e
	 */
	const handleImgUpload = async (e) => {
		const oneFile = e.target.files[0];
		e.target.value = '';
		let gotPhotoInfo = await uploadFileAndGetUrl(oneFile);
		if (gotPhotoInfo) {
			let nextPhotoUrl = `${SERVER_API_HOST}${gotPhotoInfo.url}`;
			const copyFile = { ...file };
			copyFile.name = gotPhotoInfo.fileName;
			setFile(copyFile);
			setProfileUrl(nextPhotoUrl);
		}
	};

	const uploadFileAndGetUrl = async (oneFile) => {
		if (oneFile) {
			const { type: fileType, name: fileName, size: fileSize } = oneFile;
			const formData = new FormData();
			formData.append('file', oneFile);

			const uploadResult = await uploadFile(formData);
			const { status, data } = uploadResult;
			if (status === 200 && !!data) {
				return setFile(data.fileName), data;
			} else {
				alert('파일 업로드를 실패했습니다.');
				return null;
			}
		} else {
			return null;
		}
	};
	return (
		<>
			<div className="mypage_cont">
				<h2>정보수정</h2>
				<div className="info_editor">
					<h3>등급</h3>
					<div>
						<strong>{roles && roles.includes('AUCTION') ? '옥션등급' : '일반등급'}</strong>
						{roles && roles.includes('AUCTION') ? '' : <AuctionPopup />}
					</div>
				</div>
				<div className="info_editor">
					<h3>로그인 정보</h3>
					<div>
						<p className="t1">아이디</p>
						<input id="id" type="text" value={loginId} disabled />
						<p className="t1">비밀번호</p>
						<input
							id="password"
							type="password"
							placeholder="비밀번호"
							value={password}
							onChange={handleValueChange}
						/>
						<input
							id="rePassword"
							type="password"
							placeholder="비밀번호 확인"
							value={rePassword}
							onChange={handleValueChange}
						/>
					</div>
				</div>
				<div className="info_editor">
					<h3>개인 정보</h3>
					<div>
						<input
							id="name"
							type="text"
							placeholder="이름"
							value={name}
							onChange={handleValueChange}
						/>
						<input
							id="email"
							type="text"
							placeholder="이메일"
							value={email}
							onChange={handleValueChange}
						/>
						<input
							id="phone"
							type="text"
							placeholder="01012345678"
							value={phone}
							onChange={handleValueChange}
						/>
						<input
							id="nickName"
							type="text"
							placeholder="닉네임"
							value={nickName}
							onChange={handleValueChange}
						/>
						<div className="profile_img">
							<p>프로필 이미지 변경</p>
							<div className="change_box">
								<img
									src={profileUrl ? profileUrl : ico_profile}
									alt="profile image"
									style={{ width: '50px', height: '50px' }}
								/>

								<div className="filebox">
									<input className="upload-name" value={file ? file.name : '파일을 선택해주세요'} />
									<label htmlFor="input_file">파일선택</label>
									<input
										type="file"
										id="input_file"
										className="upload-hidden"
										accept="image,jpg,impge/png,image/jpeg, image/gif"
										onChange={handleImgUpload}
									/>
								</div>
							</div>
						</div>
						<label>
							<input id="agreeMail" type="checkbox" checked={agreeMail} onClick={handleCheckBox} />
							<span>정보 메일을 받겠습니다.</span>
						</label>
						<label>
							<input id="agreeSms" type="checkbox" checked={agreeSms} onClick={handleCheckBox} />
							<span>문자메세지를 받겠습니다.</span>
						</label>
					</div>
				</div>
				<p className="error_txt">{errorText}</p>
				<div className="btn_wrap">
					<button onClick={handleModifyCancel}>취소</button>
					<button className="ok" onClick={handleModifyConfirm}>
						수정
					</button>
				</div>
				{isModifyConfirmModalOpened ? (
					<div className="bg_dimmed" style={{ display: 'block' }}>
						<section className="alert">
							<p>정보수정이 완료되었습니다.</p>
							<div className="btn_wrap right">
								<button className="ok" onClick={handleModifyComplete}>
									확인
								</button>
							</div>
						</section>
					</div>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default ModifyInfoMenu;
