import React, { useEffect, useState } from 'react';
import { getReviewListApi } from '../../apis/review';
import ReviewList from './ReviewList';
import Pagination from './Pagination';
import ReviewDetail from './ReviewDetail';

const Review = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isCheckedPage, setIsCheckedPage] = useState(false);
	const [noticeData, setNoticeData] = useState({});
	const [oneNoticeData, setOneNoticeData] = useState({});
	const [openDetail, setOpenDetail] = useState(false);
	const [page, setPage] = useState(0);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const LIMIT = 10;

	useEffect(() => {
		isCheckedPage && getNoticeList();
	}, [page]);

	useEffect(() => {
		checkPage();
	}, []);

	const checkPage = () => {
		let storePage = sessionStorage.getItem('noticePage');
		if (storePage) {
			setPage(Number(storePage));
		}
		sessionStorage.removeItem('noticePage');
		getNoticeList();
		setIsCheckedPage(true);
	};

	const getNoticeList = () => {
		try {
			setIsLoading(true);
			getReviewListApi(LIMIT, page).then(({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setNoticeData(data || {});
				} else {
					alert(detail);
				}
				setIsLoading(false);
			});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const { totalPage } = noticeData;

	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);

	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setPage(num);
	};

	const prevPage = () => {
		if (page + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((page + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setPage(page - 1);
	};

	const nextPage = () => {
		if (page + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setPage(page + 1);
			if ((page + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};

	/**
	 * new마크 계산
	 */
	// const newDate =
	// 	noticeData.content &&
	// 	noticeData.content.map((data) => {
	// 		const date = new Date();
	// 		const dataDate = new Date(data.createdAt);
	// 		const diff = (date.getTime() - dataDate.getTime()) / 1000 / 60 / 60 / 24 < 1;
	// 		return diff ? diff : false;
	// 	});
	return (
		<>
			<div className="container notice">
				<section className="sub_top">
					<div className="sub_top_mini_img"></div>
					<h1 style={{ zIndex: '100' }}>리뷰pick</h1>
					<p style={{ zIndex: '100' }}></p>
				</section>
				<section className="inner">
					{openDetail && oneNoticeData ? (
						<ReviewDetail oneNoticeData={oneNoticeData} setOpenDetail={setOpenDetail} />
					) : (
						<>
							{isLoading ? (
								<div className="notice_list">
									<ul>
										<li className="tit">
											<span>제목</span>
											<span>작성일</span>
										</li>
										<li>등록된 이벤트를 불러오고 있습니다...</li>
									</ul>
								</div>
							) : (
								<>
									{noticeData?.content?.length > 0 && (
										<ReviewList
											noticeData={noticeData}
											setOpenDetail={setOpenDetail}
											setOneNoticeData={setOneNoticeData}
											prevPage={prevPage}
											paginate={paginate}
											nextPage={nextPage}
											newPageNumbers={newPageNumbers}
											page={page}
										/>
									)}
									{noticeData?.content?.length === 0 && (
										<div className="notice_list">
											<ul>
												<li className="tit">
													<span>제목</span>
													<span>작성일</span>
												</li>
												<li>등록된 이벤트가 없습니다.</li>
											</ul>
										</div>
									)}
								</>
							)}
						</>
					)}
				</section>
			</div>
		</>
	);
};

export default Review;
