import { SERVER_API_HOST } from 'common/environment';
import { apiCall } from './apiCall';

// 메인에 예약 리스트
export const getHaplMainListApi = (store, authToken, page) => {
	const id = !!store ? store.id : null;
	const token = !!authToken ? authToken : null;
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/list?page=${page}&offset=10&${
			id ? `&storeId=${id}` : ''
		}`,
		'get',
		{},
		{
			authorization: token,
		},
	);
};
export const getMobileHaplMainListApi = (store, authToken, page) => {
	const id = !!store ? store.id : null;
	const token = !!authToken ? authToken : null;
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/list?page=${page}&offset=5&${
			id ? `&storeId=${id}` : ''
		}`,
		'get',
		{},
		{
			authorization: token,
		},
	);
};
// 월별 예약 현황리스트
export const getMonthReservationInfoApi = (storeId, authToken, calendarMonth) => {
	const token = !!authToken ? authToken : null;
	//standardDay=2022-01-25
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/time/list?storeId=${storeId}&includePast=false&standardDay=${calendarMonth}-01`,
		'get',
		{},
		{
			authorization: token,
		},
	);
};

// 방문예약 하플 생성
export const postReserveHaplApi = (reservationForm, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation`,
		'post',
		{ ...reservationForm },
		{
			authorization: authToken,
		},
	);
};

// 예약 단건 조회
export const getReservationDetailInfoApi = (id, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/get/${id}`,
		'get',
		{},
		{
			authorization: authToken,
		},
	);
};

// 예약 삭제
export const deleteOneHaplApi = (id, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/cancel/${id}`,
		'delete',
		{},
		{
			authorization: authToken,
		},
	);
};

// 예약 변경
export const changeReserveHaplApi = (id, form, authToken) => {
	return apiCall(`${SERVER_API_HOST}/api/visit/reservation/update/${id}`, 'put', form, {
		authorization: authToken,
	});
};

export const getOwnReservationListApi = (page, authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/visit/reservation/my/list?page=0&offset=3&direction=desc&property=createdAt`,
		'get',
		{},
		{
			authorization: authToken,
		},
	);
};
