import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
	loading: false,
	user: {
		loggedIn: false,
		data: {
			roles: ['GUEST'],
			loginId:'guest'
		},
	},
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGGED_IN':
			return {
				...state,
				user: {
					loggedIn: action.loggedIn,
					data: action.data,
				},
			};
		case 'GUEST_IN':
			return {
				...state,
				user: {
					loggedIn: false,
					data: action.data,
				},
			};
		case 'LOGGED_OUT':
			return initialState;
		case 'UPDATE_USER_DATA':
			return {
				...state,
				user: {
					data: action.data,
				},
			};
		case 'LOADING':
			return {
				...state,
				loading: true,
			};
		case 'LOADED':
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(appReducer, initialState);
	return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppState = () => {
	const { state } = useContext(AppContext);
	return state;
};

export const useAppDispatch = () => {
	const { dispatch } = useContext(AppContext);
	return dispatch;
};
