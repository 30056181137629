import React from 'react';

const Buypagination = ({ newPageNumbers, prevPage, buyPage, paginate, nextPage }) => {
	return (
		<>
			<div className="paging">
				<a className="btn prev" onClick={() => prevPage()} />
				{newPageNumbers &&
					newPageNumbers.map((number) => {
						return (
							<a
								key={number}
							
								className={number === buyPage + 1 ? 'num' : ''}
								onClick={() => paginate(number)}
							>
								{number}
							</a>
						);
					})}
				<a className="btn next" onClick={() => nextPage()} />
			</div>
		</>
	);
};

export default Buypagination;
