import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getFaqListApi = (page, text) => {
	return apiCall(
		`${SERVER_API_HOST}/api/faq?`,
		'get',
		{
			offset: 10,
			faqType: 'NORMAL',
			page,
			text,
		},
		{},
	);
};

export const getFaqKeywordsListApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/site/info?`, 'get', { size: 10000 }, {});
};
