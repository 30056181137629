import React, { useState, useEffect, Children } from 'react';
import { Link } from 'react-router-dom';
import { MYPAGE_MENU } from 'constants/constants';
import LogoutPopup from './components/ModifyInfoMenu/components/LogoutPopup';
import WithdrawalPopup from './components/ModifyInfoMenu/components/WithdrawalPopup';
import MypageAuctionPopup from './components/Popup/MypageAuctionPopup';
import ico_profile from 'assets-pc/img/common/ico_profile.svg';
import { useAppState } from 'context/AppContext';

import ModifyInfoMenu from './components/ModifyInfoMenu/ModifyInfoMenu';

const MyPage = ({ child }) => {
	const currentPage = document.location.pathname;
	const userData = useAppState();

	const { name, profileUrl, roles } = userData && userData.user.data;
	const [myPageMenu, setMyPageMenu] = useState('');

	useState(() => {
		setMyPageMenu('');
	}, [!myPageMenu]);

	const selectMenu = (koMenu) => {
		setMyPageMenu(koMenu);
	};
	return (
		<>
			<div className="container mypage">
				<section className="inner">
					<div className="mypage_info">
						<div className="profile_nav">
							<img
								src={!!profileUrl ? profileUrl : ico_profile}
								alt="profile image"
								style={{ width: '50px', height: '50px', borderRadius: '70%' }}
							/>
							<div>
								<strong>{name && name}님,</strong>
							</div>
							<div>환영합니다.</div>
							<ul>
								<li>
									<strong className="grade">
										{roles && roles.includes('AUCTION') ? '옥션등급' : '일반등급'}
									</strong>
									{roles && roles.includes('AUCTION') ? '' : <MypageAuctionPopup />}
								</li>
								{MYPAGE_MENU.map((menu, idx) =>
									currentPage === `/mypage/${menu.enMenu}` ? (
										<li
											className="on"
											key={idx}
											onClick={() => selectMenu(menu.koMenu, menu.enMenu)}
											style={{ cursor: 'pointer' }}
										>
											<Link to={`/mypage/${menu.enMenu}`}>{menu.koMenu}</Link>
										</li>
									) : (
										<li
											className=""
											key={idx}
											onClick={() => selectMenu(menu.koMenu, menu.enMenu)}
											style={{ cursor: 'pointer' }}
										>
											<Link to={`/mypage/${menu.enMenu}`}>{menu.koMenu}</Link>
										</li>
									),
								)}
							</ul>
							<LogoutPopup />
							<WithdrawalPopup />
						</div>
						{currentPage === '/mypage/modify' ? <ModifyInfoMenu /> : child && child()}
					</div>
				</section>
			</div>
		</>
	);
};

export default MyPage;
