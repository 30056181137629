import { SERVER_API_HOST } from 'common/environment';
import { apiCall } from './apiCall';

export const postSignUpApi = (
	id,
	password,
	email,
	nickName,
	phoneNumber,
	name,
	agreeMail,
	agreeSms,
) => {
	return apiCall(
		`${SERVER_API_HOST}/api/public/join`,
		'post',
		{
			loginId: id,
			password: password,
			name: name,
			email: email,
			phone: phoneNumber,
			nickName: nickName,
			agreeMail: agreeMail,
			agreeSms: agreeSms,
		},
		{
			ContentType: 'application/json',
		},
	);
};
