import React, { useEffect, useState } from 'react';
import { getFilterApi, postFilterApi } from 'apis/filter';
import { useAppDispatch, useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';
import { getUserSessionApi } from 'apis/common';

const MyFilter = ({ setShowMyFilter }) => {
	const [filterList, setFilterList] = useState([]);
	const [newFilterList, setNewFilterList] = useState([]);
	// 5개이상 클릭하려고 할 때 모달안내
	const [maxChecked, setMaxChecked] = useState(false);
	const appDispatch = useAppDispatch();
	const appState = useAppState();
	// 필터리스트만 반환
	const appfilter = appState?.user?.data && appState.user.data.favoriteFilters;
	// 수정하기 클릭 시 alert Modal
	const [modifyModal, setModifyModal] = useState(false);

	useEffect(() => {
		getFilterList();
	}, []);

	const getFilterList = () => {
		getFilterApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				if (data) {
					let findUseFilter = data.filter(
						(el) =>
							el.key === 'BRAND' ||
							el.key === 'MODEL' ||
							el.key === 'CONDITION' ||
							el.key === 'PRODUCED_YEAR' ||
							el.key === 'GENDER' ||
							el.key === 'CASE_DIAMETER' ||
							el.key === 'MOVEMENT' ||
							el.key === 'SUPPLEMENTS' ||
							el.key === 'CASE_MATERIAL' ||
							el.key === 'REFERENCE' ||
							el.key === 'DIAL_COLOR' ||
							el.key === 'DIAL_TYPE' ||
							el.key === 'STORE',
					);
					setFilterList(findUseFilter ? findUseFilter : []);
				}
			} else {
				alert(detail);
			}
		});
	};
	useEffect(() => {
		if (filterList && appfilter) {
			getNewFilterData();
		}
	}, [appfilter, filterList]);

	// 새로운 필터리스트로 반환하는 함수
	const getNewFilterData = () => {
		const newFilterData =
			filterList &&
			filterList.map((value) =>
				appfilter.includes(value.key) ? { ...value, favorite: true } : { ...value },
			);
		setNewFilterList(newFilterData);
	};
	// value가 true인것의 key값모음
	const selectedCheckBox =
		newFilterList &&
		newFilterList.filter((value) => value.favorite === true).map((value) => value.key);
	const MAXCOUNT = 5;

	// filterData받아온거 일반/기능을 기준으로 나누기
	const generalFilterNameList = [
		'BRAND',
		'MODEL',
		'CONDITION',
		'PRICE',
		'PRODUCED_YEAR',
		'GENDER',
		'SERIAL_NO',
		'COMPONENTS',
		'STORE',
	];

	const handleCheckBox = (e) => {
		const { name, checked } = e.target;
		if (selectedCheckBox.length >= MAXCOUNT && checked) {
			alert('필터 즐겨찾기는 5개까지 가능합니다.');
		} else if (selectedCheckBox.length < MAXCOUNT) {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: checked } : { ...value };
			});
			setNewFilterList(convert);
		} else if (selectedCheckBox.length === MAXCOUNT && !checked) {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: false } : { ...value };
			});
			setNewFilterList(convert);
		} else {
			const convert = newFilterList.map((value) => {
				return value.key === name ? { ...value, favorite: false } : { ...value };
			});
			setNewFilterList(convert);
			setMaxChecked(true);
		}
	};

	const handleBtn = () => {
		setMaxChecked(false);
		setModifyModal(false);
	};

	const goToModify = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			postFilterApi(selectedCheckBox, authToken).then(({ data: { data, detail, status } }) => {
				if (status === 'SUCCESS') {
					getUserSessionApi(authToken).then(({ data }) => {
						if (data?.status === 'SUCCESS') {
							appDispatch({ type: 'UPDATE_USER_DATA', data: data?.data || {} });
							// setModifyModal(true);
							alert('적용이 완료되었습니다.')
						} else {
							alert(data?.detail);
							//todo
						}
					});
				} else {
					alert(detail);
					//alert("오류안내")
				}
			});
		}
	};

	const clearNewFilterList = () => {
		let copyNewFilterList = [...newFilterList];
		for (let i = 0; i < copyNewFilterList.length; i++) {
			copyNewFilterList[i].favorite = false;
		}

		setNewFilterList(copyNewFilterList);
	};
	return (
		<>
			{/* {modifyModal && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t1">
							적용이 완료되었습니다.
						</p>
						<button className="btn_ok" onClick={() => setModifyModal(false)}>확인</button>
					</article>
				</div>
			)} */}
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowMyFilter(false)}></button>
					<h2>필터관리</h2>
				</section>

				<section className="filter_managemenr">
					<div className="category_tit">
						<b>기본필터</b>
						<span>
							(
							{
								newFilterList.filter(
									(value) => generalFilterNameList.includes(value.key) && value.favorite,
								).length
							}
							개 항목)
						</span>
					</div>
					<ul>
						{newFilterList
							.filter((value) => generalFilterNameList.includes(value.key))
							.map((value, index) => {
								return (
									<li key={index}>
										<label>
											<span>{value.name}</span>
											<input
												type="checkbox"
												name={value.key}
												onChange={handleCheckBox}
												checked={value.favorite}
											/>
										</label>
									</li>
								);
							})}
					</ul>
					<div className="category_tit">
						<b>특징 및 기능</b>
						<span>
							(
							{
								newFilterList.filter(
									(value) => !generalFilterNameList.includes(value.key) && value.favorite,
								).length
							}
							개 항목)
						</span>
					</div>
					<ul>
						{newFilterList
							.filter((value) => !generalFilterNameList.includes(value.key))
							.map((value, index) => {
								return (
									<li key={index}>
										<label>
											<span>{value.name}</span>
											<input
												type="checkbox"
												name={value.key}
												onChange={handleCheckBox}
												checked={value.favorite}
											/>
										</label>
									</li>
								);
							})}
					</ul>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={clearNewFilterList}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={goToModify}>
							적용
						</button>
					</div>
				</section>
			</main>
		</>
	);
};
export default MyFilter;
