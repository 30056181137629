import React, { useState } from 'react';
import Buypagination from './Buypagination';

const BuyReservation = ({ buyData, buyPage, setBuyPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = buyData;
	const pageNumbers = [];

	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);
	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setBuyPage(num);
	};
	const prevPage = () => {
		if (buyPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((buyPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setBuyPage(buyPage - 1);
	};

	const nextPage = () => {
		if (buyPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setBuyPage(buyPage + 1);
			if ((buyPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<table className="tbl">
				<colgroup>
					<col style={{ width: '5%' }} />
					<col style={{ width: '7%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '7%' }} />
				</colgroup>
				<thead>
					<tr>
						<th>번호</th>
						<th>구분</th>
						<th>신청일</th>
						<th>상품명</th>
						<th>방문지점</th>
						<th>예약일정</th>
						<th>상태</th>
					</tr>
				</thead>
				<tbody>
					{buyData?.content?.length > 0 ? (
						buyData.content &&
						buyData.content.map((data, index) => {
							return (
								<tr key={index}>
									<td>{data.id ? data.id : '-'}</td>
									<td>
										<span className="type">구매</span>
									</td>
									<td>{data?.createdAt ? data.createdAt.split('T')[0] : '-'}</td>
									<td>
										<b>{data?.watch?.model ? data.watch.model : '-'}</b>
									</td>
									<td>{data?.store?.name ? data.store.name : '-'}</td>
									<td>
										{data?.visitDateTime
											? data.visitDateTime.split('T').toString().slice(0, 16).replace(',', ' ')
											: '-'}
									</td>
									<td>
										<span
											style={{ width: '55px' }}
											className={data?.state === 'VISITED' ? 'state cancel' : 'state complete'}
										>
											{data?.state === 'REGISTERED'
												? '고객등록'
												: 'APPROVED'
												? '승인'
												: 'VISITED'
												? '매장 방문'
												: '-'}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr className="empty">
							<td colSpan="7">
								<p>최근 예약내역이 없습니다.</p>
							</td>
						</tr>
					)}
					{/* <tr>
						<td>2</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr>
					<tr>
						<td>3</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state complete">완료</span>
						</td>
					</tr>
					<tr>
						<td>4</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr>
					<tr>
						<td>5</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr>
					<tr>
						<td>6</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr>
					<tr>
						<td>7</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr>
					<tr>
						<td>8</td>
						<td>
							<span className="type">구매</span>
						</td>
						<td>2020-11-23</td>
						<td>
							<b>롤렉스 서브마리너</b>
						</td>
						<td>서울</td>
						<td>2020-11-23 01~02시</td>
						<td>
							<span className="state cancel">취소</span>
						</td>
					</tr> */}
				</tbody>
			</table>
			{buyData && buyData?.content?.length > 0 ? (
				<Buypagination
					newPageNumbers={newPageNumbers}
					prevPage={prevPage}
					buyPage={buyPage}
					paginate={paginate}
					nextPage={nextPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default BuyReservation;
