import React, { useState } from 'react';
import MobileHeader from '../MobileHeader';
import MobileFooter from '../MobileFooter';
import MobileNav from '../MobileNav';
// import 'assets-mobile/css/common.css';
import 'assets-mobile/css/font.css';
// import 'assets-mobile/css/layout.css';
import 'assets-mobile/scss/common.scss';
import 'assets-mobile/scss/font.scss';
import 'assets-mobile/css/frontEnd.scss';
import 'assets-mobile/scss/layout.scss';

const MobileLayout = ({ children }) => {
	const [mobileNav, setMobileNav] = useState(false);
	return (
		<React.Fragment>
			<div className="bg_dimmed"></div>
			<div className="mobile-wrapper" style={{ overflowX: 'hidden' }}>
				<MobileHeader mobileNav={mobileNav} setMobileNav={setMobileNav} />
				{mobileNav ? (
					<MobileNav />
				) : (
					<>
						<div>{children}</div>
						<div>{window?.location?.pathname === '/' && <MobileFooter />}</div>
					</>
				)}
			</div>
		</React.Fragment>
	);
};

export default MobileLayout;
