import React, { useState, useEffect } from 'react';
import HaplSlider from './HaplSlider';
import { useHistory } from 'react-router';
// import CommonPagination from 'components/CommonPagination';
import CommonHelper from 'helpers/CommonHelper';
import { getHaplMainListApi } from 'apis/hapl';
import { HAPLE_STATUS } from 'common/constants';
import Pagination from 'components/Notice/Pagination';

const HaplMain = ({
	filterSelectedStore,
	setFilterSelectedStore,
	setCurrStep,
	setOneStoreReservation,
	mainReservedInfo,
	DEFAULT_STORE_SELECT,
	goStep1,
	setDetailId,
	storeList,
	currStep,
	setMainReservedInfo,
}) => {
	const [openSelect, setOpenSelect] = useState(false);
	const history = useHistory();
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const LIMIT = 10;
	const [page, setPage] = useState(0);
	// const [storeList, setStoreList] = useState([]);
	const handleSelect = () => {
		setOpenSelect(!openSelect);
	};
	// useEffect(() => {
	// 	getStoreList();
	// }, []);
	const goDetailPage = (id) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('로그인 후 조회가 가능합니다.');
			history.push('/signin?redirectUrl=/hapl');
		} else {
			setDetailId(id);
			setCurrStep('detail');
		}
	};

	// useEffect(() => {
	// 	if (currStep === 'main') {
	// 		getMainList();
	// 	}
	// }, [filterSelectedStore, page, currStep]);
	useEffect(() => {
		getMainList(filterSelectedStore, 0);
	}, []);
	const { totalPage } = mainReservedInfo;

	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);

	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setPage(num);
		getMainList(filterSelectedStore, num);
	};

	const prevPage = () => {
		if (page + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((page + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setPage(page - 1);
		getMainList(filterSelectedStore, page - 1);
	};
	const nextPage = () => {
		if (page + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setPage(page + 1);
			if ((page + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
		getMainList(filterSelectedStore, page + 1);
	};

	const changeSelectedStore = (store) => {
		setFilterSelectedStore(store);
		setPage(0);
		setStart(0);
		setEnd(4);
		getMainList(store, 0);
	};
	return (
		<>
			<HaplSlider
				storeList={storeList}
				setCurrStep={setCurrStep}
				setOneStoreReservation={setOneStoreReservation}
				goStep1={goStep1}
			/>
			<section className="reservation-list-wrapper">
				<div className="reservation-top">
					<span className="reservation-notice">
						<span className="dot">·</span> 당일 예약취소는 오전 11시까지 반드시 해당매장으로
						전화연결 후 매니저와 상담해주세요.
					</span>
					<div className="select-store" onClick={handleSelect}>
						<span className="selected-name">{filterSelectedStore.name}</span>
						<div alt="triangle img" className={openSelect ? 'triangle-img up' : 'triangle-img'} />
						{!!openSelect ? (
							<div className="select-list">
								<div
									className="oneSelect"
									onClick={() => changeSelectedStore(DEFAULT_STORE_SELECT)}
								>
									{DEFAULT_STORE_SELECT.name}
								</div>
								{storeList.map((store, idx) => (
									<div key={idx} className="oneSelect" onClick={() => changeSelectedStore(store)}>
										{store.name}
									</div>
								))}
							</div>
						) : (
							''
						)}
					</div>
				</div>
				<div className="reservation-table">
					<table>
						<thead>
							<tr>
								<th>신청자</th>
								<th>직영점</th>
								<th>이용일자</th>
								<th>이용시간</th>
								<th>예약상태</th>
							</tr>
						</thead>
						{mainReservedInfo?.content?.length > 0 ? (
							<tbody>
								{mainReservedInfo?.content.map((list, idx) => (
									<tr key={idx} onClick={() => goDetailPage(list.id)}>
										<td>{list?.name ? CommonHelper.masking(list.name) : ''}</td>
										<td>{list.store.name}</td>
										<td>{list.day}</td>
										<td>{list.timeStr}</td>
										<td>
											<div
												style={
													list.status === 'WAIT'
														? {
																margin: '0 auto',
																width: '100px',
																height: '33px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																backgroundColor: 'rgba(185, 160, 126, 1)',
																color: 'white',
														  }
														: list.status === 'CANCEL'
														? {
																margin: '0 auto',
																width: '100px',
																height: '33px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																backgroundColor: 'rgba(145, 151, 207, 1)',
																color: 'white',
														  }
														: {}
												}
											>
												{HAPLE_STATUS[list?.status] || '-'}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						) : (
							<div className="no-list">예약 리스트가 아직 없습니다.</div>
						)}
					</table>
					<Pagination
						// noticeData={noticeData}
						prevPage={prevPage}
						paginate={paginate}
						nextPage={nextPage}
						newPageNumbers={newPageNumbers}
						page={page}
					/>
				</div>
			</section>
		</>
	);
	// 메인화면 예약 현황 리스트
	function getMainList(store, page) {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getHaplMainListApi(store, authToken, page).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setMainReservedInfo(data || {});
			} else {
				alert(detail);
			}
		});
	}
};

export default HaplMain;
