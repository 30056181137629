import React from 'react';
import {useHistory} from 'react-router-dom'
import WarrantyTop from 'assets-mobile/img/warranty/warranty_top.png';
import ImgSell from 'assets-mobile/img/warranty/img_sell.png';
import Line from 'assets-mobile/img/warranty/line.svg';
import ImgBuy from 'assets-mobile/img/warranty/img_buy.png';
import Private1 from 'assets-mobile/img/warranty/private_01.svg';
import Private2 from 'assets-mobile/img/warranty/private_02.svg';
import Private3 from 'assets-mobile/img/warranty/private_03.svg';
import Private4 from 'assets-mobile/img/warranty/private_04.svg';
import SellSystem from 'assets-mobile/img/warranty/sell_system.png';
import BuySystem from 'assets-mobile/img/warranty/buy_system.png';
import ImgSystem from 'assets-mobile/img/warranty/img_system.png';

const Guarantee = () => {
	const history = useHistory();
	return (
		<>
			<main className="warranty">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => history.goBack()}></button>
					<h2>보증거래</h2>
				</section>

				<section className="warranty_top">
					<img src={WarrantyTop} alt="" />
					<p className="t1">하이 시간에서 안전하고 간편하게</p>
					<p className="t2">보증거래 하실 수 있도록 도와드립니다.</p>
				</section>

				<section className="warranty_info">
					<h3>개인직거래</h3>
					<p className="t1">판매자와 구매자의 개인간 직거래인 경우</p>
					<p className="t2">
						하이시간의 보증거래 시스템은 판매자와 구매자 <br />
						개인간의 직거래 안전 보장 시스템입니다.
					</p>
					<div className="direct_box">
						<img src={ImgSell} alt="" />
						<div>
							<p>
								하이시간 <br />
								보증거래 시스템 <br />
								(안전거래)
							</p>
							<img src={Line} alt="" />
							<p>개인직거래</p>
						</div>
						<img src={ImgBuy} alt="" />
					</div>
					<ul className="direct_list">
						<li>
							<span>전문가 감정</span>
							<img src={Private1} alt="" />
						</li>
						<li>
							<span>전자보증서</span>
							<img src={Private2} alt="" />
						</li>
						<li>
							<span>안전결제</span>
							<img src={Private3} alt="" />
						</li>
						<li>
							<span>사후관리</span>
							<img src={Private4} alt="" />
						</li>
					</ul>

					<h3>위탁판매</h3>
					<p className="t1">정해진 구매자 없이 위탁 판매를 의뢰한 경우</p>
					<p className="t2">
						정해진 구매자가 없을 때 하이시간 거래소를 통해 <br />
						위탁판매를 하실 수 있게 안내해드립니다.
					</p>
					<div className="img_box">
						<img src={SellSystem} alt="" />
					</div>

					<h3>위탁구매</h3>
					<p className="t1">정해진 판매자 없이 위탁으로 구매를 할 경우</p>
					<p className="t2">
						정해진 판매자가 없을 때 하이시간 거래소에서 위탁 <br />
						구매하실 수 있게 안내해드립니다.
					</p>
					<div className="img_box">
						<img src={BuySystem} alt="" />
					</div>

					<h3>사후관리 시스템</h3>
					<p className="t1">고객에게 신뢰받는 기업 - 하이시간</p>
					<p className="t2">
						하이시간에서 최적의 고객 맞춤 케어서비스를 <br />
						제공 받으실 수 있습니다
					</p>
					<div className="system_box">
						<h4>(주) 하이시간 / 사후 관리 시스템</h4>
						<img src={ImgSystem} alt="" />
						<ul>
							<li>보증서(카드) 발급</li>
							<li>데이터 베이스 저장(회원정보)</li>
							<li>시리얼번호로 도난품 거래방지</li>
							<li>정기수리 / 점검 정보 안내</li>
							<li>시계 구매, 판매, 수리 이력 저장</li>
						</ul>
					</div>
				</section>
			</main>
		</>
	);
};

export default Guarantee;
