import React from 'react';
import { useSiteState } from 'context/SiteContext';

const FilterCaseMaterial = ({
	setShowCaseMaterial,
	selectedCaseMaterial,
	setSelectedCaseMaterial,
	handleCheckBoxCommon,
}) => {
	const siteState = useSiteState()?.data;
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowCaseMaterial(false)}></button>
					<h2>케이스 소재</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									케이스 소재
									<div style={{ fontSize: '3.8vw', opacity: '0.5', fontWeight: '500' }}>
										선택 소재({selectedCaseMaterial.length || 0}건)
									</div>
								</h3>
							</div>
							<ul>
								{siteState?.caseMaterials?.map((material, idx) => (
									<li key={idx}>
										<b>{material || '-'}</b>
										<input
											type="checkbox"
											name={material || '-'}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedCaseMaterial, setSelectedCaseMaterial)
											}
											checked={selectedCaseMaterial.includes(material)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedCaseMaterial([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowCaseMaterial(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterCaseMaterial;
