import React, { useState, useEffect } from 'react';
import BuyReservation from './components/BuyReservation';
import SellReservation from './components/SellReservation';
import { getBuyReservationApi, getSellReservationApi } from 'apis/reservation';
import { useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';

const MyReservationList = ({ setShowMyRerservation }) => {
	const appState = useAppState();
	const userId = appState && appState.user.data.id;
	const OFFSET = 15;
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();
	const [buyData, setBuyData] = useState({});
	const [sellData, setSellData] = useState({});
	const [btnValue, setBtnValue] = useState('buy');
	const [buyPage, setBuyPage] = useState(0);
	const [sellPage, setSellPage] = useState(0);

	useEffect(() => {
		getBuyReservationData();
	}, [buyPage]);

	const getBuyReservationData = () => {
		getBuyReservationApi(authToken, userId, OFFSET, buyPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setBuyData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};
	useEffect(() => {
		getSellReservationData();
	}, [sellPage]);

	const getSellReservationData = () => {
		getSellReservationApi(authToken, userId, OFFSET, sellPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setSellData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};

	const handleBtn = (e) => {
		const { name } = e.target;
		if (name === 'buy') {
			setBtnValue(name);
		}
		if (name === 'sell') {
			setBtnValue('sell');
		}
	};
	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowMyRerservation(false)}></button>
					<h2>예약내역</h2>
				</section>

				<section className="booking history">
					<div className="tab_wrap">
						<a className={btnValue === 'buy' ? 'on' : ''} name="buy" onClick={handleBtn}>
							구매
						</a>
						<a
							className={btnValue === 'sell' ? 'on ' : ''}
							name="sell"
							onClick={handleBtn}
						>
							판매
						</a>
					</div>
					{/* <p className="txt">취소 관련 문의는 예약지점으로 전화 문의해주세요.</p> */}
					<p className="txt">* 예약을 취소하실 경우 전화주세요. 1644-5751</p>
					{btnValue === 'buy' ? (
						<BuyReservation buyData={buyData} buyPage={buyPage} setBuyPage={setBuyPage} />
					) : (
						<SellReservation sellData={sellData} sellPage={sellPage} setSellPage={setSellPage} />
					)}
					{/* <ul className="history_list">
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">
								Rolex Black Dial <br />
								Submar-iner41mm
							</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status cancel">취소</button>
						</li>
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">Rolex Black Dial Submar-iner41mm</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status cancel">취소</button>
						</li>
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">
								Rolex Black Dial Submar- <br />
								iner41mm Rolex Black Dial
								<br /> Submar-iner41mm
							</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status">완료</button>
						</li>
					</ul>
					 */}
					{/* <ul className="history_list">
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">
								Rolex Black Dial <br />
								Submar-iner41mm
							</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status cancel">취소</button>
						</li>
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">Rolex Black Dial Submar-iner41mm</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status cancel">취소</button>
						</li>
						<li>
							<p className="date">2020-11-23</p>
							<p className="watch_name">
								Rolex Black Dial Submar- <br />
								iner41mm Rolex Black Dial
								<br /> Submar-iner41mm
							</p>
							<dl>
								<div>
									<dt>방문지점</dt>
									<dd>서울직영점</dd>
								</div>
								<div>
									<dt>예약일</dt>
									<dd>2020-11-23 01~02시</dd>
								</div>
							</dl>
							<button className="status">완료</button>
						</li>
					</ul> */}
				</section>
			</main>
		</>
	);
};
export default MyReservationList;
