import React from 'react';

const FilterGender = ({
	setShowGenderFilter,
	selectedGender,
	setSelectedGender,
	handleCheckBoxCommon,
}) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowGenderFilter(false)}></button>
					<h2>성별</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									성별
								</h3>
							</div>
							<ul>
								<li>
									<b>남성</b>
									<input
										type="checkbox"
										name="남성"
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
										checked={selectedGender.includes('남성')}
									/>
								</li>
								<li>
									<b>여성</b>
									<input
										type="checkbox"
										name="여성"
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
										checked={selectedGender.includes('여성')}
									/>
								</li>
								<li>
									<b>남녀공용</b>
									<input
										type="checkbox"
										name="남녀공용"
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
										checked={selectedGender.includes('남녀공용')}
									/>
								</li>
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedGender([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowGenderFilter(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterGender;
