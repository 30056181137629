import { useState, useEffect, useRef } from 'react';

export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // delay 후 value 업데이트
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // value 바뀌면 timeout 취소 & unmount 혹은 변화 delay
      //delay 기간동안 timeout은 없어지고 다시 시작
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // value나 delay가 바뀌면 다시
  );

  return debouncedValue;
}
