import React from 'react';
import instagramImg from 'assets-pc/img/common/img_instagram.svg';
import facebookImg from 'assets-pc/img/common/img_facebook.svg';
import twitterImg from 'assets-pc/img/common/img_twitter.svg';
import kakaotalkImg from 'assets-pc/img/common/img_kakaotalk.svg';
import blogImg from 'assets-pc/img/common/img_blog.svg';
import mailImg from 'assets-pc/img/common/img_mail.svg';

const Footer = () => {
	return (
		<>
			<footer>
				<div className="big_frame">
					<nav style={{ position: 'relative' }}>
						<ul className="link">
							<li>
								<a href="/termsOfService" style={{ fontWeight: 'bold' }}>
									이용약관
								</a>
							</li>
							<li>
								<a href="/personalInfo" style={{ fontWeight: 'bold' }}>
									개인정보취급방침
								</a>
							</li>
							{/* <li>
							<a>구매 전 필독사항</a>
						</li> */}
						</ul>
						<ul style={{ position: 'absolute', right: '0', top: '0', lineHeight: '25px' }}>
							<li>팔로우하기</li>
							<li style={{ fontWeight: '500', opacity: '0.7' }}>
								<a href="https://cafe.naver.com/hisigan" target="_blank">
									NAVER CAFE
								</a>
							</li>
							<li style={{ fontWeight: '500', opacity: '0.7' }}>
								<a href="https://blog.naver.com/hi_sigan" target="_blank">
									NAVER BLOG
								</a>
							</li>
							<li style={{ fontWeight: '500', opacity: '0.7' }}>
								<a href="https://www.instagram.com/hisigan_official/" target="_blank">
									INSTAGRAM
								</a>
							</li>
							<li style={{ fontWeight: '500', opacity: '0.7' }}>
								<a href="https://www.youtube.com/channel/UChurZ73ZijJ3wSSplPlXE5g" target="_blank">
									YOUTUBE
								</a>
							</li>
							<li style={{ fontWeight: '500', opacity: '0.7' }}>
								<a href="https://pf.kakao.com/_dnSxgs" target="_blank">
									KAKAO Channel
								</a>
							</li>
						</ul>
						{/* <ul className="sns_list">
						<li>
							<a>
								<img src={instagramImg} alt="instagram" />
							</a>
						</li>
						<li>
							<a>
								<img src={facebookImg} alt="facebook" />
							</a>
						</li>
						<li>
							<a>
								<img src={twitterImg} alt="twitter" />
							</a>
						</li>
						<li>
							<a>
								<img src={kakaotalkImg} alt="kakaotalk" />
							</a>
						</li>
						<li>
							<a>
								<img src={blogImg} alt="blog" />
							</a>
						</li>
						<li>
							<a>
								<img src={mailImg} alt="mail" />
							</a>
						</li>
					</ul> */}
					</nav>
					<address style={{ fontWeight: 'normal' }}>
						<p>
							<span>(주)시간대표이사</span>
							<span>지성식</span>
							<span>본사</span>
							<span>부산광역시 해운대구 센텀중앙로97 A동2710호</span>
						</p>
						<p>
							<span>사업자등록번호</span>
							<span>406-87-01998</span>
							<span>통신판매업신고번호</span>
							<span>제2020-부산해운대-1610호</span>
							{/* <a>사업자정보확인</a> */}
						</p>
						<p>
							<span>개인정보보호책임자</span>
							<span>지성식</span>
							<span>고객상담</span>
							<span>1644-5751</span>
							<span>팩스</span>
							<span>051-711-3049</span>
							<span>이메일</span>
							<span>hi_sigan@naver.com</span>
						</p>
					</address>
					<p className="copy">ⓒ2020 ㈜시간. All Rights Reserved.</p>
				</div>
			</footer>
		</>
	);
};

export default Footer;
