import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import GoogleMapComponent from '../GoogleMapComponent';
import BusanPreview from 'assets-pc/front-img/marin_preview.png';
import BusanSlide01 from 'assets-pc/front-img/marin_pc_1.png';
import BusanSlide02 from 'assets-pc/front-img/marin_pc_2.png';
import BusanSlide03 from 'assets-pc/front-img/marin_pc_3.png';

const Busan = ({ setMockShopName, mockShopName }) => {
	const seoulRef = useRef();
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const onBeforeChange = (older, newidx) => {
		let calc = newidx / 3; // 7 은 총 슬라이더 갯수
		let newCalc = (calc * 3 + 1) / 3;
		seoulRef.current.style.transForm = `translate3d(0px,0px,0px) scaleX(${newCalc}) scaleY(1)`;
		seoulRef.current.style.width = newCalc * 100 + '%';
	};
	const settings = {
		beforeChange: onBeforeChange,
		fade: true,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1500,
		autoplaySpeed: 3000,
		arrows: true,
		appendDots: (dots) => (
			<div
				style={{
					borderRadius: '10px',
					bottom: '-45px',
					display: 'none',
				}}
			>
				<ul style={{ margin: '0px' }}> {dots} </ul>
			</div>
		),

		customPaging: (i) => <div className="main-swiper-pagination-bullet"></div>,
	};

	return (
		<>
			<section className="inner">
				<div className="shop_info">
					<div className="info_top info_top_plus">
						<p className="txt">
							<img className="preview-img" src={BusanPreview} />
						</p>
						<div className="intro_box intro_box_plus">
							<p className="intro-title-wrapper">
								하이시간,
								<span className="bold"> 마린시티 인사말</span>
							</p>
							<p
								style={{
									fontSize: '16px',
									fontWeight: '500',
									lineHeight: '29px',
									margin: '0',
								}}
							>
								부산에서 즐길 수 있는 최고의 경험은 하이시간 부티크를 방문하는 것입니다.
								<br />
								해운대 마린시티에 위치한 하이시간 부티크에서 최고급 품질의 시계들과 하이시간만이
								갖고 있는 특별한 제품들을 만나 보실 수 있습니다.
								<br />
								<br />
								또한, 전문 수석 엔지니어로부터 시계를 최상으로 유지 관리하는 비법과 테크
								<br />
								닉을 조언 받아 시계의 기능을 보다 잘 이해하게 될 것입니다.
								<br />
								<br />
								하이시간 마린시티 부티크와 함께 신비로운 시계의 여정을 함께 떠나 보세요.
							</p>
						</div>
					</div>
					<SliderWripper>
						<div className="info_mid info_mid_plus">
							<Slider {...settings}>
								<div>
									<img className="store-slide-img" src={BusanSlide01} />
								</div>
								<div>
									<img className="store-slide-img" src={BusanSlide02} />
								</div>
								<div>
									<img className="store-slide-img" src={BusanSlide03} />
								</div>
							</Slider>
						</div>

						{/* <div className="swiper-pagination"></div> */}
						<div
							className="main-swiper-pagination bar main-swiper-pagination-progressbar"
							style={{
								position: 'relative',
								width: '1076px',
								margin: '31px auto 0',
							}}
						>
							<div
								className="main-swiper-pagination-progressbar-fill"
								ref={seoulRef}
								style={{
									width: (1 / 3) * 100 + '%',
									height: '3px',
									transitionDuration: '1000ms',
									backgroundColor: '#D1AD86',
								}}
							></div>
						</div>
					</SliderWripper>
					<div className="store-btn-wrapper">
						{/* 호버시 #D3B391 */}
						<button
							style={
								mockShopName === '1'
									? {
											width: '23%',
											background: '#D7BDA1',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
									: {
											width: '23%',
											background: '#2B3035',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
							}
							onClick={() => setMockShopName('1')}
						>
							하이시간 본사(센텀시티)
						</button>
						<button
							style={
								mockShopName === '2'
									? {
											width: '23%',
											background: '#D7BDA1',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
									: {
											width: '23%',
											background: '#2B3035',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
							}
							onClick={() => setMockShopName('2')}
						>
							서울 압구정점
						</button>
						<button
							style={
								mockShopName === '3'
									? {
											width: '23%',
											background: '#D7BDA1',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
									: {
											width: '23%',
											background: '#2B3035',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
							}
							onClick={() => setMockShopName('3')}
						>
							부산 마린시티점
						</button>
						<button
							style={
								mockShopName === '4'
									? {
											width: '23%',
											background: '#D7BDA1',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
									: {
											width: '23%',
											background: '#2B3035',
											height: '40px',
											color: 'white',
											fontWeight: '500',
									  }
							}
							onClick={() => setMockShopName('4')}
						>
							대구 수성점
						</button>
					</div>
					<div style={{ width: '1076px', margin: '61px auto' }}>
						{/* <ShopContent oneData={oneData} /> */}
						<div
							className="cont shop1"
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<div className="address_box">
								<div
									className="poppins"
									style={{
										height: '24px',
										fontWeight: 'bold',
										fontSize: '16px',
										color: '#D1AD86',
									}}
								>
									Hisigan Boutique
								</div>
								<h3
									style={{
										fontSize: '28px',
										lineHeight: '42px',
										fontWeight: '700',
										height: '42px',
										marginTop: '4px',
									}}
								>
									{/* {oneData?.name ? oneData.name : '-'} */}
									마린시티점
								</h3>
								<div
									style={{
										marginTop: '8px',
										fontSize: '15px',
										fontWeight: '500',
										lineHeight: '18px',
										height: '18px',
										color: '#596066',
									}}
								>
									{/* {oneData?.address ? oneData.address : '-'} */}
									부산광역시 해운대구 마린시티1로 167, 1동 120호
								</div>
								<div style={{ marginTop: '24px' }}>
									<p style={{ display: 'flex' }}>
										<div
											style={{
												width: '59px',
												fontSize: '15px',
												height: '18px',
												fontWeight: '700',
											}}
										>
											대표 전화
										</div>
										<div
											style={{
												marginLeft: '60px',
												fontSize: '15px',
												fontWeight: '500',
												height: '18px',
											}}
										>
											1644-5751
										</div>
									</p>
									<p style={{ display: 'flex', marginTop: '12px' }}>
										<div
											style={{
												width: '59px',
												fontSize: '15px',
												height: '18px',
												fontWeight: '700',
											}}
										>
											직통 전화
										</div>
										<div
											style={{
												marginLeft: '60px',
												fontSize: '15px',
												fontWeight: '500',
												height: '18px',
											}}
										>
											051-711-3054
										</div>
									</p>
									<p style={{ display: 'flex', marginTop: '12px' }}>
										<div
											style={{
												width: '59px',
												fontSize: '15px',
												height: '18px',
												fontWeight: '700',
											}}
										>
											팩스
										</div>
										<div
											style={{
												marginLeft: '60px',
												fontSize: '15px',
												fontWeight: '500',
												height: '18px',
											}}
										>
											051-711-3049
										</div>
									</p>
									<p style={{ display: 'flex', marginTop: '24px' }}>
										<div
											style={{
												width: '59px',
												fontSize: '15px',
												height: '18px',
												fontWeight: '700',
											}}
										>
											영업시간
										</div>
										<div style={{ marginLeft: '60px', fontSize: '15px', fontWeight: '500' }}>
											<div style={{ height: '18px' }}>FROM MONDAY TO SATURDAY</div>
											<div style={{ fontWeight: '700', marginTop: '4px', height: '18px' }}>
												10AM-7PM
											</div>
											<div style={{ marginTop: '12px', fontSize: '14px', height: '18px' }}>
												* 일요일과 공휴일은 휴무
											</div>
										</div>
									</p>
									<p style={{ display: 'flex', marginTop: '24px' }}>
										<div
											style={{
												width: '59px',
												fontSize: '15px',
												height: '18px',
												fontWeight: '700',
											}}
										>
											이메일
										</div>
										<div
											style={{
												marginLeft: '60px',
												fontSize: '15px',
												fontWeight: '500',
												height: '18px',
											}}
										>
											hi_sigan@naver.com
										</div>
									</p>
								</div>
							</div>
							<div className="shop_map">
								<GoogleMapComponent
									places="place_id:ChIJG3WJhECTaDURcUIofl6w0Xk"
									// latitude={Number(shopDatas?.latitude) ? Number(shopDatas.latitude) : ''}
									// longitude={Number(shopDatas?.longitude) ? Number(shopDatas.longitude) : ''}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Busan;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: ${(props) =>
			props.mostShowedProducts?.length === 1
				? '385px'
				: props.mostShowedProducts?.length === 2
				? '800px'
				: ''};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		display: ${(props) => (props.mostShowedProducts?.length < 3 ? 'flex' : 'block')};

		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.mostShowedProducts?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		width: 60px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -66px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -60px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */

	.slick-dots li {
		width: 20px;
		height: 2 px;
		margin: 0 5px;
	}

	.slick-dots .slick-active {
		width: 20px;
		height: 2px;
		background: #31383f;
		border-radius: 0;
		opacity: 1;
		width: 20px;
		top: 0px;
		overflow: hidden;
	}
`;
const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
`;
