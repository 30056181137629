import React,{useEffect} from 'react';
import HpiGraph1 from 'assets-pc/img/common/img_hpi_graph_01.svg';
import HpiGraph2 from 'assets-pc/img/common/img_hpi_graph_02.svg';
import HpiGraph3 from 'assets-pc/img/common/img_hpi_graph_03.svg';
import HpiGraph4 from 'assets-pc/img/common/img_hpi_graph_04.svg';
import HpiGraph5 from 'assets-pc/img/common/img_hpi_graph_05.svg';
import HpiGraph6 from 'assets-pc/img/common/img_hpi_graph_06.svg';

const Hpi = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<div className="container hpi">
				<section className="sub_top">
					<h1>HiSigan Price Index</h1>
					<p>시계의 가격추이를 알 수 있는 하이시간만의 가격지표 입니다.</p>
				</section>
				<section className="inner">
					<div className="cont">
						<h2>
							<b>선형 회귀</b>라는 공식을 이용하여 <strong>곡선인 차트를 선형화</strong>시켰습니다.
						</h2>
						<p className="t1">선형 회귀란?</p>
						<p className="t2">
							통계학에서, 선형 회귀(線型回歸, 영어: linear regression)는 <br />
							종속 변수 y와 한 개 이상의 독립 변수 (또는 설명 변수) X와의 선형 상관 관계를
							모델링하는 회귀분석 기법입니다.
						</p>
						<p className="t3">
							이와같이 가격이 오르내리면 <br />
							<b>직관적으로 가격의 추세를 알기가 어렵습니다.</b>
						</p>
						<img src={HpiGraph1} alt="" />
						<p className="t3">
							하이시간에서는
							<b>
								가격을 표준화시켜 추세를 <br />좀 더 명확하게 알 수 있게
							</b>
							하였습니다.
						</p>
						<img src={HpiGraph2} alt="" />
						<ul>
							<li>
								<h3>가격이 쭉 상승하면 100점</h3>
								<img src={HpiGraph3} alt="" />
							</li>
							<li>
								<h3>가격이 쭉 하락하면 0점</h3>
								<img src={HpiGraph4} alt="" />
							</li>
							<li>
								<h3>가격이 일정 가격을 유지하면 50점</h3>
								<img src={HpiGraph5} alt="" />
							</li>
							<li>
								<h3>
									100점과 50점 사이를 오른다면 해당 비율만큼 <br />
									점수를 부과하여 70점
								</h3>
								<img src={HpiGraph6} alt="" />
							</li>
						</ul>
					</div>
				</section>
			</div>
		</>
	);
};

export default Hpi;
