import React, { useEffect, useState } from 'react';
import BuyReservation from './components/Buy/BuyReservation';
import SellReservation from './components/Sell/SellReservation';
import { getBuyReservationApi, getSellReservationApi } from 'apis/reservation';
import { useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';

const ReservationMenu = () => {
	const appState = useAppState();
	const userId = appState && appState.user.data.id;
	const OFFSET = 15;
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();
	const [buyData, setBuyData] = useState({});
	const [sellData, setSellData] = useState({});
	const [btnValue, setBtnValue] = useState('buy');
	const [buyPage, setBuyPage] = useState(0);
	const [sellPage, setSellPage] = useState(0);

	useEffect(() => {
		getBuyReservationData();
	}, [buyPage]);

	const getBuyReservationData = () => {
		getBuyReservationApi(authToken, userId, OFFSET, buyPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setBuyData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};
	useEffect(() => {
		getSellReservationData();
	}, [sellPage]);

	const getSellReservationData = () => {
		getSellReservationApi(authToken, userId, OFFSET, sellPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setSellData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};

	const handleBtn = (e) => {
		const { name } = e.target;
		if (name === 'buy') {
			setBtnValue(name);
		}
		if (name === 'sell') {
			setBtnValue('sell');
		}
	};
	return (
		<>
			<div className="mypage_cont">
				<div className="list_wrap">
					<h2>예약내역</h2>
					<div className="tab_list">
						<div className="btn_box">
							<a
								className={btnValue === 'buy' ? 'on' : ''}
								name="buy"
								onClick={handleBtn}
							>
								구매
							</a>
							<a
								className={btnValue === 'sell' ? 'on ' : ''}
								name="sell"
								onClick={handleBtn}
							>
								판매
							</a>
						</div>
						{/* <p>취소관련 문의는 예약지점으로 전화 문의해주세요.</p> */}
						<div className="mypage-cancel-info">* 예약을 취소하실 경우 전화주세요. 1644-5751</div>
					</div>
					{btnValue === 'buy' ? (
						<BuyReservation buyData={buyData} buyPage={buyPage} setBuyPage={setBuyPage} />
					) : (
						<SellReservation sellData={sellData} sellPage={sellPage} setSellPage={setSellPage} />
					)}
					{/* <table className="tbl">
						<colgroup>
							<col style={{ width: '5%' }} />
							<col style={{ width: '7%' }} />
							<col style={{ width: '12%' }} />
							<col style={{ width: '%' }} />
							<col style={{ width: '10%' }} />
							<col style={{ width: '20%' }} />
							<col style={{ width: '7%' }} />
						</colgroup>
						<thead>
							<tr>
								<th>번호</th>
								<th>구분</th>
								<th>신청일</th>
								<th>상품명</th>
								<th>방문지점</th>
								<th>예약일정</th>
								<th>상태</th>
							</tr>
						</thead>
						<tbody> */}
					{/* <tr>
								<td>1</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr>
							<tr>
								<td>2</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr>
							<tr>
								<td>3</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state complete">완료</span>
								</td>
							</tr>
							<tr>
								<td>4</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr>
							<tr>
								<td>5</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr> */}
					{/* </tbody>
					</table> */}
				</div>
			</div>
		</>
	);
};

export default ReservationMenu;
