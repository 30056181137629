import { API_KEY } from 'apis/map';
import React from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import { API_KEY } from 'apis/map';

const containerStyle = {
	// width: '836px',
	// height: '400px',
	width:'620px',
	height:'380px',
	border: 0,
};

const GoogleMapComponent = ({ places }) => {
	// const center = {
	// 	lat: latitude,
	// 	lng: longitude,
	// };

	// const position = {
	// 	lat: latitude,
	// 	lng: longitude,
	// };
	return (
		// <LoadScript googleMapsApiKey={API_KEY}>
		// 	<GoogleMap
		// 		mapTypeId="roadmap"
		// 		mapContainerStyle={containerStyle}
		// 		center={center}
		// 		zoom={17}
		// 		onCenterChanged={center}
		// 	>
		// 		{/* Child components, such as markers, info windows, etc. */}
		// 		<Marker position={position} onPositionChanged={position} />
		// 	</GoogleMap>
		// </LoadScript>
		<div className="google-map-code">
			<iframe
				title={'gooleMap'}
				src={`https://www.google.com/maps/embed/v1/place
					?key=${API_KEY}
					&q=${places}`}
				// width="836"
				// height="400"
				frameBorder="0"
				style={containerStyle}
				// allowFullScreen="true"
				aria-hidden="false"
				// tabIndex="0"
			></iframe>
		</div>
	);
};

export default React.memo(GoogleMapComponent);
