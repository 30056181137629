export const PROPERTY_DIRECTION_MENU = [
	{ property: 'createdAt', direction: 'desc', name: '최신 등록 순' },
	{ property: 'hitCount', direction: 'des', name: '인기순' },
	{ property: 'price', direction: 'desc', name: '가격 높은 순' },
	{ property: 'price', direction: 'asc', name: '가격 낮은 순' },
];

export const FILTER_LIST_FIRST = [
	{ ko: '브랜드', en: 'BRAND' },
	{ ko: '모델', en: 'MODEL' },
	{ ko: '레퍼런스', en: 'REFERENCE' },
	{ ko: '케이스 사이즈', en: 'CASE_DIAMETER' },
	{ ko: '구동방식', en: 'MOVEMENT' },
	{ ko: '구매년도', en: 'PRODUCED_YEAR' },
];

export const FILTER_LIST_SECOND = [
	{ ko: '구성품', en: 'SUPPLEMENTS' },
	{ ko: '성별', en: 'GENDER' },
	{ ko: '케이스 소재', en: 'CASE_MATERIAL' },
	{ ko: '다이얼 색상', en: 'DIAL_COLOR' },
	{ ko: '다이얼 타입', en: 'DIAL_TYPE' },
	{ ko: '보유지점', en: 'STORE' },
	{ ko: '컨디션', en: 'CONDITION' },
];

export const SUPPLEMENTS_EN_KO = {
	SIGAN_GUARANTEE: '하이시간보증서',
	GUARANTEE: '보증서',
	BOX: '박스',
	BOOKLET: '북렛',
	MEDAL: 'MEDAL',
};

export const GENDER_EN_KO = {
	MALE: '남성',
	FEMALE: '여성',
	UNISEX: '남녀공용',
};
export const MOVEMENT_EN_KO = {
	AUTOMATIC: '자동',
	PASSIVITY: '수동',
	QUARTZ: '쿼츠',
};

export const INIT_FILTER_BODY = [
	{
		type: 'BRAND',
		keywords: [],
	},
	{
		type: 'MODEL',
		keywords: [],
	},
	{
		type: 'REFERENCE',
		min: '',
		max: '',
	},
	{
		type: 'CASE_DIAMETER',
		keywords: [],
	},
	{
		type: 'MOVEMENT',
		keywords: [],
	},
	{
		type: 'PRODUCED_YEAR',
		min: '',
		max: '',
	},
	{
		type: 'SUPPLEMENTS',
		keywords: [],
	},
	{
		type: 'GENDER',
		keywords: [],
	},
	{
		type: 'CASE_MATERIAL',
		keywords: [],
	},
	{
		type: 'DIAL_COLOR',
		keywords: [],
	},
	{
		type: 'DIAL_TYPE',
		keywords: [],
	},
	{
		type: 'STORE',
		keywords: [],
	},
	{
		type: 'CONDITION',
		keywords: [],
	},
];

export const MOVEMENT_KO_EN = {
	오토매틱: 'AUTOMATIC',
	수동: 'PASSIVITY',
	쿼츠: 'QUARTZ',
};

export const SUPPLEMENTS_KO_EN = {
	하이시간보증서: 'SIGAN_GUARANTEE',
	보증서: 'GUARANTEE',
	박스: 'BOX',
	북렛: 'BOOKLET',
	MEDAL: 'MEDAL',
};

export const GENDER_KO_EN = {
	남성: 'MALE',
	여성: 'FEMALE',
	남녀공용: 'UNISEX',
};

export const CASE_MATERIAL_LIST = [
	'스틸',
	'옐로우골드',
	'로즈골드',
	'화이트골드',
	'옐로우골드+스틸',
	'로즈골드+스틸',
	'화이트골드+스틸',
	'로즈골드+가죽밴드',
	'티타늄',
	'카본',
	'플래티넘',
	'로즈골드+오이스터플렉스',
	'화이트골드+다이아',
];

export const DIAL_COLOR_LIST = [
	'검정색',
	'갈색',
	'금색',
	'회색',
	'선더스트',
	'실버',
	'초코컴퓨터판',
	'초콜렛',
	'화이트',
	'투명',
	'파랑',
	'샴페인',
	'청색',
	'아이스블루',
	'핑크',
	'올리브그린',
	'그린',
	'레드',
	'파베다이아',
	'오베르진+69다이아',
	'브라운',
	'다크 브라운',
];

export const DIAL_TYPE_LIST = [
	'바인덱스',
	'로마자',
	'아라비아',
	'8다이아몬드+2바게트다이아몬드',
	'17별 다이아몬드',
	'10포인트 다이아몬드',
	'막대',
	'로마자',
	'스켈레톤',
	'막대 + 로마자',
	'막대 + 아라비아',
	'바게트 다이아몬드',
	'숫자 없음',
];

export const NORMAL_FILTER_LEFT = {
	0: '40px',
	1: '211px',
	2: '383px',
	3: '554px',
	4: '725px',
	5: '896px',
	6: '1068px',
};
export const BRAND_FILTER_LEFT = ['40px', '211px', '383px', '554px', '-12px', '159px', '330px'];
// 	0: '40px',
// 	1: '211px',
// 	2: '383px',
// 	3: '554px',
// 	4: '-12px',
// 	5: '159px',
// 	6: '330px',
// };
export const MODEL_FILTER_LEFT = {
	0: '40px',
	1: '211px',
	2: '383px',
	3: '554px',
	4: '370px',
	5: '542px',
	6: '712px',
};
export const FILTER_WIDTH = {
	BRAND: '910px',
	MODEL: '528px',
	REFERENCE: '320px',
	CASE_DIAMETER: '200px',
	MOVEMENT: '200px',
	PRODUCED_YEAR: '320px',
	SUPPLEMENTS: '200px',
	GENDER: '200px',
	CASE_MATERIAL: '280px',
	DIAL_COLOR: '220px',
	DIAL_TYPE: '300px',
	STORE: '200px',
	CONDITION: '348px',
};
export const MOBILE_POPULAR_MODEL_LIST = ['서브마리너','데이토나', '로얄오크','데이저스트','노틸러스']
export const HAPLE_STATUS = {
	WAIT: '예약중',
	CONFIRM: '예약 승인',
	CANCEL: '예약 취소',
};