import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getSignInApi = (id, password) => {
	return apiCall(
		`${SERVER_API_HOST}/api/public/login`,
		'post',
		{ loginId: id, password },
		{ ContentType: 'application/json' },
	);
};

export const getFindMemberApi = (emailAddress) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/find/password`,
		'post',
		{ email: emailAddress },
		{ ContentType: 'application/json' },
	);
};
