import { useState, useEffect, useRef } from 'react';
export const Lpad = (str, len) => {
	str = str + '';
	while (str.length < len) {
		str = '0' + str;
	}
	return str;
};
export const initTimer = (endTime, id) => {
	let getElement = document.getElementById(id);
	if (endTime && id && getElement) {
		
		function msg_timer(id) {
			let stDate = new Date().getTime();
			let endDate = new Date(endTime).getTime();
			
			let remainDate = endDate - stDate;
			let days = Math.floor(remainDate / (1000 * 60 * 60 * 24));

			let hours = Math.floor((remainDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			let minutes = Math.floor((remainDate % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((remainDate % (1000 * 60)) / 1000);

			let m = `${days}일 ${Lpad(hours, 2)}:${Lpad(minutes, 2)}:${Lpad(seconds, 2)}이후 마감`; // 남은 시간 text형태로 변경

			getElement.innerHTML = m; // div 영역에 보여줌

			if (remainDate < 0) {
				// 시간이 종료 되었으면..
				clearInterval(tid); // 타이머 해제
				getElement.innerHTML = `0일 00:00:00이후 마감`;
			} else {
				remainDate = remainDate - 1000; // 남은시간 -1초
			}
		}
		let tid = setInterval(msg_timer, 1000);
	}
};
