import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { findWatchApi } from 'apis/buy';
import CommonHelper from 'helpers/CommonHelper';
const initFindWatchInfo = {
	model: '',
	price: '',
	phone: '',
	description: '',
};

const FindWatch = () => {
	const history = useHistory();
	const [findWatchInfo, setFindWatchInfo] = useState(initFindWatchInfo);
	const [errorText, setErrorText] = useState('');
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [showInfoAgree, setShowInfoAgree] = useState(false);
	const [completeRequest, setCompleteRequest] = useState(false);
	///////
	const handleFindModelInput = (e) => {
		const { name, value } = e.target;
		let copyFindWatchInfo = { ...findWatchInfo };
		if (name === 'price') {
			let deleteAll = value
				.replace(/ /g, '')
				.replace(/,/g, '')
				.replace(/[^0-9]/g, '');
			copyFindWatchInfo[name] = deleteAll;
		} else {
			copyFindWatchInfo[name] = value;
		}
		setFindWatchInfo(copyFindWatchInfo);
	};
	const handleChecked = (e) => {
		const { checked } = e.target;
		if (checked) {
			setAgreeChecked(true);
		} else {
			setAgreeChecked(false);
		}
	};
	const checkValidation = () => {
		if (findWatchInfo.model.length === 0) {
			setErrorText('상품명을 입력해주세요.');
		} else if (findWatchInfo.price.length === 0) {
			setErrorText('희망가격을 입력해주세요.');
		} else if (!agreeChecked) {
			setErrorText('개인정보수집 이용 동의에 체크해주세요.');
		} else {
			const authToken = CommonHelper.getAuthToken()
				? CommonHelper.getAuthToken()
				: CommonHelper.getSessionAuthToken();
			findWatchApi(authToken, findWatchInfo).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					//성공 신청했다고 알려주기
					setCompleteRequest(true);
					setFindWatchInfo(initFindWatchInfo);
				} else {
					alert(detail);
				}
			});
		}
	};
	const checkCompleteRequest = () => {
		setCompleteRequest(false)
		history.goBack();
	};

	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => history.goBack()}></button>
					<h2>시계 찾아줘</h2>
				</section>
				<section className="find_watch">
					<p className="t1">
						원하는 시계를 찾아드리는 서비스입니다. 아래의 양식을 <br />
						입력해주시면 원하시는 시계를 찾아드립니다.
					</p>
					<h3>
						상품명 <span>*</span>
					</h3>
					<div className="input_div">
						<input
							type="text"
							className="input_t"
							placeholder="상품명을 입력해주세요."
							name="model"
							value={findWatchInfo.model || ''}
							onChange={handleFindModelInput}
						/>
					</div>
					<h3>
						희망가격 <span>*</span>
					</h3>
					<div className="input_div">
						<input
							type="text"
							className="input_t"
							placeholder="희망가격을 입력해주세요."
							name="price"
							value={findWatchInfo.price ? Number(findWatchInfo.price).toLocaleString() : ''}
							onChange={handleFindModelInput}
						/>
					</div>
					<h3>
						핸드폰 번호 <span>*</span>
					</h3>
					<div className="input_div">
						<input
							type="text"
							className="input_t"
							placeholder="핸드폰 번호를 입력해 주세요."
							name="phone"
							value={findWatchInfo.phone || ''}
							onChange={handleFindModelInput}
						/>
					</div>
					<h3>요청사항</h3>
					<textarea
						name="description"
						id=""
						cols="30"
						rows="10"
						placeholder="요청사항을 입력해주세요."
						value={findWatchInfo.description || ''}
						onChange={handleFindModelInput}
					></textarea>
					<div className="agree_box">
						<input type="checkbox" value={agreeChecked} onChange={handleChecked} />
						<div>
							<p className="t1">
								<a onClick={() => setShowInfoAgree(true)}>개인정보수집</a> 이용에 동의합니다.
							</p>
							<p className="t2">
								서비스 이용을 위해 필요한 정보로써, 동의를 해주셔야 <br />
								서비스를 이용 하실 수 있습니다.
							</p>
						</div>
					</div>
					{errorText && <p className="error_txt">{errorText}</p>}
					<button className="btn" onClick={checkValidation}>
						신청하기
					</button>
				</section>
			</main>
			{showInfoAgree && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<table className="tbl_info">
							<thead>
								<tr>
									<th>수집항목</th>
									<th>수집목적</th>
									<th>보유기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										거래종류, 판매할 재화 상품명, 구매처, 연식, 구매할 재화 상품명, 구매할 재화
										희망가격
									</td>
									<td>거래종류 및 거래 물품 파악</td>
									<td rowSpan="2">수집, 이용 목적이 달성될 때까지</td>
								</tr>
								<tr>
									<td>방문지점, 방문가능 날짜, 시간, 방문내용, 재화 사진</td>
									<td>방문상담 일정 예약</td>
								</tr>
							</tbody>
						</table>
						<button className="btn" onClick={() => setShowInfoAgree(false)}>
							닫기
						</button>
					</article>
				</div>
			)}
			{completeRequest && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t2">신청이 완료되었습니다.</p>
						<p className="t3">
							최대한 빠른 시일내에 연락 드리도록 <br />
							하겠습니다.
						</p>
						<button className="btn" onClick={checkCompleteRequest}>
							확인
						</button>
					</article>
				</div>
			)}
		</>
	);
};

export default FindWatch;
