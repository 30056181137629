import React,{useEffect} from 'react';
import Guarantee1 from 'assets-pc/img/common/image_guarantee_01.svg'
import Guarantee2 from 'assets-pc/img/common/image_guarantee_02.svg';
import Guarantee3 from 'assets-pc/img/common/image_guarantee_03.svg';
import Guarantee4 from 'assets-pc/img/common/image_guarantee_04.svg';
import TabletScreen from '../TabletLanding'

const Guarantee = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<div className="container warranty">
				<section className="sub_top">
					<h1>보증거래</h1>
					<p>하이 시간에서 안전하고 편리하게 거래하실 수 있도록 안내해드립니다.</p>
				</section>
				<section className="inner" style={{paddingTop:'60px'}}>
					<TabletScreen />
					{/* <div className="cont">
						<h2>개인직거래</h2>
						<div>
							<p className="t1">
								판매자와 구매자가 <b>개인간 직거래</b>를 할 경우
							</p>
							<p className="t2">판매자와 구매자 개인간의 직거래 안전 보장 시스템입니다.</p>
							<div className="info_box box1">
								<img src={Guarantee1} alt="" />
							</div>
						</div>
					</div>
					<div className="cont">
						<h2>위탁 판매</h2>
						<div>
							<p className="t1">
								<b>정해진 구매자 없이 위탁으로 판매</b>를 할 경우
							</p>
							<p className="t2">
								정해진 구매자가 없을 때 하이시간 거래소에서 위탁 판매하실 수 있게 안내해드립니다.
							</p>
							<div className="info_box box2">
								<img src={Guarantee2} alt="" />
							</div>
						</div>
					</div>
					<div className="cont">
						<h2>위탁 구매</h2>
						<div>
							<p className="t1">
								<b>정해진 판매자 없이 위탁으로 판매</b>를 할 경우
							</p>
							<p className="t2">
								정해진 판매자가 없을 때 하이시간 거래소에서 위탁 판매하실 수 있게 안내해드립니다.
							</p>
							<div className="info_box box3">
								<img src={Guarantee3} alt="" />
							</div>
						</div>
					</div>
					<div className="cont">
						<h2>사후관리 시스템</h2>
						<div>
							<p className="t1">
								<b>고객에게 신뢰받는 기업</b>- 하이시간
							</p>
							<p className="t2">
								하이시간 거래소의 최적의 사후관리 시스템으로 안전한 거래를 하실 수 있습니다.
							</p>
							<div className="info_box box4">
								<img src={Guarantee4} alt="" />
							</div>
						</div>
					</div> */}
				</section>
			</div>
		</>
	);
};

export default Guarantee;
