import React, { useState } from 'react';
import Pagination from '../../MyReservationList/components/Pagination';

const BuyReservation = ({ buyData, buyPage, setBuyPage }) => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const { totalPage } = buyData;
	const pageNumbers = [];

	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);
	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setBuyPage(num);
	};
	const prevPage = () => {
		if (buyPage + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((buyPage + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setBuyPage(buyPage - 1);
	};

	const nextPage = () => {
		if (buyPage + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setBuyPage(buyPage + 1);
			if ((buyPage + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
	};
	return (
		<>
			<ul className="history_list">
				{buyData?.content?.length > 0 ? (
					buyData.content &&
					buyData.content.map((data, index) => {
						return (
							<li key={index}>
								<p className="date">{data?.createdAt?.split('T')[0] || '-'}</p>
								<p className="watch_name">
									{data?.auction?.watch?.brand || '-'} <br />
									{data?.auction?.watch?.model || '-'} {data?.auction?.watch?.caseDiameter || '-'}mm
								</p>
								<dl>
									<div>
										<dt>참여자 수</dt>
										<dd>{data?.auction?.orderPeopleCount || '0'}명</dd>
									</div>
									<div>
										<dt>입찰가</dt>
										<dd>
											<b>{data?.price ? Number(data?.price).toLocaleString() : '-'}</b>
										</dd>
									</div>
								</dl>
								<span
									className={
										data?.auction?.auctionStatus === 'DONE'
											? 'status end'
											: data?.auction?.auctionStatus === 'RUN'
											? 'status ing'
											: data?.auction?.auctionStatus === 'WAIT'
											? 'status stay'
											: 'status'
									}
								>
									{data?.auction?.auctionStatus
										? data.auction.auctionStatus === 'WAIT'
											? '대기중'
											: data.auction.auctionStatus === 'RUN'
											? '진행중'
											: data.auction.auctionStatus === 'DONE'
											? '완료'
											: ''
										: ''}
								</span>
							</li>
						);
					})
				) : (
					<li>
						<p>최근 예약내역이 없습니다.</p>
					</li>
				)}
			</ul>
			{buyData && buyData?.content?.length > 0 ? (
				<Pagination
					newPageNumbers={newPageNumbers}
					prevPage={prevPage}
					buyPage={buyPage}
					paginate={paginate}
					nextPage={nextPage}
				/>
			) : (
				''
			)}
		</>
	);
};

export default BuyReservation;
