import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';
import { MOVEMENT_KO_EN, SUPPLEMENTS_KO_EN, GENDER_KO_EN } from 'common/constants';

export const getBrandListApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/watch/brand/list`, 'get', {}, {});
};
export const getPopularListApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/reservation/sale/list/top?limit=10`, 'get', {}, {});
};

export const getMobileSellingProductsApi = (
	propertyDirection,
	page,
	authToken,
	selectedBrandList,
	selectedModelFilter,
	searchReference,
	selectedMinCaseDiameters,
	selectedMaxCaseDiameters,
	selectedMovement,
	selectedSupplements,
	selectedGender,
	selectedCaseMaterial,
	selectedDialColor,
	selectedDialType,
	selectedStoreIdList,
	producedYearMinInput,
	producedYearMaxInput,
	conditionStart,
	conditionEnd,
	topSearchBox,
	statusBox
) => {
	let newMovement = selectedMovement.map((el) => {
		return MOVEMENT_KO_EN[el];
	});
	let newSupplements = selectedSupplements.map((el) => {
		return SUPPLEMENTS_KO_EN[el];
	});
	let newGender = selectedGender.map((el) => {
		return GENDER_KO_EN[el];
	});
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/list?page=${page}&offset=16&property=${propertyDirection.property}&direction=${propertyDirection.direction}`,
		'post',
		{
			states: statusBox,
			filters: [
				{
					type: 'INTEGRATED',
					keyword: topSearchBox,
				},
				{
					type: 'BRAND',
					keywords: selectedBrandList,
				},
				{
					type: 'MODEL',
					keywords: selectedModelFilter,
				},
				{
					type: 'SERIAL_NO',
					keywords: [searchReference],
				},
				{
					type: 'CASE_DIAMETER',
					min: selectedMinCaseDiameters || 0,
					max: selectedMaxCaseDiameters || 100,
				},
				{
					type: 'MOVEMENT',
					keywords: newMovement,
				},
				{
					type: 'PRODUCED_YEAR',
					min: !!producedYearMinInput ? producedYearMinInput : '0',
					max: !!producedYearMaxInput ? producedYearMaxInput : '3000',
				},
				{
					type: 'SUPPLEMENTS',
					keywords: newSupplements,
				},
				{
					type: 'GENDER',
					keywords: newGender,
				},
				{
					type: 'CASE_MATERIAL',
					keywords: selectedCaseMaterial,
				},
				{
					type: 'DIAL_COLOR',
					keywords: selectedDialColor,
				},
				{
					type: 'DIAL_TYPE',
					keywords: selectedDialType,
				},
				{
					type: 'STORE',
					keywords: selectedStoreIdList,
					// min: selectedStoreIdList.sort()[0],
					// max: selectedStoreIdList.sort()[selectedStoreIdList.length - 1],
				},

				{
					type: 'CONDITION',
					min: Number(conditionStart) !== 0 ? conditionStart : 0,
					max: Number(conditionEnd) !== 0 ? conditionEnd : 10,
				},
			],
		},
		{
			authorization: authToken || null,
		},
	);
};

export const getSellingProductsApi = (
	propertyDirection,
	page,
	authToken,
	selectedBrandList,
	selectedModelFilter,
	searchReference,
	selectedMinCaseDiameters,
	selectedMaxCaseDiameters,
	selectedMovement,
	selectedSupplements,
	selectedGender,
	selectedCaseMaterial,
	selectedDialColor,
	selectedDialType,
	selectedStoreIdList,
	producedYearMinInput,
	producedYearMaxInput,
	conditionStart,
	conditionEnd,
	status,
	statusBox
) => {
	let newMovement = selectedMovement.map((el) => {
		return MOVEMENT_KO_EN[el];
	});
	let newSupplements = selectedSupplements.map((el) => {
		return SUPPLEMENTS_KO_EN[el];
	});
	let newGender = selectedGender.map((el) => {
		return GENDER_KO_EN[el];
	});
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/list?page=${page}&offset=16&property=${propertyDirection.property}&direction=${propertyDirection.direction}`,
		'post',
		{
			// state: status,
			states: statusBox,
			filters: [
				{
					type: 'BRAND',
					keywords: selectedBrandList,
				},
				{
					type: 'MODEL',
					keywords: selectedModelFilter,
				},
				{
					type: 'SERIAL_NO',
					keywords: [searchReference],
				},
				{
					type: 'CASE_DIAMETER',
					min: selectedMinCaseDiameters || 0,
					max: selectedMaxCaseDiameters || 100,
				},
				{
					type: 'MOVEMENT',
					keywords: newMovement,
				},
				{
					type: 'PRODUCED_YEAR',
					min: !!producedYearMinInput ? producedYearMinInput : '0',
					max: !!producedYearMaxInput ? producedYearMaxInput : '3000',
				},
				{
					type: 'SUPPLEMENTS',
					keywords: newSupplements,
				},
				{
					type: 'GENDER',
					keywords: newGender,
				},
				{
					type: 'CASE_MATERIAL',
					keywords: selectedCaseMaterial,
				},
				{
					type: 'DIAL_COLOR',
					keywords: selectedDialColor,
				},
				{
					type: 'DIAL_TYPE',
					keywords: selectedDialType,
				},
				{
					type: 'STORE',
					keywords: selectedStoreIdList,
					// min: selectedStoreIdList.sort()[0],
					// max: selectedStoreIdList.sort()[selectedStoreIdList.length - 1],
				},

				{
					type: 'CONDITION',
					min: Number(conditionStart) !== 0 ? conditionStart : 0,
					max: Number(conditionEnd) !== 0 ? conditionEnd : 10,
				},
			],
		},
		{
			authorization: authToken || null,
		},
	);
};
// {
// 					type: 'STORE',
// 					keywords: [],
// 				},

// 가격 높은 순 : property=price&direction=desc
// 가격 낮은 순 : property=price&direction=asc
// 최신 등록 순 : property=createdAt&direction=desc
// 인기순 : property=hitCount&direction=desc

export const getRecentlyProductsApi = (productNumArray) => {
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/list`,
		'post',
		{ ids: productNumArray },
		{},
	);
};

export const searchModelListApi = (searchModelInput) => {
	return apiCall(
		`${SERVER_API_HOST}/api/filter/standard`,
		'post',
		{
			filters: [
				{
					type: 'MODEL',
					keywords: searchModelInput ? [searchModelInput] : [],
				},
			],
		},
		{},
	);
};

export const getStoreListApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/store/list?page=0&offset=5`, 'get', {}, {});
};
export const getFilterLisetApi = (authToken) => {
	if (authToken) {
		return apiCall(
			`${SERVER_API_HOST}/api/site/filter/list`,
			'get',
			{},
			{ authorization: authToken },
		);
	} else {
		return apiCall(`${SERVER_API_HOST}/api/site/filter/list`, 'get', {}, {});
	}
};

export const findWatchApi = (authToken, findForm) => {
	return apiCall(`${SERVER_API_HOST}/api/seek`, 'post', findForm, { authorization: authToken });
};

export const getSiteInfoApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/site/info`, 'get', {}, {});
};
