import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { useHistory } from 'react-router-dom';
import GoogleMapComponent from './MobileGoogleMap';
import Busan1 from 'assets-mobile/img/branch/busan_01.png';
import Busan2 from 'assets-mobile/img/branch/busan_02.png';
import Busan3 from 'assets-mobile/img/branch/busan_03.png';

const Busan = ({ setNowStoreId }) => {
	const history = useHistory();
	let widthAndHeight = (window.innerWidth / 3) * 2;
	const sizeCheck = () => {
		widthAndHeight = (window.innerWidth / 3) * 2;
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	useEffect(() => {
		let swiper = new Swiper('.branch_img', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			pagination: {
				el: '.branch_img_paging',
				clickable: true,
			},
		});
	}, []);
	const goStore = () => {
		history.push('/hapl');
	};
	return (
		<>
			<section className="branch_tab">
				<a className="on">본사</a>
				<a onClick={() => setNowStoreId(1)}>압구정점</a>
				<a onClick={() => setNowStoreId(2)}>마린시티점</a>
				<a onClick={() => setNowStoreId(3)}>대구수성점</a>
			</section>

			<section className="swiper-container branch_img">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<img src={Busan1} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Busan2} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Busan3} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
				</div>
				<div className="branch_img_paging swiper-pagination"></div>
			</section>

			<section className="greetings">
				<h3>
					하이시간, <span>본사 인사말</span>
				</h3>
				<p>
					하이엔드 브랜드 시계부터 직거래시 공간 대여 서비스까지, 안전하고 편리한 하이시간을 경험해
					보세요.
					<br />
					<br />
					-실시간 시계 시세 조회 <br />
					-개인간의 직거래시 하이시간 전 지점 무료 공간대여 <br />
					-하이엔드 브랜드 시계부터 가장 핫한 신상품 시계까지
				</p>
				<p>
					당신의 시간을 빛나게 하는 '하이시간' 하이시간이 당신의 라이프스타일에 품격을 더 해
					드리겠습니다.
					{/* <br /> */}
				</p>
			</section>

			<section className="branch_intro">
				<p className="t1">Hisigan Headquarters</p>
				<h3>하이시간 본사</h3>
				<p className="adress">부산광역시 해운대구 센텀중앙로 97 A동 2710호</p>
				<div className="map_box">
					<GoogleMapComponent places="place_id:ChIJZ-5J96GTaDURpb-XTZEHjaQ" />
					{/* <iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.2292865826025!2d129.12322171524517!3d35.17583738031519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356893a1f749ee67%3A0xa48d07914d97bfa5!2z7ZWY7J207Iuc6rCE!5e0!3m2!1sko!2skr!4v1621479493029!5m2!1sko!2skr"
							style="border:0;"
							// allowfullscreen=""
							loading="lazy"
						></iframe> */}
				</div>
				<ul>
					<li>
						<b>대표 전화</b>
						<span>1644-5751</span>
					</li>
					<li>
						<b>팩스</b>
						<span>051-711-3049</span>
					</li>
					<li>
						<b>영업시간</b>
						<div>
							<p>
								FROM MONDAY TO FRIDAY <br />
								<b>10AM-6PM</b>
							</p>
							<p>* 주말과 공휴일은 휴무</p>
						</div>
					</li>
					<li>
						<b>이메일</b>
						<span>hi_sigan@naver.com</span>
					</li>
				</ul>
			</section>
		</>
	);
};

export default Busan;
