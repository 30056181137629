import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swiper, { Navigation, Pagination } from 'swiper';
import styled from 'styled-components';
import 'assets-mobile/libs/swiper.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import SwiperCore, { Pagination } from 'swiper/core';
import Rolling from './components/Rolling';
import StoreSlide from './components/StoreSlide';
import PopularProduct from './components/PopularProduct';
import Carousel from 'mobile-components/Carousel';
import {
	searchWatchModelApi,
	getRollingApiList,
	getMobileMainRecentRegisterApi,
	getInstaListApi,
} from 'apis/mainPage';
import { getPopularListApi } from 'apis/buy';
import { useSiteState } from 'context/SiteContext';
import useDebounce from 'utils/useDebounce';
import { MOBILE_POPULAR_MODEL_LIST } from 'common/constants';
import CommonHelper from 'helpers/CommonHelper';
// import 'assets-mobile/libs/swiper.min.css';
// import 'swiper/swiper-bundle.css';

import Watch1 from 'assets-mobile/img/main/watch_01.png';
import RealTime from 'assets-mobile/img/main/realtime.png';
import Brand1 from 'assets-mobile/img/main/brand_01.png';
import Brand2 from 'assets-mobile/img/main/brand_02.png';
import Brand3 from 'assets-mobile/img/main/brand_03.png';
import Brand4 from 'assets-mobile/img/main/brand_04.png';
import Brand5 from 'assets-mobile/img/main/brand_05.png';
import Brand6 from 'assets-mobile/img/main/brand_06.png';
import Brand7 from 'assets-mobile/img/main/brand_07.png';
import Brand8 from 'assets-mobile/img/main/brand_08.png';
import Brand9 from 'assets-mobile/img/main/brand_09.png';
import Deals1 from 'assets-mobile/img/main/deals_01.svg';
import Deals2 from 'assets-mobile/img/main/deals_02.svg';
import Deals3 from 'assets-mobile/img/main/deals_03.svg';
import Deals4 from 'assets-mobile/img/main/deals_04.svg';
import Boutique1 from 'assets-mobile/img/main/boutique_01.png';
import YoutubeSlide from './components/YoutubeSlide';
import RecentlyProducts from './components/RecentlyProduct';
import MainTop from 'assets-mobile/img/main/main_top_bg.png';
import PriceM2 from 'assets-mobile/img/main/test-m-2.png';
import PriceM3 from 'assets-mobile/img/main/test-m-3.png';
import PlaceM2 from 'assets-mobile/img/main/place-test-2.png';
import GuaranteeM2 from 'assets-mobile/img/main/guarantee-test-1.png';
import Certification1 from 'assets-pc/front-img/certification_1.svg';
import Certification2 from 'assets-pc/front-img/certification_2.svg';
import Certification3 from 'assets-pc/front-img/certification_3.svg';
import Certification4 from 'assets-pc/front-img/certification_4.svg';
import Certification5 from 'assets-pc/front-img/certification_5.svg';
import Certification6 from 'assets-pc/front-img/certification_6.svg';
import Certification7 from 'assets-pc/front-img/certification_7.svg';
import Certification8 from 'assets-pc/front-img/certification_8.svg';
import Certification9 from 'assets-pc/front-img/certification_9.svg';
import MainHapl from 'assets-pc/front-img/m-main-hapl-bg.png';
import OtherSlide from './components/OtherSlide';
import ReviewSlide from './components/ReviewSlide';
import Insta from 'assets-pc/front-img/insta.png';
import { getHaplMainListApi } from 'apis/hapl';
import { getReviewListApi } from 'apis/review';
import './index.scss';
Swiper.use([Navigation, Pagination]);

const Main = () => {
	const history = useHistory();
	const [instaList, setInstaList] = useState([]);
	const mainSearchRef = useRef(null);
	const searchResultRef = useRef(null);
	const mainSearchRef2 = useRef(null);
	const searchResultRef2 = useRef(null);
	const [topSearchBox, setTopSearchBox] = useState('');
	const [showPopularSearch, setShowPopularSearch] = useState(true);
	const siteState = useSiteState().data;
	const [popularList, setPopularList] = useState(siteState?.searchKeywords || []); //사용할 지 안할 지 아직 몰라서 뒀음.
	// const [recentRegisterProducts, setRecentRegisterProducts] = useState([]);
	const [resultModelArray, setResultModelArray] = useState([]);
	const [reviewData, setReviewData] = useState([]);
	// const debouncedSearchTerm = useDebounce(topSearchBox, 1000); //0.5초뒤에 검색

	const [haplData, setHaplData] = useState([]);

	useEffect(() => {
		getMainHaplList();
		getReviewList();
	}, []);

	// useEffect(() => {
	// 	searchWatchModel();
	// }, [debouncedSearchTerm]);

	useEffect(() => {
		// getMobileMainRecentRegister();
		getInstaList();
	}, []);

	useEffect(() => {
		siteState?.searchKeywords && setPopularList(siteState?.searchKeywords || []);
	}, [siteState]);

	/**
	 * siteState.backgroundImages가 1개라도 있는 경우
	 */
	useEffect(() => {
		//focus 시 검색 창 혹은 인기 검색어 보여줌
		function checkFocus() {
			if (window.innerWidth < 1024 && !!searchResultRef?.current) {
				searchResultRef.current.style.visibility = 'visible';
			}
		}
		//focus out 시 검색 창 혹은 인기 검색어창 안보이게 함
		function checkOutFocus(e) {
			const getSearchListElements = document.getElementsByClassName('oneOfList');
			let findTwo = Array.from(getSearchListElements).filter((el) => {
				return el === e.target;
			}).length;
			if (findTwo === 0 && window.innerWidth < 1024 && !!searchResultRef?.current) {
				searchResultRef.current.style.visibility = 'hidden';
			}
		}

		if (mainSearchRef && mainSearchRef.current) {
			mainSearchRef.current.addEventListener('focus', checkFocus, false);
			document.body.addEventListener('click', checkOutFocus, false);
			// return function cleanup() {
			// 	mainSearchRef.current.removeEventListener('focus', checkFocus, false);
			// 	mainSearchRef.current.removeEventListener('blur', checkOutFocus, false);
			// };
		}
	}, [siteState?.backGroundImages]);

	const getReviewList = () => {
		try {
			// setIsLoading(true);
			getReviewListApi(4, 0).then(({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setReviewData(data.content || {});
				} else {
					alert(detail);
				}
				// setIsLoading(false);
			});
		} catch (err) {
			// setIsLoading(false);
		}
	};
	const goToBuyPageSearchBrand = (brand) => {
		localStorage.setItem('mainBrand', JSON.stringify(brand));
		// window.location.href = `/buy`;
		window.location.href = 'https://hisigan.co.kr/user/main/getSale.do?filter=N';
	};

	useEffect(() => {
		const swiper5 = new Swiper('.mobile_banner_list', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: false,
			pagination: {
				el: '.swiper-paginations',
				clickable: true,
				// renderBullet: function (index, className) {
				// 	return '<span class="' + className + '">' + (index + 1) + '</span>';
				// },

				// dynamicBullets: true,
				// dynamicMainBullets: 5,
				// type: 'bullets',
			},
		});
	}, [siteState.backGroundImages]);

	// let userAgent = navigator.platform;
	// if (userAgent.match(/i(Phone|Pod)/i) !== null) {
	// 	setIsIphone(true);
	// }
	let isIphone = false;
	let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
	if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
		//IOS
		isIphone = true;
	}

	const sendBuyPageFromSearch = () => {
		localStorage.setItem('mainSearch', JSON.stringify(topSearchBox));
		// window.location.href = `/buy`;
		window.location.href = 'https://hisigan.co.kr/user/main/getSale.do?filter=N';
	};
	return (
		<>
			<main className="main">
				{siteState?.backGroundImages?.length > 0 ? (
					<div className="swiper-container mobile_banner_list">
						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<section
									className="main_top"
									style={{
										backgroundImage: `url(${
											siteState?.backGroundImages?.length > 0
												? siteState.backGroundImages[0].url
												: MainTop
										})`,
									}}
								>
									<p className="t1">구매하고 싶은 시계를</p>
									<p className="t2">검색해 주세요.</p>
									<p className="t3">믿을 수 있는 곳에서 인증 받고 판매하세요.</p>
									<div className="search_box">
										<input
											type="text"
											placeholder="모델명 또는 레퍼런스로 검색하세요..."
											ref={mainSearchRef}
											className="oneOfList"
											value={topSearchBox}
											onChange={(e) => setTopSearchBox(e.target.value)}
										/>
										<button className="btn_search" onClick={sendBuyPageFromSearch}></button>
										{/* {showPopularSearch && (
											<section ref={searchResultRef} style={{ visibility: 'hidden' }}>
												{topSearchBox.length === 0 ? (
													<div className="search_list">
														<div className="oneOfList">
															<span className="oneOfList">인기 검색어</span>
														</div>
														<ul>
															{MOBILE_POPULAR_MODEL_LIST?.map((el, idx) => (
																<li
																	key={idx}
																	onClick={() => goToBuyPageSearchModel(el)}
																	className="oneOfList"
																>
																	{el}
																</li>
															))}
														</ul>
													</div>
												) : (
													<div className="search_list">
														<div>
															<span>검색 결과</span>
														</div>
														<ul>
															{resultModelArray.map((el, idx) => (
																<li
																	key={idx}
																	onClick={() => goToBuyPageSearchModel(el.model)}
																	className="oneOfList"
																>
																	{el.model}
																</li>
															))}
														</ul>
													</div>
												)}
											</section>
										)} */}
									</div>
								</section>
							</div>
							{siteState?.backGroundImages?.slice(1).map((el, idx) => (
								<div className="swiper-slide" key={idx}>
									<a target={el?.link ? '_blank' : ''} href={el?.link ? el?.link : '#a'}>
										<section
											className="main_top"
											style={{ backgroundImage: `url(${el.url || MainTop})` }}
										></section>
									</a>
								</div>
							))}
						</div>
						<Wrapper>
							{siteState?.backGroundImages?.length > 1 ? (
								<div className="swiper-paginations"></div>
							) : (
								''
							)}
						</Wrapper>
					</div>
				) : (
					<section
						className="main_top"
						style={{
							backgroundImage: `url(${MainTop})`,
						}}
					>
						<p className="t1">구매하고 싶은 시계를</p>
						<p className="t2">검색해주세요.</p>
						<p className="t3">믿을 수 있는 곳에서 인증 받고 판매하세요.</p>
						<div className="search_box">
							<input
								type="text"
								placeholder="모델명 또는 레퍼런스로 검색하세요..."
								ref={mainSearchRef2}
								className="oneOfList2"
								value={topSearchBox}
								onChange={(e) => setTopSearchBox(e.target.value)}
							/>
							<button className="btn_search" onClick={sendBuyPageFromSearch}></button>
							{/* {showPopularSearch && (
								<section ref={searchResultRef2} style={{ visibility: 'hidden' }}>
									{topSearchBox.length === 0 ? (
										<div className="search_list">
											<div className="oneOfList2">
												<span className="oneOfList2">인기 검색어</span> */}
							{/* <button onClick={() => setPopularList([])} className="oneOfList2">
												전체 삭제
											</button> */}
							{/* </div>
											<ul>
												{MOBILE_POPULAR_MODEL_LIST?.map((el, idx) => (
													<li
														key={idx}
														onClick={() => goToBuyPageSearchModel(el)}
														className="oneOfList2"
													>
														{el}
													</li>
												))}
											</ul>
										</div>
									) : (
										<div className="search_list">
											<div>
												<span>검색 결과</span>
											</div>
											<ul>
												{resultModelArray.map((el, idx) => (
													<li
														key={idx}
														onClick={() => goToBuyPageSearchModel(el.model)}
														className="oneOfList2"
													>
														{el.model}
													</li>
												))}
											</ul>
										</div>
									)}
								</section>
							)} */}
						</div>
					</section>
				)}

				<Rolling />
				{/* <section className="most_view">
					{recentRegisterProducts ? (
						<PopularProduct recentRegisterProducts={recentRegisterProducts} />
					) : (
						''
					)}
				</section> */}

				<section className="real_time">
					<p className="tit">실시간 시세 그래프</p>
					<p className="t1">
						판매 완료된 상품의 시세를 <br />
						간단한 그래프로 확인하여 구매자와 판매자가
						<br />
						시세를 예측할 수 있어 손해를 보지 않아요.
					</p>
					{isIphone ? (
						<div className="mobile_sigan_iframe">
							<iframe
								src={'https://sigan24.cafe24.com/graph/m_flex2.html'}
								style={{ height: '100%', border: '0', width: '100%' }}
							></iframe>
						</div>
					) : (
						<div className="mobile_sigan_iframe">
							<iframe
								src={'https://sigan24.cafe24.com/graph/m_flex.html'}
								style={{ height: '100%', border: '0', width: '100%' }}
							></iframe>
						</div>
					)}
				</section>
				<section className="mobile-hapl-section">
					<div className="img-section" onClick={() => history.push('/hapl')}>
						<h2 className="title">공간대여서비스</h2>
						<div className="img-wrapper">
							<img src={MainHapl} />
							<div className="bg">
								<div className="first">
									업계최초 <br /> 공간대여 서비스
								</div>
								<div className="second">
									명품시계 직거래도 프라이빗하게
									<br />
									하이시간 공간대여서비스를 이용하세요.
								</div>
							</div>
						</div>
					</div>
					<div className="reserve-section" onClick={() => history.push('/hapl')}>
						<div className="part">
							<div className="head">부티크</div>
							<ul className="body">
								{haplData?.map((el, idx) => (
									<li className="list" key={idx}>
										{el.store.name || ''}
									</li>
								))}
							</ul>
						</div>
						<div className="part">
							<div className="head">예약일시</div>
							<ul className="body">
								{haplData?.map((el, idx) => (
									<li className="list" key={idx}>
										{el.day || ''}
									</li>
								))}
							</ul>
						</div>
						<div className="part">
							<div className="head">예약자</div>
							<ul className="body">
								{haplData?.map((el, idx) => (
									<li className="list" key={idx}>
										{el.name ? CommonHelper.masking(el.name) : ''}
									</li>
								))}
							</ul>
						</div>
					</div>
				</section>
				<section className="boutique" style={{ marginTop: '0' }}>
					<StoreSlide />
				</section>
				<section className="review">
					<h2 style={{ padding: '0 0 5vw 0' }}>베스트리뷰</h2>
					<ReviewSlide siteState={siteState} reviewData={reviewData} />
				</section>
				<h2 style={{ padding: '0 0 0 5vw', marginTop: '5vw' }}>바로가기</h2>

				<OtherSlide siteState={siteState} />
				{/* <section className="popular_brand">
					<h2>하이시간 인기 브랜드</h2>
					<ul>
						<li onClick={() => goToBuyPageSearchBrand('롤렉스')}>
							<img src={Brand1} alt="" />
							<p>Rolex</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('파텍필립')}>
							<img src={Brand2} alt="" />
							<p>
								Patek <br />
								Philppe
							</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('오데마피게')}>
							<img src={Brand3} alt="" />
							<p>
								Audemars <br />
								Piguet
							</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('리차드밀')}>
							<img src={Brand4} alt="" />
							<p>
								Richard <br />
								mille
							</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('IWC')}>
							<img src={Brand5} alt="" />
							<p>IWC</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('예거 르쿨트르')}>
							<img src={Brand6} alt="" />
							<p>
								Jaeger <br />
								lecoultre
							</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('오메가')}>
							<img src={Brand7} alt="" />
							<p>Omega</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('까르띠에')}>
							<img src={Brand8} alt="" />
							<p>Cartier</p>
						</li>
						<li onClick={() => goToBuyPageSearchBrand('브레게')}>
							<img src={Brand9} alt="" />
							<p>Breguet</p>
						</li>
					</ul>
				</section> */}
				<RecentlyProducts />

				<section className="instar_list">
					<h2>하이시간 인스타그램</h2>
					<ul id="insta_list_api">
						{/* {instaList.map((el, idx) => ( */}
						<li>
							<a href="https://www.instagram.com/hisigan_official/" target="_blank">
								<img src={Insta} alt="" />
							</a>
						</li>
						{/* ))} */}
					</ul>
					<a href="https://www.instagram.com/hisigan_official/" target="_blank">
						인스타그램 바로가기
					</a>
				</section>

				<section className="movie_list">
					<YoutubeSlide />
				</section>
				<section className="popular_brand">
					<h2>인증서 및 수상내역</h2>
					<ul style={{ marginTop: '7vw', marginBottom: '13vw' }}>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification1} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification2} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification3} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification4} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification5} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification6} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification7} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification8} alt="" />
						</li>
						<li style={{ backgroundColor: 'unset' }}>
							<img src={Certification9} alt="" />
						</li>
					</ul>
				</section>
			</main>
		</>
	);

	function searchWatchModel() {
		searchWatchModelApi(topSearchBox).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setResultModelArray(data || []);
			} else {
				alert(detail);
			}
		});
	}

	// function getMobileMainRecentRegister() {
	// 	getMobileMainRecentRegisterApi().then(({ data: { data, detail, status } }) => {
	// 		if (status === 'SUCCESS') {
	// 			setRecentRegisterProducts(data.content || []);
	// 		} else {
	// 			alert(detail);
	// 		}
	// 	});
	// }

	function getInstaList() {
		getInstaListApi().then((response) => {
			setInstaList(response?.data?.data.slice(0, 6) || []);
		});
	}

	function getMainHaplList() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getHaplMainListApi(null, '', 0).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setHaplData(data?.content?.slice(0, 5) || []);
			} else {
				alert(detail);
			}
		});
	}
};
export default Main;

const Wrapper = styled.div`
	.swiper-pagination-bullets {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0;
		z-index: 100;
		/* left:50% */
		width: 100%;
	}
	.swiper-pagination-bullet {
		background-color: black;
		opacity: 0.7;
		margin: 5px;
		/* width: 15px;
		height: 15px; */
		margin-bottom: 12px;
	}
	.swiper-pagination-bullet-active {
		background-color: #b08c71;
		opacity: 1;
		margin: 5px;
		/* width: 15px;
		height: 15px; */
		margin-bottom: 12px;
	}
`;
