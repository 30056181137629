import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CommonHelper from 'helpers/CommonHelper';
import { getMonthReservationInfoApi } from 'apis/hapl';
const TIME = {
	// '10:00~11:00': 'H10',
	'11:00~12:00': 'H11',
	'12:00~13:00': 'H12',
	'13:00~14:00': 'H13',
	'14:00~15:00': 'H14',
	'15:00~16:00': 'H15',
	'16:00~17:00': 'H16',
	'17:00~18:00': 'H17',
	// '18:00~19:00': 'H18',
};

const HOLIDAY = {
	'2022-01-01': '2022-01-01',
	'2022-05-05': '2022-05-05',
	'2022-05-19': '2022-05-19',
	'2022-06-06': '2022-06-06',
	'2022-08-15': '2022-08-15',
	'2022-09-20': '2022-09-20',
	'2022-09-21': '2022-09-21',
	'2022-09-22': '2022-09-22',
	'2022-10-03': '2022-10-03',
	'2022-10-09': '2022-10-09',
	'2022-12-25': '2022-12-25',
};

const HaplCalendarModify = ({
	test,
	selectedDay,
	setSelectedDay,
	selectedTime,
	setSelectedTime,
	timesInSelect,
	setTimesInSelect,
	reservationForm,
	setReservationForm,
	detailInfo,
	setTest,
}) => {
	const [getMoment, setMoment] = useState(moment());
	const today = getMoment; // today == moment()
	const [openSelectTime, setOpenSelectTime] = useState(false);
	// const [timesInSelect, setTimesInSelect] = useState([]);
	const [calendarMonth, setCalendarMonth] = useState(today.format('YYYY-MM')); // 캘린더 데이터 가져올 month
	const [monthReservation, setMonthReservation] = useState(); // 캘린더 month별 data
	const [nowHour, setNowHour] = useState(); // 현재시간

	const weekString = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	const firstWeek = today.clone().startOf('month').week();
	const lastWeek =
		today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

	useEffect(() => {
		setCalendarMonth(today.format('YYYY-MM'));
	}, [getMoment]);

	// useEffect(() => { // detail Info 들고오려고 했던거
	// 	let [year, month, day] = detailInfo.day.split('-');
	// 	let getMonth = `${year}-${month}`;
	// 	setCalendarMonth(getMonth);
	// 	setMoment(moment(detailInfo.day))
	// }, [detailInfo]);

	const changeSelectedDay = (dayFormat) => {
		setSelectedDay(dayFormat);
		setSelectedTime(null);
		setOpenSelectTime(false);
		let copy = { ...reservationForm };
		copy.day = dayFormat;
		copy.time = '';
		setReservationForm(copy);
	};

	const selectOneTime = (time) => {
		if (
			!time.breakTime &&
			!time.past &&
			!time.reserved &&
			time.possible &&
			moment(selectedDay).day() !== 0
		) {
			setOpenSelectTime(false);
			setSelectedTime(time);
			let copy = { ...reservationForm };
			copy.time = TIME[time.range];
			setReservationForm(copy);
		}
	};

	useEffect(() => {
		if (detailInfo && calendarMonth) {
			getMonthReservationInfo();
		}
	}, [detailInfo, calendarMonth]);

	useEffect(() => {
		if (selectedDay && monthReservation && getMoment) {
			getTimesOneDay(selectedDay, monthReservation);
		}
	}, [selectedDay, monthReservation, getMoment]);

	useEffect(() => {
		if (timesInSelect) {
			let findOneModifyHapl = timesInSelect.filter(
				(el) => selectedDay === reservationForm.day && el.range === detailInfo.timeStr,
			);
			findOneModifyHapl.length > 0 && setSelectedTime(findOneModifyHapl[0]);
		}
	}, []);

	const getSoldTimeForDay = (arr) => {
		let result = {};
		const values = Object.values(arr); // [ 1, 2, 3, 4, 'Hello' ]
		for (let i = 0; i < values.length; i++) {
			let v = values[i].timeMap;
			result[values[i].day] = [];
			// console.log(v)
			const values2 = Object.values(v);
			let count = 0;
			for (let t = 0; t < values2.length; t++) {
				let test = values2[t].reserved;
				// console.log(test)
				if (!!test) {
					count = count + 1;
				}
			}
			result[values[i].day].push(count);
		}
		setTest(result);
	};
	const calendarArr = () => {
		let result = [];
		for (let week = firstWeek; week <= lastWeek; week++) {
			result = result.concat(
				<tr key={week} className="day-tr">
					{Array(7)
						.fill(0)
						.map((data, index) => {
							let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day');
							let todayCacu = moment();

							if (selectedDay === days.format('YYYY-MM-DD')) {
								return (
									<td
										key={index}
										className="day-td today"
										onClick={() => changeSelectedDay(days.format('YYYY-MM-DD'))}
									>
										<p className="day">{days.format('D')}</p>
										<p>
											{test &&
											days.format('YYYY-MM-DD') &&
											test[days.format('YYYY-MM-DD')]?.length > 0
												? `${test[days.format('YYYY-MM-DD')][0]}/${
														detailInfo.store.id === 4 ? '7' : 
														'6'
												  }`
												: ''}
										</p>
									</td>
								);
							} else if (days.format('MM') !== today.format('MM')) {
								return (
									<td key={index} style={{ visibility: 'hidden' }} className="day-td">
										<p className="day">{days.format('D')}</p>
									</td>
								);
							} else {
									if (days.day() === 0) {
										return (
											<td
												key={index}
												className="day-td full"
												disabled
												readOnly
												onClick={() => alert('예약불가')}
											>
												<p className="day">{days.format('D')}</p>
												<p>휴무일</p>
											</td>
										);
									} else if (
										test &&
										days.format('YYYY-MM-DD') &&
										test[days.format('YYYY-MM-DD')]?.length > 0 &&
										test[days.format('YYYY-MM-DD')][0] > 7
									) {
										return (
											<td
												key={index}
												className="day-td full"
												disabled
												readOnly
												onClick={() => changeSelectedDay(days.format('YYYY-MM-DD'))}
											>
												<p className="day">{days.format('D')}</p>
												<p>
													{test &&
													days.format('YYYY-MM-DD') &&
													test[days.format('YYYY-MM-DD')]?.length > 0
														? `${test[days.format('YYYY-MM-DD')][0]}/${'6'}`
														: ''}
												</p>
											</td>
										);
									} else if (todayCacu > days) {
										return (
											<td
												key={index}
												className="day-td full"
												disabled
												readOnly
												onClick={() => changeSelectedDay(days.format('YYYY-MM-DD'))}
											>
												<p className="day">{days.format('D')}</p>
												<p>
													{test &&
													days.format('YYYY-MM-DD') &&
													test[days.format('YYYY-MM-DD')]?.length > 0
														? `${test[days.format('YYYY-MM-DD')][0]}/${'6'}`
														: ''}
												</p>
											</td>
										);
									} else {
										return (
											<td
												key={index}
												className="day-td"
												onClick={() => changeSelectedDay(days.format('YYYY-MM-DD'))}
											>
												<p className="day">{days.format('D')}</p>
												<p>
													{test &&
													days.format('YYYY-MM-DD') &&
													test[days.format('YYYY-MM-DD')]?.length > 0
														? `${test[days.format('YYYY-MM-DD')][0]}/${'6'}`
														: ''}
												</p>
											</td>
										);
									}
							}
						})}
				</tr>,
			);
		}
		return result;
	};

	return (
		<div className="calendar">
			<div className="control">
				<div className="left">
					<button
						onClick={() => {
							setMoment(getMoment.clone().subtract(1, 'month'));
						}}
						className="prev"
					>
						{`<`}
					</button>
					<span className="year-month">{today.format('YYYY 년 MM 월')}</span>
					<button
						onClick={() => {
							setMoment(getMoment.clone().add(1, 'month'));
						}}
						className="next"
					>
						{`>`}
					</button>
					<span className="desc">[신청수/총모집수]</span>
				</div>
				<div className="right">
					<div className="not-possible">예약불가</div>
					<div className="possible">선택</div>
					<div className="select" onClick={() => setOpenSelectTime(!openSelectTime)}>
						{selectedTime ? selectedTime.range : '시간선택'}
						{/* select랑 에약내역 보여주기 */}
						<div className="triangle down"></div>
					</div>
					{openSelectTime ? (
						<ul className="time-select">
							{timesInSelect.map((time, idx) => (
								<li
									key={idx}
									className={
										!!time.breakTime ||
										!!time.past ||
										!!time.reserved ||
										!time.possible ||
										moment(selectedDay).day() === 0
											? 'disabled'
											: ''
									}
									onClick={() => selectOneTime(time)}
								>
									·&nbsp;{time.range}&nbsp;
									{!!time.breakTime
										? '점심시간'
										: !!time.past || !!time.reserved || !time.possible
										? '예약완료'
										: '예약가능'}
									{selectedTime === time ? <div className="selected-content">v</div> : ''}
								</li>
							))}
						</ul>
					) : (
						''
					)}
				</div>
			</div>
			<div className="container-gray">
				<table>
					<tbody className="week-body">
						<tr className="week-string">
							{weekString.map((el, idx) => (
								<td className="one-title" key={idx}>
									{el}
								</td>
							))}
						</tr>
					</tbody>
				</table>
				<table className="day-table">
					<tbody>{calendarArr()}</tbody>
				</table>
			</div>
		</div>
	);
	function getTimesOneDay(oneDay, obj) {
		// 2022-03-01들어오면
		// obj['2022-03-01'] -> timeMap 객체 -> 배열화
		if (oneDay && obj && obj[oneDay]) {
			let mapObj = obj[oneDay];
			const values = Object.values(mapObj.timeMap).slice(1, 8); //
			const nowDate = new Date();
			const newValues = values.map((el) => {
				const valueDate = new Date(`${selectedDay} ${el.range.split('~')[0]}:00`);
				let result = Math.floor((valueDate - nowDate) / 1000 / 60 / 60);
				// if (result < 24) {
				// 	el.possible = false;
				// } else {
				// 	el.possible = true;
				// }
				el.possible = true;
				return el;
			});
			setTimesInSelect(newValues);
		}
	}

	//step1 달력 현황
	function getMonthReservationInfo() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getMonthReservationInfoApi(detailInfo?.store?.id, authToken, calendarMonth).then(
			({ data: { data, detail, status } }) => {
				if (status === 'SUCCESS') {
					setMonthReservation(data);
					getSoldTimeForDay(data);
				} else {
					alert(detail);
				}
			},
		);
	}
};

export default HaplCalendarModify;
