import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { useHistory } from 'react-router-dom';
import GoogleMap from './MobileGoogleMap';
import branchDaegu from 'assets-mobile/img/branch/branch_daegu.png';
import Daegu1 from 'assets-pc/front-img/m-daegu-01.png';
import Daegu2 from 'assets-pc/front-img/m-daegu-02.png';
import Daegu3 from 'assets-pc/front-img/m-daegu-03.png';

const Daegu = ({ setNowStoreId }) => {
	const history = useHistory();
	let widthAndHeight = (window.innerWidth / 3) * 2;
	const sizeCheck = () => {
		widthAndHeight = (window.innerWidth / 3) * 2;
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	useEffect(() => {
		let swiper = new Swiper('.branch_img', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			pagination: {
				el: '.branch_img_paging',
				clickable: true,
			},
		});
	}, []);
	const goStore = () => {
		history.push('/hapl');
	};
	return (
		<>
			<section className="branch_tab">
				<a onClick={() => setNowStoreId(0)}>본사</a>
				<a onClick={() => setNowStoreId(1)}>압구정점</a>
				<a onClick={() => setNowStoreId(2)}>마린시티점</a>
				<a className="on">대구수성점</a>
			</section>

			<section className="swiper-container branch_img">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<img src={Daegu1} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Daegu2} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Daegu3} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
				</div>
				<div className="branch_img_paging swiper-pagination"></div>
			</section>

			<section className="greetings">
				<h3>
					하이시간, <span>대구의 인사말</span>
				</h3>
				<p>당신의 일상에 특별함과 즐거움을 선물 해 주는 곳, 하이시간 대구 부티크입니다.</p>
				<p>
					대구의 심장부, 수성구 그곳에서 시계 전문가가 고객님께 더 풍부하고, 더 혁신적인 맞춤
					서비스를 제공 해 드립니다.
				</p>
				<p>
					소수만이 누릴 수 있는 특별한 시계를 만나는 행운을 하이시간 대구 부티크에서 경험해 보세요.
					부티크에서 만나 뵙기를 기대합니다.
				</p>
			</section>

			<section className="branch_intro">
				<p className="t1">Hisigan Boutique</p>
				<h3>대구 수성점</h3>
				<p className="adress">대구광역시 수성구 동대구로 59제 1 대우트럼프월드아파트 제B동</p>
				<div className="map_box">
					<GoogleMap
						places="place_id:ChIJcy4LWuXjZTUR9opdbVqE2Ls"
						// latitude={Number(shopDatas?.latitude) ? Number(shopDatas.latitude) : ''}
						// longitude={Number(shopDatas?.longitude) ? Number(shopDatas.longitude) : ''}
					/>
					{/* <iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.446148721292!2d128.6199000152631!3d35.838062280158006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3565e259fac83a77%3A0x7fcfeaa152a3d5c0!2z64yA6rWs6rSR7Jet7IucIOyImOyEseq1rCDrkZDsgrDrj5kg7LKt7IiY66GcMjbquLggMzYgMzA27Zi4!5e0!3m2!1sko!2skr!4v1621479200497!5m2!1sko!2skr"
							style="border:0;"
							// allowfullscreen=""
							loading="lazy"
						></iframe> */}
				</div>
				<ul>
					<li>
						<b>대표 전화</b>
						<span>1644-5751</span>
					</li>
					<li>
						<b>직통 전화</b>
						<span>053-716-3051</span>
					</li>
					<li>
						<b>팩스</b>
						<span>053-716-3052</span>
					</li>
					<li>
						<b>영업시간</b>
						<div>
							<p>
								FROM MONDAY TO SATURDAY <br />
								<b>10AM-7PM</b>
							</p>
							<p>* 일요일과 공휴일은 휴무</p>
						</div>
					</li>
					<li>
						<b>이메일</b>
						<span>hi_sigan@naver.com</span>
					</li>
				</ul>
			</section>
		</>
	);
};

export default Daegu;
