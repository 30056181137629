import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router';
import HaplMain from './components/HaplMain';
import HaplStep1 from './components/HaplStep1';
import HaplStep2 from './components/HaplStep2';
import HaplDetail from './components/HaplDetail';
import HaplStep1Modify from './components/HaplStep1Modify';

import { getStoreListApi } from 'apis/buy';
import CommonHelper from 'helpers/CommonHelper';
import HaplReservation from '../../assets-pc/front-img/hapl-4.png';
import ButiqueInfo from '../../assets-pc/front-img/boutique-4.png';

import './index.scss';
const DEFAULT_STORE_SELECT = {
	name: '직영점 선택',
	id: null,
};

const Hapl = () => {
	const [filterSelectedStore, setFilterSelectedStore] = useState(DEFAULT_STORE_SELECT);
	const [currStep, setCurrStep] = useState('main'); // main, step1, step2, detail, modifyStep1
	const [oneStoreReservation, setOneStoreReservation] = useState();
	const [mainReservedInfo, setMainReservedInfo] = useState([]);
	const [responseAfterReserve, setResponseAfterReserve] = useState();
	const history = useHistory();

	const [detailId, setDetailId] = useState();

	const [storeList, setStoreList] = useState([]);

	const [detailInfo, setDetailInfo] = useState({});

	// main, detail, step1, step2 관리

	const goStep1 = (storeInfo) => {
		setOneStoreReservation(storeInfo);
		setCurrStep('step1');
	};

	useEffect(() => {
		getStoreList();
	}, []);

	const goStore = () => {
		history.push('/shop');
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currStep]);

	return (
		<div className="container hapl">
			<section className="header">
				<div className="sub-top">
					<h1>하이시간 공간대여 서비스</h1>
					<div className="sub-title">
						명품시계 어디서 직거래하시나요? <br />
						하이시간에서 직거래도 명품답게 거래하세요.
					</div>
					<div className="btn-box">
						<img src={HaplReservation} alt="공간대여 예약" className="reservation-img" />
						<img
							src={ButiqueInfo}
							alt="부티크 소개"
							className="store-img"
							onClick={goStore}
						/>
					</div>
				</div>
			</section>
			{currStep === 'main' ? (
				<HaplMain
					filterSelectedStore={filterSelectedStore}
					setFilterSelectedStore={setFilterSelectedStore}
					setCurrStep={setCurrStep}
					setOneStoreReservation={setOneStoreReservation}
					mainReservedInfo={mainReservedInfo}
					DEFAULT_STORE_SELECT={DEFAULT_STORE_SELECT}
					goStep1={goStep1}
					setDetailId={setDetailId}
					storeList={storeList}
					currStep={currStep}
					setMainReservedInfo={setMainReservedInfo}
				/>
			) : (
				''
			)}
			{currStep === 'step1' ? (
				<HaplStep1
					setCurrStep={setCurrStep}
					oneStoreReservation={oneStoreReservation}
					setResponseAfterReserve={setResponseAfterReserve}
				/>
			) : (
				''
			)}
			{currStep === 'step2' ? (
				<HaplStep2
					responseAfterReserve={responseAfterReserve}
					oneStoreReservation={oneStoreReservation}
					setCurrStep={setCurrStep}
					setDetailId={setDetailId}
				/>
			) : (
				''
			)}
			{currStep === 'detail' ? (
				<HaplDetail
					detailId={detailId}
					storeList={storeList}
					setCurrStep={setCurrStep}
					detailInfo={detailInfo}
					setDetailInfo={setDetailInfo}
				/>
			) : (
				''
			)}
			{currStep === 'modifyStep1' ? (
				<HaplStep1Modify detailInfo={detailInfo} setCurrStep={setCurrStep} />
			) : (
				''
			)}
		</div>
	);

	// 부티크 지점 정보
	function getStoreList() {
		getStoreListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}
};

export default Hapl;
