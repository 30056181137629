import React, { useState, useEffect } from 'react';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const FaqDetail = ({ setOpenDetail, oneFaqData }) => {
	const [photoSize, setPhotoSize] = useState('');
	const sizeCheck = () => {
		setPhotoSize(window.innerWidth);
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);
	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setOpenDetail(false)}></button>
					<h2>고객센터</h2>
				</section>

				<section className="faq_view" style={{ paddingRight: '6.667vw' }}>
					<p className="tit" style={{ width: '90%' }}>
						{oneFaqData?.question ? oneFaqData.question : '-'}
					</p>
					<p
						style={{
							fontSize: '3.889vw',
							color: '#31383f',
							marginBottom: '5.556vw',
							lineHeight: '1.6',
						}}
					>
						<Wrapper size={photoSize}>
							<Viewer
								initialValue={replaceNewlineWithBrTag(decodingHtmlTags(oneFaqData.answer))}
								initialEditType="markdown"
							/>
						</Wrapper>
					</p>
				</section>
			</main>
		</>
	);
};

export default FaqDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	/* display: flex;
	justify-content: center; */
	img {
		width: ${(props) => (props.size ? `${props.size}px` : '240px')} !important;
	}
`;
