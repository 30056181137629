import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getMobileHaplMainListApi } from 'apis/hapl';
import CommonHelper from 'helpers/CommonHelper';
import Pagination from 'components/Notice/Pagination';
import { HAPLE_STATUS } from 'common/constants';

const HaplMain = ({
	setOneStoreSearch,
	storeList,
	oneStoreSearch,
	currStep,
	setCurrStep,
	setDetailId,
	filterSelectedStore,
	setFilterSelectedStore,
	DEFAULT_STORE_SELECT,
}) => {
	const [openSelect, setOpenSelect] = useState(false);
	const history = useHistory();
	const [mainReservedInfo, setMainReservedInfo] = useState({});
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	const LIMIT = 10;
	const [page, setPage] = useState(0);

	const goStore = () => {
		history.push('/store');
	};
	// useEffect(() => {
	// 	if (currStep === 'main') {
	// 		getMainList();
	// 	}
	// }, [filterSelectedStore, page, currStep]);

	useEffect(() => {
		getMainList(filterSelectedStore, 0);
	}, []);

	const { totalPage } = mainReservedInfo;

	const pageNumbers = [];
	for (let i = 0; i < totalPage; i++) {
		pageNumbers.push(i + 1);
	}
	const newPageNumbers = pageNumbers.slice(start, end);

	const paginate = (number) => {
		const num = number === 0 ? number : number - 1;
		setPage(num);
		getMainList(filterSelectedStore, num);
	};

	const prevPage = () => {
		if (page + 1 === 1) {
			return alert('첫페이지입니다.');
		}
		if ((page + 1) % 4 === 1) {
			const s = start > 0 ? start - 4 : 0;
			const e = end > 0 ? end - 4 : end;

			setStart(s);
			setEnd(e);
		}
		setPage(page - 1);
		getMainList(filterSelectedStore, page - 1);
	};

	const nextPage = () => {
		if (page + 2 > pageNumbers.length) {
			return alert('마지막페이지입니다.');
		} else {
			setPage(page + 1);
			if ((page + 2) % 4 === 1) {
				const s = start + 4;
				const e = end + 4;
				setStart(s);
				setEnd(e);
			}
		}
		getMainList(filterSelectedStore, page + 1);
	};

	const goDetailPage = (id) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('로그인 후 조회가 가능합니다.');
			history.push('/signin?redirectUrl=/hapl');
		} else {
			setDetailId(id);
			setCurrStep('detail');
		}
	};

	const selectOneStore = (el) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('로그인 후 이용이 가능합니다.');
			history.push('/signin?redirectUrl=/hapl');
		} else {
			setOneStoreSearch(el);
			setCurrStep('step1');
		}
	};

	const handleSelect = () => {
		setOpenSelect(!openSelect);
	};

	const changeSelectedStore = (store) => {
		setFilterSelectedStore(store);
		setPage(0);
		setStart(0);
		setEnd(4);
		getMainList(store, 0);
	};
	return (
		<>
			<div className="all-store" onClick={() => setOneStoreSearch(null)}>
				전체직영점
			</div>
			<div className="store-box">
				<div className="first-floor">
					{storeList.map((el, idx) => (
						<button
							key={idx}
							className={oneStoreSearch === el ? 'selected-store' : ''}
							// onClick={() => setOneStoreSearch(el)}
							onClick={() => selectOneStore(el)}
						>
							{el.name} 예약
						</button>
					))}
				</div>
			</div>
			<div className="bar"></div>
			<div className="select-store" onClick={handleSelect}>
				<span className="selected-name">{filterSelectedStore.name}</span>
				<div alt="triangle img" className={openSelect ? 'triangle-img up' : 'triangle-img'} />
				{!!openSelect ? (
					<div className="select-list">
						<div className="oneSelect" onClick={() => changeSelectedStore(DEFAULT_STORE_SELECT)}>
							{DEFAULT_STORE_SELECT.name}
						</div>
						{storeList.map((store, idx) => (
							<div key={idx} className="oneSelect" onClick={() => changeSelectedStore(store)}>
								{store.name}
							</div>
						))}
					</div>
				) : (
					''
				)}
			</div>
			<div className="list-box">
				<ul>
					{mainReservedInfo?.content?.length > 0 &&
						mainReservedInfo.content.map((list, idx) => (
							<li key={idx} className="list" onClick={() => goDetailPage(list.id)}>
								<span className="name">{list?.name ? CommonHelper.masking(list.name) : ''}</span>
								<div className="mini-bar"></div>
								<span className="store-name">{list.store.name}</span>
								<div className="mini-bar"></div>
								<span className="day">{list.day}</span>
								<div className="mini-bar"></div>
								<span className="time-str">{list.timeStr}</span>
								<span
									className=""
									style={
										list.status === 'WAIT'
											? {
													marginRight: '1.5vw',
													width: '12vw',
													height: '6vw',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													backgroundColor: 'rgba(185, 160, 126, 1)',
													color: 'white',
											  }
											: list.status === 'CANCEL'
											? {
													marginRight: '1.5vw',
													width: '12vw',
													height: '6vw',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													backgroundColor: 'rgba(145, 151, 207, 1)',
													color: 'white',
											  }
											: {
													marginRight: '1.5vw',
													width: '12vw',
													height: '6vw',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: 'black',
													border: '1px solid rgba(196, 196, 196, 1)',
											  }
									}
								>
									{HAPLE_STATUS[list?.status] || '-'}
								</span>
								<span className="arrow"></span>
							</li>
						))}
				</ul>
			</div>
			<div className="paging-box">
				<Pagination
					// noticeData={noticeData}
					prevPage={prevPage}
					paginate={paginate}
					nextPage={nextPage}
					newPageNumbers={newPageNumbers}
					page={page}
				/>
			</div>
		</>
	);
	// 메인화면 예약 현황 리스트
	function getMainList(store, page) {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getMobileHaplMainListApi(store, authToken, page).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setMainReservedInfo(data || {});
			} else {
				alert(detail);
			}
		});
	}
};

export default HaplMain;
