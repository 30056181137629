import React, { useState, useEffect } from 'react';
import Slick from 'react-slick';
import Swiper from 'swiper';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets-mobile/libs/swiper.min.css';
import YoutubeThumbnail1 from 'assets-pc/front-img/youtube33.jpeg';
import YoutubeThumbnail2 from 'assets-pc/front-img/youtube22.jpeg';
import YoutubeThumbnail3 from 'assets-pc/front-img/youtube11.jpeg';

const YoutubeSlide = () => {
	let [mySwiper, setMySwiper] = useState(null);
	const [totalWidth, setTotalWidth] = useState('');
	const sliderLength = 3;
	const onBeforeChange = (older, newidx) => {
		let calc = newidx / sliderLength; // 7 은 총 슬라이더 갯수
		let newCalc = (calc * sliderLength + 1) / sliderLength;
		// spanRef.current.style.transForm = `translate3d(0px,0px,0px) scaleX(${newCalc}) scaleY(1)`;
		// spanRef.current.style.width = newCalc * 100 + '%';
	};
	const sizeCheck = () => {
		setTotalWidth(window.innerWidth);
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);
	const settings = {
		beforeChange: onBeforeChange,
		fade: true,
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 3000,
		arrows: false,
		// appendDots: (dots) => (
		// 	<div>
		// 		<ul className="custom" style={{ bottom: '116%', left: '85%' }}>
		// 			{dots}
		// 		</ul>
		// 	</div>
		// ),

		customPaging: (i) => <div>{i + 1}</div>,
	};
	const imgStyle = {
		// width: '100%',
		// height: '100%',
		position: 'absolute',
		width: '600px',
		overflow: 'hidden',
		height: '400px',
		cursor: 'pointer',
	};
	useEffect(() => {
		const swiper3 = new Swiper('.youtube_list', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: false,
			pagination: {
				el: '.youtube_list_paging',
				clickable: true,
			},
		});
	}, []);

	return (
		<>
			<h2>하이시간 TV 최신영상</h2>
			<div className="swiper-container youtube_list">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<div className="youtube_box">
							<a
								href={`https://www.youtube.com/watch?v=z6qPPHYZ-zk`}
								target="_black"
								style={{
									// position: 'absolute',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								<img src={YoutubeThumbnail1} style={{ width: '100%' }} />
							</a>
						</div>
						<p>
						[하이시간TV] 2023년 롤렉스 신제품 발표 롤렉스의 알록달록한 세상
						</p>
					</div>
					<div className="swiper-slide">
						<div className="youtube_box">
							<a
								href={`https://www.youtube.com/watch?v=iNgOpXBMS-8&t=541s`}
								target="_black"
								style={{
									// position: 'absolute',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								<img src={YoutubeThumbnail2} style={{ width: '100%' }} />
							</a>
						</div>
						<p>
						[하이시간TV] 오데마피게 로얄오크 시계에 전부 다이아로 커스텀을 한다면? 당신의 선택은? 
							{/* 당신이 롤렉스 오데마피게를 성골을 못하는 이유 우리가 딱 알려드림 왜 나는 롤렉스를 못
							... */}
						</p>
					</div>
					<div className="swiper-slide">
						<div className="youtube_box">
							<a 
								href={`https://www.youtube.com/watch?v=-ZIaknL9T_4`}
								target="_black"
								style={{
									// position: 'absolute',
									width: '126%',
									left: '-13%',
									overflow: 'hidden',
								}}
							>
								<img src={YoutubeThumbnail3} style={{ width: '100%' }} />
							</a>
						</div>
						<p>
						[하이시간TV] 롤렉스를 이길자 누구인가? 성골하면 프리미엄 상승 4월 셋째 주 롤렉스 시세는?
						</p>
					</div>
				</div>
			</div>
			<div className="youtube_list_paging swiper-pagination"></div>
			<a href="https://www.youtube.com/channel/UChurZ73ZijJ3wSSplPlXE5g" target="_black">
				유튜브 바로가기
			</a>

			{/* <h2>하이시간 TV 최신영상</h2>
			<SliderWripper>
				<div className="swiper-container youtube_list">
					<div className="swiper-wrapper">
						<Slick {...settings}>
							<div className="youtube-swiper-slide">
								<div className="youtube_box">
									<a
										href={`https://www.youtube.com/watch?v=6XxcemQER3w`}
										target="_black"
										style={{
											// position: 'absolute',
											width: '100%',
											overflow: 'hidden',
										}}
									>
										<img src={YoutubeThumbnail1} style={{ width: '100%' }} />
									</a>
								</div>
								<p>
									롤렉스 서브마리너 신형 진품 짝퉁 다가지고옴! 두 제품 전격비교 시계거래소에서 이슈
									됐던...
								</p>
							</div>
							<div className="youtube-swiper-slide">
								<div className="youtube_box">
									<a
										href={`https://www.youtube.com/watch?v=JNz_yLXlXLI`}
										target="_black"
										style={{
											// position: 'absolute',
											width: '100%',
											overflow: 'hidden',
										}}
									>
										<img src={YoutubeThumbnail2} style={{ width: '100%' }} />
									</a>
								</div>
								<p>
									롤렉스 백화점 성골 팁 대 방출 그리고 하이시간에서 얘기하는 성골? 그리고 롤렉스 VIP
									정책...
								</p>
							</div>
							<div className="youtube-swiper-slide">
								<div className="youtube_box">
									<a
										href={`https://www.youtube.com/watch?v=XGMGZyXng84`}
										target="_black"
										style={{
											// position: 'absolute',
											width: '126%',
											left: '-13%',
											overflow: 'hidden',
										}}
									>
										<img src={YoutubeThumbnail3} style={{ width: '100%' }} />
									</a>
								</div>
								<p>
									시계거래소에서 핫 했던 사건 롤렉스 서브마리너 헐크 가품 사건을 각색한 하이드라마
									가짜 시계를 판 사나이
								</p>
							</div>
						</Slick>
					</div>
				</div>
			</SliderWripper>
			<a href="https://www.youtube.com/channel/UChurZ73ZijJ3wSSplPlXE5g" target="_black">
				유투브
			</a> */}
		</>
	);
};
export default YoutubeSlide;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-slide {
		visibility: hidden;
	}
	.slick-slide.slick-active {
		visibility: visible;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;

		opacity: 0.75;
		color: white;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -25px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -25px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		/* margin-bottom: 30px; */
	}

	.slick-dots {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		/* width: 20px; */
		/* height: 2px; */
		/* margin: 0 6px; */
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		/* width: 20px; */
		/* height: 2px; */
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */
	/* .slick-dots li {
} */

	.slick-dots .slick-active {
		color: #31383f;
		border-radius: 0;
		opacity: 1;
		overflow: hidden;
	}
`;
