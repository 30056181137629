import { apiCall } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getMostSoldProductsApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/reservation/sale/list/top/sold?limit=6`, 'get', {}, {});
};
export const getModelListApi = (modelInput) => {
	return apiCall(
		`${SERVER_API_HOST}/api/filter/standard`,
		'post',
		{
			filters: [
				{
					type: 'INTEGRATED',
					keyword: modelInput,
				},
			],
		},
		{},
	);
};

export const sendRegisterSaleApi = (authToken, registerInfo, convertVisitDate, oneModel) => {
	let storeArray = [registerInfo.store.toString()];
	return apiCall(
		`${SERVER_API_HOST}/api/reservation/sale/create`,
		'post',
		{
			watch: {
				brand: oneModel.brand || null,
				model: oneModel.model || null,
				serialNo: registerInfo.serialNo || null,
				caseDiameter: registerInfo.caseDiameter || null,
				caseMaterial: oneModel.caseMaterial || null,
				dialType: oneModel.dialType || null,
				dialColor: oneModel.dialColor || null,
				movement: registerInfo.movement,
				producedYear: registerInfo.producedYear,
				supplements: registerInfo.supplements,
				gender: registerInfo.gender,

				condition: registerInfo.condition || null,
			},
			attachedFiles: registerInfo.attachedFiles || [],
			useAuction: registerInfo.useAuction,
			storeIds: storeArray,
			visitDateTime: convertVisitDate,
			price: registerInfo.price,
			description: registerInfo.description,
			holdingType: registerInfo.holdingType,
			hasReceipt: registerInfo.hasReceipt,
		},
		{
			authorization: authToken,
		},
	);
};

export const getOneModelDetailApi = (id) => {
	return apiCall(`${SERVER_API_HOST}/api/watch/get/${id}`, 'get', {}, {});
};

// caseMaterial: '스틸',
// dialColor: '검정색',
// dialType: '바인덱스',
