import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import styled, { css } from 'styled-components';

const BuyDetailSlider = ({ oneSellingProductInfo, onePhotoUrl, setOnePhotoUrl }) => {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
	};
	const onClickonePhotoUrl = (photoUrl) => {
		setOnePhotoUrl(photoUrl);
	};

	return (
		<>
			<SliderWripper oneSellingProductInfo={oneSellingProductInfo}>
				<section className="buy-slider-wrapper-plus">
					<Slider {...settings} className="buy_slider_flex">
						{oneSellingProductInfo?.attachedFiles?.map((photoUrl, idx) => (
							<div
								className={onePhotoUrl === photoUrl ? 'on img_wrapper' : 'img_wrapper'}
								key={idx}
								onClick={() => onClickonePhotoUrl(photoUrl)}
							>
								<a>
									<img src={photoUrl || null} alt="시계 사진" />
								</a>
							</div>
						))}
					</Slider>
				</section>
			</SliderWripper>
		</>
	);
};

export default BuyDetailSlider;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: 500px;
		height: 90px;
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		/* display: ${(props) =>
			props.oneSellingProductInfo?.attachedFiles?.length < 5 ? 'flex' : 'block'}; */
		display: flex;

		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		align-items: center;
		flex-direction: column;
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
		.slick-track {
			margin-left: 0;
		}
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 36px;
		line-height: 1;
		width: 30px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: 0px;
		width: 30px;
		top: 45px;
	}
	[dir='rtl'] .slick-prev {
		/* right: -25px; */
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		/* right: -60px; */
		right: 21px;
		top: 45px;
		width: 30px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		/* left: -25px; */
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */

	.slick-dots li {
		width: 20px;
		height: 2 px;
		margin: 0 5px;
	}

	.slick-dots .slick-active {
		width: 20px;
		height: 2px;
		background: #31383f;
		border-radius: 0;
		opacity: 1;
		width: 20px;
		top: 0px;
		overflow: hidden;
	}
	.slick-slide .slick-active {
		height: 80px;
	}
`;
const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
`;
