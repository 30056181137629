import React from 'react';

const FilterMovement = ({
	setShowStores,
	selectedStoreIdList,
	setSelectedStoreIdList,
	selectedStoreList,
	setSelectedStoreList,
	currentStoreList,
}) => {
	const handleCheckBoxStore = (e) => {
		const { name, checked, id } = e.target;
		let copyStoreList = [...selectedStoreList];
		let copyStoreIdList = [...selectedStoreIdList];
		if (checked) {
			copyStoreList.push(name);
			copyStoreIdList.push(id);
		} else {
			copyStoreList = copyStoreList.filter((el) => el !== name);
			copyStoreIdList = copyStoreIdList.filter((el) => el !== id);
		}
		setSelectedStoreList(copyStoreList);
		setSelectedStoreIdList(copyStoreIdList);
	};

    const deleteAllStores = () => {
        setSelectedStoreList([])
        setSelectedStoreIdList([])
    }
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowStores(false)}></button>
					<h2>보유지점</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									보유지점
								</h3>
							</div>
							<ul>
								{currentStoreList.map((store, idx) => (
									<li key={idx}>
										<b>{store.name}</b>
										<input
											type="checkbox"
											name={store.name}
											id={store.id}
											checked={selectedStoreList.includes(store.name)}
											onChange={handleCheckBoxStore}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={deleteAllStores}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowStores(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterMovement;
