import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { useHistory } from 'react-router-dom';
import GoogleMapComponent from './MobileGoogleMap';
import Busan1 from 'assets-mobile/img/branch/marin_01.png';
import Busan2 from 'assets-mobile/img/branch/marin_02.png';
import Busan3 from 'assets-mobile/img/branch/marin_03.png';

const BusanMarin = ({ setNowStoreId }) => {
	const history = useHistory();
	let widthAndHeight = (window.innerWidth / 3) * 2;
	const sizeCheck = () => {
		widthAndHeight = (window.innerWidth / 3) * 2;
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	useEffect(() => {
		let swiper = new Swiper('.branch_img', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			pagination: {
				el: '.branch_img_paging',
				clickable: true,
			},
		});
	}, []);

	const goStore = () => {
		history.push('/hapl');
	};
	return (
		<>
			<section className="branch_tab">
				<a onClick={() => setNowStoreId(0)}>본사</a>
				<a onClick={() => setNowStoreId(1)}>압구정점</a>
				<a className="on">마린시티점</a>
				<a onClick={() => setNowStoreId(3)}>대구수성점</a>
			</section>

			<section className="swiper-container branch_img">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<img src={Busan1} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Busan2} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Busan3} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
				</div>
				<div className="branch_img_paging swiper-pagination"></div>
			</section>

			<section className="greetings">
				<h3>
					하이시간, <span>마린시티점 인사말</span>
				</h3>
				<p>
					하이엔드 브랜드 시계부터 직거래시 공간 대여 서비스까지, 안전하고 편리한 하이시간을
					경험해보세요.
					<br />
					<br />
					부산에서 즐길 수 있는 최고의 경험은 하이시간 부티크를 방문하는 것입니다. 해운대 마린시티에
					위치한 하이시간 부티크에서 최고급 품질의 시계들과 하이시간만이 갖고 있는 특별한 제품들을
					만나 보실 수 있습니다
				</p>
				<p>
					또한, 전문 수석 엔지니어로부터 시계를 최상으로 유지 관리하는 비법과 테크닉을 조언 받아
					시계의 기능을 <br />
					보다 잘 이해하게 될 것입니다.
				</p>
				<p>하이시간 마린시티 부티크와 함께 신비로운 시계의 여정을 함께 떠나 보세요.</p>
			</section>

			<section className="branch_intro">
				<p className="t1">Hisigan Boutique</p>
				<h3>마린시티점</h3>
				<p className="adress">부산광역시 해운대구 마린시티1로 167, 1동 120호</p>
				<div className="map_box">
					<GoogleMapComponent places="place_id:ChIJG3WJhECTaDURcUIofl6w0Xk" />
					{/* <iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.2292865826025!2d129.12322171524517!3d35.17583738031519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356893a1f749ee67%3A0xa48d07914d97bfa5!2z7ZWY7J207Iuc6rCE!5e0!3m2!1sko!2skr!4v1621479493029!5m2!1sko!2skr"
							style="border:0;"
							// allowfullscreen=""
							loading="lazy"
						></iframe> */}
				</div>
				<ul>
					<li>
						<b>대표 전화</b>
						<span>1644-5751</span>
					</li>
					<li>
						<b>직통 전화</b>
						<span>051-711-3054</span>
					</li>
					<li>
						<b>팩스</b>
						<span>051-711-3049</span>
					</li>
					<li>
						<b>영업시간</b>
						<div>
							<p>
								FROM MONDAY TO SATURDAY <br />
								<b>10AM-7PM</b>
							</p>
							<p>* 일요일과 공휴일은 휴무</p>
						</div>
					</li>
					<li>
						<b>이메일</b>
						<span>hi_sigan@naver.com</span>
					</li>
				</ul>
			</section>
		</>
	);
};

export default BusanMarin;
