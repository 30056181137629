import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import MemberOnlyRoute from './MemberOnlyRoute';
import Main from 'mobile-components/Main';
import Buy from 'mobile-components/Buy';
import Hpi from 'mobile-components/Hpi';
import Guarantee from 'mobile-components/Guarantee';
import MyReservationList from 'mobile-components/MyReservationList';
import SignIn from 'mobile-components/SignIn';
import FindMember from 'mobile-components/FindMember';
import SignUp from 'mobile-components/SignUp';
import FindWatch from 'mobile-components/Buy/components/FindWatch';
import BuyDetail from 'mobile-components/BuyDetail';
import Sell from 'mobile-components/Sell';
import SellStep from 'mobile-components/SellStep';
import Auction from 'mobile-components/Auction';
import AuctionDetail from 'mobile-components/AuctionDetail';
import Notice from 'mobile-components/Notice';
import NoticeDetail from 'mobile-components/NoticeDetail';
import Review from 'mobile-components/Review';
import ReviewDetail from 'mobile-components/ReviewDetail';
import Faq from 'mobile-components/Faq';
import Store from 'mobile-components/Store';
import TermsOfService from 'mobile-components/TermsOfService';
import PersonalInfoRule from 'mobile-components/PersonalInfoRule';
import CareService from 'mobile-components/CareService';
import Hapl from 'mobile-components/Hapl';
import BuyRoute from './BuyRoute';
const MobileRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Switch>
					{/* <Route path="/login" render={() => (isLogin ? <Redirect to="/" /> : <Login />)} /> */}
					{/* <Route path="/" exact component={Main} /> */}
					<Route
						path="/"
						exact
						component={() => {
							window.location.href = 'https://www.hisigan.co.kr/';
						}}
					/>
					<Route path="/signIn" exact component={SignIn} />
					<Route path="/signUp" exact component={SignUp} />
					<Route path="/findMember" exact component={FindMember} />
					<Route path="/findWatch" exact component={FindWatch} />
					<BuyRoute path="/buy" />
					<BuyRoute path="/buy/:id" />
					<BuyRoute path="/buy/brand/:brand" />
					<BuyRoute path="/buy/model/:model" />
					<BuyRoute path="/buy/reference/:reference" />
					{/* subbuy */}
					<Route path="/subbuy" exact component={Buy} />
					<Route path="/subbuy/:id" exact component={BuyDetail} />
					<Route path="/subbuy/brand/:brand" exact component={Buy} />
					<Route path="/subbuy/model/:model" exact component={Buy} />
					<Route path="/subbuy/reference/:reference" exact component={Buy} />
					{/* <Route path="/sell" exact component={Sell} /> */}
					<Route path="/auction" exact component={Auction} />
					<Route path="/auction/:id" exact component={AuctionDetail} />
					<Route path="/notice" exact component={Notice} />
					<Route path="/notice/:id" exact component={NoticeDetail} />
					<Route path="/review" exact component={Review} />
					<Route path="/review/:id" exact component={ReviewDetail} />
					<Route path="/faq" exact component={Faq} />
					<Route path="/store" exact component={Store} />
					<Route path="/store/:id" exact component={Store} />
					<Route path="/guarantee" exact component={Guarantee} />
					<Route path="/hpi" exact component={Hpi} />
					<Route path="/hapl" exact component={Hapl} />
					<Route path="/termsOfService" exact component={TermsOfService} />
					<Route path="/personalInfo" exact component={PersonalInfoRule} />
					<Route path="/care" exact component={CareService} />
					{/* <Route path="/sell/:id" exact component={Sell} /> */}
					{/* <Route path="/myPage/reservation" exact component={MyReservationList} /> */}
				</Switch>
			</BrowserRouter>
		</>
	);
};

export default MobileRoutes;
