import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';
import CommonHelper from 'helpers/CommonHelper';

export const getReviewListApi = (LIMIT, page) =>
	apiCall(
		`${SERVER_API_HOST}/api/board/list?group=review&page=${page}&offset=${LIMIT}&viewContent=false`,
		'get',
		{},
		{},
	);

export const getReviewDetailApi = (id) =>
	apiCall(`${SERVER_API_HOST}/api/board/get/${id}`, 'get', {}, {});
