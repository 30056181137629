import React, { useState, useEffect } from 'react';
import { Viewer } from '@toast-ui/react-editor';
import styled, { css } from 'styled-components';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';


const FaqDetail = ({clickedListArr, faq}) => {
    return (
			<Wrapper close={clickedListArr.includes(faq.id)}>
				<Viewer
					initialValue={replaceNewlineWithBrTag(decodingHtmlTags(faq.answer))}
					initialEditType="markdown"
				/>
			</Wrapper>
		);
};
export default FaqDetail;

const Wrapper = styled.div`
	${(props) => {
		if (!props.close) {
			return css`
				display: none;
			`;
		}
	}}
	img {
		max-width: 1000px;
	}
`;