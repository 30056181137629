import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';
import Chart from 'chart.js';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import CommonHelper from 'helpers/CommonHelper';
import { postAuctionApplyApi } from 'apis/auctionApply';
import { useAppState } from 'context/AppContext';
import AuctionDatePicker from './AuctionDatePicker';
import { getStoreListApi } from 'apis/buy';
import { getOneAuctionProductApi, sendTenderApi } from 'apis/auctionDetail';
import { GENDER_EN_KO, MOVEMENT_EN_KO, SUPPLEMENTS_EN_KO } from 'common/constants';
import { initTimer } from 'utils/useTimer';
import BuyDetailSlider from 'components/BuyDetail/components/BuyDetailSlider';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const fontStyle = {
	marginTop: '5px',
	marginBottom: '12px',
	color: 'red',
	fontSize: '12px',
};
const AuctionDetail = () => {
	const [lastPriceInput, setLastPriceInput] = useState('');
	const history = useHistory();
	const [oneAuctionProductInfo, setOneAuctionProductInfo] = useState({});
	const [onePhotoUrl, setOnePhotoUrl] = useState('');
	const [currentStoreList, setCurrentStoreList] = useState([]);
	//팜업
	const [signUpAlert, setSignAlert] = useState(false);
	const [upgradeRankAlert, setUpgradeRankAlert] = useState(false);
	const [tenderAlert, setTenderAlert] = useState(false);
	const [tenderSuccessAlert, setTenderSuccessAlert] = useState(false);
	//
	const [auctionEnrollment, setAuctionEnrollment] = useState(false);
	const [enrollCheck, setEnrollCheck] = useState(false);
	const [enrollInputValue, setEnrollInputValue] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [validation, setValidation] = useState(false);
	// select된 시간을 서버에 보내는 형식으로 바꾸기
	const offset = new Date().getTimezoneOffset() * 60000;
	const convertStartDate = new Date(startDate - offset).toISOString().split('.')[0];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	// login data가져오기
	const data = useAppState();
	const userData = data?.user?.data?.roles?.includes('AUCTION');

	const auctionApplyBtn = () => {
		// if (!authToken) {
		// 	setSignAlert(true); // 로그인하라고 popup
		// } else if (authToken && !userData) {
		// 	setUpgradeRankAlert(true); //등업하라고 popup
		// } else if (authToken && userData) {
		// 	setTenderAlert(true); //입찰 popup
		// }
		if (!authToken) {
			setSignAlert(true);
		} else {
			window.open(
				oneAuctionProductInfo.description,
				'드로우 신청 폼',
				'width=660, height=1000, toolbar=no, menubar=no, scrollbars=yes, resizable=yes',
			);
		}
	};

	//옥션등업 팝업열기
	const goToEnrollment = () => {
		setAuctionEnrollment(true);
	};

	//큰 photoUrl선택
	const onClickOnePhotoUrl = (photoUrl) => {
		setOnePhotoUrl(photoUrl);
	};

	/**
	 * /auction/:id 상품상세로 들어왔을 때 최근 본 상품등록을 위해 localStorage에 저장
	 */
	const handleSessionRecently = () => {
		let getStorage = localStorage.getItem('auctionFilter');
		let nowProductId = window.location.pathname.split('/')[2];
		if (!getStorage) {
			let makeArrayForSession = nowProductId;
			localStorage.setItem('auctionFilter', `${makeArrayForSession}`);
		} else if (getStorage.split(',').length < 5) {
			let splitBox = getStorage.split(',');
			if (splitBox.includes(nowProductId)) {
				return;
			} else {
				localStorage.setItem('auctionFilter', `${nowProductId},${getStorage}`);
			}
		} else if (getStorage.split(',').length >= 5) {
			let splitBox = getStorage.split(',');
			splitBox.unshift(nowProductId);
			let sliceSplitBox = splitBox.slice(0, 5);
			let sliceSplitStringBox = sliceSplitBox.toString();
			localStorage.setItem('auctionFilter', `${sliceSplitStringBox}`);
		}
	};

	useEffect(() => {
		handleSessionRecently();
		getStoreList();
		getOneAuctionProduct();
	}, []);

	//옥션 등급 등업신청
	const handleEnrollCheck = () => {
		if (enrollInputValue.length > 0 && startDate) {
			postAuctionApplyApi(enrollInputValue, convertStartDate, authToken).then(
				({ data: { data, detail, status } }) => {
					if (status === 'SUCCESS') {
						setEnrollCheck(true);
						setEnrollInputValue('');
						setStartDate(new Date());
					} else {
						// alert('등업 신청에 실패하였습니다. 고객상담으로 문의주시면 도와드리겠습니다.')
						alert(detail);
					}
				},
			);
		} else {
			setValidation(true);
		}
	};

	const clickCancelBtn = () => {
		setSignAlert(false);
		setAuctionEnrollment(false);
		setUpgradeRankAlert(false);
		setSignAlert(false);
		setEnrollCheck(false);
		setValidation(false);
		setStartDate(new Date());
		setEnrollInputValue('');
	};

	const handleEnrollValue = (e) => {
		setEnrollInputValue(e.target.value);
		e.target.value.length > 0 ? setValidation(false) : setValidation(true);
	};

	//옥션상세 priceHistories의 차트
	const getChart = (oneAuctionData) => {
		const productPriceArr = oneAuctionData?.priceHistories?.map((item) => {
			return item.price;
		});
		const productDayArr = oneAuctionData?.pricehistoires?.map((item) => {
			const year = item?.day?.slice(0, 4);
			const month = item?.day?.slice(4, 6);
			const day = item?.day?.slice(6, 8);
			return `${year}-${month}-${day}`;
		});
		let myChart = document.getElementById('auctionDetailChart');
		// productPriceArr?.map((item, idx) => {
		var ctx = myChart.getContext('2d');
		var gradientFill = ctx.createLinearGradient(0, 70, 0, 0);
		gradientFill.addColorStop(1, 'rgba(84, 93, 102, .5)');
		gradientFill.addColorStop(0, 'rgba(84, 93, 102, 0)');

		new Chart(ctx, {
			// The type of chart we want to create
			type: 'line',

			// The data for our dataset
			data: {
				labels: productDayArr?.slice(-8, -1),
				datasets: [
					{
						backgroundColor: gradientFill,
						borderColor: 'rgba(0, 0, 0, 0.25)',
						data: productPriceArr?.slice(-8, -1),
						pointRadius: 0,
						lineTension: 0,
						boderWidth: 1,
						showline: true,
					},
				],
			},

			// Configuration options go here
			options: {
				animation: {
					duration: 0,
				},
				legend: {
					display: false,
				},
				tooltips: false,
				clip: false,
				elements: {
					point: {
						radius: 0,
					},
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								color: '#F5F7FA',
							},
							ticks: {
								display: true,
								padding: 10,
								min: Math.min.apply(this, productPriceArr?.slice(-8, -1)),
								max: Math.max.apply(this, productPriceArr?.slice(-8, -1)),
								callback: function (value, index, values) {
									if (index === values.length - 1)
										return Math.min.apply(this, productPriceArr?.slice(-8, -1));
									else if (index === 0) return Math.max.apply(this, productPriceArr?.slice(-8, -1));
									else return '';
								},
							},
						},
					],
				},
			},
		});
		// });
	};

	//가격input 콤마 핸들링
	const handleLastPrice = (e) => {
		const { value } = e.target;
		let deleteAll = value
			.replaceAll(' ', '')
			.replaceAll('₩', '')
			.replaceAll(',', '')
			.replaceAll(/[^0-9]/g, '');
		setLastPriceInput(deleteAll);
	};

	const goToSell = (modelName) => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			history.push(`/sell/${encodeURIComponent(modelName)}`);
		} else {
			setSignAlert(true);
		}
	};
	return (
		<>
			{tenderSuccessAlert && (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p>
							<strong>입찰이 완료 되었습니다.</strong>
						</p>
						<p>입찰 내역은 마이페이지에서 확인하실 수 있습니다.</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={() => setTenderSuccessAlert(false)}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			{tenderAlert && (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p className="t1">
							<strong>
								입찰 금액 : {lastPriceInput ? `${Number(lastPriceInput).toLocaleString()}` : `0`}원
							</strong>
						</p>
						<p>입력하신 금액으로 입찰하시겠습니까?</p>
						<div className="btn_wrap right">
							<button onClick={() => setTenderAlert(false)}>취소</button>
							<button className="ok" onClick={sendTender}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			{signUpAlert ? (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert ">
						<p>
							로그인이 필요한 서비스입니다. <br />
							회원이시라면 로그인 후 이용해주세요.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={clickCancelBtn}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			{upgradeRankAlert ? (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p>
							옥션등급 회원만 이용 가능한 서비스입니다. <br />
							옥션등급으로 승급을 진행하시겠습니까?
						</p>
						<div className="btn_wrap">
							<button onClick={clickCancelBtn}>취소</button>
							<button className="ok" onClick={goToEnrollment}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			{auctionEnrollment ? (
				<div className="bg_dimmed auction-detail-block">
					<section className="popup grade_application">
						<h2>옥션등급 신청</h2>
						<div className="txt_box">
							<p className="t1">
								옥션에 참여하기 위해선 옥션등급으로 올려야 참여가 가능합니다. 옥션등급은
								하이시간계좌번호로 50000원을 입금하시면 관리자 확인 후 일반 등급에서 옥션등급으로
								올라갑니다.
							</p>
							<p className="t2">
								이후 옥션 참여시 입찰할 수 있는 권한이 생기며 입찰 후 구매취소를 하게 되면 보증금이
								차감되면서 일반등급으로 강등됩니다.
							</p>
						</div>
						<div className="cont">
							<h3>예금주</h3>
							<input
								type="text"
								placeholder="예금주를 입력해주세요."
								onChange={handleEnrollValue}
								value={enrollInputValue}
							/>
							{validation ? <p style={fontStyle}>예금주를 입력해주세요</p> : ''}
							<h3>입금시간</h3>
							<div className="auction-detail-block">
								<AuctionDatePicker startDate={startDate} setStartDate={setStartDate} />
								{!startDate ? <p style={fontStyle}>날짜를 선택해주세요</p> : ''}
							</div>
						</div>
						<div className="cont2">
							<h3>입금하실 곳</h3>
							<div>
								<dl>
									<dt>은행</dt>
									<dd>기업은행</dd>
								</dl>
								<dl>
									<dt>계좌번호</dt>
									<dd>126301 04 391926</dd>
								</dl>
								<dl>
									<dt>예금주</dt>
									<dd>(주) 시간</dd>
								</dl>
							</div>
						</div>
						<div className="btn_wrap">
							<button className="ok" onClick={handleEnrollCheck}>
								신청하기
							</button>
							<button onClick={clickCancelBtn}>취소</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			{enrollCheck ? (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p>
							<strong>신청이 완료 되었습니다.</strong>
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={clickCancelBtn}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
			<div className={'container auction_detail'}>
				<div className="top_cont">
					<div className="watch_list watch_list_plus">
						<ul>
							<li className="on on_plus">
								<img src={onePhotoUrl || null} alt="시계 사진" className="watch_img_plus" />
							</li>
						</ul>
					</div>
					<div className="watch_info">
						<p className="bland">
							{oneAuctionProductInfo?.watch?.brand || '-'}{' '}
							{oneAuctionProductInfo?.watch?.model || '-'}
						</p>
						<p className="option">
							{oneAuctionProductInfo?.watch?.supplements.includes('GUARANTEE') && (
								<img src={warrantyIco} alt="보증서" title="보증서가 있는 상품입니다." />
							)}
							{oneAuctionProductInfo?.watch?.supplements.includes('BOX') && (
								<img src={boxIco} alt="정품박스" title="정품박스가 있는 상품입니다." />
							)}
						</p>
						<p className="watch_name">
							{oneAuctionProductInfo?.watch?.brand || '-'}{' '}
							{oneAuctionProductInfo?.watch?.model || '-'}
						</p>
						<p className="t1">{/* {oneAuctionProductInfo?.description || '-'} */}</p>
						<p className="t2">
							최근 48시간 동안{' '}
							<span>
								{oneAuctionProductInfo?.hourCount
									? Number(oneAuctionProductInfo.hourCount).toLocaleString()
									: 0}
								명
							</span>
							이 이 시계를 조회하였습니다.
						</p>
						<p className="end" id={`옥션조회-${oneAuctionProductInfo.id}`}>
							{initTimer(oneAuctionProductInfo?.endDate, `옥션조회-${oneAuctionProductInfo.id}`)}
						</p>
						<p className="join_num">
							{/* {oneAuctionProductInfo?.orderPeopleCount
								? Number(oneAuctionProductInfo.orderPeopleCount).toLocaleString()
								: 0}
							명 참여 중 */}
						</p>
						{/* <p
							className={
								oneAuctionProductInfo?.standardWatch?.hpi > 0
									? 'fluctuations up'
									: oneAuctionProductInfo?.standardWatch?.hpi < 0
									? 'fluctuations down'
									: oneAuctionProductInfo?.standardWatch?.hpi === 0
									? 'fluctuations'
									: ''
							}
						>
							<span>{oneAuctionProductInfo?.standardWatch?.hpi || '-'}</span>
						</p>
						<input
							type="text"
							value={
								oneAuctionProductInfo.price
									? `₩ ${Number(oneAuctionProductInfo.price).toLocaleString()}`
									: `₩ 0`
							}
							className="bidding"
							// onChange={handleLastPrice}
							disabled
						/> */}
						<div className="t3">
							<strong className="price">
								₩{' '}
								{oneAuctionProductInfo?.price
									? Number(oneAuctionProductInfo?.price).toLocaleString()
									: '-'}
							</strong>
							<p
								className={
									oneAuctionProductInfo?.standardWatch?.hpi > 0
										? 'fluctuations up'
										: oneAuctionProductInfo?.standardWatch?.hpi < 0
										? 'fluctuations down'
										: oneAuctionProductInfo?.standardWatch?.hpi === 0
										? 'fluctuations'
										: ''
								}
							>
								<span>{oneAuctionProductInfo?.standardWatch?.hpi || '-'}</span>
							</p>
						</div>
						{oneAuctionProductInfo?.auctionStatus === 'DONE' ? (
							<button className="buy_bidding buy_bidding_end" disabled>
								종료된 상품입니다.
							</button>
						) : (
							<button className="buy_bidding" onClick={auctionApplyBtn}>
								드로우 신청
							</button>
						)}
						<p className="alert_txt">
							{/* 입찰 후 구매 취소시 등급이 일반 등급으로 내려가게 됩니다. */}
						</p>
						<p className="t4">재고보유지점</p>
						<ul className="store_list">
							{currentStoreList.map((store) => (
								<li key={store.id}>
									<label>
										<input
											type="checkbox"
											checked={
												oneAuctionProductInfo?.stores?.filter((el) => el.id === store.id).length > 0
											}
											className="round store-round-space"
											style={{ margin: '1px' }}
											disabled
										/>
										<span>{store.name || '-'}</span>
									</label>
								</li>
							))}
						</ul>
						{/* <p className="t5">
							<span>혹시 동일한 시계 모델을 판매하시고 싶은신가요?</span>{' '}
							<a
								style={{ cursor: 'pointer' }}
								onClick={() => goToSell(oneAuctionProductInfo?.watch?.model)}
							>
								판매하러 가기
							</a>
						</p> */}
					</div>
				</div>
				<ul className="tab_list">
					<BuyDetailSlider
						oneSellingProductInfo={oneAuctionProductInfo}
						onePhotoUrl={onePhotoUrl}
						setOnePhotoUrl={setOnePhotoUrl}
					/>
				</ul>
				<div className="graph_box">
					<p>최근 가격 변동 추이</p>
					<div className="graph" style={{ paddingTop: '22px' }}>
						<canvas id="auctionDetailChart" width="1128" height="81"></canvas>
					</div>
				</div>
				<div style={{ margin: '30px auto', width: '80%' }}>
					{oneAuctionProductInfo?.additionalInfo && (
						<Wrapper>
							<Viewer
								initialValue={replaceNewlineWithBrTag(
									decodingHtmlTags(oneAuctionProductInfo?.additionalInfo || ''),
								)}
								initialEditType="markdown"
							/>
						</Wrapper>
					)}
				</div>
				<h3>정보</h3>
				<table className="tbl">
					<colgroup>
						<col className="table-col-first" />
						<col className="table-col-second" />
					</colgroup>
					<thead>
						<tr>
							<th>레퍼런스</th>
							<td>{oneAuctionProductInfo?.watch?.serialNo || '-'}</td>
						</tr>
						<tr>
							<th>브랜드</th>
							<td>{oneAuctionProductInfo?.watch?.brand || '-'}</td>
						</tr>
						<tr>
							<th>모델</th>
							<td>{oneAuctionProductInfo?.watch?.model || '-'}</td>
						</tr>
						<tr>
							<th>무브먼트</th>
							<td>
								{oneAuctionProductInfo?.watch?.movement
									? MOVEMENT_EN_KO[oneAuctionProductInfo.watch.movement]
									: '-'}
							</td>
						</tr>
						<tr>
							<th>케이스 소재</th>
							<td>{oneAuctionProductInfo?.watch?.caseMaterial || '-'}</td>
						</tr>
						<tr>
							<th>지름 (mm)</th>
							<td>{oneAuctionProductInfo?.watch?.caseDiameter || '-'}</td>
						</tr>
						<tr>
							<th>구매년도</th>
							<td>{oneAuctionProductInfo?.watch?.producedYear || '미확인'}</td>
						</tr>
						<tr>
							<th>상품 컨디션 (0~10)</th>
							<td>{oneAuctionProductInfo?.watch?.condition || '-'}</td>
						</tr>
						<tr>
							<th>구성품</th>
							<td>
								{oneAuctionProductInfo?.watch?.supplements?.map((el, idx) => {
									return (
										<span style={{ marginRight: '10px' }} key={idx}>
											{el ? SUPPLEMENTS_EN_KO[el] : '-'}{' '}
										</span>
									);
								})}
							</td>
						</tr>
						<tr>
							<th>성별</th>
							<td>
								{oneAuctionProductInfo?.watch?.gender
									? GENDER_EN_KO[oneAuctionProductInfo?.watch?.gender]
									: '-'}
							</td>
						</tr>
						<tr>
							<th>다이얼 색상</th>
							<td>{oneAuctionProductInfo?.watch?.dialColor || '-'}</td>
						</tr>
						<tr>
							<th>다이얼 타입</th>
							<td>{oneAuctionProductInfo?.watch?.dialType || '-'}</td>
						</tr>

						{/* <tr>
							<th>시계줄 소재</th>
							<td>가죽</td>
						</tr>
						<tr>
							<th>구매년도</th>
							<td>미확인</td>
						</tr>
						<tr>
							<th>상품 컨디션</th>
							<td>매우 우수</td>
						</tr>
						<tr>
							<th>구성품</th>
							<td>정품 박스 있음, 서류 원본 있음</td>
						</tr>
						<tr>
							<th>성별</th>
							<td>남성용 / 남녀 공용</td>
						</tr>
					</thead>
				</table>
				<h3>무브먼트/칼리버</h3>
				<table className="tbl">
					<colgroup>
						<col style={{ width: '300px' }} />
						<col style={{ width: 'auto' }} />
					</colgroup>
					<thead>
						<tr>
							<th>무브먼트</th>
							<td>자동</td>
						</tr>
						<tr>
							<th>무브먼트/칼리버</th>
							<td>Automatic</td>
						</tr>
						<tr>
							<th>진동 빈도</th>
							<td>28800 A/h</td>
						</tr>
						<tr>
							<th>케이스</th>
							<td>-</td>
						</tr>
						<tr>
							<th>케이스 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>케이스 지름</th>
							<td>39 mm</td>
						</tr>
						<tr>
							<th>두께</th>
							<td>14.5 mm</td>
						</tr>
						<tr>
							<th>베젤 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>유리</th>
							<td>사파이어 글라스</td>
						</tr>
						<tr>
							<th>다이얼</th>
							<td>흰색</td>
						</tr>
						<tr>
							<th>다이얼번호</th>
							<td>로마숫자</td>
						</tr>
					</thead>
				</table>
				<h3>브레이슬릿/스트랩</h3>
				<table className="tbl">
					<colgroup>
						<col style={{ width: '300px' }} />
						<col style={{ width: 'auto' }} />
					</colgroup>
					<thead>
						<tr>
							<th>시계줄 소재</th>
							<td>가죽</td>
						</tr>
						<tr>
							<th>브레이슬릿 색상</th>
							<td>검정색</td>
						</tr>
						<tr>
							<th>잠금장치</th>
							<td>잠금장치</td>
						</tr>
						<tr>
							<th>잠금장치 소재</th>
							<td>스틸</td>
						</tr>
						<tr>
							<th>기능</th>
							<td>날짜</td>
						</tr>
						<tr>
							<th>기타</th>
							<td>뒷면 표시, 발광 시계 바늘, 순정부품</td>
						</tr> */}
					</thead>
				</table>
			</div>
		</>
	);

	function sendTender() {
		//옥션아이디, 토큰, price
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		let nowProductId = window.location.pathname.split('/')[2];
		if (!!authToken) {
			sendTenderApi(authToken, nowProductId, lastPriceInput).then(({ data }) => {
				if (data?.status === 'SUCCESS') {
					setTenderAlert(false);
					setTenderSuccessAlert(true);
				} else {
					alert(data?.detail);
				}
			});
		} else {
			setSignAlert(true);
		}
	}

	function getStoreList() {
		getStoreListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data?.content || []);
			} else {
				alert(detail);
			}
		});
	}
	function getOneAuctionProduct() {
		let nowProductId = window.location.pathname.split('/')[2];
		getOneAuctionProductApi(nowProductId).then(({ data }) => {
			if (data?.status === 'SUCCESS') {
				setOneAuctionProductInfo(data.data || {});
				setOnePhotoUrl(data?.data?.attachedFiles[0] ? data.data.attachedFiles[0] : null);
				if (data?.data?.lastPrice === 0) {
					setLastPriceInput(data?.data?.price || 0);
				} else {
					setLastPriceInput(data?.data?.lastPrice || 0);
				}
				getChart(data.data || {});
			} else {
				alert(data?.detail);
			}
		});
	}
};

export default AuctionDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	display: flex;
	justify-content: center;
	img {
		max-width: 1100px;
	}
`;
