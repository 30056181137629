import { SERVER_API_HOST } from 'common/environment';
import { apiCall } from './apiCall';

export const API_KEY = 'AIzaSyASus4jcVjVm_JSSLIwbL9wbtqEeF6qHPo';

export const getShopInfoApi = (page, OFFSET) =>
	apiCall(`${SERVER_API_HOST}/api/store/list?`, 'get', { page: page, offset: OFFSET }, {});


export const getYoutubeApi = () => {
	apiCall(
		`https://www.googleapis.com/youtube/v3/search?channelId=UChurZ73ZijJ3wSSplPlXE5g&part=snippet&maxResults=3&key=${API_KEY}`, 'get',{},{}
	);
}

// https://www.googleapis.com/youtube/v3/search?part=snippet&key=${key}&q=${query}&maxResult=${max}&type=video&videoEmbeddable=true
