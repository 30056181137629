import React from 'react';
import styled from 'styled-components';

const FilterModel = ({
	handleCheckBoxCommon,
	modelResultBox,
	searchModelInput,
	setSearchModelInput,
	selectedModelFilter,
	setSelectedModelFilter,
	setShowModelFilter,
}) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowModelFilter(false)}></button>
					<h2>모델</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div
						className="searched_brands"
						style={{ backgroundColor: '#fff', padding: '6vw 12.222vw 0 6.667vw' }}
					>
						<div className="search_wrap" style={{ position: 'relative' }}>
							<input
								type="text"
								className="input_t"
								placeholder="모델명을 입력해주세요."
								value={searchModelInput}
								onChange={(e) => setSearchModelInput(e.target.value)}
							/>
							<SearchIcon className="btn_search buy_model_filter_search_btn"></SearchIcon>
						</div>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									전체 모델
									<div style={{ fontSize: '3.8vw', opacity: '0.5', fontWeight: '500' }}>
										선택 모델({selectedModelFilter.length || 0}건)
									</div>
								</h3>
							</div>
							<ul>
								{modelResultBox.map((model, idx) => (
									<li key={idx}>
										<b>{model.model || '-'}</b>
										<input
											type="checkbox"
											name={model.model || '-'}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedModelFilter, setSelectedModelFilter)
											}
											checked={selectedModelFilter.includes(model.model)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedModelFilter([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowModelFilter(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterModel;

const SearchIcon = styled.div`
	position: absolute;
	right: 2.778vw;
	top: 2.778vw;
	display: block;
	width: 6.667vw;
	height: 6.667vw;
	background: url(../../../mobile-assets/img/common/ic_search.svg) no-repeat 0 0 / cover;
`;
