import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getOneAuctionProductApi = (id) => {
    return apiCall(`${SERVER_API_HOST}/api/auction/get/${id}`,'get',{},{});
}

export const sendTenderApi = (authToken, nowProductId, lastPriceInput) => {
    return apiCall(`${SERVER_API_HOST}/api/auction/order/${nowProductId}`,'post',{
        price: lastPriceInput
    },{
        authorization:authToken
    });
}