import React, { useRef, useEffect, useState } from 'react';
import Routes from 'routes/Routes';
import CommonHelper from 'helpers/CommonHelper';
import { useAppDispatch, useAppState } from 'context/AppContext';
import { useSiteDispatch, useSiteState } from 'context/SiteContext';
import { getUserSessionApi, getSiteSettingApi } from 'apis/common';
import Layout from './components/Layout';
import StopScreen from 'components/StopScreen';
import MobileScreen from 'components/MobileScreen';
import MobileLanding from 'components/MobileLanding';
import TabletLanding from 'components/TabletLanding';
import MobileLayout from 'mobile-components/MobileLayout'
import MobileRoutes from 'routes/MobileRoutes'
import UnserviceableBrowser from 'components/UnserviceableBrowser';
require('es6-promise').polyfill();
require('es5-shim');
require('es5-shim/es5-sham');
require('console-polyfill');

const App = () => {
	const appDispatch = useAppDispatch();
	const { user: userData } = useAppState();
	const siteDispatch = useSiteDispatch();
	const { data: siteData } = useSiteState();
	const [isMobile, setIsMobile] = useState(false);
	const [isTablet, setIsTablet] = useState(false);
	const [isMobileSize, setIsMobileSize] = useState(false);
	const [isActiveBrowser, setIsActiveBrowser] = useState(false);

	// console.log(userData) //userData변화 보고싶을 때 다시 사용
	// console.log(siteData); // siteData 변화 보고싶을 때 다시 사용

	/**
	 * 로그인 되어있을 시, 유저의 session 가지고 오는 함수
	 */
	const getUserSession = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!!authToken) {
			getUserSessionApi(authToken).then(({ data }) => {
				if (data?.status === 'SUCCESS') {
					if (data?.data?.roles?.length === 1 && data?.data?.roles?.includes('GUEST')) {
						appDispatch({ type: 'GUEST_IN', data: data?.data || {} });
					} else {
						appDispatch({ type: 'LOGGED_IN', loggedIn: true, data: data?.data || {} });
					}
				} else {
					alert(data?.detail);
				}
			});
		}
	};

	/**
	 * 점검화면 및 사이트 정보를 얻기위한 함수 => 상위에 둔 이유 : 점검화면
	 */
	const getSiteSetting = () => {
		getSiteSettingApi().then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				siteDispatch({ type: 'STOP_SERVICE', data: data || {} });
			} else {
				alert(detail);
			}
		});
	};

	const mobileOrPc = () => {
		// // PC 환경

		if (navigator.platform) {
			// setIsMobile(filter.indexOf(navigator.platform.toLowerCase()) < 0);
			// console.log(filter.indexOf(navigator.platform.toLowerCase()) < 0);
			const test =
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/Windows Phone/i)
					? true
					: false;
			setIsMobile(test);

			const tabletTest =
				navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Tablet/i) ? true : false;
			//아이패드 || 안드로이드 태블릿
			setIsTablet(tabletTest);

			const isChrome = window.navigator.userAgent.indexOf('Chrome') > -1;
			const isEdge = window.navigator.userAgent.indexOf('Edge/') > -1;
			const isNewEdge = window.navigator.userAgent.indexOf('Edg/') > -1; // for new edge chromium
			const agt = navigator.userAgent.toLowerCase();
			const isSafari = agt.indexOf('safari') != -1;

			if (!!isChrome || !!isEdge || !!isNewEdge || !!isSafari) {
				setIsActiveBrowser(true);
			} else {
				setIsActiveBrowser(false);
			}
		}

		// if(window.innerWidth){
		// 	const tablet = window.innerWidth >= 768
		// }

		//pc냐 아니냐
		// var filter = 'win16|win32|win64|macintel|mac|'; // PC일 경우 가능한 값
		// if (navigator.platform) {
		// 	if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
		// 		alert('모바일에서 접속하셨습니다');
		// 	} else {
		// 		alert('PC에서 접속하셨습니다');
		// 	}
		// }
	};
	const sizeCheck = () => {
		if (window.innerWidth <= 1024) {
			setIsMobileSize(true);
		} else {
			setIsMobileSize(false);
		}
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	useEffect(() => {
		getUserSession();
		getSiteSetting();
		mobileOrPc();
	}, []);

	return (
		<>
			{!!isMobileSize ? (
				<>
				<MobileLayout >
					<MobileRoutes/>
				</MobileLayout>
				</>
			) : !isActiveBrowser ? (
				<UnserviceableBrowser />
			) : siteData?.siteCheck ? (
				<StopScreen />
			) : (
				<Layout>
					<Routes />
				</Layout>
			)}
			{/* {isMobile ? (
				// <MobileScreen />
				<MobileLanding />
			) : isTablet ? (
				<TabletLanding />
			) : !isActiveBrowser ? (
				<UnserviceableBrowser />
			) : siteData?.siteCheck ? (
				<StopScreen />
			) : (
				<Layout>
					<Routes />
				</Layout>
			)} */}
		</>
	);
};

export default App;

// const getUserSession = () => {
// 	const authToken = CommonHelper.getAuthToken()
// 		? CommonHelper.getAuthToken()
// 		: CommonHelper.getSessionAuthToken();
// 	if (!!authToken) {
// 		getUserSessionApi(authToken).then(({ data }) => {
// 			if (data?.status < 400) {
// 				appDispatch({ type: 'LOGGED_IN', loggedIn: true, data: data?.data || {} });
// 			}
// 		});
// 	}
// };
