import React, { useState, useEffect } from 'react';

const CommonPagination = ({ commonData, limit,page, setPage }) => {
		const [start, setStart] = useState(0);
		const [end, setEnd] = useState(5);
		const [pageNumbers, setPageNumbers] = useState([]);
		const [newPageNumbers, setNewPageNumbers] = useState([]);

		useEffect(() => {
			handlePageNumbers()
		}, [page, commonData]);

		const handlePageNumbers = () => {
			const { totalPage } = commonData;
			let pageData = [];
			let newPageData =  [];

			for (let i = 0; i < totalPage; i++) {
				pageData.push(i + 1);
			}
			if (page === 0) {
				setStart(0);
				setEnd(5);
				newPageData = pageData.slice(0, 5);
			} else {
				newPageData = pageData.slice(start, end);
			}

			setPageNumbers(pageData);
			setNewPageNumbers(newPageData);
		}

		const paginate = (number) => {
			const num = number === 0 ? number : number - 1;
			setPage(num);
		};

		const prevPage = () => {
			if (page + 1 === 1) {
				return alert('첫페이지입니다.');
			}
			if ((page + 1) % 5 === 1) {
				const s = start > 0 ? start - 5 : 0;
				const e = end > 0 ? end - 5 : end;

				setStart(s);
				setEnd(e);
			}
			setPage(page - 1);
		};

		const nextPage = () => {
			if (page + 2 > pageNumbers.length) {
				return alert('마지막페이지입니다.');
			} else {
				setPage(page + 1);
				if ((page + 2) % 5 === 1) {
					const s = start + 5;
					const e = end + 5;
					setStart(s);
					setEnd(e);
				}
			}
		};
	return (
		<>
			<div className="paging">
				<a className="btn prev" onClick={() => prevPage()} />
				{newPageNumbers &&
					newPageNumbers.map((number) => {
						return (
							<a
								key={number}
								className={number === page + 1 ? 'num' : ''}
								onClick={() => paginate(number)}
							>
								{number}
							</a>
						);
					})}

				<a className="btn next" onClick={() => nextPage()} />
			</div>
		</>
	);
};

export default CommonPagination;
