import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router';
import moment from 'moment';
import HaplCalendar from './HaplCalendar';
import CommonHelper from 'helpers/CommonHelper';
import { getOwnReservationListApi, postReserveHaplApi } from 'apis/hapl';
const DEFAULT_RESERVATION_FORM = {
	day: '',
	time: '',
	storeId: '',
	userType: 'SELLER',
	name: '',
	userCount: null,
	phone: '',
	email: '',
	transactionType: 'DIRECT_TRANSACTION',
	appraisalsType: 'NOT',
	paidServices: [],
	etcQuestion: '',
};

const HaplStep1 = ({ setCurrStep, oneStoreReservation, setResponseAfterReserve }) => {
	const [reservationForm, setReservationForm] = useState(DEFAULT_RESERVATION_FORM);

	const [selectedDay, setSelectedDay] = useState();
	const [selectedTime, setSelectedTime] = useState();

	const [test, setTest] = useState(); // 날마다 예약되어있는 건수들 계산

	const [page, setPage] = useState(0); //개인예약내역 페이징
	const [ownReservation, setOwnReservation] = useState();
	const [showFixed, setShowFixed] = useState(true);
	const [phoneNumValidation, setPhoneNumValidation] = useState(false);

	// useEffect(() => {
	// 	getOwnReservationList();
	// }, []);

	useEffect(() => {
		let copy = { ...reservationForm };
		let day = moment();
		copy.day = day.format('YYYY-MM-DD');
		copy.storeId = oneStoreReservation.id;
		setReservationForm(copy);

		let today = moment();
		setSelectedDay(today.format('YYYY-MM-DD'));
	}, []);

	// useEffect(() => {
	// 	if (oneStoreReservation && calendarMonth) {
	// 		getMonthReservationInfo();
	// 	}
	// }, [oneStoreReservation, calendarMonth]);

	const getSoldTimeForDay = (arr) => {
		let result = {};
		const values = Object.values(arr); // [ 1, 2, 3, 4, 'Hello' ]
		for (let i = 0; i < values.length; i++) {
			let v = values[i].timeMap;
			result[values[i].day] = [];
			// console.log(v)
			const values2 = Object.values(v);
			let count = 0;
			for (let t = 0; t < values2.length; t++) {
				let test = values2[t].reserved;
				// console.log(test)
				if (!!test) {
					count = count + 1;
				}
			}
			result[values[i].day].push(count);
		}
		// console.log(result)
		setTest(result);
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		let copyForm = { ...reservationForm };
		copyForm[name] = value;
		if (name === 'email' && !CommonHelper.phoneNumberRules(value)) {
			setPhoneNumValidation(true);
		} else if (name === 'email' && !!CommonHelper.phoneNumberRules(value)) {
			setPhoneNumValidation(false);
		}
		setReservationForm(copyForm);
	};

	const handlePaidServices = (e) => {
		const { value, name } = e.target;
		let copyForm = { ...reservationForm };
		let find = copyForm.paidServices.filter((el) => el === value).length;
		if (find === 0) {
			copyForm.paidServices.push(value);
		} else {
			copyForm.paidServices = copyForm.paidServices.filter((el) => el !== value);
		}
		setReservationForm(copyForm);
	};

	const checkBeforeReserve = () => {
		if (!reservationForm.day) {
			alert('날짜를 정해주세요');
		} else if (!reservationForm.time) {
			alert('시간을 선택해주세요.');
		} else if (!reservationForm.name) {
			alert('신청자 명을 기입해주세요.');
		} else if (!reservationForm.userCount) {
			alert('방문하실 인원 수를 기입해주세요.');
		} else if (!reservationForm.email) {
			alert('핸드폰 번호를 입력해주세요.');
		} else if (!CommonHelper.phoneNumberRules(reservationForm.email)) {
			alert('핸드폰 번호를 입력예시에 맞게 입력해주세요.')
		} else {
			postReserveHapl();
		}
	};

	const getTime = () => {
		const nowDate = moment(selectedDay);
		const prevDate = nowDate.clone().subtract(1, 'days').format('YYYY-MM-DD');
		return prevDate;
	};

	return (
		<section className="step1-wrapper">
			<div className="fixed-own-reservation">
				{showFixed ? (
					<div className="one-reservation">
						<div className="title">
							<span>나의 예약정보</span>
							<span className="cursor" onClick={() => setShowFixed(false)}>
								x
							</span>
						</div>
						<div className="content">
							<div>·&nbsp;{oneStoreReservation.name || ''}</div>
							<div className="between">
								<span>·&nbsp;이용일자&nbsp;</span>
								<span>{selectedDay || ''}</span>
							</div>
							<div className="between">
								<span>·&nbsp;이용시간&nbsp;</span>
								<span>{selectedTime ? selectedTime.range : ''}</span>
							</div>
							<div className="between">
								<span>·&nbsp;취소기간&nbsp;</span>
								<span>{selectedDay ? getTime() : ''}</span>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
			<div className="step1-numbering">
				<div className="light-brown">
					<div className="num">1</div>
				</div>
				<div className="line" />
				<div className="light-gray">
					<div className="num">2</div>
				</div>
				<div className="step1-text">나의예약정보</div>
				<div className="step2-text">예약완료</div>
			</div>

			<div className="step1-notice">
				<div className="selected-store">
					<span className="text">{oneStoreReservation.name}</span>
				</div>
				<div className="step1-title">예약 날짜 / 예약 시간</div>
				<div className="notice-box">
					<div className="purple-icon"></div>
					<span>!</span>
					<div className="title">유의사항</div>
					<p>
						· 시간선택은 원하는 시간대를 선택하시면 됩니다.(단위:시간)
						<br />· 예약 신청 후 예약수정, 예약취소는 24시간 이전에 가능하오나, 예약당일날 취소인
						경우는 1644-5751로 문의바랍니다.
						<br />· 정밀감정시 1주일 정도 소요됩니다. 거래일을 꼭 확인해주세요.
					</p>
				</div>
			</div>
			<div>
				{/* 캘린더 위치 */}
				<HaplCalendar
					test={test}
					selectedDay={selectedDay}
					setSelectedDay={setSelectedDay}
					selectedTime={selectedTime}
					setSelectedTime={setSelectedTime}
					reservationForm={reservationForm}
					setReservationForm={setReservationForm}
					oneStoreReservation={oneStoreReservation}
					setTest={setTest}
				/>
			</div>
			<div className="user-info">
				<p className="user-info-title">이용자 정보</p>
				<table className="tbl">
					<colgroup>
						<col className="table-col-first" />
						<col className="table-col-second" />
					</colgroup>
					<thead>
						<tr>
							<th>판매자/구매자</th>
							<td>
								<ul>
									<li>
										<label>
											<input
												type="checkbox"
												checked={reservationForm.userType === 'SELLER'}
												className="round"
												value="SELLER"
												name="userType"
												onChange={handleChange}
											/>
											<span>판매자</span>
										</label>
									</li>
									<li>
										<label>
											<input
												type="checkbox"
												checked={reservationForm.userType === 'BUYER'}
												className="round"
												value="BUYER"
												name="userType"
												onChange={handleChange}
											/>
											<span>구매자</span>
										</label>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>신청자 명</th>
							<td>
								<input
									placeholder="신청자 명"
									className="user-name"
									onChange={handleChange}
									name="name"
									value={reservationForm.name || ''}
								/>
							</td>
						</tr>
						<tr>
							<th>이용인원</th>
							<td>
								<input
									placeholder="0"
									className="people-num"
									value={reservationForm.userCount || ''}
									onChange={handleChange}
									name="userCount"
								/>
								<span className="people-num-text">명</span>
							</td>
						</tr>
						<tr>
							<th>핸드폰</th>
							<td className="email-tr">
								<input
									className="email-id"
									value={reservationForm.email || ''}
									name="email"
									onChange={handleChange}
									style={phoneNumValidation ? { border: '1px solid red' } : {}}
								/>
								<div className="desc">(입력예: 010-0000-0000)</div>
								{phoneNumValidation ? (
									<div className="desc" style={{ color: 'red' }}>
										입력예시에 맞게 작성해주세요.
									</div>
								) : (
									''
								)}

								{/* @
								<input
									className="email-address"
									value={emailSecond || ''}
									name="emailSecond"
									onChange={handleEmail}
								/>
								<select
									placeholder="직접입력"
									className="email-select"
									name="emailSecondSelect"
									onChange={handleEmail}
								>
									{emailList.map((email, idx) => (
										<option value={email.value} key={idx}>
											{email.label}
										</option>
									))}
								</select>
								<p className="arrow"></p> */}
							</td>
						</tr>
					</thead>
				</table>
			</div>
			<div className="deal-info">
				<p className="deal-info-title">거래 정보</p>
				<table className="tbl">
					<colgroup>
						<col className="table-col-first" />
						<col className="table-col-second" />
					</colgroup>
					<thead>
						<tr>
							<th>거래유형</th>
							<td>
								<ul>
									<li>
										<label>
											<input
												type="checkbox"
												checked={reservationForm.transactionType === 'DIRECT_TRANSACTION'}
												className="round"
												value="DIRECT_TRANSACTION"
												name="transactionType"
												onChange={handleChange}
											/>
											<span>직거래</span>
										</label>
									</li>
									<li className="consignment">
										<label>
											<input
												type="checkbox"
												checked={reservationForm.transactionType === 'CONSIGNMENT'}
												className="round"
												value="CONSIGNMENT"
												name="transactionType"
												onChange={handleChange}
											/>
											<span>위탁</span>
										</label>
									</li>
									<li className="purchase">
										<label>
											<input
												type="checkbox"
												checked={reservationForm.transactionType === 'PURCHASE'}
												className="round"
												value="PURCHASE"
												name="transactionType"
												onChange={handleChange}
											/>
											<span>매입</span>
										</label>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>감정여부</th>
							<td>
								<ul>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												checked={reservationForm.appraisalsType === 'NOT'}
												className="round"
												name="appraisalsType"
												value="NOT"
												onChange={handleChange}
											/>
											<span>감정안함</span>
										</label>
									</li>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												checked={reservationForm.appraisalsType === 'SIMPLE'}
												className="round"
												name="appraisalsType"
												value="SIMPLE"
												onChange={handleChange}
											/>
											<span>단순감정</span>
										</label>
									</li>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												checked={reservationForm.appraisalsType === 'DETAIL'}
												className="round"
												name="appraisalsType"
												value="DETAIL"
												onChange={handleChange}
											/>
											<span>정밀감정</span>
										</label>
									</li>
								</ul>
								<div className="desc">(단순감정: 10만원 / 정밀감정: 30만원 VAT별도)</div>
							</td>
						</tr>
						<tr>
							<th>유료서비스</th>
							<td>
								<ul>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												className="pay-service"
												checked={
													reservationForm.paidServices.filter((el) => el === 'OVER_HAUL').length > 0
												}
												name="paidServices"
												value="OVER_HAUL"
												onChange={handlePaidServices}
											/>
											<span>오버홀</span>
										</label>
									</li>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												className="pay-service"
												checked={
													reservationForm.paidServices.filter((el) => el === 'POLISHING').length > 0
												}
												name="paidServices"
												value="POLISHING"
												onChange={handlePaidServices}
											/>
											<span>폴리싱</span>
										</label>
									</li>
									<li className="normal-checkbox">
										<label>
											<input
												type="checkbox"
												className="pay-service"
												checked={
													reservationForm.paidServices.filter((el) => el === 'FILM_SERVICE')
														.length > 0
												}
												name="paidServices"
												value="FILM_SERVICE"
												onChange={handlePaidServices}
											/>
											<span>필름시공</span>
										</label>
									</li>
									<span className="place-holder">*중복 체크 가능</span>
								</ul>
							</td>
						</tr>
						<tr>
							<th>기타설명</th>
							<td className="email-tr">
								<textarea
									className="description-email"
									placeholder="필수입력사항: 브랜드/ 모델명/ 레퍼런스/ 년식 &#13;&#10;입력예: 롤렉스/ 서브마리너/ 126613LN / 2019.05"
									value={reservationForm.etcQuestion}
									name="etcQuestion"
									onChange={handleChange}
								/>
							</td>
						</tr>
					</thead>
				</table>
			</div>
			<div className="btn-box">
				<button className="btn-cancel" onClick={() => setCurrStep('main')}>
					입력취소
				</button>
				<button className="btn-reservation" onClick={checkBeforeReserve}>
					예약하기
				</button>
			</div>
		</section>
	);

	// //step1 달력 현황
	// function getMonthReservationInfo() {
	// 	const authToken = CommonHelper.getAuthToken()
	// 		? CommonHelper.getAuthToken()
	// 		: CommonHelper.getSessionAuthToken();
	// 	getMonthReservationInfoApi(oneStoreReservation.id, authToken, calendarMonth).then(
	// 		({ data: { data, detail, status } }) => {
	// 			if (status === 'SUCCESS') {
	// 				setMonthReservation(data);
	// 				getSoldTimeForDay(data);
	// 			} else {
	// 				alert(detail);
	// 			}
	// 		},
	// 	);
	// }

	function postReserveHapl() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		postReserveHaplApi(reservationForm, authToken).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setResponseAfterReserve(data);
				setCurrStep('step2');
			} else {
				alert(detail);
			}
		});
	}

	// function getOwnReservationList() {
	// 	const authToken = CommonHelper.getAuthToken()
	// 		? CommonHelper.getAuthToken()
	// 		: CommonHelper.getSessionAuthToken();
	// 	getOwnReservationListApi(page, authToken).then(({ data: { data, detail, status } }) => {
	// 		if (status === 'SUCCESS') {
	// 			setOwnReservation(data);
	// 		} else {
	// 			alert(detail);
	// 		}
	// 	});
	// }
};

export default HaplStep1;
