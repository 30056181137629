import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Viewer } from '@toast-ui/react-editor';
import { getReviewDetailApi } from 'apis/review';
import { decodingHtmlTags, replaceNewlineWithBrTag } from 'helpers/stringHelpers';

const ReviewDetail = ({ oneNoticeData, setOpenDetail }) => {
	const [detailData, setDetailData] = useState(oneNoticeData);
	useEffect(() => {
		getNoticeDetail();
	}, []);

	const getNoticeDetail = () => {
		getReviewDetailApi(detailData.id).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setDetailData(data ? data : {});
			} else {
				alert(detail);
			}
		});
	};

	const currentDate = new Date().getTime();
	const createDate = new Date(detailData.createdAt).getTime();
	const marked = replaceNewlineWithBrTag(decodingHtmlTags(detailData.content));
	return (
		<>
			<section className="notice_view">
				<div className="view_top">
					<p className="tit">{detailData?.title ? detailData.title : ''}</p>
					<p className="date">
						<span>
							{' '}
							{detailData?.createdAt ? detailData.createdAt.split('T')[0].replace(/-/g, '.') : ''}
						</span>
						<span className="new">
							{(currentDate - createDate) / (1000 * 3600 * 24) <= 7 ? (
								<span className="ico">New</span>
							) : (
								''
							)}{' '}
						</span>
					</p>
				</div>
				<div
					// className="view_cont"
					style={{ padding: '5.556vw 0' }}
				>
					{marked ? (
						<Wrapper>
							<Viewer initialValue={marked} initialEditType="markdown" />
						</Wrapper>
					) : (
						''
					)}
				</div>
			</section>
		</>
	);
};

export default ReviewDetail;
const Wrapper = styled.div`
	width: 100%;
	margin: 30px auto;
	/* display: flex;
	justify-content: center; */
	img {
		max-width: 240px;
	}
	p {
		font-size: 3.889vw;
		color: #31383f;
		margin-bottom: 5.556vw;
		line-height: 1.6;
	}
`;
