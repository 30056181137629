import React, { useEffect, useState } from 'react';
import AuctionBuy from './components/Buy/AuctionBuy';
import AuctionSell from './components/Sell/AuctionSell';
import { postAuctionBuyMenuApi, postAuctionSellMenuApi } from 'apis/auctionMenu';
import { useAppState } from 'context/AppContext';
import CommonHelper from 'helpers/CommonHelper';

const AuctionMenu = () => {
	const [auctionBuyData, setAuctionBuyData] = useState({});
	const [auctionSellData, setAuctionSellData] = useState({});
	const [btnValue, setBtnValue] = useState('buy');
	const [buyPage, setBuyPage] = useState(0);
	const [sellPage, setSellPage] = useState(0);
	const appState = useAppState();
	const userId = appState && appState.user.data.id;
	const OFFSET = 15;
	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	useEffect(() => {
		getAuctionBuyData();
	}, [buyPage]);

	const getAuctionBuyData = () => {
		postAuctionBuyMenuApi(authToken, userId, OFFSET, buyPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setAuctionBuyData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};
	useEffect(() => {
		getAuctionSellData();
	}, [sellPage]);

	const getAuctionSellData = () => {
		postAuctionSellMenuApi(authToken, userId, OFFSET, sellPage).then(
			({ data: { status, detail, data } }) => {
				if (status === 'SUCCESS') {
					setAuctionSellData(data || {});
				} else {
					alert(detail);
				}
			},
		);
	};

	const handleBtnValue = (e) => {
		const { name } = e.target;
		if (name === 'buy') {
			setBtnValue('buy');
		} else {
			setBtnValue('sell');
		}
	};
	return (
		<>
			<div className="mypage_cont">
				<div className="list_wrap">
					<h2>옥션내역</h2>
					<div className="tab_list">
						<div className="btn_box">
							<a
								className={btnValue === 'buy' ? 'on' : ''}
								name="buy"
								onClick={(e) => handleBtnValue(e)}
							>
								구매
							</a>
							<a
								className={btnValue === 'sell' ? 'on' : ''}
								name="sell"
								onClick={(e) => handleBtnValue(e)}
							>
								판매
							</a>
						</div>
						<div className="mypage-cancel-info">* 예약을 취소하실 경우 전화주세요. 1644-5751</div>
					</div>
					{btnValue === 'buy' ? (
						<AuctionBuy auctionBuyData={auctionBuyData} buyPage={buyPage} setBuyPage={setBuyPage} />
					) : (
						<AuctionSell
							auctionSellData={auctionSellData}
							sellPage={sellPage}
							setSellPage={setSellPage}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default AuctionMenu;
