import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Thumnail from 'assets-mobile/img/main/img_thumnail.png';
import Warranty from 'assets-mobile/img/main/ico_warranty.svg';
import IcoBox from 'assets-mobile/img/main/ico_box.svg';
import { handlePremiumNum } from 'common/common';
import { getMobileAuctionProductsApi } from 'apis/auctionMain';
import { PROPERTY_DIRECTION_MENU } from 'common/constants';
import CommonHelper from 'helpers/CommonHelper';
import CommonPagination from 'components/CommonPagination';
import BuyFilter from './components/BuyFilter';
import { useSiteState } from 'context/SiteContext';
import useDebounce from 'utils/useDebounce';
import { getBrandListApi, getStoreListApi } from 'apis/buy.js';
import DeadlineAuction from './components/DeadlineAuction';

const Auction = () => {
	const [showFilter, setShowFilter] = useState(false);
	const [checkStorage, setCheckStorage] = useState(false);
	const history = useHistory();
	const [showSort, setShowSort] = useState(false);
	/////판매중인 상품 리스트
	const [currentSellingProducts, setCurrentSellingProducts] = useState([]); // 판매중인 상품 리스트
	const [currentSellingInfo, setCurrentSellingInfo] = useState({}); //판매중인 상품 리스트, 페이징이 포함된 상위 정보state
	////검색 리스트
	// const [selectedSearchResult, setSelectedSearchResult] = useState([]);
	const siteState = useSiteState().data;
	///필터
	const [filterFirst, setFilterFirst] = useState([]); //상세필터 전 6개 (favorite true가 앞으로)
	const [filterSecond, setFilterSecond] = useState([]); // 상세필터 후 7개
	/////필터-브랜드
	const [totalBrandList, setTotalBrandList] = useState([]); // api 받아온 전체 브랜드 리스트
	const [selectedBrandList, setSelectedBrandList] = useState([]); //선택된 브랜드 리스트
	///////필터-모델
	const [searchModelInput, setSearchModelInput] = useState('');
	const [modelResultBox, setModelResultBox] = useState([]);
	const [selectedModelFilter, setSelectedModelFilter] = useState([]);
	const debouncedSearchTerm = useDebounce(searchModelInput, 500);
	//////레퍼런스
	const [searchReference, setSearchReference] = useState('');
	////케이스 사이즈
	const [selectedMinCaseDiameters, setSelectedMinCaseDiaMeters] = useState('');
	const [selectedMaxCaseDiameters, setSelectedMaxCaseDiameters] = useState('');
	//////구동방식
	const [selectedMovement, setSelectedMovement] = useState([]);
	/////////구매년도
	const [producedYearMinInput, setProducedYearMinInput] = useState('');
	const [producedYearMaxInput, setProducedYearMaxInput] = useState('');
	/////////구성품
	const [selectedSupplements, setSelectedSupplements] = useState([]);
	///////성별
	const [selectedGender, setSelectedGender] = useState([]);
	///////케이스 소재
	const [selectedCaseMaterial, setSelectedCaseMaterial] = useState([]);
	//////다이얼 색상
	const [selectedDialColor, setSelectedDialColor] = useState([]);
	///////다이얼 타입
	const [selectedDialType, setSelectedDialType] = useState([]);
	////////
	const [rangeValue, setRangeValue] = useState(0);
	const [conditionStart, setConditionStart] = useState('');
	const [conditionEnd, setConditionEnd] = useState('');
	////////////////상품 리스트 정렬방식
	const [propertyDirection, setPropertyDirection] = useState(PROPERTY_DIRECTION_MENU[0]);
	/////
	const [page, setPage] = useState(0);
	///////보유지점에 보여줄 지점 리스트
	const [currentStoreList, setCurrentStoreList] = useState([]); //보여주기 위해 store name 모음
	/////필터 선택된 지점 리스트
	const [selectedStoreList, setSelectedStoreList] = useState([]);
	const [selectedStoreIdList, setSelectedStoreIdList] = useState([]); // 데이터로 보낼 store ids

	useEffect(() => {
		getSellingProducts();
	}, [page]);
	useEffect(() => {
		getSellingProducts();
		checkStorage && history.action !== 'POP' ? setPage(0) : setPage(page);
	}, [
		propertyDirection,
		selectedBrandList,
		selectedModelFilter,
		searchReference,
		selectedMinCaseDiameters,
		selectedMaxCaseDiameters,
		selectedMovement,
		selectedSupplements,
		selectedGender,
		selectedCaseMaterial,
		selectedDialColor,
		selectedDialType,
		selectedStoreIdList,
		producedYearMaxInput,
		producedYearMinInput,
		conditionStart,
		conditionEnd,
	]);

	const handleStandard = (oneName) => {
		let findOne = PROPERTY_DIRECTION_MENU.filter((el) => el.name === oneName);
		setPropertyDirection(findOne[0]);
		setShowSort(false);
	};

	useEffect(() => {
		getBrandList();
		getStoreList();
	}, []);
	useEffect(() => {
		history.action === 'POP' && searchOptions();
	}, [history.action]);

	const checkTokenAndOpenFind = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (!authToken) {
			alert('시계찾아줘 요청은 로그인 후 사용 해주세요.');
			history.push('/signin?redirectUrl=/auction');
		} else {
			history.push('/findWatch');
		}
	};

	const goToDetail = (auction) => {
		const filterOptions = {
			propertyDirection,
			page,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
		};
		sessionStorage.setItem('auctionFilterCache', JSON.stringify(filterOptions));
		history.push(`/auction/${auction.id}`);
	};

	const searchOptions = () => {
		let result = JSON.parse(sessionStorage.getItem('auctionFilterCache'));

		if (result) {
			const {
				propertyDirection,
				page,
				selectedBrandList,
				selectedModelFilter,
				searchReference,
				selectedMinCaseDiameters,
				selectedMaxCaseDiameters,
				selectedMovement,
				selectedSupplements,
				selectedGender,
				selectedCaseMaterial,
				selectedDialColor,
				selectedDialType,
				selectedStoreIdList,
				producedYearMinInput,
				producedYearMaxInput,
				conditionStart,
				conditionEnd,
			} = result;

			sessionStorage.removeItem('auctionFilterCache');
			setPropertyDirection(propertyDirection);
			setPage(page);
			setSelectedBrandList(selectedBrandList);
			setSelectedModelFilter(selectedModelFilter);
			setSearchReference(searchReference);
			setSelectedMinCaseDiaMeters(selectedMinCaseDiameters);
			setSelectedMaxCaseDiameters(selectedMaxCaseDiameters);
			setSelectedMovement(selectedMovement);
			setSelectedSupplements(selectedSupplements);
			setSelectedGender(selectedGender);
			setSelectedCaseMaterial(selectedCaseMaterial);
			setSelectedDialColor(selectedDialColor);
			setSelectedDialType(selectedDialType);
			setSelectedStoreIdList(selectedStoreIdList);
			setProducedYearMinInput(producedYearMinInput);
			setProducedYearMaxInput(producedYearMaxInput);
			setConditionStart(conditionStart);
			setConditionEnd(conditionEnd);
		}

		setCheckStorage(true);
	};

	const caculateForTotalFilter = (oneFilter, twoFilter) => {
		if (oneFilter?.length > 0 || twoFilter?.length > 0) return 1;
		else return 0;
	};
	let totalFilterSelected =
		selectedBrandList?.length +
		selectedModelFilter?.length +
		caculateForTotalFilter(searchReference) +
		caculateForTotalFilter(selectedMinCaseDiameters, selectedMaxCaseDiameters) +
		selectedMovement?.length +
		caculateForTotalFilter(producedYearMinInput, producedYearMaxInput) +
		selectedSupplements?.length +
		selectedGender?.length +
		selectedCaseMaterial?.length +
		selectedDialColor?.length +
		selectedDialType?.length +
		selectedStoreIdList?.length +
		caculateForTotalFilter(conditionStart, conditionEnd);

	return (
		<>
			{showFilter ? (
				<BuyFilter
					setShowFilter={setShowFilter}
					selectedBrandList={selectedBrandList}
					setSelectedBrandList={setSelectedBrandList}
					selectedModelFilter={selectedModelFilter}
					setSelectedModelFilter={setSelectedModelFilter}
					modelResultBox={modelResultBox}
					searchModelInput={searchModelInput}
					setSearchModelInput={setSearchModelInput}
					searchReference={searchReference}
					setSearchReference={setSearchReference}
					selectedMinCaseDiameters={selectedMinCaseDiameters}
					setSelectedMinCaseDiaMeters={setSelectedMinCaseDiaMeters}
					selectedMaxCaseDiameters={selectedMaxCaseDiameters}
					setSelectedMaxCaseDiameters={setSelectedMaxCaseDiameters}
					selectedMovement={selectedMovement}
					setSelectedMovement={setSelectedMovement}
					selectedSupplements={selectedSupplements}
					setSelectedSupplements={setSelectedSupplements}
					selectedGender={selectedGender}
					setSelectedGender={setSelectedGender}
					selectedCaseMaterial={selectedCaseMaterial}
					setSelectedCaseMaterial={setSelectedCaseMaterial}
					selectedDialColor={selectedDialColor}
					setSelectedDialColor={setSelectedDialColor}
					selectedDialType={selectedDialType}
					setSelectedDialType={setSelectedDialType}
					selectedStoreIdList={selectedStoreIdList}
					setSelectedStoreIdList={setSelectedStoreIdList}
					producedYearMaxInput={producedYearMaxInput}
					setProducedYearMaxInput={setProducedYearMaxInput}
					producedYearMinInput={producedYearMinInput}
					setProducedYearMinInput={setProducedYearMinInput}
					conditionStart={conditionStart}
					setConditionStart={setConditionStart}
					conditionEnd={conditionEnd}
					setConditionEnd={setConditionEnd}
					totalBrandList={totalBrandList}
					currentStoreList={currentStoreList}
					selectedStoreList={selectedStoreList}
					setSelectedStoreList={setSelectedStoreList}
				/>
			) : (
				<main className="auction">
					<section className="auction_top">
						<h2>하이드로우</h2>
					</section>

					<DeadlineAuction />

					<section className="product_list" style={{ marginBottom: '25%' }}>
						<p className="total_txt">
							전체{' '}
							<b>
								{currentSellingInfo?.total ? Number(currentSellingInfo?.total).toLocaleString() : 0}
								개
							</b>
						</p>
						{currentSellingProducts.length > 0 ? (
							<>
								<ul>
									{currentSellingProducts.map((auction, idx) => (
										<li key={idx} onClick={() => goToDetail(auction)}>
											<div className="top">
												<img
													src={auction?.attachedFiles ? auction.attachedFiles[0] : Thumnail}
													alt="시계사진"
												/>
												<div className="info">
													{!!auction?.price &&
														!!auction?.standardWatch?.officialPrice &&
														handlePremiumNum(
															auction?.price,
															auction?.standardWatch?.officialPrice,
														) && (
															<p className="sale">
																{handlePremiumNum(
																	auction?.price,
																	auction?.standardWatch?.officialPrice,
																)}
																% Premium
															</p>
														)}
													<p className="option">
														{auction?.watch?.supplements?.includes('GUARANTEE') && (
															<img src={Warranty} alt="보증서" title="보증서가 있는 상품입니다." />
														)}
														{auction?.watch?.supplements?.includes('BOX') && (
															<img
																src={IcoBox}
																alt="정품박스"
																title="정품박스가 있는 상품입니다."
															/>
														)}
													</p>
													{!!auction?.standardWatch?.hpi ? (
														<p
															className={
																auction?.standardWatch?.hpi > 0
																	? 'fluctuations up'
																	: auction?.standardWatch?.hpi < 0
																	? 'fluctuations down'
																	: auction?.standardWatch?.hpi === 0
																	? 'fluctuations'
																	: ''
															}
														>
															{auction?.standardWatch?.hpi}
														</p>
													) : (
														''
													)}
												</div>
											</div>
											<div className="btm">
												<p className="watch_name ellipsis poppins">
													{auction?.watch?.brand || '-'} <br />
													{auction?.watch?.model || '-'}
												</p>
												<p className="price">
													₩{' '}
													{auction?.lastPrice
														? Number(auction.lastPrice).toLocaleString()
														: Number(auction.price).toLocaleString()}
												</p>
												<ul>
													<li>{auction?.watch?.producedYear || '-'}</li>
													<li>{auction?.watch.condition || '-'}</li>
													<li>{auction?.watch?.serialNo || '-'}</li>
												</ul>
											</div>
										</li>
									))}
								</ul>
								<CommonPagination
									limit="16"
									commonData={currentSellingInfo}
									page={page}
									setPage={setPage}
								/>
							</>
						) : (
							<section className="nodata_wrap">
								<p className="t1">
									{/* 조건에 맞는 시계를 찾을 수 없습니다. <br />
									조건을 바꿔보세요. */}
									조건에 맞는 시계를 찾고 있습니다.
									<br />
									찾는 시계가 없다면 조건을 바꿔보세요.
								</p>
								<p className="t2">
									혹시 찾으시는 시계를 찾을 수 없다면 <br />
									<b>‘시계찾아줘’</b>를 요청해보세요.
								</p>
								<button className="link" onClick={checkTokenAndOpenFind}>
									시계 찾아줘 바로가기
								</button>
							</section>
						)}
					</section>
					<section className="filter_wrap">
						<button className="btn_filter" onClick={() => setShowFilter(true)}>
							필터
							<span>{totalFilterSelected}</span>
						</button>
						<button className="btn_sort" onClick={() => setShowSort(true)}>
							정렬
						</button>
					</section>
				</main>
			)}
			{showSort && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="fliter_pop">
						<ul>
							{PROPERTY_DIRECTION_MENU.map((standard, idx) => (
								<li key={idx}>
									<button onClick={() => handleStandard(standard.name)}>{standard.name}</button>
								</li>
							))}
						</ul>
					</article>
				</div>
			)}
		</>
	);

	function getSellingProducts() {
		getMobileAuctionProductsApi(
			propertyDirection,
			page,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
		).then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentSellingProducts(data.content || []);
				setCurrentSellingInfo(data);
			} else {
				alert(detail);
			}
		});
	}
	function getStoreList() {
		getStoreListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}
	function getBrandList() {
		getBrandListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setTotalBrandList(data);
			} else {
				alert(detail);
			}
		});
	}
};

export default Auction;
