import { apiCall, apiCallByFormData } from './apiCall';
import { CLIENT_URL, SERVER_API_HOST } from 'common/environment';

export const getUserSessionApi = (authToken) => {
	return apiCall(
		`${SERVER_API_HOST}/api/user/session`,
		'get',
		{},
		{
			authorization: authToken,
		},
	);
};

export const getSiteSettingApi = () => {
	return apiCall(`${SERVER_API_HOST}/api/site/info`, 'get', {}, {});
};
