import React from 'react';
import IcoChecking from 'assets-pc/img/common/ico_checking.svg';
import ImgLogo from 'assets-pc/img/common/pc_logo.png';
import { useSiteState } from 'context/SiteContext';
import { handleIsnpectionTime } from 'common/common';
// import 'assets-pc/css/common.css';
// import 'assets-pc/css/font.css';
// import 'assets-pc/css/layout.css';
import 'assets-pc/scss/common.scss';
// import 'assets-pc/css/frontEndAssets.css';
import 'assets-pc/css/frontEndAssets.scss';
import 'assets-pc/scss/font.scss';
import 'assets-pc/scss/layout.scss';

const StopScreen = () => {
	const { data } = useSiteState();
	const startTime = new Date(data.siteCheckStartDate);
	const endTime = new Date(data.siteCheckEndDate);
	const middle = endTime - startTime;
	const middleNum = Math.floor((middle % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

	return (
		<body>
			<div className="bg_dimmed"></div>
			<div id="wrap" className="info pc-wrapper" >
				<div className="top_img_box">
					<img
						src={IcoChecking}
						alt=""
						// style={{ width: '200px' }}
						width="200"
					/>
				</div>
				<p className="t1">
					보다 나은 서비스 제공을 위해서 <br />
					시스템 점검중 입니다.
				</p>
				<p className="t2">
					안정적이고 편리한 서비스를 위한 부분이오니 <br />
					양해 부탁드립니다.
				</p>
				<p className="t3">점검일시</p>
				<p className="t4">
					{handleIsnpectionTime(data.siteCheckStartDate)} ~{' '}
					{handleIsnpectionTime(data.siteCheckEndDate)} ({middleNum}시간)
				</p>
				<p className="logo">
					<img src={ImgLogo} alt="Hi sigan" style={{ width: '124px' }} />
				</p>
			</div>
		</body>
	);
};

export default StopScreen;
