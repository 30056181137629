import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { postAuctionApplyApi } from 'apis/auctionApply';
import CommonHelper from 'helpers/CommonHelper';
import AuctionDatePicker from '../../components/AuctionDetail/AuctionDatePicker';
import { useAppState } from 'context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import 'assets-pc/css/common.css';
const fontStyle = {
	marginTop: '5px',
	marginBottom: '12px',
	color: 'red',
	fontSize: '12px',
};

const AuctionRegister = ({ setShowAuctionRegister }) => {
	const [showApplyComplete, setShowApplyComplete] = useState(false);
	const userData = useAppState();
	const { roles } = userData.user.data;

	const [auctionApplyCompletedPopup, setAuctionApplyCompletedPopup] = useState(false);
	const [isAuctionPopuped, setIsAuctionPopuped] = useState(false);

	//input value
	const [accountHolderName, setAccountHolderName] = useState('');
	const [nameValidation, setNameValidation] = useState(true);

	const [startDate, setStartDate] = useState(new Date());
	const [dateValidation, setDateValidation] = useState(true);

	const offset = new Date().getTimezoneOffset() * 60000;
	const convertStartDate = new Date(startDate - offset).toISOString().split('.')[0];

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	const handleAuctionPopup = () => {
		setIsAuctionPopuped(!isAuctionPopuped);
	};
	const handleAccountHolderName = (e) => {
		setAccountHolderName(e.target.value);
	};

	const handleAuctionApply = () => {
		if (!accountHolderName && !startDate) {
			return setNameValidation(false), setDateValidation(false);
		} else if (!accountHolderName && startDate) {
			return setNameValidation(false), setDateValidation(true);
		} else if (accountHolderName && !startDate) {
			return setNameValidation(true), setDateValidation(false);
		} else if (accountHolderName && startDate) {
			return postAuctionApplyApi(accountHolderName, convertStartDate, authToken).then(
				({ data: { data, detail, status } }) => {
					if (status === 'SUCCESS') {
						setShowApplyComplete(true);
						// setShowAuctionRegister(false)
						setAuctionApplyCompletedPopup(true);
						setNameValidation(true);
						setDateValidation(true);
						setAccountHolderName('');
						setStartDate(new Date());
					} else {
						// alert('등업 신청에 실패하였습니다. 고객상담으로 문의주시면 도와드리겠습니다.');
						alert(detail);
					}
				},
			);
		}
	};

	const clickCancelBtn = () => {
		setIsAuctionPopuped(!isAuctionPopuped);
		setAuctionApplyCompletedPopup(false);
		setNameValidation(true);
		setStartDate(new Date());
		setAccountHolderName('');
	};
	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowAuctionRegister(false)}></button>
					<h2>옥션등급 신청</h2>
				</section>
				<section className="auction">
					<div className="text_box">
						<p className="t1">
							옥션에 참여하기 위해선 옥션등급으로 올려야 <br />
							참여가 가능합니다. 하이시간 계좌번호로
							<br />
							<b>‘50,000’원</b>을 입금하시면 관리자 확인 후<br />
							옥션등급으로 올라갑니다.(옥션에 입찰 가능)
						</p>
						<p className="t2">
							입찰 후 구매취소를 하게 되면 보증금이 차감되면서 일반등급으로 강등됩니다.
						</p>
					</div>
					<p className="tit">
						입금자 <span>*</span>
					</p>
					<div className="input_div">
						<input
							type="text"
							className="input_t"
							placeholder="입금자를 입력해주세요"
							onChange={handleAccountHolderName}
							value={accountHolderName}
						/>
						{nameValidation ? '' : <p style={fontStyle}>예금주를 입력해주세요</p>}
					</div>

					<p className="tit">
						입금시간 <span>*</span>
					</p>
					<div className="input_div">
						{/* <input type="text" className="input_t" placeholder="입금시간을 입력해주세요." /> */}
						<ReactDatePicker
							locale={ko}
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							showTimeSelect
							timeFormat="HH:mm"
							timeIntervals={60}
							timeCaption="time"
							dateFormat="MMMM d, yyyy h:mm aa"
							wrapperClassName="mobile-wrapper input_t input_t_auction"
							customInput={
								<input
									type="text"
									className="input_t"
									style={{ width: '100%' }}
									placeholder="입금시간을 입력해주세요."
								/>
							}
						/>
						{dateValidation ? '' : <p className="error_txt">날짜를 선택해주세요</p>}
					</div>
					<h3>입금하실 곳</h3>
					<div className="deposit_box">
						<ul>
							<li>
								<span>은행</span>
								<b>기업은행</b>
							</li>
							<li>
								<span>계좌번호</span>
								<b>126301 04 391926</b>
							</li>
							<li>
								<span>예금주</span>
								<b>(주) 시간</b>
							</li>
						</ul>
					</div>
					<a className="btn_ok" onClick={handleAuctionApply}>
						신청하기
					</a>
				</section>
			</main>
			{showApplyComplete && (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<article className="popup_alert">
						<p className="t2">옥셩등급 신청이 완료되었습니다.</p>
						<p className="t3">
							등록된 휴대폰번호로 입금계좌 정보를 <br />
							발송해 드렸습니다.
						</p>
						<button className="btn_ok" onClick={() => setShowAuctionRegister(false)}>
							확인
						</button>
					</article>
				</div>
			)}
		</>
	);
};

export default AuctionRegister;
