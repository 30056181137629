import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDefaultMenuApi } from 'apis/defaultMenu';
import CommonHelper from 'helpers/CommonHelper';

const DefaultMenu = () => {
	const [defaultData, setDefaultData] = useState({});
	const [defaultReservationData, setDefaultReservationData] = useState([]);
	const [defaultAuctionData, setDefaultAuctionData] = useState([]);
	const history = useHistory();

	const authToken = CommonHelper.getAuthToken()
		? CommonHelper.getAuthToken()
		: CommonHelper.getSessionAuthToken();

	// 전체 데이터 받아오기
	useEffect(() => {
		getDefaultData();
	}, []);

	const getDefaultData = () => {
		getDefaultMenuApi(authToken).then(({ data: { status, detail, data } }) => {
			if (status === 'SUCCESS') {
				setDefaultData(data || {});
			} else {
				alert(detail);
			}
		});
	};

	// reservation 데이터 따로 가공하는 함수 호출
	useEffect(() => {
		if (defaultData) {
			handleReservationData();
		}
	}, [defaultData]);

	const handleReservationData = () => {
		const buyData =
			defaultData && defaultData.purchases
				? defaultData.purchases.map((data) => {
						return { ...data, reservationType: 'purchases' };
				  })
				: '';
		const sellData =
			defaultData && defaultData.sales
				? defaultData.sales.map((data) => {
						return { ...data, reservationType: 'sales' };
				  })
				: '';
		const concatData = buyData.concat(sellData);
		const sortData =
			concatData &&
			concatData.sort((a, b) => {
				return new Date(b?.createdAt) - new Date(a?.createdAt);
			});
		setDefaultReservationData(sortData);
	};

	//auction 데이터 가공하는 함수 호출
	useEffect(() => {
		if (defaultData) {
			handleAuctionData();
		}
	}, [defaultData]);

	const handleAuctionData = () => {
		const sellData =
			defaultData && defaultData.auctions
				? defaultData.auctions.map((data) => {
						return { ...data, AuctionType: 'sell' };
				  })
				: '';

		const buyData =
			defaultData && defaultData.auctionOrders
				? defaultData.auctionOrders.map((data) => {
						return { ...data, AuctionType: 'buy' };
				  })
				: '';
		const concatData = buyData.concat(sellData);
		const sortData =
			concatData &&
			concatData.sort((a, b) => {
				return new Date(b?.createdAt) - new Date(a?.createdAt);
			});
		setDefaultAuctionData(sortData);
	};

	// 예약 전체보기
	const goToReservation = () => {
		history.push('myPage/reservation');
	};

	// 옥션 전체보기
	const goToAuction = () => {
		history.push('myPage/auction');
	};

	return (
		<>
			<div className="mypage_cont">
				<div className="list_wrap">
					<h2>예약내역</h2>
					<a className="btn_all" onClick={goToReservation}>
						전체보기
					</a>
					<table className="tbl">
						<colgroup>
							<col style={{ width: '5%' }} />
							<col style={{ width: '7%' }} />
							<col style={{ width: '12%' }} />
							<col style={{ width: '%' }} />
							<col style={{ width: '10%' }} />
							<col style={{ width: '20%' }} />
							<col style={{ width: '7%' }} />
						</colgroup>
						<thead>
							<tr>
								<th>번호</th>
								<th>구분</th>
								<th>신청일</th>
								<th>상품명</th>
								<th>방문지점</th>
								<th>예약일정</th>
								<th>상태</th>
							</tr>
						</thead>
						<tbody>
							{defaultReservationData?.length > 0 ? (
								defaultReservationData.slice(0, 5).map((data, index) => {
									return (
										<tr key={index}>
											<td>{data?.id ? data.id : '-'}</td>
											<td>
												<span className="type">
													{data.reservationType === 'purchases' ? '구매' : '판매'}
												</span>
											</td>
											<td>{data?.createdAt ? data.createdAt.slice(0, 10) : '-'}</td>
											<td>
												<b>{data?.watch?.model ? data.watch.model : '-'}</b>
											</td>
											<td>{data?.store?.name ? data.store.name : '-'}</td>
											<td>
												{data?.visitDateTime
													? data.visitDateTime.split('T').toString().slice(0, 16).replace(',', ' ')
													: '-'}
											</td>
											<td>
												<span
													style={{ width: '55px' }}
													className={data?.state === 'VISITED' ? 'state cancel' : 'state complete'}
												>
													{data?.state === 'REGISTERED'
														? '고객등록'
														: 'APPROVED'
														? '승인'
														: 'VISITED'
														? '매장방문'
														: 'SALE_REGISTERED'
														? '판매 등록'
														: 'SOLD'
														? '판매 완료'
														: '-'}
												</span>
											</td>
										</tr>
									);
								})
							) : (
								<tr className="empty">
									<td colSpan="7">
										<p>최근 이력이 없습니다.</p>
									</td>
								</tr>
							)}
							{/* <tr>
								<td>2</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr>
							<tr>
								<td>3</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state complete">완료</span>
								</td>
							</tr>
							<tr>
								<td>4</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr>
							<tr>
								<td>5</td>
								<td>
									<span className="type">구매</span>
								</td>
								<td>2020-11-23</td>
								<td>
									<b>롤렉스 서브마리너</b>
								</td>
								<td>서울</td>
								<td>2020-11-23 01~02시</td>
								<td>
									<span className="state cancel">취소</span>
								</td>
							</tr> */}
						</tbody>
					</table>
					<div className="mypage-cancel-info">* 예약을 취소하실 경우 전화주세요. 1644-5751</div>
				</div>
				{/* <div className="list_wrap">
					<h2>옥션내역</h2>
					<a className="btn_all" onClick={goToAuction}>
						전체보기
					</a>
					<table className="tbl">
						<colgroup>
							<col style={{ width: '5%' }} />
							<col style={{ width: '7%' }} />
							<col style={{ width: '12%' }} />
							<col style={{ width: '%' }} />
							<col style={{ width: '10%' }} />
							<col style={{ width: '20%' }} />
							<col style={{ width: '7%' }} />
						</colgroup>
						<thead>
							<tr>
								<th>번호</th>
								<th>구분</th>
								<th>신청일</th>
								<th>상품명</th>
								<th>참여자수</th>
								<th>가격</th>
								<th>상태</th>
							</tr>
						</thead>
						<tbody>
							{defaultAuctionData?.length > 0 ? (
								defaultAuctionData.slice(0, 5).map((data, index) => {
									return (
										<tr key={index}>
											<td>
												{data?.AuctionType && data.AuctionType === 'sell'
													? data?.id
														? data.id
														: '-'
													: 'buy'
													? data?.auction?.id
														? data.auction.id
														: '-'
													: '-'}
											</td>
											<td>
												<span className="type">
													{data?.AuctionType && data.AuctionType === 'buy' ? '구매' : '판매'}
												</span>
											</td>
											<td>{data?.createdAt ? data.createdAt.slice(0, 10) : '-'}</td>
											<td>
												<b>
													{data?.AuctionType && data.AuctionType === 'sell'
														? data?.watch?.model
															? data.watch.model
															: '-'
														: 'buy'
														? data?.auction?.watch?.model
															? data.auction.watch.model
															: '-'
														: '-'}
												</b>
											</td>
											<td>
												{data?.AuctionType && data.AuctionType === 'sell'
													? data?.orderPeopleCount
														? data.orderPeopleCount + '명'
														: '-'
													: 'buy'
													? data?.auction?.orderPeopleCount
														? data.auction.orderPeopleCount + '명'
														: '-'
													: '-'}
											</td>
											<td>
												{data?.AuctionType && data.AuctionType === 'sell' ? (
													<b className="price">
														{data?.auctionStatus
															? data.auctionStatus === 'DONE'
																? data.fixedPrice === 0
																	? data.price.toLocaleString('en')
																	: data.fixedPrice.toLocaleString('en')
																: data.auctionStatus === 'RUN'
																? data.lastPrice > 0
																	? data.lastPrice.toLocaleString('en')
																	: data.price.toLocaleString('en')
																: data.auctionStatus === 'WAIT'
																? data.price.toLocaleString('en')
																: '-'
															: '-'}
													</b>
												) : (
													<b>{data?.price ? data.price.toLocaleString('en') : '-'}</b>
												)}
											</td>
											<td>
												{data?.AuctionType && data.AuctionType === 'sell' ? (
													<span
														className={
															data?.auctionStatus
																? data?.auctionStatus === 'WAIT'
																	? 'state_txt stay'
																	: data?.auctionStatus === 'RUN'
																	? 'state_txt'
																	: data?.auctionStatus === 'DONE'
																	? 'state_txt t1'
																	: ''
																: ''
														}
													>
														{data?.auctionStatus
															? data.auctionStatus === 'WAIT'
																? '대기중'
																: data.auctionStatus === 'RUN'
																? '진행중'
																: data.auctionStatus === 'DONE'
																? '완료'
																: ''
															: ''}
													</span>
												) : (
													<span
														className={
															data?.auction?.auctionStatus
																? data?.auction?.auctionStatus === 'WAIT'
																	? 'state_txt stay'
																	: data?.auction?.auctionStatus === 'RUN'
																	? 'state_txt'
																	: data?.auction?.auctionStatus === 'DONE'
																	? 'state_txt t1'
																	: ''
																: ''
														}
													>
														{data?.auction?.auctionStatus
															? data.auction.auctionStatus === 'WAIT'
																? '대기중'
																: data.auction.auctionStatus === 'RUN'
																? '진행중'
																: data.auction.auctionStatus === 'DONE'
																? '완료'
																: ''
															: ''}
													</span>
												)}
											</td>
										</tr>
									);
								})
							) : (
								<tr className="empty">
									<td colSpan="7">
										<p>최근 이력이 없습니다.</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div> */}
			</div>
		</>
	);
};

export default DefaultMenu;
