import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import GoogleMapComponent from './MobileGoogleMap';
import BranchSeoul from 'assets-mobile/img/branch/branch_seoul.png';
import Seoul1 from 'assets-pc/front-img/m-seoul-01.png';
import Seoul2 from 'assets-pc/front-img/m-seoul-02.png';
import Seoul3 from 'assets-pc/front-img/m-seoul-03.png';

const Seoul = ({ setNowStoreId }) => {
	const history = useHistory();
	let widthAndHeight = (window.innerWidth / 3) * 2;
	const sizeCheck = () => {
		widthAndHeight = (window.innerWidth / 3) * 2;
	};
	useEffect(() => {
		sizeCheck();
		window.addEventListener('resize', sizeCheck);
	}, []);

	useEffect(() => {
		let swiper = new Swiper('.branch_img', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			pagination: {
				el: '.branch_img_paging',
				clickable: true,
			},
		});
	}, []);
	const goStore = () => {
		history.push('/hapl');
	};

	return (
		<>
			<section className="branch_tab">
				<a onClick={() => setNowStoreId(0)}>본사</a>
				<a className="on">압구정점</a>
				<a onClick={() => setNowStoreId(2)}>마린시티점</a>
				<a onClick={() => setNowStoreId(3)}>대구수성점</a>
			</section>

			<section className="swiper-container branch_img" style={{ height: `${widthAndHeight}px` }}>
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<img src={Seoul1} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Seoul2} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
					<div className="swiper-slide">
						<img src={Seoul3} alt="" style={{ height: `${widthAndHeight}px` }} />
					</div>
				</div>
				<div className="branch_img_paging swiper-pagination"></div>
			</section>

			<section className="greetings">
				<h3>
					하이시간, <span>서울의 인사말</span>
				</h3>
				<p>
					패션의 중심지 압구정 로데오 거리에 위치한 하이시간 부티크는 고객들과 소통할 수 있는
					럭셔리한 공간이 마련되어 있습니다.
				</p>
				<p>
					열정적인 시계 애호가 뿐만 아니라 시계 입문자의 요구 사항을 모두 충족시키는 서비스를
					제공하기 위해 오랜 경력의 시계 전문가가 상주 해 있습니다.
				</p>
				<p>
					하이시간 서울 부티크에서 다양한 컬렉션을 만나 보고, <br />
					엄격하고 뛰어난 전문 조언을 확인 해 보세요.
				</p>
			</section>

			<section className="branch_intro">
				<p className="t1">Hisigan Boutique</p>
				<h3>서울 압구정점</h3>
				<p className="adress">서울특별시 강남구 신사동 압구정로50길 5-10 1층</p>
				<div className="map_box">
					<GoogleMapComponent
						places="place_id:ChIJsyhBUlyjfDURJN0QxCaVADs"
						// latitude={Number(shopDatas?.latitude) ? Number(shopDatas.latitude) : ''}
						// longitude={Number(shopDatas?.longitude) ? Number(shopDatas.longitude) : ''}
					/>
					{/* <iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.1641225689496!2d127.03685991531061!3d37.52762827980519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3879bd38003%3A0x95881391200200d3!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDsi6Dsgqzrj5kg7JWV6rWs7KCV66GcNTDquLggNS0xMCAx7Li1!5e0!3m2!1sko!2skr!4v1621477566842!5m2!1sko!2skr"
							style="border:0;"
							// allowfullscreen=""
							loading="lazy"
						></iframe> */}
				</div>
				<ul>
					<li>
						<b>대표 전화</b>
						<span>1644-5751</span>
					</li>
					<li>
						<b>직통 전화</b>
						<span>02-2039-3265</span>
					</li>
					<li>
						<b>팩스</b>
						<span>051-711-3049</span>
					</li>
					<li>
						<b>영업시간</b>
						<div>
							<p>
								FROM MONDAY TO SATURDAY <br />
								<b>10AM-7PM</b>
							</p>
							<p>* 일요일과 공휴일은 휴무</p>
						</div>
					</li>
					<li>
						<b>이메일</b>
						<span>hi_sigan@naver.com</span>
					</li>
				</ul>
			</section>
		</>
	);
};

export default Seoul;
