import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MemberOnlyRoute from './MemberOnlyRoute';
import Layout from 'components/Layout';
import Main from 'components/Main';
import SignIn from 'components/SignIn';
import SignUp from 'components/SignUp';
import Guarantee from 'components/Guarantee';
import Hpi from 'components/Hpi';
import Buy from 'components/Buy';
import Buy2 from 'components/Buy2';
import BuyDetail from 'components/BuyDetail';
import Faq from 'components/Faq';
import Notice from 'components/Notice';
import NoticeDetail from 'components/NoticeDetail';
import ShopInfo from 'components/ShopInfo';
import MyPage from 'components/MyPage';
import ReservationMenu from 'components/MyPage/components/ReservationMenu';
import DefaultMenu from 'components/MyPage/components/DefaultMenu';
import FilterMenu from 'components/MyPage/components/FilterMenu';
import AuctionMenu from 'components/MyPage/components/AuctionMenu/AuctionMenu';
import PasswordCheckPage from 'components/MyPage/components/ModifyInfoMenu/PasswordCheckPage';

import Auction from 'components/Auction';
import AuctionDetail from 'components/AuctionDetail';
import Sell from 'components/Sell';
import TermsOfService from 'components/TermsOfService';
import PersonalInfoRule from 'components/PersonalInfoRule';
import SellModelInfo from 'components/Sell/components/SellModelInfo';
import CareService from 'components/CareService';
import Hapl from 'components/Hapl';
import Review from 'components/Review';
import ReviewDetail from 'components/ReviewDetail';
import BuyRoute from './BuyRoute';

const Routes = () => {
	return (
		<React.Fragment>
			{/* <Route path="/" exact component={Main} /> */}
			<Route path="/" exact component={() => {
				window.location.href = 'https://www.hisigan.co.kr/'
			}} />
			<Switch>
				<Route path="/signIn" exact component={SignIn} />
				<Route path="/signUp" exact component={SignUp} />
				<Route path="/forgot/id" exact component={SignUp} />
				<Route path="/forgot/password" exact component={SignUp} />
				<Route path="/change/password" exact component={SignUp} />
				<BuyRoute path="/buy" />
				<BuyRoute path="/buy/brand/:brand" />
				<BuyRoute path="/buy/sold/:brand" />
				<BuyRoute path="/buy/:id" />
				<Route path="/subbuy" exact component={Buy} />
				<Route path="/subbuy/brand/:brand" exact component={Buy} />
				<Route path="/subbuy/sold/:brand" exact component={Buy2} />
				<Route path="/subbuy/:id" exact component={BuyDetail} />
				{/* <Route path="/sell" exact component={Sell} />
				<Route path="/sell/:id" exact component={Sell} /> */}
				<Route path="/auction" exact component={Auction} />
				<Route path="/auction/:id" exact component={AuctionDetail} />

				<Route path="/myPage" exact component={() => <MyPage child={DefaultMenu} />} />
				<Route
					path="/myPage/reservation"
					exact
					component={() => <MyPage child={ReservationMenu} />}
				/>
				<Route path="/myPage/auction" exact component={() => <MyPage child={AuctionMenu} />} />
				<MemberOnlyRoute path="/myPage/filter" exact component={MyPage} child={FilterMenu} />
				<Route path="/mypage/modify" exact component={PasswordCheckPage} />
				<Route path="/stores" exact component={Main} />
				<Route path="/notice" exact component={Notice} />
				<Route path="/notice/:id" exact component={NoticeDetail} />
				<Route path="/review" exact component={Review} />
				<Route path="/review/:id" exact component={ReviewDetail} />
				<Route path="/faq" exact component={Faq} />
				<Route path="/guarantee" exact component={Guarantee} />
				<Route path="/hpi" exact component={Hpi} />
				<Route path="/care" exact component={CareService} />
				<Route path="/shop/:name" exact component={ShopInfo} />
				<Route path="/shop" exact component={ShopInfo} />
				<Route path="/termsOfService" exact component={TermsOfService} />
				<Route path="/personalInfo" exact component={PersonalInfoRule} />
				<Route path="/hapl" exact component={Hapl} />
			</Switch>
		</React.Fragment>
	);
};

export default Routes;
