import { API_KEY } from 'apis/map';
import React from 'react';

const containerStyle = {
	width: '100%',
};

const MobileGoogleMap = ({ places }) => {
	return (
		<div className="google-map-code">
			<iframe
				title={'gooleMap'}
				src={`https://www.google.com/maps/embed/v1/place
					?key=${API_KEY}
					&q=${places}`}
				frameBorder="0"
				style={containerStyle}
				aria-hidden="false"
			></iframe>
		</div>
	);
};

export default React.memo(MobileGoogleMap);
