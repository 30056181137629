import React, { useState, useEffect } from 'react';
import { findWatchApi } from 'apis/buy';
import CommonHelper from 'helpers/CommonHelper';
const initFindWatchInfo = {
	model: '',
	price: '',
	phone: '',
	description: '',
};

const FindWatch = ({ setShowFindWatchPopup, setShowAlertComplete }) => {
	const [findWatchInfo, setFindWatchInfo] = useState(initFindWatchInfo);
	const [errorText, setErrorText] = useState('');
	const [agreeChecked, setAgreeChecked] = useState(false);

	///////
	const handleFindModelInput = (e) => {
		const { name, value } = e.target;
		let copyFindWatchInfo = { ...findWatchInfo };
		if (name === 'price') {
			let deleteAll = value
				.replace(/ /g, '')
				.replace(/,/g, '')
				.replace(/[^0-9]/g, '');
			copyFindWatchInfo[name] = deleteAll;
		} else {
			copyFindWatchInfo[name] = value;
		}
		setFindWatchInfo(copyFindWatchInfo);
	};
	const handleChecked = (e) => {
		const { checked } = e.target;
		if (checked) {
			setAgreeChecked(true);
		} else {
			setAgreeChecked(false);
		}
	};
	const checkValidation = () => {
		if (findWatchInfo.model.length === 0) {
			setErrorText('상품명을 입력해주세요.');
		} else if (findWatchInfo.price.length === 0) {
			setErrorText('희망가격을 입력해주세요.');
		} else if (!agreeChecked) {
			setErrorText('개인정보수집 이용 동의에 체크해주세요.');
		} else {
			const authToken = CommonHelper.getAuthToken()
				? CommonHelper.getAuthToken()
				: CommonHelper.getSessionAuthToken();
			findWatchApi(authToken, findWatchInfo).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					//성공 신청했다고 알려주기
					setFindWatchInfo(initFindWatchInfo);
					setShowAlertComplete(true);
					setShowFindWatchPopup(false);
				} else {
					alert(detail);
				}
			});
		}
	};
	const closeAll = () => {
		setShowAlertComplete(false);
	};
	return (
		<>
			<div className="bg_dimmed dimmed_block">
				<section className="popup reservation">
					<h2>시계 찾아줘</h2>
					<p>
						원하는 시계를 찾아드리는 서비스입니다. <br />
						아래의 양식을 입력해주시면 원하시는 시계를 찾아드립니다.
					</p>
					<div className="cont">
						<h3>
							상품명<span>*</span>
						</h3>
						<input
							type="text"
							placeholder="상품명을 입력해 주세요"
							name="model"
							value={findWatchInfo.model || ''}
							onChange={handleFindModelInput}
							className="find-watch-input-text"
						/>
						<h3>
							희망가격<span>*</span>
						</h3>
						<input
							type="text"
							placeholder="희망가격을 입력해 주세요."
							name="price"
							value={findWatchInfo.price ? Number(findWatchInfo.price).toLocaleString() : ''}
							onChange={handleFindModelInput}
							className="find-watch-input-text"
						/>
						<h3>
							전화번호<span>*</span>
						</h3>
						<input
							type="text"
							placeholder="전화번호를 입력해 주세요."
							name="phone"
							value={findWatchInfo.phone || ''}
							onChange={handleFindModelInput}
							className="find-watch-input-text"
						/>
						<h3>요청사항(선택)</h3>
						<textarea
							name="description"
							id=""
							cols="30"
							rows="10"
							placeholder="요청사항을 입력해 주세요."
							value={findWatchInfo.description || ''}
							onChange={handleFindModelInput}
						></textarea>

						<p className="alert_txt">{errorText}</p>
						<div className="agree_box">
							<input type="checkbox" value={agreeChecked} onChange={handleChecked} />
							<span>
								<a>개인정보수집</a> 이용에 동의합니다.
							</span>
							<p>
								서비스 이용을 위해 필요한 정보로써, 동의를 해주셔야 서비스를 이용 하실 수 있습니다.
							</p>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="full btn-margin" onClick={checkValidation}>
							신청하기
						</button>
						<button className="full btn-margin" onClick={() => setShowFindWatchPopup(false)}>
							닫기
						</button>
					</div>
				</section>
			</div>
		</>
	);
};
export default FindWatch;
