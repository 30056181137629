import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Seoul from './components/Seoul';
import Daegu from './components/Daegu';
import Busan from './components/Busan';
import BusanMarin from './components/BusanMarin';
import './index.scss';

const Store = () => {
	const [nowStoreId, setNowStoreId] = useState(0);
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
		if (window?.location?.pathname?.split('/')[2]?.length > 0) {
			setNowStoreId(Number(window.location.pathname.split('/')[2]));
		}
	}, []);
		const goStore = () => {
			history.push('/hapl');
		};
	return (
		<>
			<main className="mypage">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => history.goBack()}></button>
					<h2>부티크</h2>
				</section>

				<div className="top-photo-box">
					<div className="top-photo"></div>
					<div className="title-box">
						<div className="big-title">고객님의 소중한 자산</div>
						<div className="small-title">언제 거래하셔도 명품의 가치를 지켜드리는</div>
						<div className="small-title2">하이시간 입니다.</div>
					</div>
					<div className="btn-wrapper">
						<div className="btn-box">
							<button className="reservation-btn" onClick={goStore}>
								<span className="left-top-logo">HiSiGAN PLACE</span>
								<span className="content">공간대여 예약</span>
								<span className="line1"></span>
								<span className="line2"></span>
							</button>
							<button className="introduce-btn">
								<span className="left-top-logo">HiSiGAN PLACE</span>
								<span className="content">부티크 소개</span>
								<span className="line1"></span>
								<span className="line2"></span>
							</button>
						</div>
					</div>
				</div>
				{nowStoreId === 0 && <Busan setNowStoreId={setNowStoreId} />}
				{nowStoreId === 1 && <Seoul setNowStoreId={setNowStoreId} />}
				{nowStoreId === 2 && <BusanMarin setNowStoreId={setNowStoreId} />}
				{nowStoreId === 3 && <Daegu setNowStoreId={setNowStoreId} />}
			</main>
		</>
	);
};

export default Store;
