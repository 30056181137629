import React from 'react';

const FilterBrand = ({
	totalBrandList,
	handleCheckBoxCommon,
	selectedBrandList,
	setSelectedBrandList,
	setShowBrandFilter,
}) => {
	return (
		<>
			<main className="buy">
				<section className="back_wrap">
					<button className="btn_back" onClick={() => setShowBrandFilter(false)}></button>
					<h2>브랜드</h2>
				</section>

				<section
					className="fliter_list"
					style={{ paddingTop: '3.333vw', marginTop: '0', backgroundColor: ' #F5F7FA' }}
				>
					<div className="searched_brands" style={{ backgroundColor: '#fff' }}>
						<div className="search_order_list">
							<div>
								<h3
									style={{
										margin: '0',
										padding: '5% 0',
										fontSize: '4vw',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									전체 브랜드
									<div style={{ fontSize: '3.8vw', opacity: '0.5', fontWeight: '500' }}>
										선택 브랜드({selectedBrandList.length || 0}건)
									</div>
								</h3>
							</div>
							<ul>
								{totalBrandList.map((brand, idx) => (
									<li key={idx}>
										<b>{brand.brand || '-'}</b>
										<span>{brand.count || 0}</span>
										<input
											type="checkbox"
											name={brand.brand || '-'}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedBrandList, setSelectedBrandList)
											}
											checked={selectedBrandList.includes(brand.brand)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_cancel" onClick={() => setSelectedBrandList([])}>
							전체 해제
						</button>
						<button className="btn_ok" onClick={() => setShowBrandFilter(false)}>
							닫기
						</button>
					</div>
				</section>
			</main>
		</>
	);
};

export default FilterBrand;
