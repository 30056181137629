import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';

import CommonHelper from 'helpers/CommonHelper';
import { getSignInApi } from 'apis/signIn';

import FindMemberPopup from 'components/SignIn/components/FindMemberPopup';
import { useAppDispatch, useAppState } from 'context/AppContext';
import { getUserSessionApi } from 'apis/common';

const SignIn = withRouter(({ history }) => {
	const [detailData, setDetailData] = useState('');
	const [id, setId] = useState('');
	const [password, setPassword] = useState('');

	const [isSigninInfoCorrected, setIsSigninInfoCorrected] = useState(true);
	const [isSigninInfoTyped, setIsSigninInfoTyped] = useState(true);
	const [isIdTyped, setIsIdTyped] = useState(true);
	const [isPwTyped, setIsPwTyped] = useState(true);

	const [isOutoSigninChecked, setIsAutoSigninChecked] = useState(false);
	const appDispatch = useAppDispatch();
	const appState = useAppState();
	const handleSignin = () => {
		if (id && password) {
			getSignInApi(id, password).then(({ data: { data, detail, status } }) => {
				if (status === 'SUCCESS') {
					if (data?.token) {
						getUserSessionApi(data.token).then(({ data }) => {
							if (data?.status === 'SUCCESS') {
								appDispatch({ type: 'LOGGED_IN', loggedIn: true, data: data?.data || {} });
							}
						});
					}
					if (isOutoSigninChecked) {
						CommonHelper.setAuthenticateToken(data.token);
						window.location.href = window.location.origin + window.location.search.split('=')[1];
					} else {
						CommonHelper.setSessionAuthenticateToken(data.token);
						window.location.href = window.location.origin + window.location.search.split('=')[1];
					}
				} else if (status === 'FAILED') {
					return (
						setIsSigninInfoCorrected(false),
						setIsSigninInfoTyped(true),
						setIsIdTyped(true),
						setIsPwTyped(true),
						setDetailData(detail)
					);
				} else {
					return alert(detail);
				}
			});
		} else if (!id && password) {
			return (
				setIsIdTyped(false),
				setIsPwTyped(true),
				setIsSigninInfoCorrected(true),
				setIsSigninInfoTyped(true)
			);
		} else if (id && !password) {
			return (
				setIsPwTyped(false),
				setIsIdTyped(true),
				setIsSigninInfoCorrected(true),
				setIsSigninInfoTyped(true)
			);
		} else if (!id && !password) {
			return (
				setIsSigninInfoTyped(false),
				setIsIdTyped(true),
				setIsPwTyped(true),
				setIsSigninInfoCorrected(true)
			);
		}
	};

	const handleInputChange = (e) => {
		switch (e.target.id) {
			case 'id':
				setId(e.target.value);
				break;
			case 'password':
				setPassword(e.target.value);
				break;
		}
	};

	const handleOutoSignin = () => {
		setIsAutoSigninChecked(!isOutoSigninChecked);
	};

	const handleKeyPress = (e) => {
      if(e.key === 'Enter'){
		  handleSignin()
	  }
	}

	return (
		<>
			<div className="container login">
				<section className="inner">
					<h2>로그인</h2>
					<div className="login_input">
						<input
							id="id"
							type="text"
							placeholder="아이디"
							value={id}
							onChange={handleInputChange}
							onKeyPress={handleKeyPress}
						/>
						<input
							id="password"
							type="password"
							placeholder="비밀번호"
							value={password}
							onChange={handleInputChange}
							onKeyPress={handleKeyPress}
						/>
						{isSigninInfoTyped ? '' : <p className="error_txt">로그인정보를 입력해 주세요.</p>}
						{isSigninInfoCorrected ? '' : <p className="error_txt">{detailData}</p>}
						{isIdTyped ? '' : <p className="error_txt">아이디를 입력해 주세요.</p>}
						{isPwTyped ? '' : <p className="error_txt">비밀번호를 입력해 주세요.</p>}
						<button className="btn_login" onClick={handleSignin}>
							로그인
						</button>
						<label className="auto_login">
							<input type="checkbox" onClick={handleOutoSignin} />
							<span>자동로그인</span>
						</label>
					</div>
					<div className="link_wrap">
						<Link to="/signUp">회원가입</Link>
						<FindMemberPopup />
					</div>
				</section>
			</div>
		</>
	);
});

export default SignIn;
