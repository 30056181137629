import React, { useState } from 'react';
import styled from 'styled-components';
import Captcha from 'demos-react-captcha';
import { getFindMemberApi } from 'apis/signIn';

const FindMemberPopup = () => {
	const [isFindMemberModalOpened, setIsFindMemberModalOpened] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [captchaValue, setCaptchaValue] = useState(false);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [errorText, setErrorText] = useState('');

	const pStyle = {
		color: 'red',
		fontSize: '12px',
	};

	const handleEmailInputChange = (e) => {
		let newAddress = e.target.value.replaceAll(' ', '');
		setEmailAddress(newAddress);
	};

	const captchaChange = (value) => {
		setCaptchaValue(value);
	};

	const handleFindMemberConfirm = () => {
		setIsConfirmed(true);
		if (!emailAddress) {
			return setErrorText('이메일을 입력해주세요.');
		} else if (emailAddress && !captchaValue) {
			return setErrorText('자동등록방지 숫자가 일치하지 않습니다.');
		} else if (emailAddress && captchaValue) {
			getFindMemberApi(emailAddress).then(({ data: { status, data, detail } }) => {
				if (status === 'SUCCESS') {
					if (data === true) {
						return (
							setErrorText(''),
							setEmailAddress(''),
							setCaptchaValue(false),
							alert('비밀번호찾기 메일이 입력하신 회원님의 이메일로 발송되었습니다.'),
							setIsFindMemberModalOpened(!isFindMemberModalOpened)
						);
					} else {
						return setErrorText(detail);
					}
				} else {
					return setErrorText(''), setEmailAddress(''), setCaptchaValue(false), alert(detail);
				}
			});
		}
	};

	const handleFindMember = () => {
		setErrorText('');
		setEmailAddress('');
		setCaptchaValue(false);
		setIsFindMemberModalOpened(!isFindMemberModalOpened);
	};

	return (
		<>
			<a style={{ cursor: 'pointer' }} onClick={handleFindMember}>
				정보찾기
			</a>
			{isFindMemberModalOpened ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="popup find_member">
						<h2>회원정보 찾기</h2>
						<p>
							회원가입 시 등록하신 이메일 주소를 입력해 주세요. 해당 이메일로 아이디와 비밀번호
							정보를 보내드립니다.
						</p>
						<input
							type="text"
							placeholder="이메일 주소를 입력해주세요"
							className="find_info_email_input"
							onChange={handleEmailInputChange}
							value={emailAddress}
						/>
						<p>자동등록방지 숫자를 순서대로 입력하세요.</p>
						<div className="auto_regist">
							<CaptchaWrapper>
								<Captcha onChange={captchaChange} placeholder="Enter captcha" />
							</CaptchaWrapper>
						</div>
						{isConfirmed ? <p style={pStyle}>{errorText}</p> : ''}
						<div className="btn_wrap right">
							<button onClick={handleFindMember}>닫기</button>
							<button className="ok" onClick={handleFindMemberConfirm}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default FindMemberPopup;
const CaptchaWrapper = styled.div`
	.rnc {
		max-width: 500px;
	}
`;
