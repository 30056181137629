import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonHelper from 'helpers/CommonHelper';
import { useAppDispatch } from 'context/AppContext';

const LogoutPopup = withRouter(({ history }) => {
	const [isLogoutModalOpened, setIsLogoutModalOpened] = useState(false);
	const appDispatch = useAppDispatch();

	const handleLogoutBtnClick = () => {
		setIsLogoutModalOpened(!isLogoutModalOpened);
	};

	const handleLogoutConfirm = () => {
		CommonHelper.clearAuthenticateToken();
		CommonHelper.clearSessionAuthenticateToken();
		appDispatch({ type: 'LOGGED_OUT' });
		history.push('/hapl');
	};

	const handleLogoutCancel = () => {
		setIsLogoutModalOpened(!isLogoutModalOpened);
	};
	return (
		<>
			<button className="btn_logout" onClick={handleLogoutBtnClick}>
				로그아웃
			</button>
			{isLogoutModalOpened ? (
				<div className="bg_dimmed" style={{ display: 'block' }}>
					<section className="alert">
						<p>로그아웃 하시겠습니까?</p>
						<div className="btn_wrap right">
							<button className="" onClick={handleLogoutCancel}>
								취소
							</button>
							<button className="ok" onClick={handleLogoutConfirm}>
								확인
							</button>
						</div>
					</section>
				</div>
			) : (
				''
			)}
		</>
	);
});

export default LogoutPopup;
