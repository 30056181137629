import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'context/AppContext';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import buyImgWatch2 from 'assets-pc/img/common/buy_img_watch2.png';
import sale2Img from 'assets-pc/img/common/img_sale2.png';
import crossIco from 'assets-pc/img/common/ico_cross.svg';
import resetIco from 'assets-pc/img/common/ico_reset.svg';
import searchWatchIco from 'assets-pc/img/common/ico_search_watch.svg';
import searchIco from 'assets-pc/img/common/ico_search2.svg';
import polygonIcoUp from 'assets-pc/img/common/ico_polygon2.svg';
import { handlePremiumNum } from 'common/common';
import {
	getBrandListApi,
	getSellingProductsApi,
	getRecentlyProductsApi,
	searchModelListApi,
	getStoreListApi,
	getFilterLisetApi,
	getSiteInfoApi,
} from 'apis/buy';
import {
	PROPERTY_DIRECTION_MENU,
	FILTER_LIST_FIRST,
	FILTER_LIST_SECOND,
	INIT_FILTER_BODY,
	NORMAL_FILTER_LEFT,
	BRAND_FILTER_LEFT,
	MODEL_FILTER_LEFT,
	FILTER_WIDTH,
	CASE_MATERIAL_LIST,
	DIAL_COLOR_LIST,
	DIAL_TYPE_LIST,
} from 'common/constants';
import CommonHelper from 'helpers/CommonHelper';
import CommonPagination from '../../CommonPagination';
import useDebounce from 'utils/useDebounce';
import FindWatch from './FindWatch';

// 가격 높은 순 : property=price&direction=desc
// 가격 낮은 순 : property=price&direction=asc
// 최신 등록 순 : property=createdAt&direction=desc
// 인기순 : property=hitCount&direction=desc

const BuyFilterProduct = () => {
	const [checkUrl, setCheckUrl] = useState(false);
	const [checkStorage, setCheckStorage] = useState(false);
	////찾아줘 팝업 유무, 정보state
	const [showFindWatchPopup, setShowFindWatchPopup] = useState(false);
	const [showLoginNeedPopup, setShowLoginNeedPopup] = useState(false);
	const [showAlertComplete, setShowAlertComplete] = useState(false);
	// const [filterBodyList, setFilterBodyList] = useState(INIT_FILTER_BODY);
	///////
	const { user: userData } = useAppState();
	const [filterList, setFilterList] = useState([]); //전체 필터 리스트
	const [filterFirst, setFilterFirst] = useState([]); //상세필터 전 6개 (favorite true가 앞으로)
	const [filterSecond, setFilterSecond] = useState([]); // 상세필터 후 7개
	const history = useHistory();
	/////////////필터준비
	const [showMoreFilterList, setShowMoreFilterList] = useState(false); //상세필터 열기
	const [selectedFilterForDetail, setSelectedFilterForDetail] = useState(null); //필터 상세 보여줄 하나 선택
	/////필터-브랜드
	const [totalBrandList, setTotalBrandList] = useState([]); // api 받아온 전체 브랜드 리스트
	const [selectedBrandList, setSelectedBrandList] = useState([]); //선택된 브랜드 리스트
	/////판매중인 상품 리스트
	const [currentSellingProducts, setCurrentSellingProducts] = useState([]); // 판매중인 상품 리스트
	const [currentSellingInfo, setCurrentSellingInfo] = useState({}); //판매중인 상품 리스트, 페이징이 포함된 상위 정보state
	///////필터-모델
	const [searchModelInput, setSearchModelInput] = useState('');
	const [modelResultBox, setModelResultBox] = useState([]);
	const [selectedModelFilter, setSelectedModelFilter] = useState([]);
	const debouncedSearchTerm = useDebounce(searchModelInput, 500);
	useEffect(() => {
		searchModelList();
	}, [debouncedSearchTerm]);
	//////레퍼런스
	const [searchReference, setSearchReference] = useState('');
	////케이스 사이즈
	const [selectedMinCaseDiameters, setSelectedMinCaseDiaMeters] = useState('');
	const [selectedMaxCaseDiameters, setSelectedMaxCaseDiameters] = useState('');
	//////구동방식
	const [selectedMovement, setSelectedMovement] = useState([]);
	/////////구매년도
	const [producedYearMinInput, setProducedYearMinInput] = useState('');
	const [producedYearMaxInput, setProducedYearMaxInput] = useState('');
	/////////구성품
	const [selectedSupplements, setSelectedSupplements] = useState([]);
	///////성별
	const [selectedGender, setSelectedGender] = useState([]);
	///////케이스 소재
	const [selectedCaseMaterial, setSelectedCaseMaterial] = useState([]);
	//////다이얼 색상
	const [selectedDialColor, setSelectedDialColor] = useState([]);
	///////다이얼 타입
	const [selectedDialType, setSelectedDialType] = useState([]);
	////////
	const [rangeValue, setRangeValue] = useState(0);
	const [conditionStart, setConditionStart] = useState('');
	const [conditionEnd, setConditionEnd] = useState('');
	////////////////상품 리스트 정렬방식
	const [propertyDirection, setPropertyDirection] = useState(PROPERTY_DIRECTION_MENU[0]);
	/////
	const [page, setPage] = useState(0);
	///////보유지점에 보여줄 지점 리스트
	const [currentStoreList, setCurrentStoreList] = useState([]); //보여주기 위해 store name 모음
	/////필터 선택된 지점 리스트
	const [selectedStoreList, setSelectedStoreList] = useState([]);
	const [selectedStoreIdList, setSelectedStoreIdList] = useState([]); // 데이터로 보낼 store ids

	//////////선택된 필터 리스트
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [recentlySeeProducts, setRecentlyProducts] = useState([]);
	const [showRecentlyPopup, setShowRecentlyPopup] = useState(false);
	///////
	const [siteInfoBox, setSiteInfoBox] = useState();
	const [statusBox, setStatusBox] = useState(['SALE_REGISTERED', 'SOLD']);

	useEffect(() => {
    	window.scrollTo({ top: 0, behavior: 'smooth' });
	},[page])

	const handleStatus = (status) => {
		const isExistStatus = statusBox.some((oneStatus) => oneStatus === status);
		let result = [...statusBox];
		if (isExistStatus) {
			result = result.filter((oneStatus) => oneStatus !== status);
		} else {
			result.push(status);
		}
		setStatusBox(result);
	};

	const showWhichPopup = () => {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		if (authToken) {
			setShowFindWatchPopup(true);
		} else {
			setShowLoginNeedPopup(true);
		}
	};
	useEffect(() => {
		checkUrl && getSellingProducts();
		checkStorage && history.action !== 'POP' ? setPage(0) : setPage(page);
	}, [
		propertyDirection,
		selectedBrandList,
		selectedModelFilter,
		searchReference,
		selectedMinCaseDiameters,
		selectedMaxCaseDiameters,
		selectedMovement,
		selectedSupplements,
		selectedGender,
		selectedCaseMaterial,
		selectedDialColor,
		selectedDialType,
		selectedStoreIdList,
		producedYearMaxInput,
		producedYearMinInput,
		conditionStart,
		conditionEnd,
		checkUrl,
		statusBox,
	]);

	useEffect(() => {
		checkUrl && getSellingProductsOtherPage();
	}, [page, window.location.pathname, checkUrl]);

	useEffect(() => {
		checkUrl && !!window.location.pathname.split('/')[2] && getSellingProducts();
	}, [checkUrl]);

	useEffect(() => {
		getBrandList();
		getStoreList();
		getSiteInfo();
		handleIfFromRankRolling();
		// searchOptions();
	}, []);

	useEffect(() => {
		history.action === 'POP' && searchOptions();
	}, [history.action]);

	useEffect(() => {
		userData && getFilterList();
	}, [userData]);

	const handleMoreFilter = () => {
		setShowMoreFilterList(!showMoreFilterList);
		setSelectedFilterForDetail(null);
	};

	const handleStandard = (e) => {
		let findOne = PROPERTY_DIRECTION_MENU.filter((el) => el.name === e.target.value);
		setPropertyDirection(findOne[0]);
	};

	const goToDetail = (id) => {
		const filterOptions = {
			propertyDirection,
			page,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
		};
		sessionStorage.setItem('buyFilterCache', JSON.stringify(filterOptions));
		history.push(`/buy/${id}`);
	};

	const searchOptions = () => {
		let result = JSON.parse(sessionStorage.getItem('buyFilterCache'));

		if (result) {
			const {
				propertyDirection,
				page,
				selectedBrandList,
				selectedModelFilter,
				searchReference,
				selectedMinCaseDiameters,
				selectedMaxCaseDiameters,
				selectedMovement,
				selectedSupplements,
				selectedGender,
				selectedCaseMaterial,
				selectedDialColor,
				selectedDialType,
				selectedStoreIdList,
				producedYearMinInput,
				producedYearMaxInput,
				conditionStart,
				conditionEnd,
			} = result;

			sessionStorage.removeItem('buyFilterCache');
			setPropertyDirection(propertyDirection);
			setPage(page);
			setSelectedBrandList(selectedBrandList);
			setSelectedModelFilter(selectedModelFilter);
			setSearchReference(searchReference);
			setSelectedMinCaseDiaMeters(selectedMinCaseDiameters);
			setSelectedMaxCaseDiameters(selectedMaxCaseDiameters);
			setSelectedMovement(selectedMovement);
			setSelectedSupplements(selectedSupplements);
			setSelectedGender(selectedGender);
			setSelectedCaseMaterial(selectedCaseMaterial);
			setSelectedDialColor(selectedDialColor);
			setSelectedDialType(selectedDialType);
			setSelectedStoreIdList(selectedStoreIdList);
			setProducedYearMinInput(producedYearMinInput);
			setProducedYearMaxInput(producedYearMaxInput);
			setConditionStart(conditionStart);
			setConditionEnd(conditionEnd);
		}

		setCheckStorage(true);
	};
	const buyFiltersNum = localStorage.getItem('buyFilters');

	useEffect(() => {
		buyFiltersNum && getRecentlyProducts();
	}, [buyFiltersNum]);

	//////////////페이지네이션/////////////////

	/**
	 * 최근 본 상품 fixed 팝업 열었다 닫았다
	 */
	const handleShowRecentlyPopup = () => {
		setShowRecentlyPopup(!showRecentlyPopup);
	};

	/**
	 * 최근 본 상품 다 지우기
	 */
	const clearAllRecentlyProduct = () => {
		localStorage.removeItem('buyFilters');
		setRecentlyProducts([]);
	};

	const deleteOneRecentProduct = (productId, idx) => {
		//////localStorage 변화
		const buyFiltersNum = localStorage.getItem('buyFilters');
		let splitBox = buyFiltersNum.split(','); //배열로 만들어주기
		let newBox = splitBox.filter((el) => el !== productId.toString()); // string string 비교를 위해 toString()
		let stringBox = newBox.toString(); //배열을 문자열로
		localStorage.setItem('buyFilters', stringBox);
		///////state 변화
		let copyRecentlyProducts = [...recentlySeeProducts];
		copyRecentlyProducts = copyRecentlyProducts.filter((el) => el.id !== productId);
		setRecentlyProducts(copyRecentlyProducts);
	};

	/**
	 * 체크박스 필터 공통 핸들링
	 * @param {*} e
	 * @param {*} state
	 * @param {*} setState
	 */
	const handleCheckBoxCommon = (e, state, setState) => {
		const { name, checked } = e.target;
		let copyState = [...state];

		if (checked) {
			copyState.push(name);
		} else {
			copyState = copyState.filter((el) => el !== name);
		}
		setState(copyState);
	};

	const handleDeleteCheckboxCommon = (oneFilter, state, setState) => {
		let copyState = [...state];
		copyState = copyState.filter((el) => el !== oneFilter);
		setState(copyState);
	};

	const combineAllFilterList = (filters) => {
		// if (filters.length > 0) {
		// 	let copySelctedFilters = [...selectedFilters]
		// 	let newAllFilterList = copySelctedFilters.concat(filters);
		// 	setSelectedFilters(newAllFilterList);
		// }
		setSelectedFilterForDetail(null);
	};

	const allReset = () => {
		setSelectedBrandList([]);
		setSelectedModelFilter([]);
		setSearchReference('');
		setSelectedMinCaseDiaMeters('');
		setSelectedMaxCaseDiameters('');
		setSelectedMovement([]);
		setSelectedSupplements([]);
		setSelectedGender([]);
		setSelectedCaseMaterial([]);
		setSelectedDialColor([]);
		setSelectedDialType([]);
		setSelectedStoreList([]);
		setSelectedStoreIdList([]);
		// setRangeValue(0);
		setConditionStart('');
		setConditionEnd('');
		setProducedYearMinInput('');
		setProducedYearMaxInput('');
	};

	const handleCheckBoxStore = (e) => {
		const { name, checked, id } = e.target;
		let copyStoreList = [...selectedStoreList];
		let copyStoreIdList = [...selectedStoreIdList];
		if (checked) {
			copyStoreList.push(name);
			copyStoreIdList.push(id);
		} else {
			copyStoreList = copyStoreList.filter((el) => el !== name);
			copyStoreIdList = copyStoreIdList.filter((el) => el !== id);
		}
		setSelectedStoreList(copyStoreList);
		setSelectedStoreIdList(copyStoreIdList);
	};
	const deleteSelectedOneStore = (storeName) => {
		let findStoreInfo = currentStoreList.filter((store) => store.name === storeName);
		let copyStoreList = [...selectedStoreList];
		let copyStoreIdList = [...selectedStoreIdList];
		copyStoreList = copyStoreList.filter((el) => el !== storeName);
		copyStoreIdList = copyStoreIdList.filter((el) => Number(el) !== findStoreInfo[0].id);
		setSelectedStoreList(copyStoreList);
		setSelectedStoreIdList(copyStoreIdList);
	};

	const deleteAllCaseDiameters = () => {
		setSelectedMinCaseDiaMeters('');
		setSelectedMaxCaseDiameters('');
	};

	const handleCaseDiameterInput = (e) => {
		const { value, name } = e.target;
		if (name === 'minimum') {
			if (value.length < 3) {
				setSelectedMinCaseDiaMeters(value);
			}
		} else if (name === 'maximum') {
			if (value.length < 3) {
				setSelectedMaxCaseDiameters(value);
			}
		}
	};

	const handleProducedYearInput = (e) => {
		const { value, name } = e.target;
		if (name === 'minimum') {
			if (value.length < 6) {
				setProducedYearMinInput(value);
			}
		} else if (name === 'maximum') {
			if (value.length < 6) {
				setProducedYearMaxInput(value);
			}
		}
	};

	const goToLoginPage = () => {
		setShowLoginNeedPopup(false);
		history.push('/signin?redirectUrl=/buy');
	};

	const deleteAllProducedYear = () => {
		setProducedYearMinInput('');
		setProducedYearMaxInput('');
	};

	const deleteAllCondition = () => {
		setConditionStart('');
		setConditionEnd('');
	};

	const handleConditionInput = (e) => {
		const { value, name } = e.target;
		let deleteAll = value.replaceAll(/[^0-9]/g, '');
		if (name === 'minimum') {
			if (deleteAll >= 0 && deleteAll <= 10) {
				setConditionStart(deleteAll);
			}
		} else if (name === 'maximum') {
			if (deleteAll >= 0 && deleteAll <= 10) {
				setConditionEnd(deleteAll);
			}
		}
	};

	const handleIfFromRankRolling = () => {
		if (window.location.pathname?.split('/')[2]) {
			const decodeBrand =
				window.location.pathname?.split('/')[3]?.length > 0
					? decodeURIComponent(window.location.pathname.split('/')[3])
					: '';
			const brandArray =
				window.location.pathname.split('/')[3]?.length > 0
					? [...selectedBrandList, decodeBrand]
					: selectedBrandList;
			setSelectedBrandList(brandArray);
		}
		setCheckUrl(true);
	};

	return (
		<>
			<div className="filter_wrap">
				<div className={showMoreFilterList ? 'select_wrap filter_open' : 'select_wrap'}>
					{/* {FILTER_LIST_FIRST.map((filter, idx) => (
						<button
							key={idx}
							className={selectedFilterForDetail === filter.en ? 'on' : ''}
							rel={filter.en}
							onClick={() => setSelectedFilterForDetail(filter.en)}
						>
							{filter.ko || '-'}
						</button>
					))}
					<button className="filter" onClick={handleMoreFilter}>
						상세 필터
					</button>
					{FILTER_LIST_SECOND.map((filter, idx) => (
						<button
							key={idx}
							className={selectedFilterForDetail === filter.en ? 'on' : ''}
							rel={filter.en}
							onClick={() => setSelectedFilterForDetail(filter.en)}
						>
							{filter.ko || '-'}
						</button>
					))} */}
					{filterFirst.map((filter, idx) => (
						<button
							key={idx}
							className={selectedFilterForDetail === filter.key ? 'on' : ''}
							rel={filter.key}
							onClick={() => setSelectedFilterForDetail(filter.key)}
						>
							{filter.name || '-'}
						</button>
					))}
					<button className="filter" onClick={handleMoreFilter}>
						상세 필터
					</button>
					{filterSecond.map((filter, idx) => (
						<button
							key={idx}
							className={selectedFilterForDetail === filter.key ? 'on' : ''}
							rel={filter.en}
							onClick={() => setSelectedFilterForDetail(filter.key)}
						>
							{filter.name || '-'}
						</button>
					))}
				</div>
			</div>
			<div className="filter_box">
				<ul className="filter_btn_list filter_btn_flow">
					{selectedBrandList.map((brand, idx) => (
						<li key={idx}>
							<span>{brand || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(brand, selectedBrandList, setSelectedBrandList)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedModelFilter.map((model, idx) => (
						<li key={idx}>
							<span>{model || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(model, selectedModelFilter, setSelectedModelFilter)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{searchReference && (
						<li>
							<span>{searchReference || '-'}</span>
							<button onClick={() => setSearchReference('')}>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					)}
					{selectedMinCaseDiameters || selectedMaxCaseDiameters ? (
						<li>
							<span>
								{selectedMinCaseDiameters}~{selectedMaxCaseDiameters}
							</span>
							<button onClick={deleteAllCaseDiameters}>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					) : (
						''
					)}
					{selectedMovement.map((movement, idx) => (
						<li key={idx}>
							<span>{movement || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(movement, selectedMovement, setSelectedMovement)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{producedYearMinInput || producedYearMaxInput ? (
						<li>
							<span>
								{producedYearMinInput}~{producedYearMaxInput}
							</span>
							<button onClick={deleteAllProducedYear}>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					) : (
						''
					)}
					{selectedSupplements.map((supplement, idx) => (
						<li key={idx}>
							<span>{supplement || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(
										supplement,
										selectedSupplements,
										setSelectedSupplements,
									)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedGender.map((gender, idx) => (
						<li key={idx}>
							<span>{gender || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(gender, selectedGender, setSelectedGender)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedCaseMaterial.map((caseMaterial, idx) => (
						<li key={idx}>
							<span>{caseMaterial || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(
										caseMaterial,
										selectedCaseMaterial,
										setSelectedCaseMaterial,
									)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedDialColor.map((dialColor, idx) => (
						<li key={idx}>
							<span>{dialColor || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(dialColor, selectedDialColor, setSelectedDialColor)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedDialType.map((dialType, idx) => (
						<li key={idx}>
							<span>{dialType || '-'}</span>
							<button
								onClick={() =>
									handleDeleteCheckboxCommon(dialType, selectedDialType, setSelectedDialType)
								}
							>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{selectedStoreList.map((store, idx) => (
						<li key={idx}>
							<span>{store || '-'}</span>
							<button onClick={() => deleteSelectedOneStore(store)}>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					))}
					{conditionStart || conditionEnd ? (
						<li>
							<span>
								{conditionStart}~{conditionEnd}
							</span>
							<button onClick={deleteAllCondition}>
								<img src={crossIco} alt="닫기" />
							</button>
						</li>
					) : (
						''
					)}
				</ul>
				<button className="reset_btn" onClick={allReset}>
					초기화 <img src={resetIco} alt="" />
				</button>
			</div>
			<div className="sort_wrap">
				<span>
					전체 상품{' '}
					<b>
						{statusBox.length === 0
							? '0'
							: currentSellingInfo?.total
							? Number(currentSellingInfo.total).toLocaleString()
							: '0'}
						개
					</b>
				</span>
				<div className="sort_right">
					<button
						className={statusBox.some((oneStatus) => oneStatus === 'SALE_REGISTERED') ? '' : 'off'}
						onClick={() => handleStatus('SALE_REGISTERED')}
					>
						판매중
					</button>
					<button
						className={statusBox.some((oneStatus) => oneStatus === 'SOLD') ? '' : 'off'}
						onClick={() => handleStatus('SOLD')}
					>
						판매완료
					</button>
					<select name="" id="" value={propertyDirection.name || ''} onChange={handleStandard}>
						{PROPERTY_DIRECTION_MENU.map((standard, idx) => (
							<option key={idx} value={standard.name}>
								{standard.name}
							</option>
						))}
					</select>
				</div>
			</div>
			{selectedFilterForDetail === 'BRAND' && (
				<BrandFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
					FILTER_WIDTH={FILTER_WIDTH}
				>
					{/* <div className="search_box">
						<strong>최근 검색 브랜드</strong>
						<div>
							<span>검색 결과가 없습니다.</span>
						</div>
					</div> */}
					<div className="all_brand">
						<strong>전체 브랜드</strong>
						<div className="scroll_box">
							<ul>
								{totalBrandList.map((brand, idx) => (
									<li key={idx}>
										<label>
											<input
												type="checkbox"
												name={brand.brand || '-'}
												onChange={(e) =>
													handleCheckBoxCommon(e, selectedBrandList, setSelectedBrandList)
												}
												checked={selectedBrandList.includes(brand.brand)}
											/>
											<span>&nbsp; {brand.brand || '-'}</span>
										</label>
									</li>
								))}
							</ul>
						</div>
						<span>선택 브랜드 ({selectedBrandList.length}건)</span>
						{/* <ul>
							{selectedBrandList.map((brand, idx) => (
								<li key={idx}>
									<label>{brand}</label>
								</li>
							))}
						</ul> */}
					</div>
					<div className="btn_wrap right">
						<button className="btn_reset" onClick={() => setSelectedBrandList([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedBrandList)}>
							닫기
						</button>
					</div>
				</BrandFilterDetail>
			)}
			{selectedFilterForDetail === 'MODEL' && (
				<ModelFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
				>
					{/* <div className="search_box">
						<strong>최근 검색 모델명</strong>
						<div>
							<span>검색 결과가 없습니다.</span>
						</div>
					</div> */}
					<div className="all_model">
						<strong>전체 모델</strong>
						<div>
							<div className="search_wrap">
								<input
									type="text"
									placeholder="목록 검색"
									onChange={(e) => setSearchModelInput(e.target.value)}
								/>
								<button>
									<img src={searchIco} alt="검색돋보기" />
								</button>
							</div>
							<ul className="scroll_box">
								<li>
									<ul>
										{modelResultBox.map((model, idx) => (
											<li key={idx}>
												<label>
													<input
														type="checkbox"
														name={model.model || '-'}
														onChange={(e) =>
															handleCheckBoxCommon(e, selectedModelFilter, setSelectedModelFilter)
														}
														checked={selectedModelFilter.includes(model.model)}
													/>
													<span>{model.model || '-'}</span>
												</label>
											</li>
										))}
									</ul>
								</li>
							</ul>
						</div>
						<span>선택 모델 ({selectedModelFilter.length}건)</span>
					</div>
					<div className="btn_wrap right">
						<button className="btn_reset" onClick={() => setSelectedModelFilter([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedModelFilter)}>
							닫기
						</button>
					</div>
				</ModelFilterDetail>
			)}
			{selectedFilterForDetail === 'REFERENCE' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>레퍼런스</strong>
						<input
							type="text"
							placeholder="레퍼런스를 입력해주세요"
							value={searchReference}
							onChange={(e) => setSearchReference(e.target.value)}
						/>
					</div>
					<div className="btn_wrap right">
						<button className="btn_reset" onClick={() => setSearchReference('')}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(searchReference)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'CASE_DIAMETER' && (
				<NormalFilterDetail
					className="filter_detail_wrap on case_dia_filter_len"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>케이스 사이즈 범위</strong>
						<div className="filter_input_flex_box">
							<div className="input_flex">
								<input
									type="text"
									value={selectedMinCaseDiameters}
									onChange={handleCaseDiameterInput}
									name="minimum"
								/>
								mm~
							</div>
							<div className="input_flex">
								<input
									type="text"
									value={selectedMaxCaseDiameters}
									onChange={handleCaseDiameterInput}
									name="maximum"
								/>
								mm
							</div>
						</div>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={deleteAllCaseDiameters}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList()}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'MOVEMENT' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>구동방식</strong>
						<ul>
							<li>
								<label>
									<input
										type="checkbox"
										name="오토매틱"
										checked={selectedMovement.includes('오토매틱')}
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
									/>
									<span>오토매틱</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="수동"
										checked={selectedMovement.includes('수동')}
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
									/>
									<span>수동</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="쿼츠"
										checked={selectedMovement.includes('쿼츠')}
										onChange={(e) => handleCheckBoxCommon(e, selectedMovement, setSelectedMovement)}
									/>
									<span>쿼츠</span>
								</label>
							</li>
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedMovement([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedMovement)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'PRODUCED_YEAR' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					{/* <div className="default_box">
						<strong>구매년도</strong>
						<input
							type="text"
							placeholder="구매년도를 입력해주세요"
							value={producedYearInput}
							onChange={(e) => (e.target.value)}
						/>
					</div> */}
					<div className="default_box">
						<strong>구매년도</strong>
						<div className="filter_input_flex_box">
							<div className="input_flex">
								<input
									type="text"
									value={producedYearMinInput}
									onChange={handleProducedYearInput}
									name="minimum"
								/>
								~
							</div>
							<div className="input_flex">
								<input
									type="text"
									value={producedYearMaxInput}
									onChange={handleProducedYearInput}
									name="maximum"
								/>
							</div>
						</div>
					</div>
					<div className="btn_wrap right">
						<button className="btn_reset" onClick={deleteAllProducedYear}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList()}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'SUPPLEMENTS' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>구성품</strong>
						<ul>
							<li>
								<label>
									<input
										type="checkbox"
										name="보증서"
										checked={selectedSupplements.includes('보증서')}
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
									/>
									<span>보증서</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="박스"
										checked={selectedSupplements.includes('박스')}
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
									/>
									<span>박스</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="북렛"
										checked={selectedSupplements.includes('북렛')}
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
									/>
									<span>북렛</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="MEDAL"
										checked={selectedSupplements.includes('MEDAL')}
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
									/>
									<span>Medal</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="하이시간보증서"
										checked={selectedSupplements.includes('하이시간보증서')}
										onChange={(e) =>
											handleCheckBoxCommon(e, selectedSupplements, setSelectedSupplements)
										}
									/>
									<span>하이시간보증서</span>
								</label>
							</li>
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedSupplements([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedSupplements)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'GENDER' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>성별</strong>
						<ul>
							<li>
								<label>
									<input
										type="checkbox"
										name="남성"
										checked={selectedGender.includes('남성')}
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
									/>
									<span>남성</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="여성"
										checked={selectedGender.includes('여성')}
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
									/>
									<span>여성</span>
								</label>
							</li>
							<li>
								<label>
									<input
										type="checkbox"
										name="남녀공용"
										checked={selectedGender.includes('남녀공용')}
										onChange={(e) => handleCheckBoxCommon(e, selectedGender, setSelectedGender)}
									/>
									<span>남녀공용</span>
								</label>
							</li>
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedGender([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedGender)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'CASE_MATERIAL' && (
				<NormalFilterDetail
					className="filter_detail_wrap on case_dia_filter_len"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>소재</strong>
						<ul>
							{siteInfoBox?.caseMaterials?.map((material, idx) => (
								<li key={idx}>
									<label>
										<input
											type="checkbox"
											name={material}
											checked={selectedCaseMaterial.includes(material)}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedCaseMaterial, setSelectedCaseMaterial)
											}
										/>
										<span>{material}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedCaseMaterial([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedCaseMaterial)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'DIAL_COLOR' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>다이얼 색상</strong>
						<ul className="filter_scroll_height">
							{siteInfoBox?.dialColors?.map((color, idx) => (
								<li key={idx}>
									<label>
										<input
											type="checkbox"
											name={color}
											checked={selectedDialColor.includes(color)}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedDialColor, setSelectedDialColor)
											}
										/>
										<span>{color}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedDialColor([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedDialColor)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'DIAL_TYPE' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>다이얼 타입</strong>
						<ul>
							{siteInfoBox?.dialTypes?.map((type, idx) => (
								<li key={idx}>
									<label>
										<input
											type="checkbox"
											name={type}
											checked={selectedDialType.includes(type)}
											onChange={(e) =>
												handleCheckBoxCommon(e, selectedDialType, setSelectedDialType)
											}
										/>
										<span>{type}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedDialType([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedDialType)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'STORE' && (
				<NormalFilterDetail
					className="filter_detail_wrap on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>보유지점</strong>
						<ul>
							{currentStoreList.map((store, idx) => (
								<li key={idx}>
									<label>
										<input
											type="checkbox"
											name={store.name}
											id={store.id}
											checked={selectedStoreList.includes(store.name)}
											onChange={handleCheckBoxStore}
										/>
										<span>{store.name}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="btn_wrap">
						<button className="btn_reset" onClick={() => setSelectedStoreList([])}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList(selectedStoreList)}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{selectedFilterForDetail === 'CONDITION' && (
				<NormalFilterDetail
					className="filter_detail_wrap condition on"
					filterFirst={filterFirst}
					filterSecond={filterSecond}
					selectedFilterForDetail={selectedFilterForDetail}
					BRAND_FILTER_LEFT={BRAND_FILTER_LEFT}
				>
					<div className="default_box">
						<strong>상품컨디션 (0~10)</strong>
						<div className="filter_input_flex_box">
							<div className="input_flex">
								<input
									type="text"
									value={conditionStart}
									onChange={handleConditionInput}
									name="minimum"
								/>
								~
							</div>
							<div className="input_flex">
								<input
									type="text"
									value={conditionEnd}
									onChange={handleConditionInput}
									name="maximum"
								/>
							</div>
						</div>
					</div>
					{/* <div className="default_box">
						<strong>상품컨디션</strong>
						<div id="slider-range-min" className="">
							<span className="start">0점</span>
							<span className="end">10점</span>
							<ConditionSliderContainer>
								<ConditionSliderInput
									className="buy_filter_condition"
									type="range"
									value={rangeValue}
									onChange={(e) => setRangeValue(e.target.value)}
									min="0"
									max="10"
									step="1"
								/>
								<NumberDivForCondition style={{ left: `${rangeValue}0%`, position: 'absolute' }}>
									<span id="custom-handle">{rangeValue}점</span>
								</NumberDivForCondition>
							</ConditionSliderContainer>
						</div>
					</div> */}
					<div className="btn_wrap right">
						<button className="btn_reset" onClick={deleteAllCondition}>
							초기화
						</button>
						<button className="btn_ok" onClick={() => combineAllFilterList()}>
							닫기
						</button>
					</div>
				</NormalFilterDetail>
			)}
			{statusBox.length === 0 || currentSellingProducts.length === 0 ? (
				<div className="pro_list_empty">
					<img src={searchWatchIco} alt="" />
					<p className="t1">
						{/* 조건에 맞는 시계를 찾을 수 없습니다. <br />
						조건을 바꿔보세요. */}
						조건에 맞는 시계를 찾고 있습니다.
						<br />
						찾는 시계가 없다면 조건을 바꿔보세요.
					</p>
					<p className="t2">
						혹시 찾으시는 시계를 찾을 수 없다면 <br />
						<b>‘시계 찾아줘’</b>를 요청해보세요.
					</p>
					<div className="btn_watch" onClick={showWhichPopup}>
						시계 찾아줘 바로가기
					</div>
				</div>
			) : (
				<>
					<ul className="pro_list">
						{currentSellingProducts.map((product, idx) => (
							<li
								key={product.id}
								onClick={() => goToDetail(product.id)}
								style={
									product.id === currentSellingProducts[currentSellingProducts.length - 1].id
										? { borderRight: '1px solid #edf0f3' }
										: {}
								}
							>
								<div className="one_product_img_wrapper">
									<img
										src={
											product?.attachedFiles.length > 0 ? product.attachedFiles[0] : buyImgWatch2
										}
										alt="시계사진"
									/>
								</div>
								<p className="watch_name">
									{product?.watch?.brand || '-'} {product?.watch?.model || '-'}
								</p>
								<p className="price">
									₩ {product.price ? Number(product.price).toLocaleString() : '-'}
								</p>
								<p
									className={
										product?.standardWatch?.hpi > 0
											? 'fluctuations up'
											: product?.standardWatch?.hpi < 0
											? 'fluctuations down'
											: product?.standardWatch?.hpi === 0
											? 'fluctuations'
											: ''
									}
								>
									<span>{product?.standardWatch?.hpi || '-'}</span>
								</p>
								<p className="comment">{product.description || '-'}</p>
								<ul>
									<li>
										<span>구매년도</span>
										<b>{product?.watch?.producedYear || '-'}</b>
									</li>
									<li>
										<span>컨디션</span>
										<b>{product?.watch?.condition || '-'}</b>
									</li>
									<li>
										<span>상품넘버</span>
										<b>{product?.watch?.serialNo || '-'}</b>
									</li>
								</ul>
								<p className="option">
									{product?.watch?.supplements?.includes('GUARANTEE') && (
										<img src={warrantyIco} alt="보증서" title="보증서가 있는 상품입니다." />
									)}
									{product?.watch?.supplements?.includes('BOX') && (
										<img src={boxIco} alt="정품박스" title="정품박스가 있는 상품입니다." />
									)}
								</p>
								{/* <p className="sale">30% Premium</p> */}
								{product?.state === 'SOLD' ? (
									<>
										<p className="sale" style={{ background: 'black', margin: '5px' }}>
											SOLD OUT
										</p>
									</>
								) : (
									<>
										{!!product?.price &&
											!!product?.standardWatch?.officialPrice &&
											handlePremiumNum(product?.price, product?.standardWatch?.officialPrice) && (
												<p className="sale">
													{handlePremiumNum(product?.price, product?.standardWatch?.officialPrice)}%
													Premium
												</p>
											)}
									</>
								)}
							</li>
						))}
					</ul>
					<CommonPagination
						limit="16"
						commonData={currentSellingInfo}
						page={page}
						setPage={setPage}
					/>
				</>
			)}
			<div className="recently_view auction-top-text-big">
				<div
					style={{ overflow: 'scroll' }}
					className={showRecentlyPopup ? 'recently_viewed_products on' : 'recently_viewed_products'}
				>
					<div className="top">
						<p className="tit">최근 본 상품 ({recentlySeeProducts.length || '0'})</p>
						<button className="btn_all_delete" onClick={clearAllRecentlyProduct}>
							전체 삭제
						</button>
					</div>
					<ul>
						{recentlySeeProducts.length > 0 &&
							recentlySeeProducts.map((recentProduct, idx) => (
								<li key={recentProduct.id || idx}>
									<div onClick={() => goToDetail(recentProduct.id)}>
										<p className="brand">
											{recentProduct?.watch?.brand || '-'} {recentProduct?.watch?.model || '-'}
										</p>
										<p className="price">
											₩ {recentProduct?.price ? Number(recentProduct.price).toLocaleString() : '-'}
										</p>
										<p
											className={
												recentProduct?.standardWatch?.hpi > 0
													? 'fluctuations up'
													: recentProduct?.standardWatch?.hpi < 0
													? 'fluctuations down'
													: recentProduct?.standardWatch?.hpi === 0
													? 'fluctuations'
													: ''
											}
										>
											<span>{recentProduct?.standardWatch?.hpi || '-'}</span>
										</p>
										<div className="option">
											{recentProduct?.watch?.supplements?.includes('GUARANTEE') && (
												<img src={warrantyIco} alt="보증서" title="보증서가 있는 상품입니다." />
											)}
											{recentProduct?.watch?.supplements?.includes('BOX') && (
												<img src={boxIco} alt="정품박스" title="정품박스가 있는 상품입니다." />
											)}
										</div>
									</div>
									<div className="img_box">
										<img
											src={
												recentProduct?.attachedFiles?.length > 0
													? recentProduct.attachedFiles[0]
													: sale2Img
											}
											className="recent_watch_img"
											alt="시계사진"
										/>
										{/* <p className="sale">30% Premium?</p> */}
										{!!recentProduct?.price && !!recentProduct?.standardWatch?.officialPrice && (
											<p className="sale">
												{handlePremiumNum(
													recentProduct?.price,
													recentProduct?.standardWatch?.officialPrice,
												)}
												% Premium
											</p>
										)}
									</div>
									<button
										className="btn_del"
										onClick={() => deleteOneRecentProduct(recentProduct.id, idx)}
									></button>
								</li>
							))}
					</ul>
					{recentlySeeProducts.length === 0 && (
						<p className="empty">
							최근 본 상품이 <br />
							업습니다.
						</p>
					)}
					{/* <div className="paging_wrap">
						<button className="prev"></button>
						<button className="next"></button>
					</div> */}
				</div>
				<button onClick={handleShowRecentlyPopup}>
					최근 본 상품 ({recentlySeeProducts.length || '0'})
				</button>
			</div>
			{showFindWatchPopup && (
				<FindWatch
					setShowFindWatchPopup={setShowFindWatchPopup}
					setShowAlertComplete={setShowAlertComplete}
				/>
			)}
			{showLoginNeedPopup && (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p>
							로그인이 필요한 서비스입니다. <br />
							회원이시라면 로그인 후 이용해주세요.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={goToLoginPage}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
			{showAlertComplete && (
				<div className="bg_dimmed auction-detail-block">
					<section className="alert">
						<p>
							신청이 완료되었습니다. <br />
							최대한 빠른 시일내에 연락 드리도록 하겠습니다.
						</p>
						<div className="btn_wrap right">
							<button className="ok" onClick={() => setShowAlertComplete(false)}>
								확인
							</button>
						</div>
					</section>
				</div>
			)}
		</>
	);
	function getBrandList() {
		getBrandListApi().then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setTotalBrandList(data);
			} else {
				alert(detail);
			}
		});
	}

		function getSellingProductsOtherPage() {
			const authToken = CommonHelper.getAuthToken()
				? CommonHelper.getAuthToken()
				: CommonHelper.getSessionAuthToken();
			const status = window.location.pathname.split('/')[2] === 'sold' ? 'SOLD' : 'SALE_REGISTERED';
			getSellingProductsApi(
				propertyDirection,
				page,
				authToken,
				selectedBrandList,
				selectedModelFilter,
				searchReference,
				selectedMinCaseDiameters,
				selectedMaxCaseDiameters,
				selectedMovement,
				selectedSupplements,
				selectedGender,
				selectedCaseMaterial,
				selectedDialColor,
				selectedDialType,
				selectedStoreIdList,
				producedYearMinInput,
				producedYearMaxInput,
				conditionStart,
				conditionEnd,
				status,
				statusBox,
			).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					setCurrentSellingProducts(data.content || []);
					setCurrentSellingInfo(data);
				} else {
					alert(detail);
				}
			});
		}

	function getSellingProducts() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		const status = window.location.pathname.split('/')[2] === 'sold' ? 'SOLD' : 'SALE_REGISTERED';
		// const decodeBrand =
		// 	window.location.pathname?.split('/')[3]?.length > 0
		// 		? decodeURIComponent(window.location.pathname.split('/')[3])
		// 		: '';
		// const urlBrandArray =
		// 	window.location.pathname.split('/')[3]?.length > 0
		// 		? [...selectedBrandList, decodeBrand]
		// 		: selectedBrandList;
		getSellingProductsApi(
			propertyDirection,
			page,
			authToken,
			selectedBrandList,
			selectedModelFilter,
			searchReference,
			selectedMinCaseDiameters,
			selectedMaxCaseDiameters,
			selectedMovement,
			selectedSupplements,
			selectedGender,
			selectedCaseMaterial,
			selectedDialColor,
			selectedDialType,
			selectedStoreIdList,
			producedYearMinInput,
			producedYearMaxInput,
			conditionStart,
			conditionEnd,
			status,
			statusBox,
		).then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				setCurrentSellingProducts(data.content || []);
				setCurrentSellingInfo(data);
				setPage(0);
			} else {
				alert(detail);
			}
		});
	}
	function getRecentlyProducts() {
		const buyFiltersNum = localStorage.getItem('buyFilters');
		if (buyFiltersNum) {
			let makeArray = buyFiltersNum.split(',');
			getRecentlyProductsApi(makeArray).then(({ data: { data, status, detail } }) => {
				if (status === 'SUCCESS') {
					setRecentlyProducts(data.content || []);
				} else {
					alert(detail);
				}
			});
		}
	}

	function searchModelList() {
		searchModelListApi(searchModelInput).then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setModelResultBox(data || []);
			} else {
				alert(detail);
			}
		});
	}

	function getStoreList() {
		getStoreListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setCurrentStoreList(data.content || []);
			} else {
				alert(detail);
			}
		});
	}

	function getFilterList() {
		const authToken = CommonHelper.getAuthToken()
			? CommonHelper.getAuthToken()
			: CommonHelper.getSessionAuthToken();
		getFilterLisetApi(authToken).then(({ data: { data, status, detail } }) => {
			if (status === 'SUCCESS') {
				// setFilterFirst()
				// setFilterSecond()
				let findUseFilter = data.filter(
					(el) =>
						el.key === 'BRAND' ||
						el.key === 'MODEL' ||
						el.key === 'CONDITION' ||
						el.key === 'PRODUCED_YEAR' ||
						el.key === 'GENDER' ||
						el.key === 'CASE_DIAMETER' ||
						el.key === 'MOVEMENT' ||
						el.key === 'SUPPLEMENTS' ||
						el.key === 'CASE_MATERIAL' ||
						el.key === 'REFERENCE' ||
						el.key === 'DIAL_COLOR' ||
						el.key === 'DIAL_TYPE' ||
						el.key === 'STORE',
				);
				let reference = findUseFilter.splice(10,1);
				findUseFilter.splice(1, 0, reference[0]);
				
				const newFilterData = findUseFilter.map((value) =>
					userData?.data?.favoriteFilters?.includes(value.key)
						? { ...value, favorite: true }
						: { ...value },
				);
				newFilterData.sort(function (x, y) {
					return x.favorite === y.favorite ? 0 : x.favorite ? -1 : 1;
				});
				const firstFilterList = newFilterData.slice(0, 6);
				const secondFilterList = newFilterData.slice(6);
				setFilterList(newFilterData);
				setFilterFirst(firstFilterList);
				setFilterSecond(secondFilterList);
			} else {
				alert(detail);
			}
		});
	}

	function getSiteInfo() {
		getSiteInfoApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setSiteInfoBox(data);
			} else {
				alert(detail);
			}
		});
	}
};
export default BuyFilterProduct;

const BrandFilterDetail = styled.div`
	width: ${FILTER_WIDTH['BRAND']};
	left: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === 'BRAND') < 0
			? BRAND_FILTER_LEFT[props.filterSecond.findIndex((el) => el.key === 'BRAND')]
			: BRAND_FILTER_LEFT[props.filterFirst.findIndex((el) => el.key === 'BRAND')]};
	top: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === 'BRAND') < 0 ? '129px' : '84px'};
`;

const ModelFilterDetail = styled.div`
	width: ${FILTER_WIDTH['MODEL']};
	left: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === 'MODEL') < 0
			? MODEL_FILTER_LEFT[props.filterSecond.findIndex((el) => el.key === 'MODEL')]
			: MODEL_FILTER_LEFT[props.filterFirst.findIndex((el) => el.key === 'MODEL')]};
	top: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === 'MODEL') < 0 ? '129px' : '84px'};
`;

const NormalFilterDetail = styled.div`
	min-width: 205px;
	width: ${(props) => FILTER_WIDTH[props.selectedFilterForDetail]};
	left: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === props.selectedFilterForDetail) < 0
			? NORMAL_FILTER_LEFT[
					props.filterSecond.findIndex((el) => el.key === props.selectedFilterForDetail)
			  ]
			: NORMAL_FILTER_LEFT[
					props.filterFirst.findIndex((el) => el.key === props.selectedFilterForDetail)
			  ]};
	top: ${(props) =>
		props.filterFirst.findIndex((el) => el.key === props.selectedFilterForDetail) < 0
			? '129px'
			: '84px'};
`;

const ConditionSliderContainer = styled.div`
	width: 100%;
	position: relative;
`;

const ConditionSliderInput = styled.input`
	/* background: linear-gradient(to right, black 0%, black 50%, #fff 50%, #fff 100%); */
	background: ${(props) =>
		`linear-gradient(to right, black 0% ${props.value}0%, white ${props.value}0% 100%)`};
	border: solid 1px gray;
	border-radius: 8px;
	height: 11px;
	width: 100%;
	outline: none;
	transition: background 450ms ease-in;
	cursor: pointer;

	-webkit-appearance: none;

	::-webkit-slider-thumb {
		background: url(${polygonIcoUp}) no-repeat 0 0/90%;
		/* border: solid 12px gold; */
		border-radius: 0;

		height: 20px;
		width: 20px;
		border-radius: 50%;
		-webkit-appearance: none;
		left: ${(props) => `${props.value}0%`};
		top: 17px;
		outline: none;
		margin-left: -9px;
		position: absolute;

		cursor: pointer;
	}
	/* -webkit-appearance: none;
	width: 100%;
	height: 15px;
	border-radius: 5px;
	background: rgba(170, 180, 196, 0.2);
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	height: 8px;
	::-webkit-slider-runnable-track {
		height: 10px;
		-webkit-appearance: none;
		color: blue;
		margin-top: -1px;
	}

	::-webkit-slider-thumb {
		-webkit-appearance: none; */
	/* width: 50px;
		height: 50px;
		border: 0 solid #000;
		top: 10px;
		outline: none;
		margin-left: -7px; */
	/* visibility: hidden; */
	/* top: 5px; */
	/* position: absolute; */
	/* background: url(${polygonIcoUp}) no-repeat 0 0/90%;
		width: 18px;
		height: 30px;
		border: 0 solid #000;
		left:${(props) => (props.value ? `${props.value}0%` : 0)};
		top: 17px;
		outline: none;
		margin-left: -7px;
		position: absolute;

		cursor: pointer;
	}
	:hover {
		cursor: pointer;
	} */
`;

const PolygonDiv = styled.div`
	background: url(${polygonIcoUp}) no-repeat 0 0/90%;
	width: 14px;
	height: 12px;
	border: 0 solid #000;
	top: 17px;
	outline: none;
	margin-left: -7px;
	position: absolute;
`;

const ColoredForConditionSlider = styled.div`
	background-color: #31383f;
	position: absolute;
	height: 8px;
	top: 7px;
	border-top-left-radius: 3px;
	border-bottom-right-radius: 3px;
	touch-action: none;
	outline: none;
	border-top-right-radius: 3px;
	border-bottom-left-radius: 3px;
`;

const NumberDivForCondition = styled.div`
	display: block;
	position: absolute;
	bottom: -20px;
	top: 31px;
	left: 50%;
	text-align: center;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 30px;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #343a40;
	font-family: Arial, Helvetica, sans-serif;
`;
