import React from 'react';
import { Route } from 'react-router-dom';

const BuyRoute = ({ component: Component, render, ...rest }) => {
	return (
		<Route
			{...rest}
			component={() => {
				window.location.href = 'https://hisigan.co.kr/user/main/getSale.do?filter=N';
				// return null;
			}}
		/>
	);
};

export default BuyRoute;
