import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { getDeadlineAuctionListApi } from 'apis/auctionMain';

const AuctionSlider = () => {
	const [deadlineAuctions, setDeadlineAuctions] = useState([]);
	const history = useHistory();
	const [testState, setTestState] = useState(false);

	useEffect(() => {
		getDeadlineAuctionList();
	}, []);
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: deadlineAuctions?.length < 3 ? deadlineAuctions?.length : 3,
		slidesToScroll: deadlineAuctions?.length < 3 ? deadlineAuctions?.length : 3,
		autoplay: true,
		speed: 500,
		autoplaySpeed: 3000,
		arrows: true,
		appendDots: (dots) => (
			<div
				style={{
					borderRadius: '10px',
					bottom: '-45px',
				}}
			>
				<ul style={{ margin: '0px' }}> {dots} </ul>
			</div>
		),

		customPaging: (i) => <div className="auction-swiper-pagination-bullet"></div>,
	};

	const goToAuctionDetailPage = (id) => {
		history.push(`/auction/${id}`);
	};

	return (
		<>
			{deadlineAuctions.length === 0 ? (
				<section className="most_viewed deadline-wrapper deadline_height">
					<h2>
						마감 임박 하이드로우
						<div className="deadline_none">마감 임박 하이드로우가 없습니다.</div>
					</h2>
				</section>
			) : (
				<SliderWripper deadlineAuctions={deadlineAuctions}>
					<section className="most_viewed deadline-wrapper">
						<h2>마감 임박 하이드로우</h2>
						<div className="auction-swiper-container">
							<div className="auction-swiper-wrapper">
								<Slider {...settings}>
									{deadlineAuctions?.map((auction, idx) => (
										<SliderDiv key={auction.id}>
											<div
												className="auction-swiper-slide auction-swiper-slide-plus"
												onClick={() => goToAuctionDetailPage(auction.id)}
											>
												<div className="product_info product_info_plus">
													<p className="watch_name">
														{auction?.watch?.brand || '-'} {auction?.watch?.model || '-'}
													</p>
													<p className={`end 마감임박-${auction?.id} plus-text`}>
														{initTimer(auction?.endDate, `마감임박-${auction?.id}`)}
													</p>
													{/* <p className="join_num">참여자수:{auction?.orderPeopleCount || 0}</p> */}
													<p className="fluctuations">
														<span>-</span>
													</p>
													<p className="option option_plus">
														{auction?.watch?.supplements?.includes('GUARANTEE') && (
															<img
																src={warrantyIco}
																alt="보증서"
																title="보증서가 있는 상품입니다."
															/>
														)}
														{auction?.watch?.supplements?.includes('BOX') && (
															<img
																src={boxIco}
																alt="정품박스"
																title="정품박스가 있는 상품입니다."
															/>
														)}
													</p>
													<ul>
														<li className="auction_slider_info_s">
															<span>구매년도</span>
															<b>{auction?.watch?.producedYear || '-'}</b>
														</li>
														<li className="auction_slider_info_s">
															<span>컨디션</span>
															<b>{auction?.watch?.condition || '0'}</b>
														</li>
														<li className="auction_slider_info_l reference">
															<span>상품넘버</span>
															<b className="reference">{auction?.watch?.serialNo || '-'}</b>
														</li>
													</ul>
												</div>
												<img
													src={auction?.attachedFiles ? auction.attachedFiles[0] : buyImgWatch}
													alt=""
													className="slider_img_size"
												/>
											</div>
										</SliderDiv>
									))}
								</Slider>
							</div>
							{/* <!-- Add Pagination --> */}
							{/* <div className="swiper-pagination-bar"></div> */}
						</div>
					</section>
				</SliderWripper>
			)}
		</>
	);

	function Lpad(str, len) {
		str = str + '';
		while (str.length < len) {
			str = '0' + str;
		}
		return str;
	}
	/**
	 * useTimer가 있지만 따로 쓰는 이유 = slider 라이브러리에서 clone하여 요소를 여러개 민드므로 getElementsByClassName으로 핸들링하기 위해서
	 * @param {*} endTime
	 * @param {*} name
	 */
	function initTimer(endTime, name) {
		let getElement;
		let getElements = document.getElementsByClassName(name);
		if (getElements.length < 3) {
			getElement = getElements[0];
		} else {
			getElement = getElements[1];
		}
		if (endTime && name && getElement) {
			function msg_timer(id) {
				let stDate = new Date().getTime();
				let endDate = new Date(endTime).getTime();
				let remainDate = endDate - stDate;
				let days = Math.floor(remainDate / (1000 * 60 * 60 * 24));

				let hours = Math.floor((remainDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((remainDate % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((remainDate % (1000 * 60)) / 1000);

				let m = `${days}일 ${Lpad(hours, 2)}:${Lpad(minutes, 2)}:${Lpad(seconds, 2)}이후 마감`; // 남은 시간 text형태로 변경

				getElement.innerHTML = m; // div 영역에 보여줌

				if (remainDate < 0) {
					// 시간이 종료 되었으면..
					clearInterval(tid); // 타이머 해제
				} else {
					remainDate = remainDate - 1000; // 남은시간 -1초
				}
			}
			let tid = setInterval(msg_timer, 1000);
		}
	}

	function getDeadlineAuctionList() {
		getDeadlineAuctionListApi().then(({ data: { data, detail, status } }) => {
			if (status === 'SUCCESS') {
				setDeadlineAuctions(data);
				setTestState(!testState);
			} else {
				alert(detail);
			}
		});
	}
};

export default AuctionSlider;

const SliderWripper = styled('div')`
	.slick-list {
		overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
		width: ${(props) =>
			props.deadlineAuctions?.length === 1
				? '385px'
				: props.deadlineAuctions?.length === 2
				? '800px'
				: ''};
	}
	/* Slider */
	.slick-slider {
		margin-bottom: 8px;

		position: relative;

		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					display: flex;
				`;
			}
		}}
		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					align-items: center;
				`;
			}
		}}
		${(props) => {
			if (props.deadlineAuctions?.length < 3) {
				return css`
					flex-direction: column;
				`;
			}
		}}
		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;

		display: block;
		/* overflow: hidden; */
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;
		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/* Arrows */
	.slick-prev,
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover,
	.slick-prev:focus,
	.slick-next:hover,
	.slick-next:focus {
		color: transparent;
		outline: none;
		background: transparent;
	}
	.slick-prev:hover:before,
	.slick-prev:focus:before,
	.slick-next:hover:before,
	.slick-next:focus:before {
		opacity: 1;
	}
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
	}

	.slick-prev:before,
	.slick-next:before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		width: 60px;
		opacity: 0.75;
		color: gray;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
		left: -66px;
	}
	[dir='rtl'] .slick-prev {
		right: -25px;
		left: auto;
	}
	.slick-prev:before {
		content: '←';
	}
	[dir='rtl'] .slick-prev:before {
		content: '→';
	}

	.slick-next {
		right: -60px;
	}
	[dir='rtl'] .slick-next {
		right: auto;
		left: -25px;
	}
	.slick-next:before {
		content: '→';
	}
	[dir='rtl'] .slick-next:before {
		content: '←';
	}

	/* Dots */
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: -25px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 2px;
		margin: 0 6px;
		padding: 0;
		bottom: 0;
		cursor: pointer;
		transition: width 0.3s ease-in-out;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 2px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		font-family: 'slick';
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 2px;
		content: '•';
		text-align: center;
		opacity: 0.25;
		color: black;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-dots li.slick-active button:before {
		opacity: 0.75;
		color: black;
	}

	/* Custom Slick Dots */
	.slick-dots li {
		width: 20px;
		height: 2 px;
		margin: 0 5px;
	}

	.slick-dots .slick-active {
		width: 20px;
		height: 2px;
		background: #31383f;
		border-radius: 0;
		opacity: 1;
		width: 20px;
		top: 0px;
		overflow: hidden;
	}
`;

const SliderDiv = styled.div`
	margin-right: 15px;
	position: relative;
	/* width:390px;
	height:250px; */
`;
