import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'context/AppContext';
import warrantyIco from 'assets-pc/img/common/ico_warranty.svg';
import boxIco from 'assets-pc/img/common/ico_box.svg';
import buyImgWatch from 'assets-pc/img/common/buy_img_watch.png';
import buyImgWatch2 from 'assets-pc/img/common/buy_img_watch2.png';
import sale2Img from 'assets-pc/img/common/img_sale2.png';
import crossIco from 'assets-pc/img/common/ico_cross.svg';
import resetIco from 'assets-pc/img/common/ico_reset.svg';
import searchWatchIco from 'assets-pc/img/common/ico_search_watch.svg';
import BuySlider from './components/BuySlider';
import BuyFilterProduct from './components/BuyFilterProduct';


const Buy = () => {
	const [loginPopup, setLoginPopup] = useState(false);
	
	const {
		user: { data: userData, loggedIn },
	} = useAppState();

	const onClickButton = () => {
		if (userData.userType !== 'VISITOR') {
			//popup을 열어준다던가 api를 호출한다던가 다음 step
			setLoginPopup(true);
		} else {
			//구매예약을 할 수 있게 다음 step
		}
	};

	return (
		<>
			<div className="container buy">
				<BuySlider />
				<section className="product_list">
					<BuyFilterProduct />
				</section>
				
			</div>
		</>
	);
};
export default Buy;
